import { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pencil } from "react-flaticons";
import { updateUserProfile } from "../../../reduxStore/operations/userAPI";
import { Form, Input, Button } from "antd";

const User = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [dirty, setDirty] = useState(false);

  // Use a ref to store the original form values once the user is loaded.
  const initialFormValues = useRef(null);

  useEffect(() => {
    if (user) {
      initialFormValues.current = {
        name: user.name,
        company: user.company?.company_name || "",
      };
    }
  }, [user]);

  // Reset the form and clear the dirty flag.
  const handleDiscard = () => {
    form.resetFields();
    setDirty(false);
  };

  const handleUpdate = async (values) => {
    if (values.name.trim() === "" || values.company_name.trim()) {
      return;
    }
    setLoading(true);
    await dispatch(
      updateUserProfile({
        name: values.name,
        company_name: values.company,
      })
    );
    setLoading(false);
    // Update the original values to the new ones after a successful update.
    initialFormValues.current = {
      name: values.name,
      company: values.company,
    };
    setDirty(false);
  };

  // Compare current values to original values.
  const handleValuesChange = (_, allValues) => {
    if (!initialFormValues.current) return;
    const original = initialFormValues.current;
    const isDirty =
      allValues.name !== original.name ||
      allValues.company !== original.company;
    setDirty(isDirty);
  };

  if (!user) {
    return (
      <div className="text-center text-gray-700">Loading user details...</div>
    );
  }

  return (
    <div className="flex items-start justify-start">
      <div className="py-4 w-[400px]">
        <Form
          form={form}
          layout="vertical"
          onFinish={handleUpdate}
          onValuesChange={handleValuesChange}
          initialValues={{
            name: user.name,
            company: user.company?.company_name,
          }}
        >
          <Form.Item
            label={<span className="text-primary">Name</span>}
            name="name"
          >
            <Input className="" placeholder="Enter your name" />
          </Form.Item>
          <Form.Item label={<span className=" text-primary ">Email</span>}>
            <Input value={user.email} disabled className="bg-gray-200" />
          </Form.Item>
          <Form.Item
            label={<span className="text-primary">Company</span>}
            name="company"
          >
            <Input className="" placeholder="Enter your company" />
          </Form.Item>
          <Form.Item>
            <div className="flex justify-start gap-6">
              <Button type="primary" htmlType="submit" loading={loading}>
                Update
              </Button>
              {dirty && (
                <Button
                  className="font-bold text-primary"
                  onClick={handleDiscard}
                  disabled={loading}
                >
                  Discard
                </Button>
              )}
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default User;
