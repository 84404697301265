import React, { useEffect, useState } from "react";
import Topbar2 from "../../components/core/topbar2/Topbar2";
import IconWrapper from "../../components/common/IconWrapper";
import { fieldIconMapping } from "utils";
import Sidebar from "../../components/core/Sidebar";
import { Cube, Loading, Minus, RecordVinyl, Tags, User } from "react-flaticons";
import { RiProgress5Line } from "react-icons/ri";
import Features2Component from "../../components/features2/features2Component";
import { useDispatch, useSelector } from "react-redux";
import {
  setFeatureGroupByOption2,
  setFeatureSortOption2,
} from "../../reduxStore/slices/feature2Slice";
import { getAllFeatures } from "../../reduxStore/operations/features2API";
import { useLocation } from "react-router-dom";

const Features2 = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const urlViewType = searchParams.get("viewType");
  const view = searchParams.get("view");
  const { personalViews } = useSelector((state) => state.personalViews);

  const viewDetails = personalViews.find((v) => v._id === view);

  const {
    features,
    groupByOption,
    sortOption,
    selectedColumns,
    viewType,
    isLoading,
    page,
  } = useSelector((state) => state.features2);

  const dispatch = useDispatch();

  useEffect(() => {
    if (view) {
      // Only dispatch if viewDetails is available
      if (viewDetails) {
        dispatch(
          getAllFeatures(
            undefined,
            undefined,
            undefined,
            undefined,
            view,
            viewDetails
          )
        );
      }
    } else {
      dispatch(getAllFeatures(undefined, undefined,  groupByOption));
    }
  }, [view, viewDetails, dispatch]); // Added dependencies

  const groupByDropdownOptions = [
    {
      value: "default",
      fixedLabel: "GroupBy",
      label: (
        <span className="flex gap-5 items-center justify-start">
          <Minus size={12} />
          Default
        </span>
      ),
    },
    {
      value: "status",
      fixedLabel: "GroupBy",
      label: (
        <span className="flex gap-4 items-center justify-start">
          <RiProgress5Line />
          Status
        </span>
      ),
    },
    {
      value: "release",
      fixedLabel: "GroupBy",
      label: (
        <span className="flex gap-4 items-center justify-start">
          <Loading size={14} />
          Release
        </span>
      ),
    },
    {
      value: "product",
      fixedLabel: "GroupBy",
      label: (
        <span className="flex gap-4 items-center justify-start">
          <Cube size={14} />
          Product
        </span>
      ),
    },
    {
      value: "tags",
      fixedLabel: "GroupBy",
      label: (
        <span className="flex gap-4 items-center justify-start">
          <Tags size={14} />
          Tags
        </span>
      ),
    },
    {
      value: "assignee",
      fixedLabel: "GroupBy",
      label: (
        <span className="flex gap-4 items-center justify-start">
          <User size={14} />
          Assignee
        </span>
      ),
    },
    {
      value: "objective",
      fixedLabel: "GroupBy",
      label: (
        <span className="flex gap-4 items-center justify-start">
          <RecordVinyl size={14} />
          Objective
        </span>
      ),
    },
  ];

  const sortOptions = [
    {
      value: "createdAt",
      label: (
        <span className="flex gap-4 items-center justify-start">
          {/* {React.createElement(Minus, { size: 12 })} */}
          Created
        </span>
      ),
      fixedLabel: "Created",
    },
    {
      value: "status",
      label: (
        <span className="flex gap-4 items-center justify-start">
          {/* {React.createElement(fieldIconMapping.status, { size: 14 })} */}
          Status
        </span>
      ),
      fixedLabel: "Status",
    },
    {
      value: "release",
      label: (
        <span className="flex gap-4 items-center justify-start">
          {/* {React.createElement(fieldIconMapping.release, { size: 14 })} */}
          Release
        </span>
      ),
      fixedLabel: "Release",
    },
    {
      value: "product",
      label: (
        <span className="flex gap-4 items-center justify-start">
          {/* {React.createElement(fieldIconMapping.product, { size: 14 })} */}
          Product
        </span>
      ),
      fixedLabel: "Product",
    },
    {
      value: "assignee",
      label: (
        <span className="flex gap-4 items-center justify-start">
          {/* {React.createElement(fieldIconMapping.assignee, { size: 14 })} */}
          Assignee
        </span>
      ),
      fixedLabel: "Assignee",
    },
    {
      value: "score",
      label: (
        <span className="flex gap-4 items-center justify-start">
          {/* {React.createElement(fieldIconMapping.score, { size: 14 })} */}
          Score
        </span>
      ),
      fixedLabel: "Score",
    },
  ];

  const handleGroupByChange = (value) => {
    dispatch(setFeatureGroupByOption2(value));
    dispatch(getAllFeatures(undefined, undefined, value, groupByOption));
    // Add any additional logic for when the selection changes
  };

  const handleSortByChange = (value) => {
    let sortOrder = "asc";

    if (value === sortOption.field) {
      if (sortOption.order === "asc") {
        sortOrder = "desc";
      }
    }
    dispatch(setFeatureSortOption2({ order: sortOrder, field: value }));
    dispatch(
      getAllFeatures(undefined, undefined, groupByOption, {
        field: value,
        order: sortOrder,
      })
    );

    // dispatch(setFeatureGroupByOption2(value));
    // dispatch(getAllFeatures(undefined, undefined, value));
    // Add any additional logic for when the selection changes
  };

  const handlePagination = () => {
    console.log(Math.ceil(features[0].featureCount / 30));
    // console.log(features);
    if (Math.ceil(features[0].featureCount / 30)<=page) {
      return
    }
    if (view) {
      // Only dispatch if viewDetails is available
      if (viewDetails) {
        dispatch(
          getAllFeatures(
            undefined,
            undefined,
            undefined,
            undefined,
            view,
            viewDetails
          )
        );
      }
    } else {
      dispatch(
        getAllFeatures(
          page + 1,
          undefined,
          groupByOption,
          sortOption,
          undefined,
          undefined,
          true
        )
      );
    }
  };

  return (
    <div className="flex font-inter w-screen h-screen   tracking-wide cursor-default">
      <Sidebar />

      <div className="flex flex-col min-w-[80%] w-[100%]  ">
        <Topbar2
          title={viewDetails ? viewDetails.label : "Feature2"}
          titleIcon={
            <IconWrapper
              icon={React.createElement(fieldIconMapping.feature)}
              iconColor="text-features-icon"
            />
          }
          groupByDropdownOptions={groupByDropdownOptions}
          handleGroupByChange={handleGroupByChange}
          groupBy={groupByOption}
          sortOptions={sortOptions}
          handleSortByChange={handleSortByChange}
          sortOption={sortOption}
        />
        <Features2Component handlePagination={handlePagination} />
      </div>
    </div>
  );
};

export default Features2;
