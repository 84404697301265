import React from "react";
import SettingsTopbar from "../SettingsTopbar";
import { ChartNetwork } from "react-flaticons";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { LuSquareArrowOutUpRight } from "react-icons/lu";

const Zapier = () => {
  return (
    <div className="w-full flex justify-center  ">
      <div>
        <SettingsTopbar
          //   icon={<ChartNetwork />}
          title="Zapier"
          description="Connect Lane with 1,000+ apps to automate workflows and sync records effortlessly."
          //   crumb={{ name: "Slack", path: "/settings/integrations/zapier" }}
          isBack={true}
        />
        <div className=" flex flex-col gap-2 max-w-[40rem] mx-auto text-title_50  pt-2 pb-10 ">
          {/* <h2 className="text-[16px] font-bold text-title mb-1">
            Use Zapier to connect Lane with hundreds of apps effortlessly.
          </h2> */}
          <p className="text-sm  mb-2">
            Use Zapier to connect Lane with hundreds of apps effortlessly.
            Zapier offers a no-code automation solution, making it easy to set
            up without technical expertise.
          </p>

          <div className="mb-5 space-y-2">
            <p className="text-sm  ">
              Start by adding Lane to your Zapier account to start automating
              workflows{" "}
            </p>
            <Button
              type="primary"
              // icon={<LuSquareArrowOutUpRight />}
              onClick={() =>
                window.open(
                  " https://zapier.com/apps/lane/integrations",
                  "_blank"
                )
              }
            >
              Create Zap with Lane <LuSquareArrowOutUpRight />
            </Button>
          </div>

          <h3 className="text-[15px] font-bold text-title mb-1 pt-20   ">
            How It Works
          </h3>
          <div className="space-y-2">
            <div>
              <h4 className="text-sm font-semibold text-gray-800 mb-0.5">
                1. Connect with Your API Key:
              </h4>
              <p className="text-sm ">
                Enter your Lane API key in Zapier to securely connect your
                account.
                <Link
                  to={"/settings/api"}
                  className=" text-button-active hover:underline mx-1 "
                >
                  Create an API key
                </Link>
                if you haven't already.
              </p>
            </div>
            <div>
              <h4 className="text-sm font-semibold text-gray-800 mb-0.5">
                2. Build Your Zap:
              </h4>
              <p className="text-sm ">
                Once connected, choose from Lane triggers and actions to create
                automated workflows. For example, automatically create or sync
                records when events occur in other apps.
              </p>
            </div>
            <div>
              <h4 className="text-sm font-semibold text-gray-800 mb-0.5">
                3. Automate and Sync:
              </h4>
              <p className="text-sm ">
                With your Zap set up, Lane will automatically process the
                data—creating records as specified in your workflow.
              </p>
            </div>
          </div>

          <p className="text-sm  mt-2 pb-6 ">
            This streamlined process lets you integrate Lane with thousands of
            apps, enhancing your productivity through automation.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Zapier;
