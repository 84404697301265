import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AngleSmallDown,
  Interrogation,
  Search,
  Settings,
  SignOutAlt,
  User,
  Users,
} from "react-flaticons";
import DemoProfileImage from "assets/demo_profile_image.webp";
import IconText from "components/common/IconText";
import AddNewPopupOverlay from "components/common/AddNewPopupOverlay";
import DropdownItem from "components/common/DropdownItem";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { fieldIconMapping, truncateText } from "utils";
import { createFeature } from "reduxStore/operations/featuresAPI";
import { createObjective } from "reduxStore/operations/objectivesAPI";
import { createIdea } from "reduxStore/operations/ideasAPI";
import { logout } from "reduxStore/operations/authAPI";
import { setLoading } from "reduxStore/slices/authSlice";
import { createRoadmap } from "reduxStore/operations/roadmapsAPI";
import { setIdeaViewType } from "reduxStore/slices/ideaSlice";
import { setFeatureViewType } from "reduxStore/slices/featureSlice";
import { setObjectiveViewType } from "reduxStore/slices/objectiveSlice";
import PersonalView from "./PersonalView";
import { resetActiveIds } from "../../utils/resetActiveIds";
import SearchDropdown from "./sidebar_component/SearchDropdown";
import FormOverlay from "./sidebar_component/FormOverlay";
import { NameInitialsAvatar } from "react-name-initials-avatar";
import { savePreviousUrl } from "../../reduxStore/operations/previousUrlOperations";
import PersonalViewsList from "./sidebar_component/spaces";
import FavoriteViews from "./sidebar_component/FavoriteViews";
import { setSelectedIdeaFilter } from "../../reduxStore/slices/selectedIdeaFilterOptionSlice";
import { loadUsetifulScript, setUsetifulTags } from "usetiful-sdk";
import { Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { PiPlugs } from "react-icons/pi";
import { setFeatureGroupByOption2, setFeatureSortOption2 } from "../../reduxStore/slices/feature2Slice";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const handleClick = () => {
    dispatch(savePreviousUrl(location.pathname + location.search)); // Save current URL
  };
  const { personalViews } = useSelector((state) => state.personalViews);
  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    if (user) {
      loadUsetifulScript("ec856d16157e3a05982fe799c0a04ec5"); // This contains your unique token key.
      setUsetifulTags({
        userId: user._id, // This identifies the user and should be unique. Change it.
        firstName: user.name, // This property defines user’s first name. Change it.
        lastName: "", // This property defines user’s last name. Change it.
      });
    }
  }, [user]);

  const addDropdownMenuOptions = [
    {
      icon: fieldIconMapping.idea,
      iconColor: "#EDB900",
      label: "Idea",
      addOptions: ["status", "importance", "product", "accounts"],
      onAddClick: ({
        title,
        status,
        product,
        importance,
        type,
        accounts,
        score,
      }) => {
        dispatch(
          createIdea(title, status, product, importance, type, accounts, score)
        );
        setSelectedOption(null);
      },
    },
    {
      icon: fieldIconMapping.feature,
      iconColor: "#3380F4",
      label: "Feature",
      addOptions: ["product", "status", "assignee", "release"],
      onAddClick: ({ title, status, release, product, score }) => {
        dispatch(createFeature(title, status, release, product, score));
        setSelectedOption(null);
      },
    },
    {
      icon: fieldIconMapping.objective,
      iconColor: "#F43379",
      label: "Objective",
      addOptions: ["status", "timeFrame", "type", "assignee"],
      onAddClick: ({ title, timeFrame, type, status }) => {
        dispatch(createObjective(title, timeFrame, type, status));
        setSelectedOption(null);
      },
    },
    {
      icon: fieldIconMapping.roadmap,
      label: "Roadmap",
      addOptions: ["status", "forRoadmap", "products", "assignee"],
      onAddClick: ({
        title,
        forRoadmap,
        products,
        releases,
        timeFrames,
        manualTimeFrame,
        status,
        assignee,
      }) => {
        dispatch(
          createRoadmap(
            title,
            forRoadmap,
            products,
            releases,
            timeFrames,
            manualTimeFrame,
            status,
            assignee
          )
        );
        setSelectedOption(null);
      },
    },
  ];

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isImageDropdownOpen, setIsImageDropdownOpen] = useState(false);
  const [isLogoDropdownOpen, setIsLogoDropdownOpen] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);
  const addDropdownRef = useRef(null);
  const imageDropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const handleOptionClick = (option) => {
    if (option.handleClick) {
      option.handleClick();
    } else {
      setSelectedOption(option);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        addDropdownRef.current &&
        !addDropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
      if (
        imageDropdownRef.current &&
        !imageDropdownRef.current.contains(event.target)
      ) {
        setIsImageDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const items = [
    {
      key: "1",
      label: (
        <div className=" space-y-2">
          <div className=" flex justify-start gap-2 ">
            <NameInitialsAvatar
              name={user?.name || "N"}
              size="21px"
              textSize="10px"
              borderWidth="1px"
              bgColor="#0A7Aff1A"
            />
            <span className="text-title font-bold text-sm">
              {user?.name || "Name"}
            </span>
          </div>
          <div className=" text-primary ">{user?.email}</div>
        </div>
      ),
      // disabled: true,

      onClick: (e) => {
        e.preventDefault();
      },
    },
    {
      type: "divider",
    },
    {
      key: "2",
      label: "Profile",
      onClick: () => {
        navigate("/settings/profile");
      },
      icon: <User size={14} />,
    },
    {
      key: "3",
      label: "Settings",
      icon: <Settings size={14} />,
      onClick: () => {
        navigate("/settings/customizations");
      },
    },
    {
      key: "4",
      label: "Integrations",
      icon: <PiPlugs size={14} />,
      onClick: () => {
        navigate("/settings/integrations");
      },
    },
    {
      key: "5",
      label: "Invite member",
      icon: <Users size={14} />,
      onClick: () => {
        navigate("/settings/members");
      },
    },
    {
      type: "divider",
    },
    {
      key: "6",
      label: "Sign Out",
      icon: <SignOutAlt size={14} />,
      onClick: () => {
        dispatch(setLoading(true));
        dispatch(logout(navigate));
      },
    },
  ];

  const handleOpenChange = (open) => {
    setIsLogoDropdownOpen(open);
  };

  return (
    <div className="flex flex-col min-w-[240px] max-w-[240px] bg-sidebar-background z-50">
      <div className="flex justify-between items-center px-4 bg-sidebar-background mt-5 sticky top-0">
        {/* <div className="flex gap-x-1.5 items-center">
          <img src="/lane_icon.svg" width={"25px"} />
          <span className="text-lg font-bold text-primary mr-2">
            {truncateText(user?.company?.company_name ?? "", 8)}
          </span>
        </div> */}
        <Dropdown
          trigger={["click"]}
          onOpenChange={handleOpenChange}
          // className="   hover:bg-topbar-button-hover"
          menu={{
            items,
          }}
        >
          <a
            className={`rounded-md px-1 transition-all duration-150  ${
              isLogoDropdownOpen
                ? "bg-topbar-button-hover"
                : "hover:bg-topbar-button-hover"
            }`}
            onClick={(e) => e.preventDefault()}
          >
            <Space>
              <div className="flex gap-x-1.5 items-center pt-1 ">
                <img src="/lane_icon.svg" width={"25px"} />
                <span className="text-lg font-bold text-primary">
                  {truncateText(user?.company?.company_name ?? "", 8)}
                </span>
              </div>

              <AngleSmallDown size={16} className=" mt-[6px] text-primary " />
              {/* <DownOutlined size={2} className="mt-1 text-primary " /> */}
            </Space>
          </a>
        </Dropdown>
      </div>
      <div className="flex flex-col h-full justify-between grow-0 custom-scrollbar   overflow-auto pt-7 pb-4 ">
        <div className="flex flex-col gap-y-1 px-2    ">
          {/* Logo and Expand Button */}
          <SearchDropdown />
          {/* <IconText
            icon={React.createElement(fieldIconMapping.insights)}
            text="Insights"
            py="py-1"
            isASidebarComponent={true}
          /> */}
          <IconText
            icon={React.createElement(fieldIconMapping.objective)}
            text="Objectives"
            py="py-1 mt-1"
            // iconColor="#F43379"
            isASidebarComponent={true}
            onClick={() => {
              resetActiveIds({ dispatch });
              dispatch(setObjectiveViewType("classiclist"));
              navigate("/objectives");
            }}
            addButton={true}
            option={addDropdownMenuOptions[2]}
            handleOptionClick={handleOptionClick}
            showPlus={true}
          />
          <IconText
            icon={React.createElement(fieldIconMapping.idea)}
            text="Ideas"
            py="py-1"
            // iconColor="#EDB900"
            isASidebarComponent={true}
            onClick={() => {
              resetActiveIds({ dispatch });
              dispatch(setIdeaViewType("modernlist"));
              dispatch(setSelectedIdeaFilter("idea"));
              navigate("/ideas?type=idea");
            }}
            addButton={true}
            option={addDropdownMenuOptions[0]}
            handleOptionClick={handleOptionClick}
            showPlus={true}
          />
          <IconText
            icon={React.createElement(fieldIconMapping.feature)}
            text="Features"
            // iconColor="#3380F4"
            py="py-1"
            isASidebarComponent={true}
            onClick={() => {
              resetActiveIds({ dispatch });
              dispatch(setFeatureViewType("classiclist"));
              navigate("/features");
            }}
            addButton={true}
            option={addDropdownMenuOptions[1]}
            handleOptionClick={handleOptionClick}
            showPlus={true}
          />
          {/* <IconText
            icon={React.createElement(fieldIconMapping.feature)}
            text="Features2"
            // iconColor="#3380F4"
            py="py-1"
            isASidebarComponent={true}
            onClick={() => {
              resetActiveIds({ dispatch });
              dispatch(setFeatureGroupByOption2("default"));
              dispatch(setFeatureSortOption2({ field: "createdAt", order: "asc" }));
              navigate("/features2");
            }}
            addButton={true}
            option={addDropdownMenuOptions[1]}
            handleOptionClick={handleOptionClick}
            showPlus={true}
          /> */}
          <IconText
            icon={React.createElement(fieldIconMapping.roadmap)}
            text="Roadmaps"
            py="py-1"
            isASidebarComponent={true}
            onClick={() => {
              resetActiveIds({ dispatch });
              navigate("/roadmaps");
            }}
            addButton={true}
            option={addDropdownMenuOptions[3]}
            handleOptionClick={handleOptionClick}
            showPlus={true}
          />
          {/* My Space Section */}
          <div className="mt-8"></div>
          <div className="text-[12px] text-primary mx-1.5">Favorites</div>
          <FavoriteViews />
          {/* <div className="mt-8"></div>
          <div className="text-[12px] text-primary mx-1.5">Records</div> */}

          {/* My Space Section */}
          <div className="mt-8"></div>
          <div className="text-[12px] text-primary mx-1.5">Spaces</div>
          <PersonalViewsList personalViews={personalViews} />
        </div>

        {/* Bottom Section */}
        {/* <div className="flex flex-col gap-y-1 px-2 mt-20">
          <Link to={"/settings/customizations"} onClick={handleClick}>
            <IconText icon={<Settings />} text="Settings" py="py-1" />
          </Link>
          <div
            className={`relative flex items-center gap-x-2 p-1 cursor-pointer   pl-1.5 rounded ${
              isImageDropdownOpen
                ? "bg-button-active_10"
                : "hover:bg-button-hover"
            }`}
            onClick={() => {
              setIsImageDropdownOpen(!isImageDropdownOpen);
            }}
            ref={imageDropdownRef}
          >
            <NameInitialsAvatar
              name={user?.name || "N"}
              size="20px"
              textSize="10px"
              borderWidth="1px"
              bgColor="#0A7Aff1A"
            />
            <span className="text-primary text-sm">{user?.name || "Name"}</span>
            {isImageDropdownOpen && (
              <div className="absolute bottom-full left-0 p-1 bg-white shadow border rounded">
                <DropdownItem
                  option={{ label: "Sign Out", icon: SignOutAlt }}
                  iconColor="text-primary"
                  iconSize={14}
                  gap_x="gap-x-2"
                  py="py-1"
                  px="px-1"
                  handleOptionClick={() => {
                    dispatch(setLoading(true));
                    dispatch(logout(navigate));
                  }}
                />
              </div>
            )}
          </div>
        </div> */}
      </div>

      {/* Popup Overlay */}
      {selectedOption && (
        <AddNewPopupOverlay
          option={selectedOption}
          onClose={() => setSelectedOption(null)}
        />
      )}
    </div>
  );
};

export default Sidebar;
