/* eslint-disable react/prop-types */
import {
  AngleSmallLeft,
  Home,
  PlusSmall,
  Settings,
  User,
} from "react-flaticons";
import Breadcrumb from "../common/Breadcrumb";
import AccountPopupOverlay from "components/common/addNewPopupOverlays/AccountPopupOverlay";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addNewAccount } from "../../reduxStore/operations/accountAPI";
import IconText from "../common/IconText";
import InviteUserPopup from "../common/InviteUserPopup";

import { Button, ConfigProvider, Divider, Tabs } from "antd";
import { Link, useLocation } from "react-router-dom";

const SettingsTopbar = ({
  tabs = null,
  activeTab,
  handleTabClick,
  customTab = "",
  isAccounts = false,
  isUsers = false,
  crumb,
  title = "",
  description = "",
  icon = null,
  defaultActiveKey = "",
  isBack = false,
  width = "w-[40rem]",
}) => {
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const dispatch = useDispatch();

  const location = useLocation();

  //For Settings=>Accounts=>Topbar=> Add Acount
  const handleAddAccount = (account) => {
    dispatch(addNewAccount(account));
  };

  //For Settings=>Users=>Topbar=> Invite user

  const crumbs = [
    { icon: Home, name: "Home", path: "/" },
    { icon: Settings, name: "Settings", path: "/settings" },
  ];
  if (crumb) {
    crumbs.push(crumb);
  }
  return (
    <div className={`${width}`}>
      <div className="flex w-full flex-col pt-12 h-fit text-gray-500 sticky top-0 bg-white z-40 text-sm     ">
        {/* <div className="flex w-full flex-col pt-12 h-fit border-b border-gray-200 shadow-sm text-gray-500 sticky top-0 bg-white z-40 text-sm     "> */}
        {/* <div className=" absolute top-0 left-0  ">
          <IconText
            icon={icon || <User />}
            iconSize={12}
            text={title}
            hoverable={false}
            isASidebarComponent={false}
            px="px-0"
            py="py-0"
            isTextActive={true}
            isIconActive={true}
          />
        </div> */}
        {/* <Breadcrumb crumbs={crumbs} /> */}

        <div className={`${isBack ? " pt-2 " : "pt-8"} space-y-2  `}>
          {isBack && (
            <Link
              // className="  flex justify-between items-center border-b border-white hover:border-gray-400 transition-all duration-200 w-fit "
              to={"/settings/integrations"}
            >
              {" "}
              <Button
                type="text"
                icon={<AngleSmallLeft size={18} className=" pr-0 mr-0" />}
                
                className=" px-1 gap-[2px] "
              >
                Back
              </Button>
              {/* <AngleSmallLeft size={18} className=" " /> Back */}
            </Link>
          )}
          <h2 className=" text-title  text-2xl ">{title}</h2>
          <p className=" text-sm"> {description} </p>
        </div>

        {tabs ? (
          <Tabs
            defaultActiveKey="profile"
            items={tabs}
            onChange={handleTabClick}
            className="mt-6 custom-tabs"
          />
        ) : (
          <div className=" pt-[10px] ">
            <Divider />
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsTopbar;

// <div className=" relative  flex mt-[60px] gap-x-8   ">
// {tabs?.map((tab, index) => (
//   <div key={index} className="pb-2">
//     <span
//       className={`text-sm cursor-pointer ${
//         activeTab.label === tab.label
//           ? "border-b-2 border-topbar-tab-active text-title_50  pb-2    font-[700]"
//           : "  "
//       }`}
//       onClick={() => handleTabClick(tab)}
//     >
//       {tab.label}
//     </span>
//   </div>
// ))}
// {customTab.length !== 0 && (
//   <h2 className="text-sm text-center cursor-pointer border-b-2 border-topbar-tab-active pb-[6px]   text-title_50 font-[500] ">
//     {customTab}
//   </h2>
// )}

// {/* This is for to add account on the topbar, "isAccounts" value send from file named SettingsCustomers.jsx " */}
// {isAddOpen && (
//   <AccountPopupOverlay
//     type="add"
//     onClose={() => setIsAddOpen(false)}
//     onSubmit={handleAddAccount}
//   />
// )}
// {isInviteOpen && (
//   <InviteUserPopup
//     onClose={() => setIsInviteOpen(false)}
//     onSubmit={handleInvite}
//   />
// )}
// {isAccounts && (
//   <button
//     onClick={() => setIsAddOpen(true)}
//     className=" absolute right-4 text-sm bottom-1 flex justify-center items-center gap-1 p-2 bg-blue-500 text-white  mb-2 ml-auto  rounded hover:bg-blue-600"
//   >
//     <span>
//       <PlusSmall size={20} />
//     </span>
//     <span>Add Customer</span>
//   </button>
// )}
// {isUsers && (
//   <button
//     onClick={() => setIsInviteOpen(true)}
//     className=" absolute right-4 text-sm bottom-1 flex justify-center items-center gap-1 p-2 bg-blue-500 text-white  mb-2 ml-auto  rounded hover:bg-blue-600"
//   >
//     <span>Invite user</span>
//   </button>
// )}
// </div>
