import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import SettingsTopbar from "../SettingsTopbar";
import { Outlet } from "react-router-dom";
import { toSnakeCase } from "utils";
import { ChartConnected } from "react-flaticons";
import { fieldIconMapping } from "../../../utils";

const SettingsCustomizations = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // const tabs = [
  //   { label: "Status" },
  //   { label: "Release" },
  //   { label: "Product" },
  //   { label: "Tags" },
  //   { label: "Time-frame" },
  // ];

  const tabs = [
    { key: "Status", label: "Status" },
    { key: "Release", label: "Release" },
    { key: "Product", label: "Product" },
    { key: "Tags", label: "Tags" },
    { key: "Time-frame", label: "Time-frame" },
  ];

  const getInitialTab = () => {
    const currentPath = location.pathname.split("/").pop();
    const foundTab = tabs.find((tab) => toSnakeCase(tab.key) === currentPath);
    return foundTab || tabs[0];
  };

  const [activeTab, setActiveTab] = useState(getInitialTab());

  const handleTabClick = (key) => {
    const foundTab = tabs.find((tab) => tab.key === key);
    if (foundTab) {
      setActiveTab(foundTab);
    }
  };

  useEffect(() => {
    if (activeTab.label === "Status") {
      navigate("");
    } else {
      navigate(`${toSnakeCase(activeTab.label)}`);
    }
  }, [activeTab, navigate]);

  return (
    <>
      <div className="relative w-full flex justify-center">
        <div>
          {" "}
          <SettingsTopbar
            title="Customizations"
            description="Customize your workspace to match your workflow"
            tabs={tabs}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />
          <div className=" pt-8  ">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsCustomizations;
