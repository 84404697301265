/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { AngleSmallDown, AngleSmallUp } from "react-flaticons";

import IconText from "components/common/IconText";
import Progress from "components/common/Progress";
import HealthRowSection from "components/common/classicList/HealthRowSection";
import DropdownMenu from "components/common/detailView/dropdown/DropdownMenu";
import StatusRowSection from "components/common/classicList/StatusRowSection";

import FeaturesRowComponent from "components/features/views/list/FeaturesRowComponent";
import IdeasRowComponent from "components/ideas/views/list/classic/IdeasRowComponent";

import { fieldIconMapping, toSnakeCase } from "utils";
import { objectiveHealths, getHealthBgColor } from "utils/objectiveUtils";
import { getKeyResultProgress } from "utils/progressUtils";
import { generateStatusArray } from "utils/status";

import { updateKeyResultData } from "reduxStore/operations/keyResultsAPI";

import {
  setActiveKeyResultId,
  setKeyResultShowDetailViewDrawer,
  setDetailKeyResults,
} from "reduxStore/slices/keyResultSlice";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const KeyResultRow = ({
  objective,
  objectiveKeyResults,
  keyResult,
  selectedColumns,
}) => {
  const { ideas } = useSelector((state) => state.ideas);
  const { features } = useSelector((state) => state.features);
  const [showInitiatives, setShowInitiatives] = useState(false);
  const statusIndex = selectedColumns.indexOf("status");
  const dispatch = useDispatch();
  const [dropdownState, setDropdownState] = useState({
    isStatusDropdownOpen: false,
    isHealthDropdownOpen: false,
  });
  const closeAllDropdowns = () => {
    setDropdownState({
      isStatusDropdownOpen: false,
      isHealthDropdownOpen: false,
    });
  };

  const keyResultIdeas = useMemo(() => {
    return (ideas ?? []).filter((idea) =>
      (keyResult?.ideas ?? []).includes(idea._id)
    );
  }, [ideas, keyResult?.ideas]);

  const keyResultFeatures = useMemo(() => {
    return (features ?? []).filter((feature) =>
      (keyResult?.features ?? []).includes(feature._id)
    );
  }, [features, keyResult?.features]);

  const keyResultProgress = getKeyResultProgress(
    keyResultIdeas,
    keyResultFeatures
  );

  // const { statuses } = useSelector((state) => state.company);
  // const [keyResultStatuses, setKeyResultStatuses] = useState([]);

  // useEffect(() => {
  //   setKeyResultStatuses(statuses.key_result);
  // }, [statuses]);

  // const statusArray = useMemo(
  //   () => generateStatusArray(keyResultStatuses),
  //   [keyResultStatuses]
  // );

  // const handleStatusOptionClick = (option) => {
  //   dispatch(
  //     updateKeyResultData(keyResult._id, { status: option._id }, objective._id)
  //   );
  //   closeAllDropdowns();
  // };

  const handleHealthOptionClick = (option) => {
    dispatch(
      updateKeyResultData(
        keyResult._id,
        { health: toSnakeCase(option.label) },
        objective._id
      )
    );
    closeAllDropdowns();
  };

  const dropdownRef = useRef(null);

  const toggleDropdown = useCallback((type) => {
    setDropdownState((prev) => {
      const newState = Object.keys(prev).reduce((acc, key) => {
        acc[key] = key === type ? !prev[key] : false;
        return acc;
      }, {});
      return newState;
    });
  }, []);

  // Handle clicks outside the component
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeAllDropdowns();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <tr
        className={`group text-xs text-title_50 cursor-pointer hover:bg-row-background relative`}
        onClick={() => {
          dispatch(setDetailKeyResults(objectiveKeyResults));
          dispatch(setActiveKeyResultId(keyResult._id));
          dispatch(setKeyResultShowDetailViewDrawer(true));
        }}
        ref={dropdownRef}
      >
        <td
          className={`flex py-1 pl-12 relative bg-white sticky top-0 left-0 z-20 group-hover:bg-row-background  group-hover:rounded-l-lg`}
        >
          {/* <div className={`w-full flex items-center justify-between pr-4`}>

            <IconText
              icon={React.createElement(fieldIconMapping.key_results)}
              text={keyResult?.title ?? ""}
              iconColor={"text-key_results-icon"}
              truncateLength={75}
              textColor="text-title_50"
              hoverable={false}
              px=""
              py="py-1.5"
            />
            {showInitiatives ? (
              <div
                className="p-1 rounded hover:bg-button-hover rounded"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowInitiatives(false);
                }}
              >
                <AngleSmallUp size={12} />
              </div>
            ) : (
              <div
                className="p-1 rounded hover:bg-button-hover rounded"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowInitiatives(true);
                }}
              >
                <AngleSmallDown size={12} />
              </div>
            )}
          </div> */}

          <div className="w-full flex items-center justify-between pr-4">
            <div className="relative group flex items-center">
              {/* Default Icon (Visible when not hovered and dropdown closed) */}
              <IconText
                icon={React.createElement(fieldIconMapping.key_results)}
                text={keyResult?.title ?? ""}
                iconColor={"text-key_results-icon group-hover:text-white "}
                truncateLength={75}
                textColor="text-title_50"
                hoverable={false}
                px=""
                py="py-1.5"
                // className={`transition-opacity duration-200 ${
                //   showInitiatives
                //     ? "opacity-0 pointer-events-none"
                //     : "opacity-100 group-hover:opacity-0 group-hover:pointer-events-none"
                // }`}
              />

              {/* Arrow Container (Visible on hover or when dropdown open) */}
              <div
                className={`absolute left-[-3px] flex items-center justify-center 
                opacity-0 group-hover:opacity-100
                `}
              >
                {/* <div
                className={`absolute left-[-3px] flex items-center justify-center transition-opacity duration-200 
                ${
                  showInitiatives
                    ? "opacity-100"
                    : "opacity-0   group-hover:opacity-100"
                }
                `}
              > */}
                {showInitiatives ? (
                  <div
                    className={`p-1 rounded hover:bg-button-hover  `}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowInitiatives(false);
                    }}
                  >
                    <IoIosArrowUp size={15} />
                  </div>
                ) : (
                  <div
                    className={`p-1 rounded bg-row-background  hover:bg-button-hover`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowInitiatives(true);
                    }}
                  >
                    <IoIosArrowDown size={15} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </td>
        <td className="max-w-44 truncate pr-1 px-3">
          {selectedColumns.includes("progress") && (
            <Progress
              value={keyResultProgress ?? 0}
              color={getHealthBgColor(keyResult?.health)}
            />
          )}
        </td>
        <td className="max-w-44 truncate pr-1 px-3">
          {selectedColumns.includes("health") && (
            <>
              <HealthRowSection
                health={keyResult?.health ?? ""}
                dropdownState={dropdownState}
                toggleDropdown={() => toggleDropdown("isHealthDropdownOpen")}
              />
              <DropdownMenu
                options={objectiveHealths() ?? []}
                isOpen={dropdownState.isHealthDropdownOpen}
                handleOptionClick={handleHealthOptionClick}
              />
            </>
          )}
        </td>
        {/* <td className="max-w-44 truncate pr-1 px-3">
          {selectedColumns.includes("status") && (
            <>
              <StatusRowSection
                status={keyResult?.status ?? {}}
                dropdownState={dropdownState}
                toggleDropdown={() => {
                  toggleDropdown("isStatusDropdownOpen");
                }}
                statusArray={statusArray ?? []}
              />

              <DropdownMenu
                options={statusArray ? statusArray : []}
                isOpen={dropdownState.isStatusDropdownOpen}
                position="top-full -mt-3 w-32"
                handleOptionClick={handleStatusOptionClick}
              />
            </>
          )}
        </td> */}
      </tr>
      {showInitiatives && (
        <>
          {(keyResultIdeas ?? []).map((idea) => (
            <React.Fragment key={idea._id}>
              <IdeasRowComponent
                idea={idea}
                selectedColumns={
                  statusIndex !== -1
                    ? Array.from({ length: statusIndex }).concat("status")
                    : []
                }
                pl="pl-[74px]"
                py="py-1"
                withCheckboxOption={false}
              />
            </React.Fragment>
          ))}
          {(keyResultFeatures ?? []).map((feature) => (
            <React.Fragment key={feature._id}>
              <FeaturesRowComponent
                feature={feature}
                selectedColumns={
                  statusIndex !== -1
                    ? Array.from({ length: statusIndex }).concat("status")
                    : []
                }
                pl="pl-[74px]"
                py="py-1"
                withCheckboxOption={false}
              />
            </React.Fragment>
          ))}
        </>
      )}
    </>
  );
};

export default KeyResultRow;
