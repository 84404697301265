import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/common/Loader";
import { toast } from "react-toastify";
import LinkedItemList from "./LinkedItemList";
import Searchbar from "../../Searchbar";
import CheckboxDropdownItem from "../../CheckboxDropdownItem";
import { fieldIconMapping } from "../../../../utils";
import IconWrapper from "../../IconWrapper";

const MultiLinkDetailViewTab = ({
  currentItem,
  selectedFilter, // expected to be a Set like new Set(["idea", "feature", "feedback"])
  selectedItems,
  handleItemSelect,
  updateLinkedItems,
  removeLinkedItems,
  clearSelectedItems,
  fetchAllItems,
  itemIconComponent,
  searchPlaceholder,
  onLinkedItemClick,
  with_linked_item_list = true,
  with_different_tabs = [],
  setMainIsDropdownOpen,
}) => {
  const dispatch = useDispatch();
  const { features } = useSelector((state) => state.features);
  const { ideas } = useSelector((state) => state.ideas);

  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [filteredFeatures, setFilteredFeatures] = useState([]);
  const [selectedIdeas, setSelectedIdeas] = useState([]);
  const [filteredIdeas, setFilteredIdeas] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  // Only show features if the "feature" filter is selected,
  // and further filter based on the search term.
  useEffect(() => {
    if (selectedFilter.has("feature")) {
      setFilteredFeatures(
        features.filter((feature) => {
          const notSelected =
            !selectedFeatures.some((sel) => sel._id === feature._id) &&
            !currentItem.features.includes(feature._id);
          const matchesSearch =
            searchTerm.trim() === "" ||
            feature.title.toLowerCase().includes(searchTerm.toLowerCase());
          return notSelected && matchesSearch;
        })
      );
    } else {
      setFilteredFeatures([]);
    }
  }, [features, selectedFeatures, currentItem, selectedFilter, searchTerm]);

  // For ideas, we check the idea's type (converted to lowercase) against the filter,
  // and also apply the search term.
  useEffect(() => {
    setFilteredIdeas(
      ideas.filter((idea) => {
        const notSelected =
          !selectedIdeas.some((sel) => sel._id === idea._id) &&
          !currentItem.ideas.includes(idea._id);
        const typeMatches = selectedFilter.has(idea.type.toLowerCase());
        const matchesSearch =
          searchTerm.trim() === "" ||
          idea.title.toLowerCase().includes(searchTerm.toLowerCase());
        return notSelected && typeMatches && matchesSearch;
      })
    );
  }, [ideas, selectedIdeas, currentItem, selectedFilter, searchTerm]);

  const handleFeatureSelection = (option) => {
    setSelectedFeatures((prev) =>
      prev.some((o) => o._id === option._id)
        ? prev.filter((o) => o._id !== option._id)
        : [...prev, option]
    );
  };

  const handleIdeaSelection = (option) => {
    setSelectedIdeas((prev) =>
      prev.some((o) => o._id === option._id)
        ? prev.filter((o) => o._id !== option._id)
        : [...prev, option]
    );
  };

  const handleUpdateLinkedItems = () => {
    const data = {
      features: selectedFeatures.map((feature) => feature._id),
      ideas: selectedIdeas.map((idea) => idea._id),
    };
    updateLinkedItems(data);
  };

  return (
    <div className="relative">
      <div className="w-[29rem]">
        <Searchbar
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder={searchPlaceholder}
        />
      </div>
      <div className="w-[465px] left-0 max-h-[22rem] absolute bg-white p-2   border rounded">
        <div className="max-h-[18rem] overflow-scroll pr-2 ">
          {/* Render selected features */}
          {selectedFeatures.map((item, index) => (
            <CheckboxDropdownItem
              key={item._id || index}
              option={item}
              handleOptionClick={handleFeatureSelection}
              selected
              checkboxTextSize="text-title_50 text-sm"
              truncateLength={35}
            />
          ))}
          {/* Render selected ideas */}
          {selectedIdeas.map((item, index) => (
            <CheckboxDropdownItem
              key={item._id || index}
              option={item}
              handleOptionClick={handleIdeaSelection}
              selected
              checkboxTextSize="text-title_50 text-sm"
              truncateLength={35}
            />
          ))}
          {/* Render available features */}
          {filteredFeatures.map((item, index) => (
            <CheckboxDropdownItem
              key={item._id || index}
              option={{
                _id: item._id,
                label: item.title,
                icon: itemIconComponent,
              }}
              handleOptionClick={handleFeatureSelection}
              selected={false}
              checkboxTextSize="text-title_50 text-sm"
              truncateLength={48}
            />
          ))}
          {/* Render available ideas */}
          {filteredIdeas.map((item, index) => {
            const typeLower = item.type.toLowerCase();
            // Choose icon based on idea type
            const icon =
              typeLower === "feedback" ? (
                <IconWrapper
                  icon={React.createElement(fieldIconMapping.feedback)}
                  iconColor="text-feedbacks-icon"
                />
              ) : (
                <IconWrapper
                  icon={React.createElement(fieldIconMapping.idea)}
                  iconColor="text-ideas-icon"
                />
              );
            return (
              <CheckboxDropdownItem
                key={item._id || index}
                option={{
                  _id: item._id,
                  label: item.title,
                  icon,
                }}
                handleOptionClick={handleIdeaSelection}
                selected={false}
                checkboxTextSize="text-title_50 text-sm"
                truncateLength={55}
              />
            );
          })}
        </div>
        <div className="flex w-fit ml-auto my-2">
          <div
            className="mr-5 p-1.5 rounded bg-white border shadow text-title text-sm cursor-pointer hover:bg-button-hover"
            onClick={() => {
              setMainIsDropdownOpen(false);
            }}
          >
            Cancel
          </div>
          <div
            className="p-1.5 bg-button-active text-white rounded text-sm cursor-pointer shadow hover:bg-[#0047AB]"
            onClick={handleUpdateLinkedItems}
          >
            Link opportunities
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiLinkDetailViewTab;
