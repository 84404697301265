/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { LuBox, LuPanelRight } from "react-icons/lu";
import { FiShare } from "react-icons/fi";
import Searchbar from "../../../common/Searchbar";
import { useDispatch, useSelector } from "react-redux";
import { getUserIdFromToken } from "utils/cookieUtils";
import { isObjectEmpty, truncateText } from "utils";
import { fetchUserDetails } from "reduxStore/operations/userAPI";
import IconText from "../../../common/IconText";
import { Copy } from "react-flaticons";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Modal } from "antd";
import JiraModalDetails from "../../../settings/integrations/JiraModalDetails";
import { getJiraProjects } from "../../../../reduxStore/operations/jiraAPI";
import { updateJiraProjects } from "../../../../reduxStore/slices/integrationsSlice";
import { FaCube } from "react-icons/fa";

const TopbarTitleRight = ({
  handleRightSidebarClick,
  withShareOption = false,
  withTopSidebarOption = false,
  searchPlaceholder = "Search...",
  searchTerm = "",
  onSearchChange = () => {},
  roadmap,
}) => {
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [linkIsCopied, setLinkIsCopied] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dropdownRef = useRef();
  const { token, companyId } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.user);
  const integrations = useSelector((state) => state.integrations);
  const { jira } = integrations;
  const userId = getUserIdFromToken(token);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const viewname = searchParams.get("view");
  const currentUrl =
    window.location.origin + location.pathname + location.search;

  const jiraClickHandler = async () => {
    setIsModalOpen(true);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        toast.success("URL copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
    setLinkIsCopied(!linkIsCopied);
  };

  useEffect(() => {
    if (userId && isObjectEmpty(user)) {
      dispatch(fetchUserDetails(userId));
    }
  }, [user, userId, dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      const returnedProjects = await dispatch(getJiraProjects(companyId));
      const formattedProjects = returnedProjects.map((project) => {
        return {
          label: project.name,
          value: project.key,
        };
      });
      dispatch(updateJiraProjects(formattedProjects));
    };
    if (jira) {
      fetchProjects();
    }
  }, [jira]);

  return (
    <div className="flex items-center justify-center gap-x-5">
      {jira &&
        location.pathname.includes("features") &&
        (location.search.includes("view=all") || !location.search) && (
          <div
            onClick={jiraClickHandler}
            className="flex items-center justify-center gap-1 rounded px-3 py-1 hover:bg-gray-100 cursor-pointer"
          >
            <img src="/jiraIcon.svg" alt="jira" width={14} height={14} />
            <span>Jira</span>
          </div>
        )}
      {location.pathname.includes("ideas") && !viewname && (
        <div
          onClick={() => naviagte("/settings/integrations")}
          className="flex items-center justify-center gap-1 rounded px-3 py-1 hover:bg-gray-100 cursor-pointer"
        >
          <img
            className="mb-px"
            src="/slack_icon.svg"
            alt="slack"
            width={14}
            height={14}
          />
          <span className="">Slack</span>
        </div>
      )}
      {/* <Searchbar placeholder={searchPlaceholder} /> */}
      {(withTopSidebarOption || withShareOption) && (
        <div className="flex  w-fit">
          {" "}
          {withShareOption && (
            <div
              className={`relative py-1.5 px-2  rounded-sm cursor-pointer ${
                isDropdownOpen
                  ? "bg-button-active_10"
                  : "hover:bg-topbar-button-hover"
              }`}
              onClick={() => {
                setIsDropdownOpen(!isDropdownOpen);
              }}
              ref={dropdownRef}
            >
              <div className="  flex  items-center   gap-1 ">
                <FiShare size={14} />
                {/* <h2 className=" text-[14px] ">Share</h2> */}
              </div>
              {isDropdownOpen && (
                <div
                  className="absolute  right-[0rem]  top-full p-5 bg-white  rounded shadow w-[28rem] mt-0.5 cursor-default z-50 "
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div className="text-title">Share Roadmap</div>
                  <div className="text-title text-xs mt-6">
                    {roadmap?.title}
                  </div>
                  <div className="text-primary text-xs mt-3">
                    Anyone in
                    {truncateText(user?.company?.company_name ?? "", 20)}
                  </div>
                  <div className="flex gap-x-1.5 items-center mt-1.5">
                    <div className="flex-1 bg-[#ECECEC] p-1 rounded  text-xs">
                      {truncateText(currentUrl, 40)}
                    </div>
                    <div>
                      <IconText
                        icon={<Copy />}
                        text={linkIsCopied ? "Copied" : "Copy Link"}
                        iconTextBgColor={
                          linkIsCopied ? "bg-green-500" : "bg-add-popup-button"
                        }
                        textColor="text-white"
                        iconColor="white"
                        textSize="text-xs"
                        iconSize={12}
                        hoverColor={!linkIsCopied && "hover:bg-blue-500"}
                        gap_x="gap-x-2"
                        px="px-1.5"
                        py="py-1.5"
                        cursorType={
                          linkIsCopied ? "cursor-not-allowed" : "cursor-pointer"
                        }
                        onClick={() =>
                          linkIsCopied ? null : copyToClipboard()
                        }
                      />
                    </div>
                  </div>
                  <hr className="my-2 w-4/5 mx-auto" />
                </div>
              )}
            </div>
          )}
          {withTopSidebarOption && (
            <div
              className="py-1.5 px-3 flex items-center  cursor-pointer rounded-sm hover:bg-topbar-button-hover"
              onClick={() => {
                setIsDropdownOpen(false);
                handleRightSidebarClick();
              }}
            >
              <LuPanelRight size={14} />
            </div>
          )}
        </div>
      )}
      <Searchbar
        pad="px-2 py-1"
        value={searchTerm}
        onChange={(e) => onSearchChange(e.target.value)}
        placeholder={"Search"}
      />
      {/* <Searchbar placeholder={searchPlaceholder} /> */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onCancel={() => setIsModalOpen(false)}
        title={
          <div>
            <div className="text-lg text-slate-700">Import Epics</div>
            <div className="text-xs text-slate-400 font-normal">
              Import set of epics as features
            </div>
          </div>
        }
        footer={null}
        width={650}
        destroyOnClose
      >
        <JiraModalDetails />
      </Modal>
    </div>
  );
};

export default TopbarTitleRight;
