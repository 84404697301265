import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "components/common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoadmaps } from "reduxStore/operations/roadmapsAPI";
import { isObjectEmpty } from "utils";
import RoadmapReleaseDetailViewComponent from "../../components/roadmaps/detailView/detailViewRelease/RoadmapReleaseDetailViewComponent";
import RoadmapIdeasDetailViewComponent from "../../components/roadmaps/detailView/detailViewIdeas/RoadmapIdeasDetailViewComponent";
import RoadmapStrategyDetailViewComponent from "../../components/roadmaps/detailView/detailViewStrategy/RoadmapStrategyDetailViewComponent";

const RoadmapsDetailView = () => {
  const dispatch = useDispatch();
  const { roadmapId } = useParams();
  const cleanedRoadmapId = roadmapId.split("&")[0];
  const { roadmaps } = useSelector((state) => state.roadmaps);
  const [allRoadmaps, setAllRoadmaps] = useState(null);
  const [roadmap, setRoadmap] = useState(null);

  useEffect(() => {
    if (isObjectEmpty(roadmaps)) {
      dispatch(getAllRoadmaps());
    } else {
      setAllRoadmaps(roadmaps);
    }
  }, [dispatch, roadmaps]);

  useEffect(() => {
    if (allRoadmaps !== null) {
      const tempRoadmap = allRoadmaps.find((item) => item._id === cleanedRoadmapId);
      setRoadmap(tempRoadmap);
    }
  }, [allRoadmaps, cleanedRoadmapId]);

  return !roadmap ? (
    <div className="absolute inset-0 flex items-center justify-center">
      <Loader />
    </div>
  ) : (
    <>
      {roadmap.for === "Release" && (
        <RoadmapReleaseDetailViewComponent
          roadmap={roadmap}
          showBreadcrumb={true}
        />
      )}
      {roadmap.for === "Ideas" && (
        <RoadmapIdeasDetailViewComponent
          roadmap={roadmap}
          showBreadcrumb={true}
        />
      )}
      {roadmap.for === "Strategy" && (
        <RoadmapStrategyDetailViewComponent
          roadmap={roadmap}
          showBreadcrumb={true}
        />
      )}
    </>
  );
};

export default RoadmapsDetailView;
