import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "reduxStore/slices/authSlice";
import Loader from "../common/Loader";
import {
  requestPasswordReset,
  resetPassword,
  verifyOtp,
} from "../../reduxStore/operations/authAPI";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { IoLockOpenOutline, IoMailOutline } from "react-icons/io5";
import { Edit } from "react-flaticons";
import { TbEdit } from "react-icons/tb";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordSame, setIsPasswordSame] = useState(true);
  const [step, setStep] = useState(1);

  const [passwordLength, setPasswordLength] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [isNumber, setIsNumber] = useState(false);
  const [isSymbol, setIsSymbol] = useState(false);
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    if (newPassword.length >= 8) {
      setPasswordLength(true);
    } else {
      setPasswordLength(false);
    }
    const hasUpperCase = newPassword
      .split("")
      .some((char) => char === char.toUpperCase() && isNaN(char));

    setIsUpperCase(hasUpperCase);

    const hasLowerCase = newPassword
      .split("")
      .some((char) => char === char.toLowerCase() && isNaN(char));

    setIsLowerCase(hasLowerCase);

    const hasNumber = newPassword
      .split("")
      .some((char) => !isNaN(char) && char !== " ");
    setIsNumber(hasNumber);
    const specialChars = /[@$!%*?&^_#]/;
    const hasSpecialChar = specialChars.test(newPassword);
    setIsSymbol(hasSpecialChar);
  }, [newPassword]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth);

  const handleSendOtp = (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(requestPasswordReset(email, setStep));
  };

  const handleVerifyOTP = (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(verifyOtp(email, otp, setStep));
  };

  const isStrongPassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&^_#])[A-Za-z\d@$!%*?&^_#]{8,}$/;
    return regex.test(password);
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (
      !isStrongPassword(newPassword) ||
      !passwordLength ||
      !isUpperCase ||
      !isLowerCase ||
      !isNumber ||
      !isSymbol
    ) {
      toast.error(
        "Password require to contain at least 8 characters including one Capital letter, one small letter and one special character."
      );
      return;
    }
    if (newPassword !== confirmPassword) {
      setIsPasswordSame(false);
      // toast.error("Passwords do not match");
      return;
    }
    dispatch(setLoading(true));
    dispatch(resetPassword(email, newPassword, setStep, navigate));
  };

  return (
    // <div className="flex flex-col gap-10 items-center justify-center min-h-screen bg-gray-100">
    //   <div className="relative bg-white p-8 rounded shadow-md w-full max-w-sm">
    //     <img
    //       src="/lane_icon_signin.svg"
    //       alt="logo"
    //       className="w-[125px] h-[75px] mr-auto"
    //     />
    //     {step === 1 && (
    //       <>
    //         <h2 className="text-2xl mb-6 text-center">Reset Password</h2>
    //         <form onSubmit={handleSendOtp}>
    //           <div className="mb-4">
    //             <label className="block text-gray-700">Email</label>
    //             <input
    //               type="email"
    //               className="w-full p-2 border border-gray-300 rounded mt-1"
    //               value={email}
    //               onChange={(e) => setEmail(e.target.value)}
    //               placeholder="Email"
    //               required
    //             />
    //           </div>
    //           <button
    //             type="submit"
    //             className="w-full bg-blue-500 text-white p-2 rounded mt-[6.5rem] hover:bg-blue-600"
    //           >
    //             {loading ? <Loader size="20" color="white" /> : "Send OTP"}
    //           </button>
    //         </form>
    //       </>
    //     )}

    //     {step === 2 && (
    //       <>
    //         <h2 className="text-2xl mb-6 text-center">Reset Password</h2>
    //         <form onSubmit={handleVerifyOTP}>
    //           <div className="mb-4">
    //             <label className="block text-gray-700">OTP</label>
    //             <input
    //               type="number"
    //               className="w-full p-2 border border-gray-300 rounded mt-1"
    //               value={otp}
    //               onChange={(e) => setOtp(e.target.value)}
    //               placeholder="OTP"
    //               required
    //             />
    //           </div>
    //           <button
    //             type="submit"
    //             className="w-full bg-blue-500 text-white p-2 rounded mt-[6.5rem] hover:bg-blue-600"
    //           >
    //             {loading ? <Loader size="20" color="white" /> : "Verify OTP"}
    //           </button>
    //         </form>
    //       </>
    //     )}

    //     {step === 3 && (
    //       <>
    //         <h2 className="text-2xl mb-6 text-center">Reset Password</h2>
    //         <form onSubmit={handleResetPassword}>
    //           <div className="mb-4">
    //             <label className="block text-gray-700">New Password</label>
    //             <input
    //               type="password"
    //               className="w-full p-2 border border-gray-300 rounded mt-1"
    //               value={newPassword}
    //               onChange={(e) => setNewPassword(e.target.value)}
    //               placeholder="New Password"
    //               required
    //             />
    //           </div>
    //           <div className="mb-4">
    //             <label className="block text-gray-700">Confirm Password</label>
    //             <input
    //               type="password"
    //               className="w-full p-2 border border-gray-300 rounded mt-1"
    //               value={confirmPassword}
    //               onChange={(e) => setConfirmPassword(e.target.value)}
    //               placeholder="Confirm Password"
    //               required
    //             />
    //           </div>
    //           <button
    //             type="submit"
    //             className="w-full bg-blue-500 text-white p-2 rounded mt-4 hover:bg-[#4694DB]"
    //           >
    //             {loading ? (
    //               <Loader size="20" color="white" />
    //             ) : (
    //               "Reset Password"
    //             )}
    //           </button>
    //         </form>
    //       </>
    //     )}
    //   </div>
    // </div>
    <div className="h-screen flex flex-col  md:flex-row font-inter overflow-hidden ">
      <div className="w-full md:w-[60%] xl:w-[45%] max-w-[55rem] min-w-[24rem]  md:min-w-[32rem] flex flex-col items-center  justify-center md:justify-start  md:pt-[10rem]  p-2 md:p-4">
        <div className="flex flex-col  justify-center p-4 md:p-8 w-[90%] max-w-[30rem]">
          {/* <div className="relative bg-white p-8 rounded w-full max-w-sm"> */}
          <img
            src="/lane_icon_signin.svg"
            alt="logo"
            className="w-[125px] h-[75px] mr-auto"
          />
          {step === 1 && (
            <>
              <h2 className="text-2xl mb-4 text-left">Reset your password</h2>
              <p className=" text-[#696969] mb-5 ">
                Enter your email to receive a link to reset your password
              </p>
              <form onSubmit={handleSendOtp}>
                <div className="  mb-4">
                  <label className="block text-[16px] text-[#696969]">
                    Email
                  </label>
                  <div className="relative w-full">
                    <IoMailOutline
                      className="absolute left-3 top-[14px] text-gray-400"
                      size={20}
                    />
                    <input
                      type="email"
                      className="w-full p-2 pl-10 border border-gray-300 rounded-md mt-1"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="w-full  font-bold  bg-signup-button text-white p-2 rounded mt-6 hover:bg-blue-600"
                >
                  {loading ? (
                    <Loader size="20" color="white" />
                  ) : (
                    "Reset password"
                  )}
                </button>
              </form>
            </>
          )}

          {step === 2 && (
            <>
              <h2 className="text-2xl mb-4   text-[26px]  text-left">
                Reset your password
              </h2>
              <p className=" text-[#696969] text-[16px] mb-1 ">
                Enter the OTP sent to
              </p>
              <div className=" flex items-center  gap-2 mb-6">
                <h2 className=" mb-6 text-[16px]">{email}</h2>
                <TbEdit
                  onClick={() => setStep(1)}
                  className=" hover:cursor-pointer  text-primary w-5 h-5 "
                />
              </div>
              <form onSubmit={handleVerifyOTP}>
                <div className="mb-4">
                  <label className="block text-[14px] text-[#696969]">
                    OPT
                  </label>
                  <input
                    type="number"
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    // placeholder="OTP"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full bg-signup-button font-bold  text-white p-2 rounded  hover:bg-blue-600"
                >
                  {loading ? <Loader size="20" color="white" /> : "Verify OTP"}
                </button>
              </form>
            </>
          )}

          {step === 3 && (
            <>
              <h2 className="text-2xl mb-4 text-left">Reset your password</h2>
              <p className=" text-[#696969] mb-6 ">
                Create a new password to secure your account
              </p>
              <form onSubmit={handleResetPassword}>
                <div className="mb-4">
                  <label className="block text-[16px] text-[#696969]">
                    New Password
                  </label>
                  {/* <input
                    type="password"
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    // placeholder="New Password"
                    required
                  /> */}

                  <div className="relative">
                    <IoLockOpenOutline
                      className="absolute left-3 top-[14px] text-gray-400"
                      size={20}
                    />
                    <input
                      type="password"
                      className="w-full p-2 pl-10 border border-gray-300 rounded-md mt-1"
                      value={newPassword}
                      onChange={(e) => {
                        setIsHidden(false);
                        setNewPassword(e.target.value);
                      }}
                      // placeholder="New Password"
                      required
                    />
                  </div>
                  <h2
                    className={`   font-semibold text-sm text-red-600  transition-all duration-100  pt-2  ${
                      isHidden ? "  hidden opacity-0  " : " block   opacity-100  "
                    }  `}
                  >
                    <span
                      className={`${passwordLength ? "text-green-500" : ""}`}
                    >
                      {" "}
                      Use at least 8 characters,
                    </span>{" "}
                    <span className={`${isUpperCase ? "text-green-500" : ""}`}>
                      an uppercase
                    </span>{" "}
                    <span className={`${isLowerCase ? "text-green-500" : ""}`}>
                      , a lowercase,
                    </span>{" "}
                    <span className={`${isNumber ? "text-green-500" : ""}`}>
                      a number
                    </span>{" "}
                    <span className={`${isSymbol ? "text-green-500" : ""}`}>
                      and a special symbol (@, $, !, %, *, ?, &, ^, _, #).
                    </span>
                  </h2>
                </div>
                <div className="mb-2">
                  <label className="block text-[16px] text-[#696969]">
                    Confirm Password
                  </label>
                  {/* <input
                    type="password"
                    className="w-full p-2 border border-gray-300 rounded mt-1"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    // placeholder="Confirm Password"
                    required
                  /> */}
                  <div className="relative">
                    <IoLockOpenOutline
                      className="absolute left-3 top-[14px] text-gray-400"
                      size={20}
                    />
                    <input
                      type="password"
                      className="w-full p-2 pl-10 border border-gray-300 rounded-md mt-1"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      // placeholder="Confirm Password"
                      required
                    />
                  </div>
                </div>
                <h2
                  className={`pb-2 text-sm text-red-500 ${
                    isPasswordSame ? "  opacity-0" : " opacity-100 "
                  } `}
                >
                  Passwords do not match.
                </h2>
                <button
                  type="submit"
                  className="w-full bg-signup-button text-white p-2  font-bold rounded hover:bg-[#4694DB]"
                >
                  {loading ? <Loader size="20" color="white" /> : "Take me in"}
                </button>
              </form>
            </>
          )}
          {/* </div> */}
        </div>
      </div>
      <div className="hidden md:block bg-[#F9FBFC] md:w-[40%] xl:w-[55%] relative pl-[6rem]">
        {/* Quote */}
        <h2 className="pt-[64px] pr-0 md:pr-[7rem]] md:text-[17px] lg:text-[24px] xl:text-[28px] text-gray-800">
          {step === 1 &&
            `"Unplanned 'fire-fighting' tasks consume 52% of product managers' time"`}
          {step === 2 &&
            `"A study across 181 companies found that the average core feature adoption rate is 24.5%"`}
          {step === 3 &&
            `"Great product managers thrive on turning ambiguity into clarity and challenges into opportunities"`}
        </h2>

        {/* Background Image */}
        <div className="absolute top-[14rem] left-[6rem] bottom-0 border  rounded-xl overflow-hidden">
          <img
            src="/signup3-cropped.svg"
            alt="Background"
            className="w-full xl:min-w-[60rem]  h-full object-cover"
            style={{
              objectPosition: "top left", // Align the image to the top-left corner
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
