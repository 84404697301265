import React, { useState } from "react";
import { Loading } from "react-flaticons";
import { useSelector } from "react-redux";
import { Form, Input, Button, Alert, Spin } from "antd";
import { apiConnector } from "../../../api/apiconnector";
import { authEndPoints } from "../../../api/api";
const { CHANGE_PASSWORD } = authEndPoints;

const ChangePassword = () => {
  const { user } = useSelector((state) => state.user);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const handleFinish = async (values) => {
    setLoading(true);
    setError("");
    setSuccess("");

    // Validate matching passwords
    if (values.newPassword !== values.confirmPassword) {
      setError("New Password and Confirm Password do not match.");
      setLoading(false);
      return;
    }

    // Ensure the new password is different from the old password
    if (values.currentPassword === values.newPassword) {
      setError("Old Password and New Password can't be the same.");
      setLoading(false);
      return;
    }

    const data = {
      password: values.currentPassword,
      newPassword: values.newPassword,
    };

    try {
      const response = await apiConnector("POST", CHANGE_PASSWORD, data);
      if (response.status === 200) {
        setSuccess("Password changed successfully!");
        form.resetFields();
        setTimeout(() => setSuccess(""), 4000);
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
    } catch (err) {
      if (err?.response?.data?.message) {
        setError(err.response.data.message);
      } else {
        setError("An unexpected error occurred. Please try again.");
      }
      console.error("Error occurred:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex items-start justify-start pt-4">
      <div className="bg-white max-w-md w-full">
        <Spin spinning={loading} tip="Updating Password...">
          <Form
            form={form}
            layout="vertical"
            onFinish={handleFinish}
            initialValues={{
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
            }}
          >
            {/* Only render current password if user did not sign up with email */}
            {!user?.emailSignUp && (
              <Form.Item
                label={<span className="text-primary">Current Password</span>}
                name="currentPassword"
                rules={[
                  {
                    required: true,
                    message: "Please enter your current password",
                  },
                ]}
              >
                <Input.Password onFocus={() => setError("")} />
              </Form.Item>
            )}
            <Form.Item
              label={<span className="text-primary">New Password</span>}
              name="newPassword"
              rules={[
                { required: true, message: "Please enter your new password" },
              ]}
            >
              <Input.Password onFocus={() => setError("")} />
            </Form.Item>
            <Form.Item
              label={<span className="text-primary">Confirm Password</span>}
              name="confirmPassword"
              rules={[
                { required: true, message: "Please confirm your new password" },
              ]}
            >
              <Input.Password onFocus={() => setError("")} />
            </Form.Item>

            {error && (
              <Alert
                message={error}
                type="error"
                showIcon
                style={{ marginBottom: 16 }}
              />
            )}
            {success && (
              <Alert
                message={success}
                type="success"
                showIcon
                style={{ marginBottom: 16 }}
              />
            )}
            <Form.Item>
              <Button type="primary" htmlType="submit" disabled={loading}>
                {loading ? (
                  <Loading size={24} className="mx-auto" />
                ) : (
                  "Update Password"
                )}
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default ChangePassword;
