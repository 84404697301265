import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewAccount,
  deleteExistingAccount,
  getAccounts,
  updateExistingAccount,
} from "reduxStore/operations/accountAPI";
import {
  PlusSmall,
  Search,
  MenuDotsVertical,
  Trash,
  Following,
} from "react-flaticons";
import DropdownItem from "components/common/DropdownItem";
import { TbEdit } from "react-icons/tb";
import { Button, Empty, Input, Typography, Modal, Form } from "antd";

const Accounts = () => {
  const dispatch = useDispatch();
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [currentAccount, setCurrentAccount] = useState({
    name: "",
    email: "",
    value: "",
  });
  const [errors, setErrors] = useState({});
  const [visibleButtonId, setVisibleButtonId] = useState(null);
  const dropdownRef = useRef(null);

  // Ensure accounts is an array
  const { accounts = [] } = useSelector((state) => state.accounts);

  // Fetch accounts on component mount
  useEffect(() => {
    dispatch(getAccounts());
  }, [dispatch]);

  // Validate current account fields; return true if valid, else set errors.
  const validateAccount = () => {
    const newErrors = {};
    if (!currentAccount.name.trim()) {
      newErrors.name = "Name is required.";
    }
    if (!currentAccount.email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(currentAccount.email)) {
      newErrors.email = "Email format is invalid.";
    }
    if (!currentAccount.value.toString().trim()) {
      newErrors.value = "Value is required.";
    } else if (isNaN(currentAccount.value)) {
      newErrors.value = "Value must be a number.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddAccount = (account) => {
    return dispatch(addNewAccount(account));
  };

  const handleEditAccount = (id, updatedAccount) => {
    return dispatch(updateExistingAccount(id, updatedAccount));
  };

  const openEditForm = (account) => {
    setCurrentAccount(account);
    setErrors({});
    setIsEditOpen(true);
  };

  // Toggle dropdown action menu for each account row
  const toggleActionMenu = (accountId) => {
    setVisibleButtonId((prevId) => (prevId === accountId ? null : accountId));
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setVisibleButtonId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Filter accounts based on search term (by name or email)
  const filteredAccounts = accounts.filter(
    (account) =>
      account.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      account.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handlers for closing modals and clearing the form and errors
  const closeAddModal = () => {
    setIsAddOpen(false);
    setCurrentAccount({ name: "", email: "", value: "" });
    setErrors({});
  };

  const closeEditModal = () => {
    setIsEditOpen(false);
    setCurrentAccount({ name: "", email: "", value: "" });
    setErrors({});
  };

  const handleAddModalOk = async () => {
    if (!validateAccount()) return;
    setIsAddLoading(true);
    try {
      await handleAddAccount(currentAccount);
      closeAddModal();
    } catch (error) {
      // Optionally handle error (ex: toast error)
      console.error("Error adding account:", error);
    } finally {
      setIsAddLoading(false);
    }
  };

  const handleEditModalOk = async () => {
    if (!validateAccount()) return;
    setIsEditLoading(true);
    try {
      await handleEditAccount(currentAccount._id, currentAccount);
      closeEditModal();
    } catch (error) {
      // Optionally handle error (ex: toast error)
      console.error("Error editing account:", error);
    } finally {
      setIsEditLoading(false);
    }
  };

  return (
    <div className="bg-white">
      {/* Search & Add Section */}
      <div className="flex justify-between w-full gap-4 items-center pt-2 pb-10">
        <Input
          placeholder="Search customer"
          prefix={
            <span
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "8px",
              }}
            >
              <Search size={12} color={isFocused ? "#696969" : "#d3d3d3"} />
            </span>
          }
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        <Button
          onClick={() => {
            setIsAddOpen(true);
            setErrors({});
          }}
          // icon={<PlusSmall size={20} />}
          type="primary"
        >
          Add customer
        </Button>
      </div>

      {/* Accounts Table */}
      {filteredAccounts.length > 0 ? (
        <table className="min-w-full table-auto">
          <thead>
            <tr className="text-sm">
              <th className="text-title text-start pr-1 font-normal bg-white">
                <span className="flex items-center gap-x-1">Name</span>
              </th>
              <th className="text-title text-start pr-1 px-3 font-normal bg-white">
                Email
              </th>
              <th className="text-title text-start pr-1 px-3 font-normal bg-white">
              Revenue ($)
              </th>
              <th className="text-primary text-start pr-1 px-3 font-normal bg-white" />
            </tr>
          </thead>
          <tbody>
            {filteredAccounts.map((account) => (
              <tr
                key={account._id}
                className="group text-title text-[14px] rounded relative"
              >
                <td className="truncate pr-1 py-2">{account.name}</td>
                <td className="truncate pr-1 px-3 py-2">{account.email}</td>
                <td className="truncate pr-1 px-3 py-2">{account.value}</td>
                <td className="truncate pr-1 px-3 py-2 opacity-0 group-hover:opacity-100 transition-opacity">
                  <div
                    className={`p-1 rounded-full w-fit hover:bg-button-hover cursor-pointer ${
                      visibleButtonId === account._id
                        ? "bg-button-active_10"
                        : ""
                    }`}
                    onClick={() => toggleActionMenu(account._id)}
                  >
                    <MenuDotsVertical size={12} />
                  </div>
                  {visibleButtonId === account._id && (
                    <div
                      ref={dropdownRef}
                      className="absolute visible right-0 text-sm p-1 w-24 bg-white z-50 rounded border shadow-md mt-0.5"
                    >
                      <DropdownItem
                        option={{ label: "Edit", icon: TbEdit }}
                        textSize="text-xs"
                        handleOptionClick={() => {
                          openEditForm(account);
                          setVisibleButtonId(null);
                        }}
                        gap_x="gap-x-2"
                      />
                      <DropdownItem
                        option={{ label: "Delete", icon: <Trash /> }}
                        textSize="text-xs"
                        handleOptionClick={() => {
                          dispatch(deleteExistingAccount(account._id));
                        }}
                        gap_x="gap-x-2"
                      />
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="flex justify-center items-top pt-12 h-[80vh]">
          <Empty
            imageStyle={{ height: 60 }}
            description={
              <Typography.Text className="font-bold text-primary">
                No customer
              </Typography.Text>
            }
          />
        </div>
      )}
      {/* Modal for Adding an Account */}
      <Modal
        title={
          <h2 className="flex gap-2 items-center pt-1 pb-2  ">
            <Following size={16} />
            <span>Customer</span>
          </h2>
        }
        open={isAddOpen}
        onCancel={closeAddModal}
        onOk={handleAddModalOk}
        okText="Add customer"
        confirmLoading={isAddLoading}
      >
        <div style={{ marginBottom: "32px" }}>
          <div style={{ marginBottom: "20px" }}>
            <Form.Item label="Name" className=" mb-0 " colon={false} />
            <Input
              placeholder="John Doe"
              value={currentAccount.name}
              onChange={(e) =>
                setCurrentAccount({ ...currentAccount, name: e.target.value })
              }
            />
            {errors.name && (
              <Typography.Text type="danger">{errors.name}</Typography.Text>
            )}
          </div>
          <div style={{ marginBottom: "20px" }}>
          <Form.Item label="Email" className=" mb-0 " colon={false} />
            <Input
              placeholder="john@company.com"
              value={currentAccount.email}
              onChange={(e) =>
                setCurrentAccount({ ...currentAccount, email: e.target.value })
              }
            />
            {errors.email && (
              <Typography.Text type="danger">{errors.email}</Typography.Text>
            )}
          </div>
          <div style={{ marginBottom: "20px" }}>
            <Form.Item label="Revenue ($)" className=" mb-0 "  colon={false} />
            <Input
              type="number"
              placeholder="Enter customer Revenue ($)"
              value={currentAccount.value}
              onChange={(e) =>
                setCurrentAccount({ ...currentAccount, value: e.target.value })
              }
            />
            {errors.value && (
              <Typography.Text type="danger">{errors.value}</Typography.Text>
            )}
          </div>
        </div>
      </Modal>

      {/* Modal for Editing an Account */}
      <Modal
        title={
          <h2 className="flex gap-2 items-center pt-1 pb-2">
            <Following size={18} />
            <span>Customer</span>
          </h2>
        }
        open={isEditOpen}
        onCancel={closeEditModal}
        onOk={handleEditModalOk}
        okText="Update"
        confirmLoading={isEditLoading}
      >
        <div style={{ marginBottom: "32px" }}>
          <div style={{ marginBottom: "20px" }}>
            <Typography.Text >Name</Typography.Text>
            <Input
              placeholder="Enter customer name"
              value={currentAccount.name}
              onChange={(e) =>
                setCurrentAccount({ ...currentAccount, name: e.target.value })
              }
            />
            {errors.name && (
              <Typography.Text type="danger">{errors.name}</Typography.Text>
            )}
          </div>
          <div style={{ marginBottom: "20px" }}>
            <Typography.Text >Email</Typography.Text>
            <Input
              placeholder="Enter customer email"
              value={currentAccount.email}
              onChange={(e) =>
                setCurrentAccount({ ...currentAccount, email: e.target.value })
              }
            />
            {errors.email && (
              <Typography.Text type="danger">{errors.email}</Typography.Text>
            )}
          </div>
          <div style={{ marginBottom: "20px" }}>
            <Typography.Text >Revenue ($)</Typography.Text>
            <Input
              type="number"
              placeholder="Enter customer Revenue ($)"
              value={currentAccount.value}
              onChange={(e) =>
                setCurrentAccount({ ...currentAccount, value: e.target.value })
              }
            />
            {errors.value && (
              <Typography.Text type="danger">{errors.value}</Typography.Text>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Accounts;
