import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  apiKeys: [], // Array to store API key objects
};

const apiKeysSlice = createSlice({
  name: "apiKeys",
  initialState,
  reducers: {
    // Replace the current API keys with a new array
    setApiKeys: (state, action) => {
      state.apiKeys = action.payload;
    },
    // Add a single API key to the array
    addApiKey: (state, action) => {
      state.apiKeys.unshift(action.payload);
    },
    // Remove an API key by its unique identifier (e.g., id)
    removeApiKey: (state, action) => {
      state.apiKeys = state.apiKeys.filter(
        (apiKey) => apiKey._id !== action.payload
      );
    },
  },
});

export const { setApiKeys, addApiKey, removeApiKey } = apiKeysSlice.actions;
export const apiKeysReducer = apiKeysSlice.reducer;
