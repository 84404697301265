import React from "react";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { Bulb, PaperPlane, Map, RecordVinyl, CommentCheck } from "react-flaticons"; // Ensure these are correct
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveObjectiveId,
  setObjectiveShowDetailViewDrawer,
} from "../../reduxStore/slices/objectiveSlice";
import {
  setDetailKeyResults,
  setKeyResults,
} from "../../reduxStore/slices/keyResultSlice";
import {
  setActiveFeatureId,
  setFeatureShowDetailViewDrawer,
} from "../../reduxStore/slices/featureSlice";
import {
  setActiveIdeaId,
  setIdeaShowDetailViewDrawer,
} from "../../reduxStore/slices/ideaSlice";
import {
  setActiveRoadmapId,
  setRoadmapShowDetailViewDrawer,
  setRoadmapViews,
} from "../../reduxStore/slices/roadmapsSlice";

// eslint-disable-next-line react/prop-types
const CustomToast = ({ linkAddress, title, module, idModule }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleGetIcon = (module) => {
    switch (module) {
      case "Objective":
        return (
          <RecordVinyl className="mt-1 h-[15px] w-[15px] mb-1 text-[#F43379] " />
        );
      case "Feature":
        return (
          <PaperPlane className="mt-1 h-[15px] w-[15px]  text-[#3380F4] " />
        );
      case "Idea":
        if (idModule.type === "Idea") {
          return <Bulb className="mt-1 h-[15px] w-[15px] text-[#EDB900] " />;
        }else{
          return <CommentCheck className="mt-1 h-[15px] w-[15px]  text-features-icon "  />
        }
      case "Roadmap":
        return <Map className="mt-1 h-[15px] w-[15px]   text-[#141414]" />;
      default:
        return (
          <FaCheckCircle className=" mt-1 h-[15px] w-[15px] text-[#F43379]   " />
        );
    }
  };

  const handleOnClick = () => {
    switch (module) {
      case "Objective":
        dispatch(setActiveObjectiveId(idModule._id));
        dispatch(setObjectiveShowDetailViewDrawer(true));
        dispatch(setDetailKeyResults(idModule?.keyResults ?? []));
        break;
      case "Feature":
        dispatch(setActiveFeatureId(idModule._id));
        dispatch(setFeatureShowDetailViewDrawer(true));
        break;
      case "Idea":
        dispatch(setActiveIdeaId(idModule._id));
        dispatch(setIdeaShowDetailViewDrawer(true));
        break;
      case "Roadmap":
        navigate(`/roadmaps/${idModule._id}`);
        dispatch(setRoadmapViews({ views: [] }));
        // dispatch(setActiveRoadmapId(idModule._id));
        // dispatch(setRoadmapShowDetailViewDrawer(true));
        break;
      default:
        return;
    }
  };

  const truncateTitle = (title) => {
    return title.length > 38 ? title.slice(0, 38) + "..." : title;
  };

  return (
    <div className="flex justify-start items-start gap-2  ">
      <FaCheckCircle className=" mt-1 h-[17px] w-[17px] text-green-500  " />

      <div className=" flex flex-col gap-1 ">
        <h2 className="text-gray-800 text-[17px]">
          New {module.toLowerCase()} added
        </h2>
        <div className="flex gap-2 items-start">
          {handleGetIcon(module)}
          <p className="text-gray-700 mb-1 text-[17px]">
            {truncateTitle(title)}
          </p>
        </div>
        <button
          onClick={handleOnClick}
          className="text-blue-500 text-[16px] text-left "
        >
          View
        </button>
      </div>
    </div>
  );
};

export default CustomToast;
