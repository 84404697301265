import React from "react";
import { resetActiveIds } from "../../../utils/resetActiveIds";
import { handleResetActiveIds } from "../../../utils/handleResetActiveIds";
import { useLocation } from "react-router-dom";
import {
  setActiveFeatureId,
  setDetailViewFeatures,
  setFeatureShowDetailViewDrawer,
} from "../../../reduxStore/slices/featureSlice";
import { fieldIconMapping } from "../../../utils";
import { useSelector } from "react-redux";
import IconWrapper from "../IconWrapper";
import MultiLinkDetailViewTab from "./linkDetailViewTab/MultiLinkDetailTab";

const LinkFeatureIdeaDetailViewTab = (props) => {
  const { pathname } = useLocation();
  const { features } = useSelector((state) => state.features);
  const { ideas } = useSelector((state) => state.ideas);
  const allItems = features + ideas;

  const fetchAllItems = () => {
    return allItems;
  };
  return (
    <div className=" w-full max-h-[20rem]    ">
      <MultiLinkDetailViewTab
        {...props}
        fetchAllItems={fetchAllItems}
        itemNameField="title"
        itemIconComponent={
          <IconWrapper
            icon={React.createElement(fieldIconMapping.feature)}
            iconColor="text-features-icon"
          />
        }
        linkedItemsKeys={["ideas", "features"]}
        searchPlaceholder="Search ideas, feedback and features"
        onLinkedItemClick={(featureId) => {
          if (pathname === "/features") {
            resetActiveIds({
              dispatch,
              resetFeature: false,
            });
          }
          if (pathname.includes("/roadmaps")) {
            resetActiveIds({ dispatch });
          }
          handleResetActiveIds(dispatch, pathname);
          dispatch(setDetailViewFeatures(features));
          dispatch(setActiveFeatureId(featureId));
          dispatch(setFeatureShowDetailViewDrawer(true));
        }}
      />
    </div>
  );
};

export default LinkFeatureIdeaDetailViewTab;
