import React from "react";
import Feature2KanbanCard from "./Feature2KabanCard";
import Kanban2Column from "components/common/kanban/Kanban2Column";

const Feature2KanbanColumn = (props) => {
  return (
    <Kanban2Column
      {...props}
      renderCardComponent={({
        item,
        products,
        innerRef,
        draggableProps,
        dragHandleProps,
        statusArray,
      }) => (
        <>
          <Feature2KanbanCard
            feature={item}
            products={products}
            innerRef={innerRef}
            draggableProps={draggableProps}
            dragHandleProps={dragHandleProps}
            statusArray={statusArray}
            setActiveFeatureCard={props.setActiveFeatureCard} // If you need to pass this prop
          />
        </>
      )}
    />
  );
};

export default Feature2KanbanColumn;
