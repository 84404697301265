import {
  AngleSmallLeft,
  ChartConnected,
  ChartNetwork,
  CreditCard,
  Users as UsersIcon,
  Following,
  User,
} from "react-flaticons";
import IconText from "../common/IconText";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPreviousUrl } from "../../reduxStore/operations/previousUrlOperations";
import { loadUsetifulScript, setUsetifulTags } from "usetiful-sdk";
import React, { useEffect } from "react";
import { fieldIconMapping } from "../../utils";

const SettingsSidebar = () => {
  const dispatch = useDispatch();
  const previousUrl = useSelector((state) => state.previousUrl.previousUrl);

  const { user } = useSelector((state) => state.user);
  useEffect(() => {
    if (user) {
      loadUsetifulScript("ec856d16157e3a05982fe799c0a04ec5"); // This contains your unique token key.
      setUsetifulTags({
        userId: user._id, // This identifies the user and should be unique. Change it.
        firstName: user.name, // This property defines user’s first name. Change it.
        lastName: "", // This property defines user’s last name. Change it.
      });
    }
  }, [user]);

  const handleResetUrl = () => {
    dispatch(resetPreviousUrl()); // Clear the previous URL after navigating back
  };

  return (
    <div className="flex flex-col justify-between min-w-[240px] max-w-[240px]  border-r z-30">
      <div>
        {/* Logo and Expand Button */}
        <div className="flex justify-between items-center cursor-default px-4 mt-5 mb-7   ">
          <div className="flex gap-x-1.5 items-center ">
            <Link
              className="cursor-pointer  hover:bg-topbar-button-hover  rounded-md  "
              to={previousUrl || "/features"} // Fallback to "/features" if no previous URL is stored
              onClick={handleResetUrl} // Clear the previous URL
            >
              <AngleSmallLeft size={22} className="text-gray-500" />
            </Link>
            <span className="text-lg font-bold text-primary">Settings</span>
          </div>
        </div>
        <div className="flex flex-col gap-y-1.5  px-2">
          <div className="text-[12px] text-primary mx-1.5">Workspace</div>
          <Link to={"customizations"}>
            <IconText
              // icon={<ChartConnected />}
              icon={React.createElement(fieldIconMapping.customization)}
              text="Customizations"
              iconSize={16}
              gap_x="gap-x-[10px]"
              py="py-1"
              isASidebarComponent={true}
            />
          </Link>
          <Link to={"customers"}>
            <IconText
              icon={<Following />}
              text="Customers"
              py="py-1"
              isASidebarComponent={true}
            />
          </Link>
          <Link to={"integrations"}>
            <IconText
              icon={React.createElement(fieldIconMapping.integration)}
              iconSize={15}
              text="Integrations"
              py="py-1"
              isASidebarComponent={true}
            />
          </Link>
          <Link to={"api"}>
            <IconText
              icon={React.createElement(fieldIconMapping.api)}
              // icon={<ChartNetwork />}
              iconSize={15}
              text="API"
              py="py-1"
              isASidebarComponent={true}
            />
          </Link>
          <div className="mt-4"></div>
          <div className="text-[12px] text-primary mx-1.5">Account</div>
          <Link to={"profile"}>
            <IconText
              icon={<User />}
              text="Profile"
              py="py-1"
              isASidebarComponent={true}
            />
          </Link>
          <Link to={"members"}>
            <IconText
              icon={<UsersIcon />}
              text="Members"
              py="py-1"
              isASidebarComponent={true}
            />
          </Link>

          <Link to={"plan"}>
            <IconText
              icon={<CreditCard />}
              text="Plan"
              py="py-1"
              isASidebarComponent={true}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SettingsSidebar;
