/* eslint-disable react/prop-types */
import React from "react";
import IconText from "../../IconText";
import { fieldIconMapping } from "utils";
import { FaCircle } from "react-icons/fa";
import { Circle, CircleSmall } from "react-flaticons";

const AddRoadmapTypeSelection = ({
  forRoadmap,
  setForRoadmap,
  setName,
  onNextClick,
  resetSelectedColumns,
}) => {
  const text = {
    release:
      "Plan and track upcoming feature releases to keep teams coordinated and stakeholders informed about what’s launching and when",
    strategy:
      "Outline goals and key initiatives to align your product vision with business objectives, ensuring a clear direction for long-term success",
    ideas:
      "Organize and prioritize work in a flexible, time-free structure, helping teams focus on immediate tasks while planning for the future",
  };
  return (
    <>
      <div className="text-primary pt-2 pb-2 text-sm ">Select a type</div>
      <div className="flex items-center gap-x-4">
        <div
          className={`cursor-pointer border  rounded ${
            forRoadmap === "Strategy"
              ? "bg-[rgba(244,51,121,0.1)] border-[#F43379] "
              : "hover:bg-button-hover  border-dark "
          }`}
        >
          <IconText
            icon={React.createElement(fieldIconMapping.objective)}
            text="Strategy"
            // text="Strategic Roadmap"
            hoverable={forRoadmap !== "Strategy"}
            iconColor={forRoadmap === "Strategy" ? "#F43379" : "#141414"}
            onClick={() => {
              if (forRoadmap !== "Strategy") {
                setForRoadmap("Strategy");
                setName("Strategic Roadmap");
                resetSelectedColumns();
              }
            }}
            textColor="text-active_50"
            textSize="text-sm"
            gap_x="gap-x-1.5"
            px="px-[12px]"
          />
        </div>
        <div
          className={`cursor-pointer border border-[#141414] rounded ${
            forRoadmap === "Release"
              ? "bg-[rgba(51,128,244,0.1)] border-[#3380F4] "
              : "hover:bg-button-hover"
          }`}
        >
          <IconText
            icon={React.createElement(fieldIconMapping.feature)}
            text="Release"
            // text="Release Roadmap"
            hoverable={forRoadmap !== "Release"}
            iconColor={forRoadmap === "Release" ? "#3380F4" : "#141414"}
            onClick={() => {
              if (forRoadmap !== "Release") {
                setForRoadmap("Release");
                setName("Release Roadmap");
                resetSelectedColumns();
              }
            }}
            textColor="text-active_50"
            textSize="text-sm"
            gap_x="gap-x-1.5"
            px="px-[12px]"
          />
        </div>

        <div
          className={`cursor-pointer border  rounded ${
            forRoadmap === "Ideas"
              ? "bg-[rgba(237,185,0,0.1)] border-[#EDB900] "
              : "hover:bg-button-hover border-dark "
          }`}
        >
          <IconText
            icon={React.createElement(fieldIconMapping.ring)}
            text="Now-Next-Later"
            // iconSize={18}
            // text="Now-Next-Later Roadmap"
            hoverable={forRoadmap !== "Ideas"}
            iconColor={forRoadmap === "Ideas" ? "#EDB900" : "#141414"}
            // iconColor={forRoadmap === "Ideas" ? "#EDB900" : "#141414"}
            onClick={() => {
              if (forRoadmap !== "Ideas") {
                setForRoadmap("Ideas");
                setName("Now-Next-Later Roadmap");
                resetSelectedColumns();
              }
            }}
            textColor="text-active_50"
            textSize="text-sm"
            gap_x="gap-x-1.5"
            px="px-[12px]"
          />
        </div>
      </div>
      <div className="text-primary text-sm  pt-4  ">
        {text[forRoadmap.toLowerCase()]}
      </div>
      {/* <button
        className="w-fit ml-auto p-1.5 bg-add-popup-button rounded text-white text-sm"
        onClick={() => onNextClick()}
      >
        Next
      </button> */}
    </>
  );
};

export default AddRoadmapTypeSelection;
