import { Input } from "antd";
import React, { useState } from "react";
import { Trash } from "react-flaticons";

const StatusLabel = ({
  label,
  onClick,
  handleDeleteItem,
  id,
  showDelete = true,
  color,
  boolean,
  statusDomain,
}) => {
  const [isPassHovered, setIsPassHovered] = useState(false);
  const [isIconHovered, setIsIconHovered] = useState(false);

  // Event handler for delete icon click
  const handleDeleteClick = (e) => {
    // id,
    // statusValue,
    // statusColor,
    // shouldOpenColorDropdown,
    // statusDomain
    e.stopPropagation(); // Prevent the label click event from triggering.\
    handleDeleteItem(statusDomain, id); // Call the delete handler
  };

  return (
    <>
      {/* Label section */}
      {/* <div
        className={`p-[5px] pl-3  text-sm  border rounded w-48 ${
          statusDomain === "parked"
            ? "bg-row-background cursor-not-allowed"
            : "cursor-text"
        }`}
        onClick={() => {
          if (statusDomain !== "parked") {
            onClick(id, label, color, boolean, statusDomain);
          }
        }}
        onMouseEnter={() => setIsPassHovered(true)} // Hover on label
        onMouseLeave={() => setIsPassHovered(false)} // Remove hover from label
      >
        {label}
      </div> */}
      <Input
        disabled={statusDomain === "parked"}
        className={`border rounded w-48 ${
          statusDomain === "parked"
            ? "bg-row-background cursor-not-allowed"
            : "cursor-text bg-white"
        }`}
        onClick={() => {
        
          if (statusDomain !== "parked") {
            onClick(id, label, color, boolean, statusDomain);
          }
        }}
        onMouseEnter={() => setIsPassHovered(true)}
        onMouseLeave={() => setIsPassHovered(false)}
        value={label}
        // readOnly={!showDelete}
      />

      {/* Delete icon wrapper */}
      {showDelete && (
        <div
          className=" relative"
          onMouseEnter={() => setIsIconHovered(true)} // Hover on delete icon wrapper
          onMouseLeave={() => setIsIconHovered(false)} // Remove hover from icon wrapper
        >
          <div
            className={`flex items-center justify-center rounded-lg p-2 transition-all duration-200 ease-in-out
            ${
              isIconHovered || isPassHovered
                ? "opacity-100 hover:bg-button-hover "
                : "opacity-0"
            }

            cursor-pointer`}
            onClick={handleDeleteClick} // Use the modified delete click handler
          >
            <Trash size={14} />
            <span
              className={`transition-all duration-200 ease-in-out pl-2   ${
                isIconHovered
                  ? "opacity-100 hover:bg-button-hover"
                  : "opacity-0"
              }  `}
            >
              Delete
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default StatusLabel;
