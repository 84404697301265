import React, { useRef } from "react";
import { FloatingMenu } from "@tiptap/react";
import { BiHeading, BiListUl, BiListOl, BiImage } from "react-icons/bi";
import { toast } from "react-toastify";
import axios from "axios";

const FloatingMenuComponent = ({
  editor,
  setIsUploadingImage,
  isUploadingImage,
}) => {
  const fileInputRef = useRef(null);
  if (!editor) return null;

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    try {
      setIsUploadingImage(true);
      const response = await axios.post(
        `${import.meta.env.VITE_BACKEND_URL + "/api/storages"}`,
        formData,
        {
          withCredentials: true,
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      const imageUrl = response.data.url;

      editor.chain().focus().setImage({ src: imageUrl }).run();
    } catch (error) {
      console.error("Image upload failed:", error);
      toast.error(
        error?.response?.data?.message ??
          "Failed to upload image. Please try again."
      );
    } finally {
      setIsUploadingImage(false);
    }
  };

  return (
    <FloatingMenu
      editor={editor}
      className={`flex flex-col ${
        !isUploadingImage
          ? "w-48 bg-white border border-gray-300 rounded-md shadow-md p-2"
          : ""
      }`}
      tippyOptions={{ placement: "bottom-start" }}
    >
      {/* Heading 1 */}
      {!isUploadingImage && (
        <>
          <button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 1 }).run()
            }
            className="flex items-center space-x-2 p-2 text-sm hover:bg-gray-100 rounded-md cursor-pointer"
          >
            <BiHeading />
            <span>Heading 1</span>
          </button>
          <button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
            className="flex items-center space-x-2 p-2 text-sm hover:bg-gray-100 rounded-md cursor-pointer"
          >
            <BiHeading />
            <span>Heading 2</span>
          </button>
          <button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            }
            className="flex items-center space-x-2 p-2 text-sm hover:bg-gray-100 rounded-md cursor-pointer"
          >
            <BiHeading />
            <span>Heading 3</span>
          </button>

          <button
            onClick={() => fileInputRef.current.click()} // Trigger file input
            className="flex items-center space-x-2 p-2 text-sm hover:bg-gray-100 rounded-md cursor-pointer"
          >
            <BiImage className="mr-2" /> Images
          </button>
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            className="hidden" // Hide the input
            onChange={handleImageUpload}
          />

          {/* Bullet List */}
          <button
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className="flex items-center space-x-2 p-2 text-sm hover:bg-gray-100 rounded-md cursor-pointer"
          >
            <BiListUl />
            <span>Bullet List</span>
          </button>

          {/* Ordered List */}
          <button
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className="flex items-center space-x-2 p-2 text-sm hover:bg-gray-100 rounded-md cursor-pointer"
          >
            <BiListOl />
            <span>Ordered List</span>
          </button>
        </>
      )}
    </FloatingMenu>
  );
};

export default FloatingMenuComponent;
