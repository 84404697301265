/* eslint-disable react/prop-types */
import React from "react";
import { getStatusIcon } from "utils/status";
import { rgbColor, fieldIconMapping } from "utils";
import IconText from "components/common/IconText";
import { Loading, Minus, MinusSmall, PlusSmall } from "react-flaticons";
import { Droppable, Draggable } from "@hello-pangea/dnd";
import { useSelector } from "react-redux";
import Importance from "components/common/Importance";
import IconWrapper from "../IconWrapper";

const KanbanColumn = ({
  group,
  itemsForGroup,
  groupByOption,
  statusArray,
  renderCardComponent,
  isRoadmapDetailColumn = false,
  roadmapTypeIcon,
  AddItemsSidebar,
  activeAddDropdown,
  setActiveAddDropdown,
  handleRemoveIdea,
}) => {
  const { products } = useSelector((state) => state.company);
  const backgroundColor = !isRoadmapDetailColumn && rgbColor(group.color, 0.03);

  const headerBackgroundColor =
    !isRoadmapDetailColumn && rgbColor(group.color, 0.2);

  const icon =
    groupByOption === "status" || groupByOption === "default" ? (
      getStatusIcon(group.label, statusArray, roadmapTypeIcon)
    ) : groupByOption === "release" ? (
      <Loading />
    ) : groupByOption === "time_frame" ? (
      React.createElement(fieldIconMapping.timeFrames)
    ) : groupByOption === "product" ? (
      React.createElement(fieldIconMapping.product)
    ) : groupByOption === "health" ? (
      React.createElement(fieldIconMapping.timeFrames)
    ) : groupByOption === "objective" ? (
      React.createElement(fieldIconMapping.objective)
    ) : groupByOption === "accounts" ? (
      React.createElement(fieldIconMapping.accounts)
    ) : groupByOption === "assignee" ? (
      React.createElement(fieldIconMapping.assignee)
    ) : groupByOption === "source" ? (
      React.createElement(fieldIconMapping.source)
    ) : groupByOption === "submittedby" ? (
      React.createElement(fieldIconMapping.creator)
    ) : groupByOption === "tags" ? (
      React.createElement(fieldIconMapping.tags)
    ) : (
      <Minus color="transparent" className="hidden" />
    );

  const iconColor = group.color
    ? rgbColor(group.color)
    : groupByOption === "objective"
    ? "text-objectives-icon"
    : "text-title_50";

  return (
    <Droppable droppableId={group._id} type="card">
      {(provided, snapshot) => (
        <div
          key={group._id}
          className="flex-none w-96 p-4 rounded-lg bg-gray-50"
          style={{ backgroundColor }}
        >
          <div className="flex items-center justify-between w-full mb-3">
            <div
              className="w-fit rounded"
              // style={{
              //   backgroundColor: headerBackgroundColor,
              // }}
            >
              {groupByOption === "importance" ? (
                <div className=" flex justify-center items-center  gap-2 pl-1 pt-2   ">
                  <IconWrapper
                    icon={React.createElement(fieldIconMapping.importance)}
                    iconColor={iconColor}
                    size={14}
                  />
                  <Importance value={group.label} readOnly={true} />
                </div>
              ) : (
                <IconText
                  icon={icon}
                  text={group.label}
                  truncateLength={30}
                  iconColor={iconColor}
                  hoverable={false}
                  textColor="text-title"
                  gap_x="gap-x-2"
                  isKanban={true}
                />
              )}
            </div>

            {isRoadmapDetailColumn && (
              <div
                className={`relative  text-gray-500     rounded cursor-pointer ${
                  activeAddDropdown?._id === group?._id
                    ? "bg-button-active_10"
                    : " hover:bg-topbar-button-active "
                }`}
                // className={`relative rounded cursor-pointer ${
                //   activeAddDropdown?._id === group?._id
                //     ? "bg-button-active_10"
                //     : "bg-topbar-button-hover hover:bg-button-hover"
                // }`}
                onClick={() => {
                  if (activeAddDropdown) {
                    setActiveAddDropdown(null);
                  } else {
                    setActiveAddDropdown({
                      _id: group?._id,
                      label: group?.label,
                    });
                  }
                }}
              >
                {activeAddDropdown?._id === group?._id ? (
                  <MinusSmall size={24} />
                ) : (
                  <PlusSmall size={24} />
                )}
                {AddItemsSidebar}
              </div>
            )}
          </div>
          {isRoadmapDetailColumn && <hr className="mb-4" />}
          <div
            className="flex flex-col gap-y-5 h-full"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {itemsForGroup?.map((item, index) => (
              <Draggable
                key={item._id}
                draggableId={item._id.toString()}
                index={index}
              >
                {(provided) =>
                  renderCardComponent({
                    item,
                    products,
                    innerRef: provided.innerRef,
                    draggableProps: provided.draggableProps,
                    dragHandleProps: provided.dragHandleProps,
                    statusArray,
                    handleRemoveIdea,
                    // setActiveCard, // Optional: Pass this if you need to set active card
                  })
                }
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
};

export default KanbanColumn;
