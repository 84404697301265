import React, { useState } from "react";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Empty, Input, Modal, Typography } from "antd";
import { Users } from "react-flaticons";
import { apiConnector } from "../../../api/apiconnector";
import { toast } from "react-toastify";
import { userEndPoints } from "../../../api/api";
const { INVITE_USER } = userEndPoints;

const Default = () => {
  const { users } = useSelector((state) => state.users);
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [email, setEmail] = useState(""); // email state
  const [loading, setLoading] = useState(false); // loading state for API call
  const [error, setError] = useState(false); // error state for invalid email

  // Email validation function
  const isEmailValid = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  // Filter users based on search term (by name or email)
  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleModalCancel = () => {
    setIsInviteOpen(false);
    setEmail(""); // clear email field when modal closes
  };

  const handleSubmit = async () => {
    if (!isEmailValid(email)) {
      setError(true);
      // Show error for 3 seconds, then clear it
      setTimeout(() => setError(false), 3000);
      return;
    }

    setLoading(true); // set loading true during API call

    try {
      const response = await apiConnector("POST", INVITE_USER, { email });

      if (response.status === 201 && response.data) {
        toast.success("User invited successfully!");
        setIsInviteOpen(false);
        setEmail(""); // clear email on successful invite
      } else {
        toast.error("Failed to invite the user. Please try again.");
      }
    } catch (err) {
      toast.error(
        err?.response?.data?.message ??
          "An error occurred while inviting the user. Please try again later."
      );
      console.error("Error inviting user:", err);
    } finally {
      setLoading(false); // set loading false once API call is finished
    }
  };

  return (
    <div className="container">
      <Modal
        title={
          <h2 className="flex gap-2 items-center  pt-1 pb-2">
            <Users size={18} />
            <span>Invite</span>
          </h2>
        }
        okText="Invite"
        open={isInviteOpen}
        onOk={handleSubmit}
        onCancel={handleModalCancel}
        confirmLoading={loading} // shows loading on the ok button
      >
        <div style={{ marginBottom: "32px" }}>
          <Input
            type="email"
            placeholder="Enter member email"
            // className="  mb-5 "
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {error && (
            <Typography.Text type="danger" className="mt-2">
              Please enter a valid email.
            </Typography.Text>
          )}
        </div>
      </Modal>

      <div className="flex gap-4 mb-10">
        <Input
          placeholder="Search member"
          prefix={
            <SearchOutlined
              style={{
                color: isFocused ? "#696969" : "#d3d3d3", // dark gray when focused, light gray when not focused
                fontSize: 14,
                marginRight: "4px",
              }}
            />
          }
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
        <Button onClick={() => setIsInviteOpen(true)} type="primary">
          Invite member
        </Button>
      </div>

      {filteredUsers && filteredUsers.length > 0 ? (
        <div>
          <table className="min-w-full table-auto">
            <thead>
              <tr className="text-sm">
                <th className="text-title text-start pr-1 font-normal w-1/2">
                  <span className="flex items-center gap-x-1">Name</span>
                </th>
                <th className="text-title text-start pr-1 font-normal w-1/2">
                  Email
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user, index) => (
                <tr
                  key={index}
                  className="text-title text-[14px] rounded relative"
                >
                  <td className="truncate pr-1 py-2 w-1/2">{user.name}</td>
                  <td className="truncate pr-1 py-2 w-1/2">{user.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex justify-center items-top h-[80vh]">
          <Empty
            style={{ image: { height: 60 } }}
            description={
              <Typography.Text className="font-bold text-primary">
                No member
              </Typography.Text>
            }
          />
        </div>
      )}
    </div>
  );
};

export default Default;

// import React, { useState } from "react";
// import { useSelector } from "react-redux";
// import InviteUserPopup from "../../common/InviteUserPopup";
// import { SearchOutlined, UserOutlined } from "@ant-design/icons";
// import { Button, Empty, Input, Modal, Typography } from "antd";
// import { Search, Users } from "react-flaticons";
// import { apiConnector } from "../../../api/apiconnector";
// import { toast } from "react-toastify";
// import { userEndPoints } from "../../../api/api";
// const { INVITE_USER } = userEndPoints;

// const Default = () => {
//   const { users } = useSelector((state) => state.users);
//   const [isInviteOpen, setIsInviteOpen] = useState(false);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [isFocused, setIsFocused] = useState(false);

//   // Filter users based on search term (by name or email)
//   const filteredUsers = users.filter(
//     (user) =>
//       user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
//       user.email.toLowerCase().includes(searchTerm.toLowerCase())
//   );

//   const handleModalCancel = () => {
//     setIsInviteOpen(false);
//   };

//   const handleSubmit = async () => {
//     if (!isEmailValid(email)) {
//       setError(true);
//       // Show error for 3 seconds, then clear it
//       setTimeout(() => setError(false), 3000);
//       return;
//     }

//     setLoading(true); // Set loading to true

//     try {
//       const response = await apiConnector("POST", INVITE_USER, { email });

//       if (response.status === 201 && response.data) {
//         toast.success("User invited successfully!");
//         onClose();
//       } else {
//         toast.error("Failed to invite the user. Please try again.");
//       }
//     } catch (error) {
//       toast.error(
//         error?.response?.data?.message ??
//           "An error occurred while inviting the user. Please try again later."
//       );
//       console.error("Error inviting user:", error);
//     } finally {
//       setLoading(false); // Set loading to false
//     }
//   };

//   return (
//     <div className="   ">
//       <Modal
//         title={
//           <h2 className="flex gap-2 items-center text-gray-600 pt-1 pb-2">
//           <Users size={18} />
//           <span>Invite</span>
//         </h2>
//         }
//         // title="Basic Modal"
//         okText="Invite"
//         open={isInviteOpen}
//         onOk={handleSubmit}
//         onCancel={handleModalCancel}
//       >
//         <p>Some contents...</p>
//         <p>Some contents...</p>
//         <p>Some contents...</p>
//       </Modal>
//       <div className="flex gap-4 mb-10">
//         <Input
//           placeholder="Search member "
//           prefix={
//             <SearchOutlined
//               style={{
//                 color: isFocused ? "#696969" : "#d3d3d3", // dark gray when focused, light gray when not focused
//                 fontSize: 14,
//                 marginRight: "4px",
//               }}
//             />
//           }
//           value={searchTerm}
//           onChange={(e) => setSearchTerm(e.target.value)}
//           onFocus={() => setIsFocused(true)}
//           onBlur={() => setIsFocused(false)}
//         />
//         <Button onClick={() => setIsInviteOpen(true)} type="primary">
//           Invite member
//         </Button>
//       </div>
//       {filteredUsers && filteredUsers.length > 0 ? (
//         <div className="">
//           <table className="min-w-full table-auto">
//             <thead>
//               <tr className="text-sm">
//                 <th className="text-title text-start pr-1 font-normal w-1/2">
//                   <span className="flex items-center gap-x-1">Name</span>
//                 </th>
//                 <th className="text-title text-start pr-1 font-normal w-1/2">
//                   Email
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredUsers.map((user, index) => (
//                 <tr
//                   key={index}
//                   className="text-title text-[14px] rounded relative"
//                 >
//                   <td className="truncate pr-1 py-2 w-1/2">{user.name}</td>
//                   <td className="truncate pr-1 py-2 w-1/2">{user.email}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       ) : (
//         <div className="flex justify-center items-top h-[80vh]">
//           <Empty
//             styles={{ image: { height: 60 } }}
//             description={
//               <Typography.Text className=" font-bold text-primary">
//                 No member
//               </Typography.Text>
//             }
//           ></Empty>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Default;
