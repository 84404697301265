import React, { useEffect, useCallback, useState } from "react";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import BubbleMenuComponent from "./BubbleMenuComponent";
import FloatingMenuComponent from "./FloatingMenuComponent";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Underline from "@tiptap/extension-underline";
import Highlight from "@tiptap/extension-highlight";
import TextAlign from "@tiptap/extension-text-align";
import OrderedList from "@tiptap/extension-ordered-list";
import BulletList from "@tiptap/extension-bullet-list";
import Link from "@tiptap/extension-link";
import "./style.css";
import ResizableImage from "./ResizableImage";
import CustomURLPrompt from "./CustomURLPrompt";
import Loading from "./Loading";

const Editor = ({ content, onChange }) => {
  const [showURLPrompt, setShowURLPrompt] = useState(false);
  const [selectedURL, setSelectedURL] = useState("");
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Document,
      Paragraph,
      Text,
      Underline,
      Highlight,
      TextAlign.configure({
        types: ["heading", "paragraph"],
      }),
      OrderedList,
      BulletList,
      ResizableImage.configure({
        inline: false,
        HTMLAttributes: {
          class: "custom-image-class",
        },
      }),
      Link.configure({
        openOnClick: true,
        autolink: true,
        linkOnPaste: true,
        defaultProtocol: "https",
        protocols: ["http", "https"],
        isAllowedUri: (url, ctx) => {
          try {
            // construct URL
            const parsedUrl = url.includes(":")
              ? new URL(url)
              : new URL(`${ctx.defaultProtocol}://${url}`);

            // use default validation
            if (!ctx.defaultValidate(parsedUrl.href)) {
              return false;
            }

            // disallowed protocols
            const disallowedProtocols = ["ftp", "file", "mailto"];
            const protocol = parsedUrl.protocol.replace(":", "");

            if (disallowedProtocols.includes(protocol)) {
              return false;
            }

            // only allow protocols specified in ctx.protocols
            const allowedProtocols = ctx.protocols.map((p) =>
              typeof p === "string" ? p : p.scheme
            );

            if (!allowedProtocols.includes(protocol)) {
              return false;
            }

            // disallowed domains
            const disallowedDomains = [
              "example-phishing.com",
              "malicious-site.net",
            ];
            const domain = parsedUrl.hostname;

            if (disallowedDomains.includes(domain)) {
              return false;
            }

            // all checks have passed
            return true;
          } catch {
            return false;
          }
        },
        shouldAutoLink: (url) => {
          try {
            // construct URL
            const parsedUrl = url.includes(":")
              ? new URL(url)
              : new URL(`https://${url}`);

            // only auto-link if the domain is not in the disallowed list
            const disallowedDomains = [
              "example-no-autolink.com",
              "another-no-autolink.com",
            ];
            const domain = parsedUrl.hostname;

            return !disallowedDomains.includes(domain);
          } catch {
            return false;
          }
        },
      }),
    ],
    editorProps: {
      attributes: {
        class:
          "prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none leading-tight ankur",
      },
    },
    content: content,
  });

  const setLink = () => {
    if (!editor) return;

    const previousUrl = editor.getAttributes("link").href || "";
    setSelectedURL(previousUrl);
    setShowURLPrompt(true);
  };

  const handleConfirmURL = (url) => {
    if (!url) {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
    } else {
      if (!/^https?:\/\//.test(url)) {
        url = `https://${url}`;
      }

      editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    }
    setShowURLPrompt(false);
  };

  useEffect(() => {
    if (!editor) return;

    const handleKeyDown = ({ event }) => {
      if (event.key === "Enter") {
        setTimeout(() => {
          const prevText = editor.getText().slice(-2); // Get last 2 characters
          if (prevText === "./") {
            setShowFloatingMenu(true);
          }
        }, 50);
      }
    };

    editor.on("keydown", handleKeyDown);
    return () => editor.off("keydown", handleKeyDown);
  }, [editor]);

  useEffect(() => {
    if (editor) {
      const saveContent = () => {
        const updatedContent = editor.getHTML();
        onChange(updatedContent);
      };

      editor.on("update", saveContent);

      return () => {
        editor.off("update", saveContent);
      };
    }
  }, [editor]);

  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      editor.commands.setContent(content);
    }
  }, [content, editor]);

  return (
    <>
      <BubbleMenuComponent
        editor={editor}
        setLink={setLink}
        setIsUploadingImage={setIsUploadingImage}
        isUploadingImage={isUploadingImage}
      />
      <FloatingMenuComponent
        editor={editor}
        setIsUploadingImage={setIsUploadingImage}
        isUploadingImage={isUploadingImage}
      />
      <EditorContent editor={editor} />
      {showURLPrompt && (
        <CustomURLPrompt
          previousUrl={selectedURL}
          onConfirm={handleConfirmURL}
          onCancel={() => setShowURLPrompt(false)}
        />
      )}
      {isUploadingImage && <Loading />}
    </>
  );
};

export default Editor;
