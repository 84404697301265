import { useEffect, useState } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import SettingsTopbar from "../components/settings/SettingsTopbar";
import { toSnakeCase } from "../utils";
import { User } from "react-flaticons";
import IconText from "../components/common/IconText";

const Profile = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    {
      key: "profile",
      label: "My Profile",
    },
    {
      key: "profile/security",
      label: "Security",
    },
  ];

  const getInitialTab = () => {
    const currentPath = location.pathname.split("/").pop();
    const foundTab = tabs.find((tab) => toSnakeCase(tab.key) === currentPath);
    return foundTab || tabs[0];
  };

  const [activeTab, setActiveTab] = useState(getInitialTab());

  const handleTabClick = (key) => {
    const foundTab = tabs.find((tab) => tab.key === key);
    if (foundTab) {
      setActiveTab(foundTab);
    }
  };

  useEffect(() => {
    const newPath = toSnakeCase(activeTab.key);
    navigate(`/settings/${newPath}`);
  }, [activeTab, navigate]);

  return (
    <div className="relative w-full flex justify-center">
      {/* <div className="absolute top-4 left-4">
        <IconText
          icon={<User />}
          iconSize={14}
          textSize="14px"
          text="Profile"
          hoverable={false}
          isASidebarComponent={false}
          px="px-0"
          py="py-0"
          isTextActive={true}
          isIconActive={true}
        />
      </div> */}
      <div>
        <SettingsTopbar
          title="Profile"
          description="Update your info, manage security, and customize your account"
          tabs={tabs}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
          icon={<User />}
          defaultActiveKey="profile"
        />
        <Outlet />
      </div>
    </div>
  );
};

export default Profile;
