import {
  appendProduct,
  updateProduct,
  removeProduct,
  setProducts,
} from "../slices/companySlice";
import { apiConnector } from "api/apiconnector";
import { productEndPoints } from "api/api";
import { setObjectives } from "../slices/objectiveSlice";
import { setRoadmaps } from "../slices/roadmapsSlice";
import { setFeatures } from "../slices/featureSlice";
import { setIdeas } from "../slices/ideaSlice";
import { setKeyResults } from "../slices/keyResultSlice";
import { getAllObjectives } from "./objectivesAPI";
import { fetchCompanyData } from "./companyAPI";
import { getPersonalViews } from "./viewsAPI";
import { getAllFeatures } from "./featuresAPI";
import { getAllIdeas } from "./ideasAPI";
import { getAllRoadmaps } from "./roadmapsAPI";
import { getAllKeyResults } from "./keyResultsAPI";
import { useSelector } from "react-redux";

const {
  ADD_COMPANY_PRODUCT,
  EDIT_COMPANY_PRODUCT,
  DELETE_COMPANY_PRODUCT,
  GET_COMPANY_PRODUCTS,
} = productEndPoints;

export const getCompanyProducts = () => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("GET", GET_COMPANY_PRODUCTS());
      if (response.status === 200) {
        dispatch(setProducts(response.data.reverse()));
      }
    } catch (error) {
      console.error("Error getting products", error);
    }
  };
};

export const addProductToCompany = (label, color) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("POST", ADD_COMPANY_PRODUCT(), {
        label,
        color,
      });
      if (response.status === 201) {
        dispatch(appendProduct(response.data.addedProduct));
      } else {
        console.error("Failed to add product:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };
};

export const editProductInCompany = (productId, label, color) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "PUT",
        EDIT_COMPANY_PRODUCT(productId),
        { label, color }
      );
      if (response.status === 200) {
        dispatch(updateProduct(response.data.updatedProduct));
        dispatch(getAllObjectives());
        dispatch(getPersonalViews()),
          dispatch(getAllFeatures()),
          dispatch(getAllIdeas()),
          dispatch(getAllRoadmaps()),
          dispatch(getAllKeyResults());
      } else {
        console.error("Failed to update product:", response.statusText);
      }
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };
};

export const deleteProductOfCompany = (productId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_COMPANY_PRODUCT(productId)
      );
      if (response.status === 200) {
        // dispatch(setObjectives(null));
        // dispatch(setRoadmaps(null));
        // dispatch(setFeatures(null));
        // dispatch(setIdeas(null));
        // dispatch(setKeyResults(null));
        dispatch(removeProduct(productId));

        dispatch(getAllObjectives());
        dispatch(getPersonalViews()),
          dispatch(getAllFeatures()),
          dispatch(getAllIdeas()),
          dispatch(getAllRoadmaps()),
          dispatch(getAllKeyResults());
      } else {
        console.error("Failed to delete product:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };
};
