import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCookie } from "utils/cookieUtils";
import { setLoading } from "reduxStore/slices/authSlice";
import Loader from "../common/Loader";
import { useSearchParams } from "react-router-dom";
import { inviteProcess } from "../../reduxStore/operations/userAPI";
import { User } from "react-flaticons";
import { IoLockOpenOutline } from "react-icons/io5";

const Invite = () => {
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [passwordLength, setPasswordLength] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [isNumber, setIsNumber] = useState(false);
  const [isSymbol, setIsSymbol] = useState(false);
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    if (password.length >= 8) {
      setPasswordLength(true);
    } else {
      setPasswordLength(false);
    }
    const hasUpperCase = password
      .split("")
      .some((char) => char === char.toUpperCase() && isNaN(char));

    setIsUpperCase(hasUpperCase);

    const hasLowerCase = password
      .split("")
      .some((char) => char === char.toLowerCase() && isNaN(char));

    setIsLowerCase(hasLowerCase);

    const hasNumber = password
      .split("")
      .some((char) => !isNaN(char) && char !== " ");
    setIsNumber(hasNumber);
    const specialChars = /[@$!%*?&^_#]/;
    const hasSpecialChar = specialChars.test(password);
    setIsSymbol(hasSpecialChar);
  }, [password]);

  const Usertoken = searchParams.get("token");

  const isStrongPassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&^_#])[A-Za-z\d@$!%*?&^_#]{8,}$/;
    return regex.test(password);
  };

  const validateForm = () => {
    let errors = {};

    if (!name) {
      errors.name = "Name is required.";
    }

    if (!password) {
      errors.password = "Password is required.";
    } else if (
      !isStrongPassword(password) ||
      !passwordLength ||
      !isUpperCase ||
      !isLowerCase ||
      !isNumber ||
      !isSymbol
    ) {
      errors.password =
        "Password require to contain at least 8 characters including one Capital letter, one small letter and one special character [@$!%*?&^_#].";
    }

    setErrorMessages(errors);

    return Object.keys(errors).length === 0;
  };

  const handleCompanyRegister = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(setLoading(true));
      dispatch(inviteProcess(name, password, Usertoken, navigate));
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    if (token) {
      // navigate("/"); // Redirect to home page if user is already logged in
    }
  }, [navigate]);

  return (
    // <div className="flex flex-col gap-4 items-center justify-center min-h-screen bg-gray-100">
    //   <div className=" relative bg-white p-8 rounded shadow-md w-full max-w-sm">
    //     <div className="  absolute top-[-120px] right-0 w-full  flex items-center justify-center  ">
    //       <img
    //         src="/logo.png  "
    //         alt="logo"
    //         className=" w-0 h-0  xl:w-[200px] xl:h-[100px] "
    //       />{" "}
    //     </div>
    //     <h2 className="text-2xl mb-6 text-center">Welcome to Lane!</h2>
    //     <form onSubmit={handleCompanyRegister}>
    //       <div className="mb-4">
    //         <label className="block text-gray-700">Name</label>
    //         <input
    //           type="text"
    //           className={`w-full p-2 border ${
    //             errorMessages.name ? "border-red-500" : "border-gray-300"
    //           } rounded mt-1`}
    //           value={name}
    //           placeholder="Name"
    //           onChange={(e) => setName(e.target.value)}
    //         />
    //         {errorMessages.name && (
    //           <p className="text-red-500 text-sm mt-1">{errorMessages.name}</p>
    //         )}
    //       </div>

    //       <div className="mb-4">
    //         <label className="block text-gray-700">Password</label>
    //         <input
    //           type="password"
    //           className={`w-full p-2 border ${
    //             errorMessages.password ? "border-red-500" : "border-gray-300"
    //           } rounded mt-1`}
    //           value={password}
    //           placeholder="Password"
    //           onChange={(e) => setPassword(e.target.value)}
    //         />
    //         {errorMessages.password && (
    //           <p className="text-red-500 text-sm mt-1">
    //             {errorMessages.password}
    //           </p>
    //         )}
    //       </div>

    //       <button
    //         type="submit"
    //         className="w-full bg-blue-500 text-white p-2 rounded mt-4 hover:bg-blue-600"
    //       >
    //         {loading ? <Loader size="20" color="white" /> : "Take me in"}
    //       </button>
    //     </form>
    //   </div>
    // </div>
    <div className="h-screen flex flex-col  md:flex-row font-inter overflow-hidden ">
      <div className="w-full md:w-[60%] xl:w-[45%] max-w-[55rem] min-w-[24rem]  md:min-w-[32rem] flex flex-col items-center justify-center p-2 md:p-4">
        <div className="flex flex-col gap-8   justify-center  p-4 md:p-8  w-full max-w-[30rem]">
          <img
            src="/lane_icon_signin.svg"
            alt="logo"
            className="w-[125px] h-[75px] mr-auto"
          />
          <h2 className="text-2xl text-left w-full  ">Create your account</h2>
          <form onSubmit={handleCompanyRegister}>
            <div className="mb-4">
              <label className="block text-gray-700">Name</label>
              {/* <input
                type="text"
                className={`w-full p-2 border ${
                  errorMessages.name ? "border-red-500" : "border-gray-300"
                } rounded mt-1`}
                value={name}
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              /> */}
              <div className="relative w-full">
                <User
                  className="absolute left-3 top-[15px] text-gray-400"
                  size={18}
                />
                <input
                  type="text"
                  className={`w-full p-2 pl-10 border ${
                    errorMessages.name ? "border-red-500" : "border-gray-300"
                  } rounded mt-1`}
                  value={name}
                  // placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              {errorMessages.name && (
                <p className="text-red-500 text-sm mt-1">
                  {errorMessages.name}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label className="block text-gray-700">Password</label>
              {/* <input
                type="password"
                className={`w-full p-2 border ${
                  errorMessages.password ? "border-red-500" : "border-gray-300"
                } rounded mt-1`}
                value={password}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
              /> */}
              <div className="relative">
                <IoLockOpenOutline
                  className="absolute left-3 top-[14px] text-gray-400"
                  size={20}
                />
                <input
                  type="password"
                  className={`w-full p-2 pl-10 border ${
                    errorMessages.password
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded mt-1`}
                  value={password}
                  // placeholder="Password"
                  onChange={(e) => {
                    setIsHidden(false);
                    setPassword(e.target.value);
                  }}
                />
              </div>
              {errorMessages.password && (
                <p className="text-red-500 text-sm mt-1">
                  {errorMessages.password}
                </p>
              )}
            </div>
            <h2
              className={`   font-semibold text-sm text-red-600  transition-all duration-100   ${
                isHidden ? "  opacity-0  " : "  opacity-100  "
              }  `}
            >
              <span className={`${passwordLength ? "text-green-500" : ""}`}>
                {" "}
                Use at least 8 characters,
              </span>{" "}
              <span className={`${isUpperCase ? "text-green-500" : ""}`}>
                an uppercase
              </span>{" "}
              <span className={`${isLowerCase ? "text-green-500" : ""}`}>
                , a lowercase,
              </span>{" "}
              <span className={`${isNumber ? "text-green-500" : ""}`}>
                a number
              </span>{" "}
              <span className={`${isSymbol ? "text-green-500" : ""}`}>
                and a special symbol (@, $, !, %, *, ?, &, ^, _, #).
              </span>
            </h2>

            <button
              type="submit"
              className="w-full  bg-signup-button font-bold text-white p-2 rounded mt-4 hover:bg-blue-600"
            >
              {loading ? <Loader size="20" color="white" /> : "Take me in"}
            </button>
          </form>
        </div>
      </div>
      <div className="hidden md:block bg-[#F9FBFC] md:w-[40%] xl:w-[55%] relative pl-[6rem]">
        {/* Quote */}
        <h2 className="pt-[64px] pr-0 md:pr-[7rem]  md:text-[17px] lg:text-[24px]  xl:text-[28px]  text-gray-800">
          “Unplanned 'fire-fighting' tasks consume 52% of product managers time”
        </h2>

        {/* Background Image */}
        <div className="absolute top-[14rem] left-[6rem] bottom-0 border  rounded-xl overflow-hidden">
        <img
            src="/signup3-cropped.svg"
            alt="Background"
            className="w-full xl:min-w-[60rem]  h-full object-cover"
            style={{
              objectPosition: "top left", // Align the image to the top-left corner
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Invite;
