/* eslint-disable react/prop-types */
import React from "react";
import IconText from "components/common/IconText";
import ProfileIcon from "components/common/ProfileIcon";
import ProductSection from "components/common/detailView/detailViewRightSidebarSections/ProductSection";
import { useDispatch, useSelector } from "react-redux";
import StatusSection from "components/common/detailView/detailViewRightSidebarSections/StatusSection";
import {
  setActiveIdeaId,
  setIdeaShowDetailViewDrawer,
} from "reduxStore/slices/ideaSlice";
import { updateIdeaData } from "reduxStore/operations/ideasAPI";
import Importance from "components/common/Importance";
import TagsSection from "components/common/detailView/detailViewRightSidebarSections/TagsSection";
import { fieldIconMapping } from "utils";
import {
  updateIdeaTags,
  updateIdeaAccounts,
} from "reduxStore/operations/ideasAPI";
import KanbanRoadmapsSection from "components/common/kanban/KanbanRoadmapsSection";
import KanbanFeaturesSection from "components/common/kanban/KanbanFeaturesSection";
import AccountsSection from "components/common/detailView/detailViewRightSidebarSections/AccountsSection";
import KanbanScoreSection from "../../../common/kanban/KanbanScoreSection";
import { Cross, CrossSmall } from "react-flaticons";
import { useLocation, useNavigate } from "react-router-dom";
import AssigneeSection from "../../../common/detailView/detailViewRightSidebarSections/AssigneeSection";
import SourceSection from "../../../common/detailView/detailViewRightSidebarSections/SourceSection";
import CreatorSection from "../../../common/detailView/detailViewRightSidebarSections/CreatorSection";
import IconWrapper from "../../../common/IconWrapper";
import { PiUserCircleDashedBold } from "react-icons/pi";

const IdeaKanbanCard = ({
  idea,
  innerRef,
  draggableProps,
  dragHandleProps,
  statusArray,
  handleRemoveIdea,
}) => {
  const dispatch = useDispatch();
  const isRoadmapView = window.location.href.includes("roadmaps");
  const { selectedColumns, showDetailViewDrawer, groupByOption } = useSelector(
    (state) => state.ideas
  );

  const { products, tags } = useSelector((state) => state.company);
  const { accounts } = useSelector((state) => state.accounts);
  const { users } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.user);
  const { selectedIdeaFilter } = useSelector(
    (state) => state.selectedIdeaFilter
  );

  const creator =
    users.find((user) => user._id.toString() === idea.submittedBy) || user; // Fallback to current user if creator is not found

  const sourceTypes = [{ label: "Internal" }, { label: "External" }];

  const handleKanbanItemClick = () => {
    dispatch(setActiveIdeaId(idea._id));
    dispatch(setIdeaShowDetailViewDrawer(!showDetailViewDrawer));
  };

  const handleImportanceOptionClick = (option) => {
    dispatch(updateIdeaData(idea._id, { importance: option }));
  };

  return (
    <div
      className=" relative group flex flex-col bg-white border border-gray-200 rounded-lg p-4 text-primary hover:cursor-pointer text-xs    "
      onClick={handleKanbanItemClick}
      ref={innerRef}
      {...(groupByOption !== "tags" &&
        groupByOption !== "accounts" && {
          ...draggableProps,
          ...dragHandleProps,
        })}
    >
      {isRoadmapView && (
        <button
          className=" group-hover:opacity-100 opacity-0  hover:bg-button-hover rounded p-0.5 cursor-pointer w-fit absolute  right-2 top-2  "
          onClick={(e) => handleRemoveIdea(e, idea)}
        >
          <CrossSmall size={14} />
        </button>
      )}
      {isRoadmapView ? (
        <IconText
          icon={React.createElement(
            idea.type === "Idea"
              ? fieldIconMapping.idea
              : fieldIconMapping.feedback
          )}
          text={idea?.title}
          truncateLength="60"
          align={"items-start"}
          iconColor={idea.type === "Idea" ? "text-ideas-icon" : "#7C33F4"}
          textColor="text-title"
          hoverable={false}
          px=""
        />
      ) : (
        <IconText
          icon={React.createElement(
            selectedIdeaFilter === "idea"
              ? fieldIconMapping.idea
              : fieldIconMapping.feedback
          )}
          text={idea?.title}
          truncateLength="60"
          align={"items-start"}
          iconColor={
            selectedIdeaFilter === "idea" ? "text-ideas-icon" : "#7C33F4"
          }
          textColor="text-title"
          hoverable={false}
          px=""
        />
      )}

      {selectedColumns.includes("tags") && (
        <div className="flex gap-x-2 items-center mt-5">
          <TagsSection
            item={idea}
            with_title={false}
            with_icon_before_tags={true}
            tags={tags ?? []}
            handleTagOptionClick={(option) => {
              dispatch(updateIdeaTags(option));
            }}
            dropdownPosition={"top-full left-0"}
          />
        </div>
      )}
      {selectedColumns.includes("accounts") && (
        <div className="flex gap-x-2 items-center">
          <AccountsSection
            item={idea}
            with_title={false}
            with_icon_before_accounts={true}
            accounts={accounts ?? []}
            handleAccountOptionClick={(option) => {
              dispatch(updateIdeaAccounts(option));
            }}
            dropdownPosition={"top-full left-0"}
          />
        </div>
      )}
      <div className="flex gap-x-4">
        {selectedColumns.includes("score") && (
          <KanbanScoreSection item={idea} />
        )}
        {
          selectedColumns.includes("creator") &&
            // <CreatorSection
            //   creator={creator}
            //   with_label_title={false}
            //   with_border={false}
            //   iconSize={14}
            //   position="gap-x-0"
            // />

            (!idea?.slack_message_link ? (
              <div className="flex justify-center items-center gap-x-0  ">
                <IconText
                  icon={React.createElement(fieldIconMapping.creator)}
                  text={""}
                  iconColor="#696969"
                  iconSize={14}
                  hoverable={false}
                  gap_x={""}
                />
                <ProfileIcon name={creator.name} size="22px" />
              </div>
            ) : (
              <div className=" flex  justify-center items-center  gap-x-2 pl-[10px] py-1  ">
                <div className=" flex gap-2 items-center justify-center    ">
                  <IconWrapper
                    size={14}
                    iconColor="#696969"
                    icon={React.createElement(fieldIconMapping.creator)}
                  />
                </div>
                <h2 className=" text-xs text-title_50 ">via Slack</h2>
              </div>
            ))

          // <div className="flex justify-center items-center gap-x-0  ">
          //   <IconText
          //     icon={React.createElement(fieldIconMapping.creator)}
          //     text={""}
          //     iconColor="#696969"
          //     iconSize={14}
          //     hoverable={false}
          //     gap_x={""}
          //   />
          //   <ProfileIcon name={creator.name} size="22px" />
          // </div>
        }
        {selectedColumns.includes("source") &&
          // <SourceSection
          //   sourceLabel={idea.source ?? "Internal"}
          //   position="gap-x-0"
          //   with_border={false}
          //   iconSize={14}
          //   with_label_text={false}
          //   sources={sourceTypes ?? []}
          //   with_dropdown={true}
          //   handleSourceOptionClick={(option) => {
          //     if (idea?.source !== option.label) {
          //       let { icon, ...restOption } = option;
          //       dispatch(
          //         updateIdeaData(
          //           idea._id,
          //           { source: option.label },
          //           idea,
          //           "source",
          //           restOption
          //         )
          //       );
          //     }
          //   }}
          // />
          (!idea?.slack_message_link ? (
            <SourceSection
              sourceLabel={idea.source ?? "Internal"}
              position="gap-x-0"
              with_border={false}
              iconSize={14}
              with_label_text={false}
              sources={sourceTypes ?? []}
              with_dropdown={true}
              handleSourceOptionClick={(option) => {
                if (idea?.source !== option.label) {
                  let { icon, ...restOption } = option;
                  dispatch(
                    updateIdeaData(
                      idea._id,
                      { source: option.label },
                      idea,
                      "source",
                      restOption
                    )
                  );
                }
              }}
            />
          ) : (
            <div className=" flex gap-x-[50px] pl-2 py-1  ">
              {/* <div className=" flex gap-2 items-center justify-center    ">
                <IconWrapper
                  icon={React.createElement(fieldIconMapping.source)}
                />
                <h2 className=" text-sm ">Source</h2>
              </div> */}
              <div className=" flex gap-2 justify-center  items-center  ">
                <img src="/slack_icon.svg" alt="jira" width={18} height={18} />
                <h2 className=" text-xs text-title_50 ">Slack</h2>
              </div>
            </div>
          ))}
      </div>
      {selectedColumns.includes("roadmap") && (
        <KanbanRoadmapsSection roadmaps={idea?.roadmaps} />
      )}
      {selectedColumns.includes("feature") && (
        <KanbanFeaturesSection features={idea?.features} />
      )}
      <hr className="w-full mt-1" />
      <div className="flex items-center justify-between mt-2.5">
        <div className="flex flex-1 gap-x-3 items-center">
          {selectedColumns.includes("status") && (
            <div className="relative">
              <StatusSection
                item={idea?.status ?? {}}
                statusArray={statusArray}
                handleStatusOptionClick={(option) => {
                  let isUpdated = "status";
                  let { icon, ...restOption } = option;
                  dispatch(
                    updateIdeaData(
                      idea._id,
                      { status: option._id },
                      idea,
                      isUpdated,
                      restOption
                    )
                  );
                }}
                position=""
                with_title={false}
                with_label={false}
                dropdownMenuWidth="w-36"
              />
            </div>
          )}
          {selectedColumns.includes("importance") && (
            <Importance
              value={idea.importance}
              handleOptionClick={handleImportanceOptionClick}
            />
          )}
          {selectedColumns.includes("product") && (
            <div className="relative">
              <ProductSection
                item={idea.product}
                products={products ?? []}
                handleProductOptionClick={(option) => {
                  dispatch(updateIdeaData(idea._id, { product: option._id }));
                }}
                position=""
                with_title={false}
              />
            </div>
          )}
        </div>
        {selectedColumns.includes("assignee") && (
          // <AssigneeSection
          //   item={idea}
          //   assignee={idea.assignee}
          //   itemLabel={idea?.assignee?.name ?? ""}
          //   assigneesArray={users}
          //   handleAssineeOptionClick={(option) => {
          //     if (idea?.assignee?._id !== option._id) {
          //       let { icon, ...restOption } = option;
          //       dispatch(
          //         updateIdeaData(
          //           idea._id,
          //           { assignee: option._id },
          //           "assignee",
          //           idea,
          //           restOption
          //         )
          //       );
          //     }
          //   }}
          //   with_title={false}
          //   with_text={false}
          //   textLength={9}
          // />
          <AssigneeSection
            item={idea}
            assignee={idea?.assignee}
            itemLabel={idea?.assignee?.name ?? ""}
            assigneesArray={users}
            handleAssineeOptionClick={(option) => {
              if (idea?.assignee?._id !== option._id) {
                let { icon, ...restOption } = option;
                dispatch(
                  updateIdeaData(
                    idea._id,
                    { assignee: option._id },
                    "assignee",
                    idea,
                    restOption
                  )
                );
              }
            }}
            with_title={false}
            with_text={false}
            textLength={9}
          />
        )}
      </div>
    </div>
  );
};

export default IdeaKanbanCard;
