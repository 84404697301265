/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Check, CrossSmall } from "react-flaticons";
import CalendarSection from "./CalendarSection";
import { Input } from "antd";

const EditInputForm = ({
  inputLabel,
  label,
  setInputLabel,
  onEditInputEnter,
  onInputButtonClick,
  inputFocusCondition,
  handleRemoveOptionClick,
  startDate,
  endDate,
  setIsCalendarOpen,
  isCalendarActiveCondition,
  setStartDate,
  setEndDate,
  withCalendar = false,
  showRemovable = true,
  resetEditOptions,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="flex items-center gap-x-3 mt-[2px]">
      {/* <input
        type="text"
        value={inputLabel}
        onChange={(e) => setInputLabel(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onEditInputEnter();
          }
        }}
        onClick={() => {
          onInputButtonClick(false);
        }}
        className="p-2 border rounded w-48"
        autoFocus={inputFocusCondition}
      /> */}

      <Input
        value={inputLabel}
        onChange={(e) => setInputLabel(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onEditInputEnter();
          }
        }}
        onClick={() => {
          onInputButtonClick(false);
        }}
        className=" border rounded w-48"
        autoFocus={inputFocusCondition}
      />
      {withCalendar && (
        <CalendarSection
          startDate={startDate ?? null}
          endDate={endDate ?? null}
          setIsCalendarOpen={setIsCalendarOpen}
          isCalendarActiveCondition={isCalendarActiveCondition}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      )}
      {label !== inputLabel && (
        <div
          className={`flex items-center rounded-lg p-2 hover:bg-button-hover cursor-pointer transition-all duration-200 ease-in-out`}
          onClick={() => {
            onEditInputEnter();
          }}
        >
          <Check size={12} />
        </div>
      )}
      {showRemovable && label !== inputLabel && (
        <div
          className={`flex items-center rounded-lg p-2 hover:bg-button-hover cursor-pointer transition-all duration-200 ease-in-out`}
          onClick={() => {
            // handleRemoveOptionClick();
            resetEditOptions();
          }}
        >
          <CrossSmall size={14} />
        </div>
      )}
    </div>
  );
};

export default EditInputForm;

// /* eslint-disable react/prop-types */
// import React, { useState } from "react";
// import { CrossSmall } from "react-flaticons";
// import CalendarSection from "./CalendarSection";

// const EditInputForm = ({
//   inputLabel,
//   setInputLabel,
//   onEditInputEnter,
//   onInputButtonClick,
//   inputFocusCondition,
//   handleRemoveOptionClick,
//   startDate,
//   endDate,
//   setIsCalendarOpen,
//   isCalendarActiveCondition,
//   setStartDate,
//   setEndDate,
//   withCalendar = false,
//   showRemovable = true,
// }) => {
//   const [isHovered, setIsHovered] = useState(false);

//   return (
//     <div className="flex items-center gap-x-3">
//       <input
//         type="text"
//         value={inputLabel}
//         onChange={(e) => setInputLabel(e.target.value)}
//         onKeyDown={(e) => {
//           if (e.key === "Enter") {
//             onEditInputEnter();
//           }
//         }}
//         onClick={() => {
//           onInputButtonClick(false);
//         }}
//         className="p-2 border rounded w-48"
//         autoFocus={inputFocusCondition}
//       />

//       {withCalendar && (
//         <CalendarSection
//           startDate={startDate ?? null}
//           endDate={endDate ?? null}
//           setIsCalendarOpen={setIsCalendarOpen}
//           isCalendarActiveCondition={isCalendarActiveCondition}
//           setStartDate={setStartDate}
//           setEndDate={setEndDate}
//         />
//       )}
//       {showRemovable && (
//         <div
//           className={`flex items-center rounded-lg p-2 hover:bg-button-hover cursor-pointer transition-all duration-200 ease-in-out`}
//           onClick={() => {
//             handleRemoveOptionClick();
//           }}
//           onMouseEnter={() => {
//             setIsHovered(true);
//           }}
//           onMouseLeave={() => {
//             setIsHovered(false);
//           }}
//         >
//           <CrossSmall size={14} />
//           <span
//             className={`transition-all duration-200 ease-in-out ${
//               isHovered ? "opacity-100 ml-2" : "opacity-0 ml-0"
//             }`}
//           >
//             Delete
//           </span>
//         </div>
//       )}
//     </div>
//   );
// };

// export default EditInputForm;
