/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useMemo, useState } from "react";
import { generateStatusArray } from "utils/status";
import { fieldIconMapping } from "utils";
import Loader from "components/common/Loader";
import { DragDropContext } from "@hello-pangea/dnd";
import Feature2KanbanColumn from "./Feature2KanbanColumn";
import { updateFeatureData } from "reduxStore/operations/featuresAPI";

const Feature2KanbanView = ({ groupedData }) => {
  console.log(groupedData);
  const dispatch = useDispatch();
  const { groupByOption, isViewsLoading } = useSelector(
    (state) => state.features2
  );
  const { statuses, products, releases } = useSelector(
    (state) => state.company
  );
  const [featureStatuses, setFeatureStatuses] = useState([]);

  useEffect(() => {
    setFeatureStatuses(statuses.feature);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(featureStatuses),
    [featureStatuses]
  );

  const [localGroupByOption, setLocalGroupByOption] = useState(null);

  useEffect(() => {
    setLocalGroupByOption(groupByOption);
  }, [groupByOption]);

  const handleOnDragEnd = (result) => {
    const { source, destination, draggableId } = result;

    if (!destination || source.droppableId === destination.droppableId) return;

    if (groupByOption === "default") {
      const updateToStatusId = destination.droppableId;
      dispatch(
        updateFeatureData(
          draggableId,
          { status: updateToStatusId }
        )
      );
    }

    if (groupByOption === "release") {
      let updateToReleaseId = destination.droppableId;
      if (updateToReleaseId === "no-release") {
        updateToReleaseId = null;
      }
      dispatch(
        updateFeatureData(
          draggableId,
          { release: updateToReleaseId },
          "release"
        )
      );
    }

    if (groupByOption === "product") {
      const updateToProductId = destination.droppableId;
      dispatch(
        updateFeatureData(
          draggableId,
          { product: updateToProductId },
          "product"
        )
      );
    }
  };

  return (
    <div className={`h-full overflow-x-auto`}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <div className="flex gap-x-5 p-4">
          {isViewsLoading ||
          localGroupByOption === null ||
          localGroupByOption !== groupByOption ? (
            <div className="flex items-center justify-center h-[600px] w-full">
              <Loader />
            </div>
          ) : (
            groupedData?.map((groupObj,index) => {
              const groupId = Object.keys(groupObj)[0];
              const groupData = groupObj[groupId];
              console.log(groupObj);
              return (
                <Feature2KanbanColumn
                  key={index}
                  groupByOption={groupByOption}
                  group={{
                    _id: groupId,
                    label: groupObj.label,
                    color: groupObj.color,
                  }}
                  itemsForGroup={groupObj.featureDetails}
                  statusArray={statusArray}
                />
              );
            })
          )}
        </div>
      </DragDropContext>
    </div>
  );
};

export default Feature2KanbanView;