import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Bulb, CommentCheck } from "react-flaticons";
import { setSelectedIdeaFilter } from "../../../../reduxStore/slices/selectedIdeaFilterOptionSlice";
import useFilterLogic from "../../../../hooks/useFilterLogic";

const TopbarIdeaToggle = ({ topbarFunctions }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { selectedIdeaFilter } = useSelector(
    (state) => state.selectedIdeaFilter
  );

  const { handleFilterOptionClick, handleSubFilterOptionClick } =
    useFilterLogic(topbarFunctions);

  // Sync state with URL query parameters
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const type = queryParams.get("type");

    if (type === "idea" || type === "feedback") {
      dispatch(setSelectedIdeaFilter(type));
    }
  }, [location.search, dispatch]);

  // Toggle between Idea and Feedback
  const handleIdeaTypeToggle = (option) => {
    if (selectedIdeaFilter === option) {
      return; // Do nothing if the current option is already selected
    }

    const filterOption = {
      label: "Type",
      subOptions: [
        { label: "Idea", _id: "Idea" },
        { label: "Feedback", _id: "Feedback" },
      ],
    };

    const subFilterOption = {
      label: option.charAt(0).toUpperCase() + option.slice(1),
      _id: option.charAt(0).toUpperCase() + option.slice(1), // "Idea" or "Feedback"
    };

    handleFilterOptionClick(filterOption);
    handleSubFilterOptionClick(subFilterOption);
    dispatch(setSelectedIdeaFilter(option));
    navigate(`?type=${option}`);
  };

  return (
    <div className="flex gap-2 items-center text-title_50">
      {/* Idea Button */}
      <div
        className={`hover:cursor-pointer flex justify-center items-center px-2 py-1 gap-2 rounded-md ${
          selectedIdeaFilter === "idea"
            ? "border border-[#edba00] bg-[rgba(237,186,0,0.05)]"
            : "hover:bg-[rgba(237,186,0,0.07)]"
        }`}
        onClick={() => handleIdeaTypeToggle("idea")}
      >
        <Bulb size={14} color={"#EDB900"} />
        <h2>Ideas</h2>
      </div>

      {/* Feedback Button */}
      <div
        className={`hover:cursor-pointer flex justify-center items-center px-2 py-1 gap-2 rounded-md ${
          selectedIdeaFilter === "feedback"
            ? "border border-[#7C33F4] bg-[rgba(124,51,244,0.05)]"
            : "hover:bg-[rgba(124,51,244,0.07)]"
        }`}
        onClick={() => handleIdeaTypeToggle("feedback")}
      >
        <CommentCheck size={14} color={"#7C33F4"} />
        <h2>Feedback</h2>
      </div>
    </div>
  );
};

export default TopbarIdeaToggle;
