import { createSlice } from "@reduxjs/toolkit";

// Initial state for selected idea filter
const initialState = {
  selectedIdeaFilter: null,
};

// Create a slice for selected idea filter
const selectedIdeaFilterSlice = createSlice({
  name: "selectedIdeaFilter",
  initialState,
  reducers: {
    setSelectedIdeaFilter(state, action) {
      state.selectedIdeaFilter = action.payload; // Set the selected idea filter
    },
  },
});

// Export actions to use in components
export const { setSelectedIdeaFilter } = selectedIdeaFilterSlice.actions;

// Export the reducer to be included in the store
export const selectedIdeaFilterReducer = selectedIdeaFilterSlice.reducer;
