import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/common/Loader";
import IconWrapper from "components/common/IconWrapper";
import ColorDropdown from "./ColorDropdown";
import { fieldIconMapping } from "utils";
import CalendarSection from "./CalendarSection";
import {
  editReleaseInCompany,
  addReleaseToCompany,
  deleteReleaseOfCompany,
} from "reduxStore/operations/releaseAPI";
import { incrementLabel } from "utils/settingsUtils";
import { Modal, Input, DatePicker, Button, Form, Divider } from "antd";
import dayjs from "dayjs";
import IconText from "../../common/IconText";
import { Pencil, PlusSmall, Trash } from "react-flaticons";
import { TbEdit } from "react-icons/tb";

const ReleaseField = () => {
  const dispatch = useDispatch();
  const { releases } = useSelector((state) => state.company);
  const editRefs = useRef({});

  const [activeStartDate, setActiveStartDate] = useState("");
  const [activeEndDate, setActiveEndDate] = useState("");

  // Add modal state
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [addNewReleaseLabel, setAddNewReleaseLabel] = useState("");
  const [addConfirmLoading, setAddConfirmLoading] = useState(false);

  // Edit modal state
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [activeEditReleaseId, setActiveEditReleaseId] = useState(null);
  const [editLabel, setEditLabel] = useState("");
  const [originalEditLabel, setOriginalEditLabel] = useState("");
  const [originalStartDate, setOriginalStartDate] = useState("");
  const [originalEndDate, setOriginalEndDate] = useState("");
  const [editConfirmLoading, setEditConfirmLoading] = useState(false);

  const resetEditOptions = () => {
    setEditLabel("");
    setOriginalStartDate("");
    setOriginalEndDate("");
    setActiveStartDate("");
    setActiveEndDate("");
    setActiveEditReleaseId(null);
  };

  const resetAddOptions = () => {
    setAddNewReleaseLabel("");
    setActiveStartDate("");
    setActiveEndDate("");
  };

  // Called when a release is clicked – loads data and opens the edit modal.
  const editButtonClick = (releaseValue, startDate, endDate, releaseId) => {
    setOriginalEditLabel(releaseValue);
    setEditLabel(releaseValue);
    setActiveEditReleaseId(releaseId);
    setOriginalStartDate(startDate);
    setOriginalEndDate(endDate);
    setIsEditModalVisible(true);
  };

  // For add, initialize with last release's info
  // const addButtonClick = (lastRelease) => {
  //   setIsAddModalVisible(true);
  //   setAddNewReleaseLabel(incrementLabel(lastRelease?.label ?? "Cycle 0"));
  //   setOriginalStartDate(lastRelease?.endDate ?? new Date());
  //   setOriginalEndDate(lastRelease?.endDate ?? new Date());
  // };

  const addButtonClick = (lastRelease) => {
    setIsAddModalVisible(true);
    setAddNewReleaseLabel(incrementLabel(lastRelease?.label ?? "Cycle 0"));
    setOriginalStartDate(lastRelease?.endDate ?? new Date());

    const baseDate = lastRelease?.endDate
      ? dayjs(lastRelease.endDate)
      : dayjs();
    setOriginalEndDate(baseDate.add(14, "day").toDate());
  };

  const handleEditRelease = () => {
    // Ensure that there is a label and both dates
    if (editLabel.trim() === "" && originalEditLabel.trim() === "") {
      return;
    }
    setEditConfirmLoading(true);
    dispatch(
      editReleaseInCompany(
        activeEditReleaseId,
        editLabel !== "" ? editLabel : originalEditLabel,
        activeStartDate || originalStartDate,
        activeEndDate || originalEndDate
      )
    )
      .then(() => {
        setEditConfirmLoading(false);
        resetEditOptions();
        setIsEditModalVisible(false);
      })
      .catch(() => {
        setEditConfirmLoading(false);
      });
  };

  const handleAddRelease = () => {
    if (
      addNewReleaseLabel.trim() === "" ||
      originalStartDate === "" ||
      originalEndDate === ""
    ) {
      return;
    }
    setAddConfirmLoading(true);
    dispatch(
      addReleaseToCompany(
        addNewReleaseLabel,
        activeStartDate || originalStartDate,
        activeEndDate || originalEndDate
      )
    )
      .then(() => {
        setAddConfirmLoading(false);
        resetAddOptions();
        setIsAddModalVisible(false);
      })
      .catch(() => {
        setAddConfirmLoading(false);
      });
  };

  const handleDeleteRelease = (id) => {
    dispatch(deleteReleaseOfCompany(id));
    resetEditOptions();
    setIsEditModalVisible(false);
  };

  if (!releases) {
    return (
      <div className="flex items-center justify-center h-[600px]">
        <Loader />
      </div>
    );
  }

  return (
    <div className="text-sm text-title_10 cursor-default">
      <div className="text-[16px] space-y-3">
        <div className="flex items-center justify-between">
          <h2 className="text-title">Releases</h2>
          <Button
            onClick={() => {
              addButtonClick(releases[0]);
            }}
            type="primary"
            // icon={<PlusSmall size={20} />}
            // className="pl-2"
          >
            Add Release
          </Button>
        </div>
        <p className="text-primary">
          Track and manage product development by organizing features into
          releases
        </p>
      </div>
      <Divider className=" p-0 mt-6 mb-10 " />

      <div className="flex flex-col gap-y-4">
        {releases.length > 0 ? (
          releases.map((release) => (
            <div
              key={release._id}
              className="flex items-center text-primary text-sm gap-x-2 w-fit group"
              ref={(el) => (editRefs.current[release._id] = el)}
            >
              <IconText
                icon={React.createElement(fieldIconMapping["release"])}
                iconColor="#696969"
                textColor="text-title"
                text={`${release.label} (${dayjs(release.startDate).format(
                  "MMM D"
                )} - ${dayjs(release.endDate).format("MMM D")})`}
              />

              <div
                onClick={() =>
                  editButtonClick(
                    release.label,
                    release.startDate,
                    release.endDate,
                    release._id
                  )
                }
                className="rounded-full p-2 ml-1 hover:bg-topbar-button-hover opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              >
                <TbEdit size={17} />
              </div>
              <div
                onClick={() => handleDeleteRelease(release._id)}
                className="rounded-full p-2 opacity-0 hover:bg-topbar-button-hover group-hover:opacity-100 transition-opacity duration-200"
              >
                <Trash size={14} />
              </div>
            </div>
          ))
        ) : (
          <div className="flex items-center gap-x-3">
            <IconWrapper
              icon={React.createElement(fieldIconMapping.release)}
              size="14"
            />
            <div className="p-2 border rounded w-48 cursor-not-allowed bg-row-background">
              No Release
            </div>
          </div>
        )}
      </div>

      {/* Add Modal */}
      <Modal
        title={
          <h2 className="flex gap-2 items-center pt-1 pb-2">
            <IconWrapper
              icon={React.createElement(fieldIconMapping.release)}
              size="14"
            />
            <span>Release</span>
          </h2>
        }
        open={isAddModalVisible}
        onOk={handleAddRelease}
        onCancel={() => {
          resetAddOptions();
          setIsAddModalVisible(false);
        }}
        okText="Add Release"
        confirmLoading={addConfirmLoading}
      >
        <Form.Item label="Release Name" colon={false} className="mb-0" />
        <Input
          placeholder="Cycle X"
          value={addNewReleaseLabel}
          onChange={(e) => setAddNewReleaseLabel(e.target.value)}
          style={{ marginBottom: 16 }}
        />
        <Form.Item label="Period" colon={false} className="mb-0" />
        <DatePicker.RangePicker
          value={[
            activeStartDate
              ? dayjs(activeStartDate)
              : originalStartDate
              ? dayjs(originalStartDate)
              : null,
            activeEndDate
              ? dayjs(activeEndDate)
              : originalEndDate
              ? dayjs(originalEndDate)
              : null,
          ]}
          onChange={(dates, dateStrings) => {
            setActiveStartDate(dateStrings[0]);
            setActiveEndDate(dateStrings[1]);
          }}
          style={{ marginBottom: "20px" }}
        />
      </Modal>

      {/* Edit Modal */}
      <Modal
        title={
          <h2 className="flex gap-2 items-center pt-1 pb-2">
            <IconWrapper
              icon={React.createElement(fieldIconMapping.release)}
              size="14"
            />
            <span>Release</span>
          </h2>
        }
        open={isEditModalVisible}
        onCancel={() => {
          resetEditOptions();
          setIsEditModalVisible(false);
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              resetEditOptions();
              setIsEditModalVisible(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handleEditRelease}
            loading={editConfirmLoading}
          >
            Update
          </Button>,
        ]}
      >
        <Form.Item label="Release Name" colon={false} className="mb-0" />
        <Input
          placeholder="Cycle X"
          value={editLabel}
          onChange={(e) => setEditLabel(e.target.value)}
          style={{ marginBottom: 16 }}
        />
        <Form.Item label="Period" colon={false} className="mb-0" />
        <DatePicker.RangePicker
          value={[
            activeStartDate
              ? dayjs(activeStartDate)
              : originalStartDate
              ? dayjs(originalStartDate)
              : null,
            activeEndDate
              ? dayjs(activeEndDate)
              : originalEndDate
              ? dayjs(originalEndDate)
              : null,
          ]}
          onChange={(dates, dateStrings) => {
            setActiveStartDate(dateStrings[0]);
            setActiveEndDate(dateStrings[1]);
          }}
          style={{ marginBottom: "20px" }}
        />
      </Modal>
    </div>
  );
};

export default ReleaseField;

// import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import Loader from "components/common/Loader";
// import IconWrapper from "components/common/IconWrapper";
// import ColorDropdown from "./ColorDropdown";
// import { fieldIconMapping } from "utils";
// import CalendarSection from "./CalendarSection";
// import {
//   editReleaseInCompany,
//   addReleaseToCompany,
//   deleteReleaseOfCompany,
// } from "reduxStore/operations/releaseAPI";
// import EditInputForm from "./EditInputForm";
// import AddInputForm from "./AddInputForm";
// import { incrementLabel } from "utils/settingsUtils";

// const ReleaseField = () => {
//   const addRef = useRef(null);
//   const editRefs = useRef({});
//   const dispatch = useDispatch();

//   const { releases } = useSelector((state) => state.company);

//   const [isCalendarOpen, setIsCalendarOpen] = useState(false);
//   const [activeStartDate, setActiveStartDate] = useState("");
//   const [activeEndDate, setActiveEndDate] = useState("");

//   // Add Related States
//   const [isAddInputOpen, setIsAddInputOpen] = useState(false);
//   const [addNewReleaseLabel, setAddNewReleaseLabel] = useState("");

//   // Edit Related States
//   const [inputLabelClicked, setInputLabelClicked] = useState(false);
//   const [activeEditReleaseId, setActiveEditReleaseId] = useState(null);
//   const [editLabel, setEditLabel] = useState("");
//   const [originalEditLabel, setOriginalEditLabel] = useState("");
//   const [originalStartDate, setOriginalStartDate] = useState("");
//   const [originalEndDate, setOriginalEndDate] = useState("");

//   const resetEditOptions = () => {
//     setIsCalendarOpen(false);
//     setEditLabel("");
//     setOriginalStartDate("");
//     setOriginalEndDate("");
//     setActiveStartDate("");
//     setActiveEndDate("");
//     setActiveEditReleaseId(null);
//   };

//   const resetAddOptions = () => {
//     setIsCalendarOpen(false);
//     setAddNewReleaseLabel(false);
//     setIsAddInputOpen(false);
//     setActiveStartDate("");
//     setActiveEndDate("");
//   };

//   const editButtonClick = (releaseValue, startDate, endDate, releaseId) => {
//     setOriginalEditLabel(releaseValue);
//     setEditLabel(releaseValue);
//     setActiveEditReleaseId(releaseId);
//     setOriginalStartDate(startDate);
//     setOriginalEndDate(endDate);
//   };

//   const addButtonClick = (lastRelease) => {
//     setIsAddInputOpen(true);
//     setAddNewReleaseLabel(incrementLabel(lastRelease?.label ?? "Cycle 0"));
//     setOriginalStartDate(lastRelease?.endDate ?? Date.now());
//     setOriginalEndDate(lastRelease?.endDate ?? Date.now());
//   };

//   const handleEditRelease = () => {
//     if (editLabel !== originalEditLabel || activeStartDate || activeEndDate) {
//       dispatch(
//         editReleaseInCompany(
//           activeEditReleaseId,
//           editLabel !== "" ? editLabel : originalEditLabel,
//           activeStartDate || originalStartDate,
//           activeEndDate || originalEndDate
//         )
//       );
//     }
//     resetEditOptions();
//   };

//   const handleAddRelease = () => {
//     console.log(addNewReleaseLabel, activeStartDate, activeEndDate);
//     return;

//     if (addNewReleaseLabel !== "" || activeStartDate || activeEndDate) {
//       dispatch(
//         addReleaseToCompany(
//           addNewReleaseLabel,
//           activeStartDate || originalStartDate,
//           activeEndDate || originalEndDate
//         )
//       );
//     }
//     resetAddOptions();
//   };

//   const handleDeleteRelease = () => {
//     dispatch(deleteReleaseOfCompany(activeEditReleaseId));
//     resetEditOptions();
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (
//         activeEditReleaseId &&
//         editRefs.current[activeEditReleaseId] &&
//         !editRefs.current[activeEditReleaseId].contains(event.target)
//       ) {
//         handleEditRelease();
//         resetAddOptions();
//         setInputLabelClicked(false);
//       } else if (
//         isAddInputOpen &&
//         addRef.current &&
//         !addRef.current.contains(event.target)
//       ) {
//         handleAddRelease();
//         resetEditOptions();
//         setInputLabelClicked(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => document.removeEventListener("mousedown", handleClickOutside);
//   }, [
//     activeEditReleaseId,
//     activeStartDate,
//     activeEndDate,
//     editLabel,
//     addNewReleaseLabel,
//     isAddInputOpen,
//   ]);

//   if (!releases) {
//     return (
//       <div className="flex items-center justify-center h-[600px]">
//         <Loader />
//       </div>
//     );
//   }

//   return (
//     <div className="text-sm text-title_10 cursor-default">
//       <div className=" text-[15px] space-y-3 pb-8 ">
//         <h2 className="text-title ">Releases</h2>
//         <p className="text-primary">
//           Select the object and manage the connected statuses
//         </p>
//       </div>
//       <div className="flex flex-col gap-y-2">
//         {releases.length > 0 ? (
//           releases.map((release) => (
//             <div
//               key={release._id}
//               className="flex items-center text-primary text-sm gap-x-3 w-fit"
//               ref={(el) => (editRefs.current[release._id] = el)}
//               onClick={() =>
//                 editButtonClick(
//                   release.label,
//                   release.startDate,
//                   release.endDate,
//                   release._id
//                 )
//               }
//             >
//               <ColorDropdown
//                 icon={React.createElement(fieldIconMapping["release"])}
//                 iconColor="#696969"
//                 openDropdownCondition={false}
//                 clickable={false}
//               />
//               {activeEditReleaseId === release._id ? (
//                 <EditInputForm
//                   inputLabel={editLabel}
//                   setInputLabel={setEditLabel}
//                   inputFocusCondition={inputLabelClicked}
//                   onEditInputEnter={handleEditRelease}
//                   onInputButtonClick={setIsCalendarOpen}
//                   startDate={activeStartDate || originalStartDate}
//                   endDate={activeEndDate || originalEndDate}
//                   isCalendarActiveCondition={isCalendarOpen}
//                   setIsCalendarOpen={setIsCalendarOpen}
//                   setStartDate={setActiveStartDate}
//                   setEndDate={setActiveEndDate}
//                   withCalendar={true}
//                   handleRemoveOptionClick={() => handleDeleteRelease()}
//                 />
//               ) : (
//                 <>
//                   <div
//                     className="p-2 border rounded w-48 cursor-text"
//                     onClick={() => {
//                       setInputLabelClicked(true);
//                     }}
//                   >
//                     {release.label}
//                   </div>
//                   <CalendarSection
//                     startDate={release.startDate}
//                     endDate={release.endDate}
//                     setIsCalendarOpen={setIsCalendarOpen}
//                     isCalendarActiveCondition={
//                       isCalendarOpen && activeEditReleaseId === release._id
//                     }
//                   />
//                 </>
//               )}
//             </div>
//           ))
//         ) : (
//           <div className="flex items-center gap-x-3">
//             <div>
//               <IconWrapper
//                 icon={React.createElement(fieldIconMapping.release)}
//                 size="14"
//               />
//             </div>
//             <div className="p-2 border rounded w-48 cursor-not-allowed bg-row-background">
//               No Release
//             </div>
//           </div>
//         )}
//         {isAddInputOpen ? (
//           <div className="flex items-center gap-x-3 w-fit" ref={addRef}>
//             <AddInputForm
//               icon={React.createElement(fieldIconMapping["release"])}
//               iconClickable={false}
//               inputLabel={addNewReleaseLabel}
//               setInputLabel={(value) => setAddNewReleaseLabel(value)}
//               withCalendar={true}
//               startDate={activeStartDate || originalStartDate}
//               endDate={activeEndDate || originalEndDate}
//               setStartDate={setActiveStartDate}
//               setEndDate={setActiveEndDate}
//               setIsCalendarOpen={setIsCalendarOpen}
//               isCalendarActiveCondition={isCalendarOpen}
//               onInputEnter={() => handleAddRelease()}
//               handleCloseOptionClick={resetAddOptions}
//             />
//           </div>
//         ) : (
//           <div
//             className="text-primary cursor-pointer hover:bg-button-hover p-1 w-fit rounded"
//             onClick={() => {
//               addButtonClick(releases[releases.length - 1]);
//             }}
//           >
//             + Add
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ReleaseField;
