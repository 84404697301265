/* eslint-disable react/prop-types */
import React from "react";
import DropdownItem from "components/common/DropdownItem";
import { Cross, CrossSmall, Star, Trash } from "react-flaticons";
import { TbEdit, TbStarFilled } from "react-icons/tb";
import { FaRegStar, FaStar } from "react-icons/fa";

const EditDeleteDropdown = ({
  editingViewId,
  handleEditView,
  handleDeleteView,
  handleFavoriteClick,
  handleFavoriteRemoveClick,
  handlePersonalClick,
  isAPersonalView,
  isAFavoriteView = false,
  inFavorite = false,
  roadmapId,
  isKr = false,
  keId,
  HandleItemRemove,
  inRoadmapTopbar = false,
}) => {
  return (
    <div
      className="flex flex-col gap-y-1 p-1.5  "
      onClick={(e) => e.stopPropagation()}
    >
      {isKr && (
        <DropdownItem
          option={{ label: "Delete", icon: Trash }}
          iconSize="14"
          textSize="text-xs"
          iconColor="text-primary"
          gap_x="gap-x-2"
          handleOptionClick={() => HandleItemRemove(keId)}
        />
      )}
      {!isKr && !isAFavoriteView && (
        <DropdownItem
          option={{ label: "Rename", icon: TbEdit }}
          dropDownBgColor={editingViewId && "bg-button-active_10"}
          dropDownBgWidth={"w-full"}
          textSize="text-xs"
          iconColor="text-primary"
          iconSize="14"
          gap_x="gap-x-2"
          handleOptionClick={handleEditView}
        />
      )}
      {!roadmapId && !isAFavoriteView && !isKr && (
        <DropdownItem
          option={{ label: "Delete", icon: Trash }}
          iconSize="14"
          textSize="text-xs"
          iconColor="text-primary"
          gap_x="gap-x-2"
          handleOptionClick={handleDeleteView}
        />
      )}
      {roadmapId && !isAFavoriteView&& (
        <DropdownItem
          option={{
            label: isAPersonalView ? "Space" : "Space",
            icon: isAPersonalView ? FaStar : FaRegStar,
          }}
          iconSize="14"
          textSize="text-xs"
          iconColor="text-primary"
          gap_x="gap-x-2"
          handleOptionClick={handlePersonalClick}
        />
      )}
      {!isKr && !isAFavoriteView && !inFavorite && !inRoadmapTopbar && (
        <DropdownItem
          option={{ label: "Favorite", icon: Star }}
          iconSize="14"
          textSize="text-xs"
          iconColor="text-primary"
          gap_x="gap-x-2"
          textbreak={true}
          truncateLength={20}
          handleOptionClick={handleFavoriteClick}
        />
      )}
      {isAFavoriteView  && (
        <DropdownItem
          option={{ label: "Remove from Favorite", icon: Cross }}
          iconSize="10"
          textSize="text-xs"
          iconColor="text-primary"
          gap_x="gap-x-3"
          textbreak={true}
          truncateLength={20}
          handleOptionClick={handleFavoriteClick}
        />
      )}
      {inFavorite && (
        <DropdownItem
          option={{ label: "Remove from Favorite", icon: Cross }}
          iconSize="10"
          textSize="text-xs"
          iconColor="text-primary"
          gap_x="gap-x-3"
          textbreak={true}
          truncateLength={20}
          handleOptionClick={handleFavoriteRemoveClick}
        />
      )}
    </div>
  );
};

export default EditDeleteDropdown;
