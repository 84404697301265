/* eslint-disable react/prop-types */
import { capitalizeFirstLetter } from "utils";
import { generateStatusArray } from "utils/status";
import React, { useEffect, useMemo, useState } from "react";
import Loader from "components/common/Loader";
import IconText from "components/common/IconText";
import { useSelector } from "react-redux";
import ListViewGroupHeader from "components/common/classicList/ListViewGroupHeader";
import { isObjectEmpty, fieldIconMapping } from "utils";
import Features2RowComponent from "./Features2RowComponent";

const Feature2ListView = ({
  groupedData,
  selectedColumns,
  collapsedGroups,
  toggleGroupCollapse,
  selectedFeatures,
  handleFeatureSelect,
  handleGroupSelect,
  groupByOption,
  isScrollLoading,handlePagination
}) => {
  const { statuses } = useSelector((state) => state.company);
  const { features, isLoading, isViewsLoading, filters } = useSelector(
    (state) => state.features
  );
  const [featureStatuses, setFeatureStatuses] = useState([]);

  useEffect(() => {
    setFeatureStatuses(statuses.feature);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(featureStatuses),
    [featureStatuses]
  );
  const [localGroupByOption, setLocalGroupByOption] = useState(null);

  useEffect(() => {
    setLocalGroupByOption(groupByOption);
  }, [groupByOption]);

  const hasData =
    groupedData &&
    groupedData.length > 0 &&
    groupedData.some((groupData) => groupData.featureDetails.length > 0);

  const loading =
    ((localGroupByOption === null || localGroupByOption !== groupByOption) &&
      isObjectEmpty(filters)) ||
    isLoading ||
    isViewsLoading;

  // New useEffect to observe the 5th element from the end
  useEffect(() => {
    if (!loading && hasData) {
      const rows = document.querySelectorAll(".overflow-x-auto table tbody tr");
      if (rows.length >= 5) {
        const targetIndex = rows.length - 5;
        const targetRow = rows[targetIndex];

        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                console.log("hello");
                handlePagination();
              }
            });
          },
          { threshold: 0.5 }
        );

        observer.observe(targetRow);

        return () => {
          observer.disconnect();
        };
      }
    }
  }, [loading, hasData, groupedData, collapsedGroups]);

  return (
    <div className="w-full h-full overflow-x-auto  ">
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <table className="table-auto bg-white">
          <thead className="sticky z-30 bg-white top-0 text-sm">
            <tr>
              <th className="min-w-[44rem] sticky top-0 left-0 text-primary text-start pt-5 pl-6 font-normal bg-white">
                Features
              </th>
              {selectedColumns?.map((header, index) =>
                header !== "score" ? (
                  <th
                    key={index}
                    className="text-start text-primary font-normal min-w-32 pt-5 px-4  "
                  >
                    {capitalizeFirstLetter(header)}
                  </th>
                ) : (
                  <th
                    key={index}
                    className="text-start text-primary font-normal min-w-32 pt-5 px-3"
                  >
                    # {capitalizeFirstLetter(header)}
                  </th>
                )
              )}
            </tr>
          </thead>
          {features && !hasData && localGroupByOption === "default" ? (
            <div className="absolute flex items-center justify-center h-[87%] overflow-scroll   w-full">
              <p className="text-lg text-gray-500">No features to show.</p>
            </div>
          ) : (
            groupedData.map((groupData) => {
              if (groupData.featureCount === 0) {
                return;
              }
              return (
                <React.Fragment key={groupData.label}>
                  {localGroupByOption !== "default" && (
                    <ListViewGroupHeader
                      group={groupData}
                      groupByOption={localGroupByOption}
                      items={groupData.featureDetails}
                      selectedItems={selectedFeatures}
                      toggleGroupCollapse={toggleGroupCollapse}
                      handleGroupSelect={handleGroupSelect}
                      collapsedGroups={collapsedGroups}
                      statusArray={statusArray}
                    />
                  )}
                  <tbody>
                    {
                      // !collapsedGroups[groupData._id] &&
                      groupData.featureDetails?.map((feature) => (
                        <>
                          <Features2RowComponent
                            key={feature._id}
                            feature={feature}
                            onSelect={handleFeatureSelect}
                            // isSelected={selectedFeatures.has(feature._id)}
                            selectedColumns={selectedColumns}
                          />
                        </>
                      ))
                    }
                  </tbody>
                </React.Fragment>
              );
            })
          )}
        </table>
      )}
      {isScrollLoading && (
        <div className=" w-full flex justify-center items-center ">
          <Loader className="" />
        </div>
      )}
    </div>
  );
};

export default Feature2ListView;

// /* eslint-disable react/prop-types */
// import { capitalizeFirstLetter } from "utils";
// import { generateStatusArray } from "utils/status";
// import React, { useEffect, useMemo, useState } from "react";
// import Loader from "components/common/Loader";
// import IconText from "components/common/IconText";
// import { useSelector } from "react-redux";
// import ListViewGroupHeader from "components/common/classicList/ListViewGroupHeader";
// import { isObjectEmpty, fieldIconMapping } from "utils";
// import Features2RowComponent from "./Features2RowComponent";

// const Feature2ListView = ({
//   groupedData,
//   selectedColumns,
//   collapsedGroups,
//   toggleGroupCollapse,
//   selectedFeatures,
//   handleFeatureSelect,
//   handleGroupSelect,
//   groupByOption,
// }) => {
//   const { statuses } = useSelector((state) => state.company);
//   const { features, isLoading, isViewsLoading, filters } = useSelector(
//     (state) => state.features
//   );
//   const [featureStatuses, setFeatureStatuses] = useState([]);

//   useEffect(() => {
//     setFeatureStatuses(statuses.feature);
//   }, [statuses]);

//   const statusArray = useMemo(
//     () => generateStatusArray(featureStatuses),
//     [featureStatuses]
//   );
//   const [localGroupByOption, setLocalGroupByOption] = useState(null);

//   useEffect(() => {
//     setLocalGroupByOption(groupByOption);
//   }, [groupByOption]);

//   const hasData =
//     groupedData &&
//     groupedData.length > 0 &&
//     groupedData.some((groupData) => groupData.featureDetails.length > 0);

//   const loading =
//     ((localGroupByOption === null || localGroupByOption !== groupByOption) &&
//       isObjectEmpty(filters)) ||
//     isLoading ||
//     isViewsLoading;

//   return (
//     <div className="w-full h-full overflow-x-auto  ">
//       {loading ? (
//         <div className="flex items-center justify-center h-full">
//           <Loader />
//         </div>
//       ) : (
//         <table className="table-auto bg-white">
//           <thead className="sticky z-30 bg-white top-0 text-sm">
//             <tr>
//               <th className="min-w-[44rem] sticky top-0 left-0 text-primary text-start pt-5 pl-6 font-normal bg-white">
//                 Features
//               </th>
//               {selectedColumns?.map((header, index) =>
//                 header !== "score" ? (
//                   <th
//                     key={index}
//                     className="text-start text-primary font-normal min-w-32 pt-5 px-4  "
//                   >
//                     {capitalizeFirstLetter(header)}
//                   </th>
//                 ) : (
//                   <th
//                     key={index}
//                     className="text-start text-primary font-normal min-w-32 pt-5 px-3"
//                   >
//                     # {capitalizeFirstLetter(header)}
//                   </th>
//                 )
//               )}
//             </tr>
//           </thead>
//           {features && !hasData && localGroupByOption === "default" ? (
//             <div className="absolute flex items-center justify-center h-[87%] overflow-scroll   w-full">
//               <p className="text-lg text-gray-500">No features to show.</p>
//             </div>
//           ) : (
//             groupedData.map((groupData) => {
//               if (groupData.featureCount === 0) {
//                 return;
//               }
//               return (
//                 <React.Fragment key={groupData.label}>
//                   {localGroupByOption !== "default" && (
//                     <ListViewGroupHeader
//                       group={groupData}
//                       groupByOption={localGroupByOption}
//                       items={groupData.featureDetails}
//                       selectedItems={selectedFeatures}
//                       toggleGroupCollapse={toggleGroupCollapse}
//                       handleGroupSelect={handleGroupSelect}
//                       collapsedGroups={collapsedGroups}
//                       statusArray={statusArray}
//                     />
//                   )}
//                   <tbody>
//                     {
//                       // !collapsedGroups[groupData._id] &&
//                       groupData.featureDetails?.map((feature) => (
//                         <Features2RowComponent
//                           key={feature._id}
//                           feature={feature}
//                           onSelect={handleFeatureSelect}
//                           // isSelected={selectedFeatures.has(feature._id)}
//                           selectedColumns={selectedColumns}
//                         />
//                       ))
//                     }
//                   </tbody>
//                 </React.Fragment>
//               );
//             })
//           )}
//         </table>
//       )}
//     </div>
//   );
// };

// export default Feature2ListView;
