/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { generateStatusArray } from "utils/status";
import AddRoadmapTypeSelection from "./AddRoadmapTypeSelection";
import AddRoadmapColumnSelection from "./AddRoadmapColumnSelection";
import { toast } from "react-toastify";

const AddRoadmapPopupOverlay = ({
  option,
  name,
  setName,
  setIsNameEditable,
  setIsWarn,
}) => {
  const [status, setStatus] = useState(null);
  const [forRoadmap, setForRoadmap] = useState("Strategy");

  const {
    statuses,
    products,
    releases = [],
    timeFrames = [],
  } = useSelector((state) => state.company);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedReleases, setSelectedReleases] = useState(new Set());
  const [selectedProducts, setSelectedProducts] = useState(new Set());
  const [selectedTimeFrames, setSelectedTimeFrames] = useState(new Set());
  const [nextClicked, setNextClicked] = useState(false);

  useEffect(() => {
    if (forRoadmap === "Release") {
      if (Array.isArray(releases) && releases.length > 0) {
        const selectedReleases = releases.slice(-3); // Get the last 3 or all if less than 3
        const selectedReleaseIds = selectedReleases.map(
          (release) => release._id
        );

        setSelectedReleases(new Set(selectedReleaseIds));
      } else {
        console.error("Releases is not an array or empty.");
        setSelectedReleases(new Set());
      }
    }else if (forRoadmap === "Ideas") {
      if (Array.isArray(timeFrames) && timeFrames.length > 0) {
        const order = ["Now", "Next", "Later"];

        const filteredIdeas = timeFrames
          .filter((release) => order.includes(release.label))
          .sort((a, b) => order.indexOf(a.label) - order.indexOf(b.label));
        
        const selectedIdeaIds = filteredIdeas.map((release) => release._id);
        
        setSelectedTimeFrames(new Set(selectedIdeaIds));
      } else {
        console.error("timeFrames is not an array or empty.");
        setSelectedTimeFrames(new Set());
      }
    } else {
      if (Array.isArray(timeFrames) && timeFrames.length > 0) {
        const selectedTimeFrames = timeFrames.slice(-3); // Get the last 3 or all if less than 3
        const selectedTimeFrameIds = selectedTimeFrames.map(
          (timeFrame) => timeFrame._id
        );

        setSelectedTimeFrames(new Set(selectedTimeFrameIds));
      } else {
        console.error("TimeFrames is not an array or empty.");
        setSelectedTimeFrames(new Set());
      }
    }
  }, [forRoadmap, releases, timeFrames]);

  const handleStatusOptionClick = (option) => {
    setStatus(option);
  };

  useEffect(() => {
    if (selectedProducts.size === 0) {
      setSelectedProducts((prevSelected) => {
        const newSelected = new Set(prevSelected);

        products.forEach((product) => {
          newSelected.add(product._id);
        });

        return newSelected;
      });
    }
  }, [selectedProducts]);

  const resetSelectedColumns = () => {
    setSelectedReleases(new Set());
    setSelectedTimeFrames(new Set());
  };

  const handleProductOptionClick = (option) => {
    setSelectedProducts((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(option)) {
        newSelected.delete(option);
      } else {
        newSelected.add(option);
      }
      return newSelected;
    });
  };

  const handleTimeDropdownOptionClick = (option) => {
    setEndDate(null);
    setStartDate(null);
    if (forRoadmap === "Release") {
      setSelectedReleases((prevSelected) => {
        const newSelected = new Set(prevSelected);
        if (newSelected.has(option)) {
          newSelected.delete(option);
        } else {
          newSelected.add(option);
        }
        return newSelected;
      });
    } else {
      setSelectedTimeFrames((prevSelected) => {
        const newSelected = new Set(prevSelected);
        if (newSelected.has(option)) {
          newSelected.delete(option);
        } else {
          newSelected.add(option);
        }
        return newSelected;
      });
    }
  };

  const [roadmapStatuses, setRoadmapStatuses] = useState([]);

  useEffect(() => {
    setRoadmapStatuses(statuses.roadmap);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(roadmapStatuses),
    [roadmapStatuses]
  );

  useEffect(() => {
    setStatus(statusArray[0]);
  }, [statusArray]);

  return (
    <div className="flex flex-col gap-y-2">
      {option.addOptions.includes("forRoadmap") && !nextClicked && (
        <AddRoadmapTypeSelection
          forRoadmap={forRoadmap}
          setName={setName}
          setForRoadmap={setForRoadmap}
          onNextClick={() => {
            if (name !== "") {
              setNextClicked(true);
              setIsNameEditable(false);
            }
            setIsWarn(true);
            setTimeout(() => {
              setIsWarn(false);
            }, 3000);
          }}
          resetSelectedColumns={resetSelectedColumns}
        />
      )}
      <AddRoadmapColumnSelection
        option={option}
        status={status}
        handleStatusOptionClick={handleStatusOptionClick}
        handleProductOptionClick={handleProductOptionClick}
        forRoadmap={forRoadmap}
        startDate={startDate}
        endDate={endDate}
        selectedProducts={selectedProducts}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        selectedTimeFrames={selectedTimeFrames}
        selectedReleases={selectedReleases}
        handleTimeDropdownOptionClick={handleTimeDropdownOptionClick}
      />

      <hr className="mt-1 mb-1 " />
      <div className="  flex justify-end pt-2    ">
        <button
          className="p-2 bg-add-popup-button font-bold hover:bg-blue-600 rounded text-sm   text-white"
          onClick={() => {
            if (selectedReleases.size !== 0 || selectedTimeFrames.size !== 0) {
              option?.onAddClick({
                title: name,
                forRoadmap: forRoadmap,
                products:
                  forRoadmap !== "Strategy"
                    ? selectedProducts.size > 0
                      ? Array.from(selectedProducts)
                      : products
                    : products,
                releases: Array.from(selectedReleases) ?? null,
                timeFrames: Array.from(selectedTimeFrames) ?? null,
                manualTimeFrame: {
                  startDate: startDate ? new Date(startDate) : null,
                  endDate: endDate ? new Date(endDate) : null,
                }, // Ensure this is an object
                status: status?._id ?? null,
              });
            } else {
              toast.error("Select Columns First");
              return;
            }
          }}
        >
          Create {option.label}
        </button>
      </div>
      {nextClicked && (
        <div className="flex flex-col gap-y-2">
          <AddRoadmapColumnSelection
            option={option}
            status={status}
            handleStatusOptionClick={handleStatusOptionClick}
            handleProductOptionClick={handleProductOptionClick}
            forRoadmap={forRoadmap}
            startDate={startDate}
            endDate={endDate}
            selectedProducts={selectedProducts}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            selectedTimeFrames={selectedTimeFrames}
            selectedReleases={selectedReleases}
            handleTimeDropdownOptionClick={handleTimeDropdownOptionClick}
          />
          <hr />
          <div className="ml-auto text-sm">
            <button
              className="p-2 mr-2 border rounded hover:bg-button-hover"
              onClick={() => {
                setNextClicked(false);
                setIsNameEditable(true);
              }}
            >
              Back
            </button>
            <button
              className="p-2 bg-add-popup-button rounded text-sm text-white"
              onClick={() => {
                if (
                  selectedReleases.size !== 0 ||
                  selectedTimeFrames.size !== 0
                ) {
                  option?.onAddClick({
                    title: name,
                    forRoadmap: forRoadmap,
                    products:
                      forRoadmap !== "Strategy"
                        ? selectedProducts.size > 0
                          ? Array.from(selectedProducts)
                          : products
                        : products,
                    releases: Array.from(selectedReleases) ?? null,
                    timeFrames: Array.from(selectedTimeFrames) ?? null,
                    manualTimeFrame: {
                      startDate: startDate ? new Date(startDate) : null,
                      endDate: endDate ? new Date(endDate) : null,
                    }, // Ensure this is an object
                    status: status?._id ?? null,
                  });
                } else {
                  toast.error("Select Columns First");
                  return;
                }
              }}
            >
              Add {option.label}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddRoadmapPopupOverlay;
