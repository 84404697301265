import React, { useEffect, useState } from "react";
import SettingsTopbar from "./SettingsTopbar";
import { CreditCard, Users as UsersIcon } from "react-flaticons";
import { useSelector } from "react-redux";
import Default from "./Users/Default";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { toSnakeCase } from "../../utils";
import InviteUserPopup from "../common/InviteUserPopup";

const Users = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    { label: "Added", key: "members" },
    { label: " Invited", key: "members/invited-users" },
  ];

  // const tabs = [
  //   {
  //     key: "profile",
  //     label: "User Profile",
  //   },
  //   {
  //     key: "profile/security",
  //     label: "Security",
  //   },
  // ];

  const getInitialTab = () => {
    const currentPath = location.pathname.split("/").pop();
    const foundTab = tabs.find((tab) => toSnakeCase(tab.key) === currentPath);
    return foundTab || tabs[0];
  };

  const [activeTab, setActiveTab] = useState(getInitialTab());

  const handleTabClick = (key) => {
    const foundTab = tabs.find((tab) => tab.key === key);
    if (foundTab) {
      setActiveTab(foundTab);
    }
  };

  useEffect(() => {
    const newPath = toSnakeCase(activeTab.key);
    navigate(`/settings/${newPath}`);
  }, [activeTab, navigate]);

  return (
    <div className="w-full flex justify-center  ">
      <div>
        <SettingsTopbar
          title="Members"
          description="Invite and manage team members in your workspace"
          icon={<UsersIcon />}
          tabs={tabs}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
          isUsers={true}
          defaultActiveKey="members"
        />

        <div className="  pt-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Users;
