import {
  clearFeatureFilters,
  setFeatureGroupByOption,
  setFeatureSortOption,
  setFeatureViewType,
  setIsFeatureViewLoading,
  toggleFeatureColumn,
  updateFeatureFilter,
} from "../reduxStore/slices/featureSlice";
import {
  clearIdeaFilters,
  setIdeaGroupByOption,
  setIdeaSortOption,
  setIdeaViewType,
  toggleIdeaColumn,
  updateIdeaFilter,
} from "../reduxStore/slices/ideaSlice";
import {
  clearObjectiveFilters,
  setIsObjectiveViewLoading,
  setObjectiveGroupByOption,
  setObjectiveSortOption,
  setObjectiveViewType,
  toggleObjectiveColumn,
  updateObjectiveFilter,
} from "../reduxStore/slices/objectiveSlice";
import {
  clearRoadmapFilters,
  setIsRoadmapViewLoading,
  setRoadmapGroupByOption,
  setRoadmapSortOption,
  setRoadmapViewType,
  toggleRoadmapColumn,
  updateRoadmapFilter,
} from "../reduxStore/slices/roadmapsSlice";
import { setIsIdeaViewLoading } from "../reduxStore/slices/ideaSlice";
import {
  clearFeatureFilters2,
  setFeatureGroupByOption2,
  setFeatureSortOption2,
  setFeatureViewType2,
  setIsFeatureViewLoading2,
  toggleFeatureColumn2,
  updateFeatureFilter2,
} from "../reduxStore/slices/feature2Slice";

export const featureTopbarButtonFunctions = (dispatch) => ({
  clearFilters: () => dispatch(clearFeatureFilters()),
  setGroupByOption: (option) =>
    dispatch(setFeatureGroupByOption(option.toLowerCase())),
  setSortOption: (option) => dispatch(setFeatureSortOption(option)),
  setViewType: (viewType) =>
    dispatch(setFeatureViewType(viewType.toLowerCase())),
  setViewsLoading: () => dispatch(setIsFeatureViewLoading(true)),
  toggleColumn: (column) => dispatch(toggleFeatureColumn(column)),
  updateFilter: (filter) => dispatch(updateFeatureFilter(filter)),
});

export const feature2TopbarButtonFunctions = (dispatch) => ({
  clearFilters: () => dispatch(clearFeatureFilters2()),
  setGroupByOption: (option) =>
    dispatch(setFeatureGroupByOption2(option.toLowerCase())),
  setSortOption: (option) => dispatch(setFeatureSortOption2(option)),
  setViewType: (viewType) =>
    dispatch(setFeatureViewType2(viewType.toLowerCase())),
  setViewsLoading: () => dispatch(setIsFeatureViewLoading2(true)),
  toggleColumn: (column) => dispatch(toggleFeatureColumn2(column)),
  updateFilter: (filter) => dispatch(updateFeatureFilter2(filter)),
});

export const ideaTopbarButtonFunctions = (dispatch) => ({
  clearFilters: () => dispatch(clearIdeaFilters()),
  setGroupByOption: (option) =>
    dispatch(setIdeaGroupByOption(option.toLowerCase())),
  setSortOption: (option) => dispatch(setIdeaSortOption(option)),
  setViewType: (viewType) => dispatch(setIdeaViewType(viewType.toLowerCase())),
  setViewsLoading: () => dispatch(setIsIdeaViewLoading(true)),
  toggleColumn: (column) => dispatch(toggleIdeaColumn(column)),
  updateFilter: (filter) => dispatch(updateIdeaFilter(filter)),
});

export const objectiveTopbarButtonFunctions = (dispatch) => ({
  clearFilters: () => dispatch(clearObjectiveFilters()),
  setGroupByOption: (option) =>
    dispatch(setObjectiveGroupByOption(option.toLowerCase())),
  setSortOption: (option) => dispatch(setObjectiveSortOption(option)),
  setViewType: (viewType) =>
    dispatch(setObjectiveViewType(viewType.toLowerCase())),
  setViewsLoading: () => dispatch(setIsObjectiveViewLoading(true)),
  toggleColumn: (column) => dispatch(toggleObjectiveColumn(column)),
  updateFilter: (filter) => dispatch(updateObjectiveFilter(filter)),
});

export const roadmapTopbarButtonFunctions = (dispatch) => ({
  clearFilters: () => dispatch(clearRoadmapFilters()),
  setGroupByOption: (option) =>
    dispatch(setRoadmapGroupByOption(option.toLowerCase())),
  setSortOption: (option) => dispatch(setRoadmapSortOption(option)),
  setViewType: (viewType) =>
    dispatch(setRoadmapViewType(viewType.toLowerCase())),
  setViewsLoading: () => dispatch(setIsRoadmapViewLoading(true)),
  toggleColumn: (column) => dispatch(toggleRoadmapColumn(column)),
  updateFilter: (filter) => dispatch(updateRoadmapFilter(filter)),
});

// Similarly, define and export functions for roadmaps, objectives, etc.
