import { apiConnector } from "api/apiconnector";
import { toast } from "react-toastify";
import { viewEndPoints } from "api/api";
import {
  appendFeatureView,
  removeFeatureView,
  setFeatureViews,
  setIsFeatureViewLoading,
  updateFeatureView,
} from "../slices/featureSlice";
import {
  appendIdeaView,
  removeIdeaView,
  setIdeaViews,
  setIsIdeaViewLoading,
  updateIdeaView,
} from "../slices/ideaSlice";
import {
  appendObjectiveView,
  removeObjectiveView,
  setIsObjectiveViewLoading,
  setObjectiveViews,
  updateObjectiveView,
} from "../slices/objectiveSlice";
import {
  appendRoadmapView,
  removeRoadmapView,
  setIsRoadmapViewLoading,
  setRoadmapIdViews,
  setRoadmapViews,
  updateRoadmap,
  updateRoadmapView,
} from "../slices/roadmapsSlice";
import {
  appendPersonalViews,
  removePersonalView,
  setPersonalViews,
  updatePersonalView,
} from "../slices/personalViewSlice";
import {
  appendFavoriteView,
  removeFavoriteView,
  setFavoriteViews,
  updateFavoriteView,
} from "../slices/favoriteViewsSlice";

const {
  ADD_VIEW,
  GET_VIEWS,
  DELETE_VIEW,
  EDIT_VIEW,
  GET_PERSONAL_VIEWS,
  GET_FAVORITE_VIEWS,
  ADD_TO_PERSONAL_VIEW,
  ADD_TO_FAVORITE_VIEW,
  REMOVE_PERSONAL_VIEW,
  UPDATE_PARTICULAR_ROADMAP_VIEW,
  REMOVE_FAVORITE_VIEW,
} = viewEndPoints;

export const addView = (
  label,
  module,
  viewTypeName,
  sortOption,
  filters,
  selectedColumns,
  groupByOption,
  navigate
) => {
  return async (dispatch) => {
    try {
      // Convert viewTypeName to 'list' if it is 'modernlist' or 'classiclist'
      const modifiedViewTypeName =
        viewTypeName === "modernlist" || viewTypeName === "classiclist"
          ? "list"
          : viewTypeName;

      const response = await apiConnector("POST", `${ADD_VIEW}`, {
        module,
        viewType: viewTypeName,
        label,
        sortOption,
        filters,
        selectedColumns,
        groupByOption,
      });

      if (response.status === 201) {
        if (module === "feature") {
          dispatch(
            appendFeatureView({
              newlyAddedView: response.data.view,
              viewTypeName: modifiedViewTypeName,
            })
          );

          navigate(`?viewType=${viewTypeName}&view=${response.data.view._id}`);
        }
        if (module === "idea") {
          dispatch(
            appendIdeaView({
              newlyAddedView: response.data.view,
              viewTypeName: modifiedViewTypeName,
            })
          );
          navigate(`?viewType=${viewTypeName}&view=${response.data.view._id}`);
        }
        if (module === "objective") {
          dispatch(
            appendObjectiveView({
              newlyAddedView: response.data.view,
              viewTypeName: modifiedViewTypeName,
            })
          );
          navigate(`?view=${response.data.view._id}`);
        }
        if (module === "roadmap") {
          dispatch(
            appendRoadmapView({
              newlyAddedView: response.data.view,
              viewTypeName: modifiedViewTypeName,
            })
          );
          navigate(`?view=${response.data.view._id}`);
        }
        dispatch(addViewToPersonal(response.data.view._id));
      } else if (response.status === 400) {
        const errorData = await response.json();
        toast.error(
          errorData.message || "Failed to add view due to invalid data"
        );
      } else {
        toast.error("Failed to add view due to server error");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        const errorMessage =
          error.response.data.message ||
          "Failed to add view due to invalid data";
        toast.error(errorMessage);
      } else {
        console.error("Error adding view:", error);
        toast.error(
          error.response?.data?.message ?? `An error occurred: ${error.message}`
        );
      }
    }
  };
};

export const fetchAllViews = (
  module,
  viewTypeName = null,
  roadmapId = null
) => {
  const modifiedViewTypeName =
    viewTypeName === "modernlist" || viewTypeName === "classiclist"
      ? "list"
      : viewTypeName;
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        `GET`,
        `${GET_VIEWS(module, modifiedViewTypeName, roadmapId)}`
      );
      if (module === "feature") {
        dispatch(setIsFeatureViewLoading(true));
      }
      if (module === "idea") {
        dispatch(setIsIdeaViewLoading(true));
      }
      if (module === "objective") {
        dispatch(setIsObjectiveViewLoading(true));
      }
      if (module === "roadmap") {
        dispatch(setIsRoadmapViewLoading(true));
      }

      if (response.status === 200) {
        if (module === "feature") {
          dispatch(
            setFeatureViews({
              views: response.data.views,
              viewTypeName: modifiedViewTypeName,
            })
          );
        }
        if (module === "idea") {
          dispatch(
            setIdeaViews({
              views: response.data.views,
              viewTypeName: modifiedViewTypeName,
            })
          );
        }
        if (module === "objective") {
          dispatch(
            setObjectiveViews({
              views: response.data.views,
              viewTypeName: modifiedViewTypeName,
            })
          );
        }
        if (module === "roadmap") {
          dispatch(
            setRoadmapViews({
              views: response.data.views,
              viewTypeName: modifiedViewTypeName,
            })
          );
        }
        if (module === "roadmapId") {
          dispatch(setRoadmapIdViews(response.data.views));
        }
      } else {
        console.error("Failed to fetch views:", response.statusText);
        toast.error("Failed to fetch views");
      }
    } catch (error) {
      console.error("Error fetching views:", error);
      toast.error("An error occurred while fetching views");
    }
  };
};

export const editView = (
  viewId,
  module,
  viewTypeName,
  label,
  sortOption,
  filters,
  selectedColumns,
  groupByOption
) => {
  const modifiedViewTypeName =
    viewTypeName === "modernlist" || viewTypeName === "classiclist"
      ? "list"
      : viewTypeName;
  return async (dispatch) => {
    try {
      const response = await apiConnector(`PUT`, `${EDIT_VIEW(viewId)}`, {
        module,
        viewType: modifiedViewTypeName,
        label,
        groupByOption,
        sortOption,
        filters,
        selectedColumns,
      });

      if (response.status === 200) {
        if (module === "feature") {
          dispatch(
            updateFeatureView({
              updatedView: response.data.view,
              viewTypeName: modifiedViewTypeName,
            })
          );
        }
        if (module === "idea") {
          dispatch(
            updateIdeaView({
              updatedView: response.data.view,
              viewTypeName: modifiedViewTypeName,
            })
          );
        }
        if (module === "objective") {
          dispatch(
            updateObjectiveView({
              updatedView: response.data.view,
              viewTypeName: modifiedViewTypeName,
            })
          );
        }
        if (module === "roadmap") {
          dispatch(
            updateRoadmapView({
              updatedView: response.data.view,
              viewTypeName: modifiedViewTypeName,
            })
          );
        }
        dispatch(updatePersonalView({ updatedView: response.data.view }));
        dispatch(updateFavoriteView({ updatedView: response.data.view }));
      } else {
        console.error("Failed to Edit View:", response.data.message);
        toast.error("Failed to edit views");
      }
    } catch (error) {
      console.error("Error Editing View", error);
      toast.error("An error occurred while editing view");
    }
  };
};

export const updateParticularRoadmapView = (viewId, updateData, roadmapId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        `PUT`,
        UPDATE_PARTICULAR_ROADMAP_VIEW(viewId),
        { updateData, roadmapId }
      );

      if (response.status === 200) {
        dispatch(
          updateRoadmapView({
            updatedView: response.data.view,
            viewTypeName: null,
          })
        );
        dispatch(updatePersonalView({ updatedView: response.data.view }));
        dispatch(
          updateRoadmap({
            updatedRoadmap: response.data.roadmap,
            isUpdated: "title",
          })
        );
      }
    } catch (error) {
      console.log("Error updating particular roadmap view", error);
      toast.error("Error updating particular roadmap view", error.message);
    }
  };
};

export const deleteView = (
  viewId,
  module,
  viewTypeName,
  navigate,
  pathname
) => {
  const modifiedViewTypeName =
    viewTypeName === "modernlist" || viewTypeName === "classiclist"
      ? "list"
      : viewTypeName;

  return async (dispatch) => {
    try {
      const response = await apiConnector(`DELETE`, `${DELETE_VIEW(viewId)}`);

      if (response.status === 200) {
        toast.success(response.data.message);
        if (module === "feature") {
          dispatch(
            removeFeatureView({
              deletedViewId: viewId,
              viewTypeName: modifiedViewTypeName,
            })
          );
          if (window.location.href.includes(viewId)) {
            navigate(``);
          }
        }
        if (module === "idea") {
          dispatch(
            removeIdeaView({
              deletedViewId: viewId,
              viewTypeName: modifiedViewTypeName,
            })
          );
          if (window.location.href.includes(viewId)) {
            // navigate(``);
            navigate(`?viewType=${viewTypeName}&view=all`);
          }
        }
        if (module === "objective") {
          dispatch(
            removeObjectiveView({
              deletedViewId: viewId,
              viewTypeName: modifiedViewTypeName,
            })
          );
          if (window.location.href.includes(viewId)) {
            navigate(`?view=all`);
          }
        }
        if (module === "roadmap") {
          dispatch(
            removeRoadmapView({
              deletedViewId: viewId,
              viewTypeName: modifiedViewTypeName,
            })
          );
          if (window.location.href.includes(viewId)) {
            navigate(`?view=all`);
          }
        }
      } else {
        console.error("Failed to Delete View:", response.data.message);
        toast.error("Failed to delete views");
      }
    } catch (error) {
      console.error("Error Deleting View", error);
      toast.error("An error occurred while deleting view");
    }
  };
};

export const addViewToPersonal = (viewId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "PATCH",
        ADD_TO_PERSONAL_VIEW(viewId)
      );
      if (response.status === 200) {
        dispatch(appendPersonalViews(response.data.view));
      }
    } catch (error) {
      console.error("Error adding view to personal:", error);
    }
  };
};
export const addViewToFavorite = (viewId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "PATCH",
        ADD_TO_FAVORITE_VIEW(viewId)
      );
      if (response.status === 200) {
        dispatch(appendFavoriteView(response.data.view));
      }
    } catch (error) {
      console.error("Error adding view to personal:", error);
    }
  };
};

export const getPersonalViews = () => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("GET", GET_PERSONAL_VIEWS());
      if (response.status === 200) {
        dispatch(setPersonalViews(response.data.personalViews));
      }
    } catch (error) {
      console.log("Error getting views", error);
    }
  };
};

export const getFavoriteViews = () => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("GET", GET_FAVORITE_VIEWS());
      if (response.status === 200) {
        dispatch(setFavoriteViews(response.data.favoriteViews));
      }
    } catch (error) {
      console.log("Error getting views", error);
    }
  };
};

export const removeViewFromPersonal = (viewId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "DELETE",
        REMOVE_PERSONAL_VIEW(viewId)
      );
      if (response.status === 200) {
        dispatch(removePersonalView(viewId));
        dispatch(removeFavoriteView(viewId));
      }
    } catch (error) {
      console.log("Error removing view from personal", error);
    }
  };
};
export const removeViewToFavorite = (viewId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "DELETE",
        REMOVE_FAVORITE_VIEW(viewId)
      );
      if (response.status === 200) {
        dispatch(removeFavoriteView(viewId));
      }
    } catch (error) {
      console.log("Error removing view from personal", error);
    }
  };
};
