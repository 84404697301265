/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from "react";
import SimpleCheckboxItem from "../../checkboxItem/SimpleCheckboxItem";
import { capitalizeFirstLetter } from "utils";
import Searchbar from "../../Searchbar";
import { fieldIconMapping } from "../../../../utils";

const SearchDropdown = ({
  searchTerm,
  setSearchTerm,
  searchPlaceholder,
  filteredItems,
  isDropdownOpen,
  handleItemSelect,
  selectedItems,
  linkedItemsKey,
  handleUpdateLinkedItems,
  itemIconComponent,
  closeDropdownAndReset,
  openDropdownAndReset,
  with_different_tabs,
}) => {
  const searchbarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchbarRef.current &&
        !searchbarRef.current.contains(event.target)
      ) {
        closeDropdownAndReset();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className={`flex-1 relative`} ref={searchbarRef}>
      <div
        className="flex items-center gap-x-2"
        onClick={isDropdownOpen ? null : openDropdownAndReset}
      >
        <Searchbar
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          placeholder={searchPlaceholder}
        />
        {(with_different_tabs ?? []).map((item) => (
          <button
            className={`${
              item.active && "bg-button-active_10"
            } p-2 text-sm border rounded`}
            key={item.label}
            onClick={(e) => {
              e.stopPropagation();
              item.onClick();
            }}
          >
            {item.label}
          </button>
        ))}
      </div>
      {isDropdownOpen && (
        <div className="z-50 absolute w-full px-2 rounded shadow-lg border bg-white text-xs top-full">
          <div className="max-h-44 bg-white overflow-scroll custom-scrollbar mt-2">
            {/* Render visible items below the selected items */}
            {filteredItems
              .filter((item) => selectedItems.has(item._id))
              .map((item, index) => {
                const isFeedback = item.type === "Feedback";
                return (
                  <div key={index + filteredItems.length}>
                    <SimpleCheckboxItem
                      option={{
                        label: item.title,
                        icon: isFeedback
                          ? fieldIconMapping.feedback
                          : itemIconComponent,
                      }}
                      selected={true}
                      checkboxTextSize="text-sm"
                      handleOptionClick={() => handleItemSelect(item)}
                      isFeedback={isFeedback}
                    />
                  </div>
                );
              })}
            {filteredItems
              .filter((item) => !selectedItems.has(item._id))
              .map((item, index) => {
                const isFeedback = item.type === "Feedback";
                return (
                  <div key={index}>
                    <SimpleCheckboxItem
                      option={{
                        label: item.title,
                        icon: isFeedback
                          ? fieldIconMapping.feedback
                          : itemIconComponent,
                      }}
                      selected={false}
                      checkboxTextSize="text-sm"
                      handleOptionClick={() => handleItemSelect(item)}
                      isFeedback={isFeedback}
                    />
                  </div>
                );
              })}
          </div>
          <div className="flex w-fit ml-auto my-2">
            <div
              className="mr-5 p-1.5 rounded bg-white border shadow text-title text-sm cursor-pointer hover:bg-button-hover"
              onClick={() => {
                closeDropdownAndReset();
              }}
            >
              Cancel
            </div>
            <div
              className="p-1.5 bg-button-active text-white rounded text-sm cursor-pointer shadow hover:bg-[#0047AB]"
              onClick={handleUpdateLinkedItems}
            >
              Link {capitalizeFirstLetter(linkedItemsKey)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchDropdown;
