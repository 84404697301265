/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateStatusArray } from "utils/status";
import StatusSection from "components/common/detailView/detailViewRightSidebarSections/StatusSection";
import ProductSection from "components/common/detailView/detailViewRightSidebarSections/ProductSection";
import TagsSection from "components/common/detailView/detailViewRightSidebarSections/TagsSection";
import AssigneeSection from "components/common/detailView/detailViewRightSidebarSections/AssigneeSection";
import ScoreSection from "components/common/detailView/detailViewRightSidebarSections/ScoreSection";
import AccountsSection from "components/common/detailView/detailViewRightSidebarSections/AccountsSection";
import ImportanceSection from "components/common/detailView/detailViewRightSidebarSections/ImportanceSection";
import RoadmapsSection from "components/common/detailView/detailViewRightSidebarSections/RoadmapsSection";
import { updateIdeaData } from "reduxStore/operations/ideasAPI";
import {
  updateIdeaTags,
  updateIdeaAccounts,
  updateIdeaScore,
} from "reduxStore/operations/ideasAPI";
import TypeSection from "../../../../common/detailView/detailViewRightSidebarSections/TypeSection";
import SourceSection from "../../../../common/detailView/detailViewRightSidebarSections/SourceSection";
import CreatorSection from "../../../../common/detailView/detailViewRightSidebarSections/CreatorSection";
import IconWrapper from "../../../../common/IconWrapper";
import { fieldIconMapping } from "../../../../../utils";
import { HiOutlineExternalLink } from "react-icons/hi";

const IdeaDetailViewRightDetailSidebar = ({ item }) => {
  const dispatch = useDispatch();
  const { statuses, products, tags } = useSelector((state) => state.company);
  const [statusArray, setStatusArray] = useState(null);
  const [allProducts, setAllProducts] = useState(null);
  const { accounts } = useSelector((state) => state.accounts);
  const { users } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.user);

  const creator =
    users.find((user) => user._id.toString() === item.submittedBy) || user; // Fallback to current user if creator is not found

  const [allIdeaStatuses, setAllIdeaStatuses] = useState(null);
  const sourceTypes = [{ label: "Internal" }, { label: "External" }];
  const ItemTypes = [{ label: "Idea" }, { label: "Feedback" }];

  useEffect(() => {
    setAllIdeaStatuses(statuses.idea);
  }, [statuses]);

  useEffect(() => {
    if (products !== null) {
      setAllProducts(products);
    }
  }, [products]);

  useEffect(() => {
    if (allIdeaStatuses !== null) {
      setStatusArray(generateStatusArray(allIdeaStatuses));
    }
  }, [allIdeaStatuses]);

  return (
    <div className="flex flex-col gap-y-2 h-full overflow-auto custom-scrollbar">
      <TypeSection
        itemLabel={item.type ?? "Idea"}
        position="gap-x-[54px]"
        with_border={false}
        handleTypeOptionClick={(option) => {
          if (item?.type !== option.label) {
            let { icon, ...restOption } = option;
            dispatch(
              updateIdeaData(
                item._id,
                { type: option.label },
                item,
                "type",
                restOption
              )
            );
          }
        }}
        types={ItemTypes ?? []}
        with_dropdown={true}
      />
      <StatusSection
        item={item?.status ?? {}}
        statusArray={statusArray}
        handleStatusOptionClick={(option) => {
          let isUpdated = "status";
          let { icon, ...restOption } = option;
          dispatch(
            updateIdeaData(
              item._id,
              { status: option._id },
              item,
              isUpdated,
              restOption
            )
          );
        }}
        position="gap-x-10"
        dropdownMenuWidth="w-36"
      />
      <ImportanceSection
        value={item?.importance ?? 0}
        handleImportanceOptionClick={(option) => {
          let isUpdated = "importance";
          dispatch(
            updateIdeaData(
              item._id,
              { importance: option },
              item,
              isUpdated,
              option
            )
          );
        }}
      />
      <AccountsSection
        item={item}
        accounts={accounts}
        position="gap-x-6"
        handleAccountOptionClick={(option) => {
          dispatch(updateIdeaAccounts(option, item));
        }}
      />
      <ProductSection
        item={item.product}
        products={allProducts}
        handleProductOptionClick={(option) => {
          let isUpdated = "product";
          let { icon, ...restOption } = option;
          dispatch(
            updateIdeaData(
              item._id,
              { product: option._id },
              item,
              isUpdated,
              restOption
            )
          );
        }}
        position="gap-x-8"
      />
      <TagsSection
        item={item}
        position="gap-x-14"
        tags={tags ?? []}
        handleTagOptionClick={(option) => {
          dispatch(updateIdeaTags(option, item));
        }}
      />
      {!item?.slack_message_link ? (
        <SourceSection
          sourceLabel={item.source ?? "Internal"}
          position="gap-x-[37px]"
          with_border={false}
          sources={sourceTypes ?? []}
          with_dropdown={true}
          handleSourceOptionClick={(option) => {
            if (item?.source !== option.label) {
              let { icon, ...restOption } = option;
              dispatch(
                updateIdeaData(
                  item._id,
                  { source: option.label },
                  item,
                  "source",
                  restOption
                )
              );
            }
          }}
        />
      ) : (
        <div className=" flex gap-x-[50px] pl-2 py-1  ">
          <div className=" flex gap-2 items-center justify-center    ">
            <IconWrapper icon={React.createElement(fieldIconMapping.source)} />
            <h2 className=" text-sm ">Source</h2>
          </div>
          <div className=" flex gap-2 justify-center  items-center  ">
            <img src="/slack_icon.svg" alt="jira" width={18} height={18} />
            <h2 className=" text-xs text-title_50 ">Slack</h2>
          </div>
        </div>
      )}

      <AssigneeSection
        item={item}
        assignee={item.assignee}
        itemLabel={item?.assignee?.name ?? ""}
        assigneesArray={users}
        position="gap-x-[22px]"
        handleAssineeOptionClick={(option) => {
          if (item?.assignee?._id !== option._id) {
            let { icon, ...restOption } = option;
            dispatch(
              updateIdeaData(
                item._id,
                { assignee: option._id },
                item,
                "assignee",
                restOption
              )
            );
          }
        }}
      />
      {!item?.slack_message_link ? (
        <CreatorSection
          creator={creator}
          with_border={false}
          position="gap-x-[34px]"
        />
      ) : (
        <div className=" flex gap-x-[45px] pl-[10px] py-1  ">
          <div className=" flex gap-2 items-center justify-center    ">
            <IconWrapper icon={React.createElement(fieldIconMapping.creator)} />
            <h2 className=" text-sm ">Creator</h2>
          </div>
          <h2 className=" text-xs text-title_50 ">via Slack</h2>
        </div>
      )}

      {item?.slack_message_link && (
        <div className="px-2">
          <hr className="my-4  " />
          <div className="flex mb-3  gap-3 items-center">
            <img src="/slack_icon.svg" alt="jira" width={18} height={18} />

            <h4 className=" text-title text-sm">Slack</h4>
          </div>
          <h2 className=" flex gap-2  text-sm ">
            <span className="   ">Conversation :</span>
            <a
              className="flex items-center  hover:bg-gray-200 px-2 rounded   cursor-pointer justify-center gap-1"
              href={item?.slack_message_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              Link
              <HiOutlineExternalLink size={16} />
            </a>{" "}
          </h2>
        </div>
      )}
      <hr className="my-4" />
      <ScoreSection
        item={item}
        updateScore={(option) => {
          dispatch(
            updateIdeaScore(
              item._id,
              item.score.type,
              option.value,
              option.effort,
              option.impact,
              option.reach,
              option.confidence
            )
          );
        }}
      />
      <hr className="my-4" />
      <div className="ml-1.5 text-sm mb-1">Roadmaps</div>
      {item?.roadmaps?.length > 0 && (
        <RoadmapsSection item={item} roadmaps={item?.roadmaps} />
      )}
    </div>
  );
};

export default IdeaDetailViewRightDetailSidebar;
