/* eslint-disable react/prop-types */
// import React from "react";
// import IconText from "components/common/IconText";
// // import DemoProfileImage from "assets/demo_profile_image.webp";
// import { fieldIconMapping } from "utils";
// import ProfileIcon from "../../ProfileIcon";

// const AssigneeSection = ({
//   itemLabel,
//   position = "gap-x-4",
//   handleStatusOptionClick
// }) => {
//   return (
//     <div className={`flex ${position} items-center text-title_50`}>
//       <IconText
//         icon={React.createElement(fieldIconMapping["assignee"])}
//         text={"Assignee"}
//         iconColor="#696969"
//         iconSize={16}
//         hoverable={false}
//         gap_x="gap-x-2"
//       />
//       <div className="flex items-center gap-x-1 text-sm">
//         {/* <img
//           src={DemoProfileImage}
//           alt="Profile"
//           className="w-6 h-6 rounded-full"
//         /> */}
//         <ProfileIcon name={itemLabel ?? "N A"} />
//         {/* <span className="text-primary">Chris</span> */}
//       </div>
//     </div>
//   );
// };

// export default AssigneeSection;

import React, { useState, useEffect, useRef } from "react";
import IconText from "components/common/IconText";
import ProfileIcon from "../../ProfileIcon";
import { fieldIconMapping } from "utils";
import DropdownMenu from "../dropdown/DropdownMenu";
import { truncateText } from "../../../../utils";
import { PiUserCircleDashedBold } from "react-icons/pi";

const AssigneeSection = ({
  item,
  assignee,
  isOwner,
  assigneesArray,
  handleAssineeOptionClick,
  position = "gap-x-4",
  with_title = true,
  with_text = true,
  dropdownMenuWidth = "w-32",
  dropdownMenuPosition = "top-full",
  textLength = 20,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLocalAssigneeOptionClick = (option) => {
    handleAssineeOptionClick(option);
    setIsDropdownOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={`flex ${position} items-center text-title_50`}>
      {with_title && (
        <IconText
          icon={React.createElement(fieldIconMapping["assignee"])}
          text={isOwner ? "Owner" : "Assignee"}
          iconColor="#696969"
          iconSize={16}
          hoverable={false}
          gap_x="gap-x-2"
        />
      )}
      <div className={`relative cursor-pointer gap-x-1  `} ref={dropdownRef}>
        <div
          // className={`flex items-center rounded-md gap-x-1  p-1 `}
          className={` flex items-center gap-x-2 w-fit  p-1 rounded hover:bg-gray-200 ${
            isDropdownOpen && "bg-button-active_10"
          }`}
          onClick={toggleDropdown}
        >
          {assignee ? (
            <ProfileIcon name={assignee?.label ?? assignee?.name} size="22px" />
          ) : (
         (
              <PiUserCircleDashedBold size={22} className=" text-primary " />
            )
          )}
          {/* {assignee ? (
            <ProfileIcon name={assignee?.label ?? assignee?.name} size="22px" />
          ) : (
            !with_title && (
              <PiUserCircleDashedBold size={22} className=" text-primary " />
            )
          )} */}

          {with_text && (
            <span className="text-title_50 text-xs">
              {truncateText(
                assignee?.label ?? assignee?.name ?? "No Assignee",
                textLength
              )}
              {/* {assignee?.label ?? assignee?.name ?? "No Assignee"} */}
            </span>
          )}
        </div>

        {isDropdownOpen && (
          <DropdownMenu
            options={assigneesArray ?? []}
            isOpen={true}
            position={`${dropdownMenuPosition} ${dropdownMenuWidth}`}
            handleOptionClick={handleLocalAssigneeOptionClick}
            item={item}
            type="assignee"
            isAssignee={true}
          />
        )}
      </div>
    </div>
  );
};

export default AssigneeSection;
