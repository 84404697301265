/* eslint-disable react/prop-types */
import React, { useCallback, useMemo } from "react";
import IconText from "components/common/IconText";
import ProfileIcon from "components/common/ProfileIcon";
import TimeFrameSection from "components/common/detailView/detailViewRightSidebarSections/TimeFrameSection";
import StatusSection from "components/common/detailView/detailViewRightSidebarSections/StatusSection";
import TagsSection from "components/common/detailView/detailViewRightSidebarSections/TagsSection";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveObjectiveId,
  setObjectiveShowDetailViewDrawer,
} from "reduxStore/slices/objectiveSlice";
import {
  updateObjectiveData,
  updateObjectiveTags,
} from "reduxStore/operations/objectivesAPI";
import { fieldIconMapping, toSnakeCase } from "utils";
import HealthSection from "components/common/detailView/detailViewRightSidebarSections/HealthSection";
import ProgressBar from "@ramonak/react-progress-bar";
import {
  getInitiativeBasedObjectiveProgress,
  getKeyResultBasedObjectiveProgress,
} from "utils/progressUtils";
import { getHealthBgColor } from "utils/objectiveUtils";
import KanbanKeyResultsSection from "components/common/kanban/KanbanKeyResultsSection";
import KanbanRoadmapsSection from "components/common/kanban/KanbanRoadmapsSection";
import KanbanFeaturesSection from "components/common/kanban/KanbanFeaturesSection";
import KanbanIdeasFeaturesSection from "components/common/kanban/KanbanIdeasFeaturesSection";
import AssigneeSection from "../../../common/detailView/detailViewRightSidebarSections/AssigneeSection";
import KanbanIdeasSection from "../../../common/kanban/KanbanIdeasSelection";

const ObjectiveKanbanCard = ({
  objective,
  innerRef,
  draggableProps,
  dragHandleProps,
  statusArray,
}) => {
  const dispatch = useDispatch();
  const { selectedColumns, showDetailViewDrawer } = useSelector(
    (state) => state.objectives
  );
  const { tags, timeFrames } = useSelector((state) => state.company);
  const { ideas } = useSelector((state) => state.ideas);
  const { features } = useSelector((state) => state.features);
  const { keyResults } = useSelector((state) => state.keyResults);
  const { users } = useSelector((state) => state.users);

  const objectiveKeyResults = useMemo(() => {
    return (keyResults ?? []).filter((keyResult) =>
      (objective?.keyResults ?? []).includes(keyResult._id)
    );
  }, [objective?.keyResults, keyResults]);

  const objectiveProgress =
    objective.type === "Key_result_based"
      ? getKeyResultBasedObjectiveProgress(objectiveKeyResults, ideas, features)
      : getInitiativeBasedObjectiveProgress(objective, ideas, features);

  const handleKanbanItemClick = () => {
    dispatch(setActiveObjectiveId(objective._id));
    dispatch(setObjectiveShowDetailViewDrawer(!showDetailViewDrawer));
  };

  const handleTagOptionClick = useCallback(
    (option) => {
      dispatch(updateObjectiveTags(objective, option.itemId, option.fullTags));
    },
    [dispatch]
  );

  return (
    <div
      className="{flex flex-col bg-white border border-gray-200 rounded-lg p-4 text-primary hover:cursor-pointer text-xs"
      onClick={handleKanbanItemClick}
      ref={innerRef}
      {...draggableProps}
      {...dragHandleProps}
    >
      <IconText
        icon={React.createElement(fieldIconMapping.objective)}
        text={objective?.title ?? ""}
        truncateLength="70"
        align={"items-start"}
        iconColor="text-objectives-icon"
        textColor="text-title"
        hoverable={false}
        px=""
      />
      {selectedColumns.includes("progress") && (
        <div className="flex items-center px-1 pl-6 ">
          <div className="flex-1">
            <ProgressBar
              completed={objectiveProgress ?? 0}
              height="10px"
              // borderRadius="4px"
              isLabelVisible={false}
              labelSize={"12px"}
              bgColor={getHealthBgColor(objective?.health)}
            />
          </div>
          <div className="flex-1 p-1">
            {objectiveProgress?.toFixed(0) ?? 0}%
          </div>
        </div>
      )}
      {selectedColumns.includes("tags") && (
        <div className="flex gap-x-2 items-center mt-5">
          <TagsSection
            item={objective}
            with_title={false}
            with_icon_before_tags={true}
            tags={tags ?? []}
            py="py-2"
            handleTagOptionClick={handleTagOptionClick}
            dropdownPosition={"top-full left-0"}
          />
        </div>
      )}
      {selectedColumns.includes("roadmap") && (
        <KanbanRoadmapsSection roadmaps={objective?.roadmaps} />
      )}
      {selectedColumns.includes("key_result") && (
        <KanbanKeyResultsSection keyResults={objective?.keyResults} />
      )}
      {/* {selectedColumns.includes("feature") && (
        <KanbanFeaturesSection features={objective?.features} />
      )}
      {selectedColumns.includes("feature") && (
        <KanbanIdeasSection ideas={objective?.ideas} />
      )} */}
      {selectedColumns.includes("opportunities") && (
        <KanbanIdeasFeaturesSection
          ideas={objective?.ideas}
          features={objective?.features}
        />
      )}
      <hr className="w-full mt-1" />
      <div className="flex items-center justify-between mt-2.5">
        <div className="flex flex-1 gap-x-3 items-center">
          {selectedColumns.includes("status") && (
            <div className="relative">
              <StatusSection
                item={objective?.status ?? {}}
                statusArray={statusArray}
                handleStatusOptionClick={(option) => {
                  if (objective?.status?._id !== option._id) {
                    let { icon, ...restOption } = option;
                    const isUpdated = "status";
                    dispatch(
                      updateObjectiveData(
                        objective._id,
                        { status: option._id },
                        isUpdated,
                        restOption,
                        objective
                      )
                    );
                  }
                }}
                position=""
                with_title={false}
                with_label={false}
              />
            </div>
          )}
          {selectedColumns.includes("time_frame") && (
            <div className="relative">
              <TimeFrameSection
                item={
                  {
                    ...objective?.timeFrame,
                    icon: React.createElement(fieldIconMapping.timeFrames),
                  } ?? {}
                }
                timeFrames={timeFrames}
                handleTimeFrameOptionClick={(option) => {
                  if (objective?.timeFrame?._id !== option._id) {
                    let { icon, ...restOption } = option;
                    const isUpdated = "timeFrame";
                    dispatch(
                      updateObjectiveData(
                        objective._id,
                        { timeFrame: option._id },
                        isUpdated,
                        restOption,
                        objective
                      )
                    );
                  }
                }}
                with_title={false}
              />
            </div>
          )}
          {selectedColumns.includes("health") && (
            <HealthSection
              itemLabel={objective?.health ?? ""}
              position="gap-x-10"
              with_title={false}
              handleHealthOptionClick={(option) => {
                if (objective?.health !== toSnakeCase(option.label)) {
                  let { icon, ...restOption } = option;
                  let isUpdated = "health";
                  dispatch(
                    updateObjectiveData(
                      objective._id,
                      { health: toSnakeCase(option.label) },
                      isUpdated,
                      restOption,
                      objective
                    )
                  );
                }
              }}
            />
          )}
        </div>
        {selectedColumns.includes("assignee") && (
          <AssigneeSection
            item={objective}
            assignee={objective.assignee}
            itemLabel={objective?.assignee?.name ?? ""}
            assigneesArray={users}
            handleAssineeOptionClick={(option) => {
              if (objective?.assignee?._id !== option._id) {
                let { icon, ...restOption } = option;
                dispatch(
                  updateObjectiveData(
                    objective._id,
                    { assignee: option._id },
                    "assignee",
                    objective,
                    restOption
                  )
                );
              }
            }}
            with_title={false}
            with_text={false}
            textLength={9}
          />
        )}
      </div>
    </div>
  );
};

export default ObjectiveKanbanCard;
