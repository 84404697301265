/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import StatusSection from "../../detailView/detailViewRightSidebarSections/StatusSection";
import ProductSection from "../../detailView/detailViewRightSidebarSections/ProductSection";
import { generateStatusArray } from "utils/status";
import { fieldIconMapping } from "utils";
import DemoProfileImage from "assets/demo_profile_image.webp";
import SimpleCheckboxItem from "components/common/checkboxItem/SimpleCheckboxItem";
import IconWrapper from "../../IconWrapper";

const AddRoadmapColumnSelection = ({
  option,
  status,
  handleStatusOptionClick,
  handleProductOptionClick,
  forRoadmap,
  selectedProducts,
  selectedTimeFrames,
  selectedReleases,
  handleTimeDropdownOptionClick,
}) => {
  const { statuses, products, releases, timeFrames } = useSelector(
    (state) => state.company
  );

  const [roadmapStatuses, setRoadmapStatuses] = useState([]);
  const [isColumnDropdownOpen, setIsColumnDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsColumnDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setRoadmapStatuses(statuses.roadmap);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(roadmapStatuses),
    [roadmapStatuses]
  );

  const getSelectedProductLabel = () => {
    if (
      selectedProducts.size === 0 ||
      selectedProducts.size === products.length
    ) {
      return "All Products";
    } else if (selectedProducts.size === 1) {
      const selectedProductId = Array.from(selectedProducts)[0];
      const selectedProduct = products.find(
        (product) => product._id === selectedProductId
      );
      return selectedProduct ? selectedProduct.label : "All Products";
    } else {
      return "Products";
    }
  };

  const getSelectedProductColor = () => {
    if (selectedProducts.size === 1) {
      const selectedProductId = Array.from(selectedProducts)[0];
      const selectedProduct = products.find(
        (product) => product._id === selectedProductId
      );
      return selectedProduct ? selectedProduct.color : "";
    } else {
      return "#A9A9A9";
    }
  };
  // const getSelectionNames = () => {
  //   // Handle selected releases
  //   if (selectedReleases.size > 0) {
  //     // Get the first selected release id
  //     const firstSelectedReleaseId = Array.from(selectedReleases)[0];

  //     // Find the corresponding release object in your releases array (assuming releases is accessible)
  //     const release = releases.find((r) => r._id === firstSelectedReleaseId);

  //     // If release is found, return its label, otherwise return a default message
  //     if (release) {
  //       return release.label; // Assuming 'label' is the field you're interested in
  //     }
  //   }

  //   // Handle selected time frames if no releases are selected
  //   if (selectedTimeFrames.size > 0) {
  //     // Get the first selected time frame id
  //     const firstSelectedTimeFrameId = Array.from(selectedTimeFrames)[0];

  //     // Find the corresponding time frame object (assuming timeFrames array is accessible)
  //     const timeFrame = timeFrames.find(
  //       (t) => t._id === firstSelectedTimeFrameId
  //     );

  //     // If time frame is found, return its label, otherwise return a default message
  //     if (timeFrame) {
  //       return timeFrame.label; // Assuming 'label' is the field you're interested in
  //     }
  //   }

  //   // Default case when nothing is selected
  //   return "Select Columns";
  // };

  const getSelectionNames = () => {
    if (selectedReleases.size > 0) {
      // Convert selectedReleases (Set) to an array and find matching objects
      const selectedReleaseObjects = Array.from(selectedReleases)
        .map((releaseId) => releases.find((r) => r._id === releaseId))
        .filter(Boolean); // Removes any undefined values

      // Return an array of labels
      return selectedReleaseObjects.length > 0
        ? selectedReleaseObjects.map((release) => release.label)
        : "Select Columns";
    }
    // Handle selected time frames if no releases are selected
    if (selectedTimeFrames.size > 0) {
      // Convert selectedTimeFrames (Set) to an array and find matching objects
      const selectedTimeFrameObjects = Array.from(selectedTimeFrames)
        .map((timeFrameId) => timeFrames.find((t) => t._id === timeFrameId))
        .filter(Boolean); // Removes any undefined values

      return selectedTimeFrameObjects.length > 0
        ? selectedTimeFrameObjects
        : "Select Columns";
    }

    // Default case when nothing is selected
    return "Select Columns";
  };

  return (
    <div className="flex flex-col gap-y-3 pt-6 ">
      {/* <div className="flex items-center gap-x-10">
        <div className="text-xs text-primary">Type</div>
        {forRoadmap === "Release" && (
          <IconText
            icon={React.createElement(fieldIconMapping.release)}
            text="Release"
            hoverable={false}
            iconColor="#A9A9A9"
            textColor="text-active_50"
            textSize="text-xs"
            gap_x="gap-x-1.5"
            py=""
            px=""
          />
        )}
        {forRoadmap === "Strategy" && (
          <IconText
            icon={React.createElement(fieldIconMapping.objective)}
            text="Strategy"
            hoverable={false}
            iconColor="#A9A9A9"
            textColor="text-active_50"
            textSize="text-xs"
            gap_x="gap-x-1.5"
            py=""
            px=""
          />
        )}
        {forRoadmap === "Ideas" && (
          <IconText
            icon={React.createElement(fieldIconMapping.idea)}
            text="Ideas"
            hoverable={false}
            iconColor="#A9A9A9"
            textColor="text-active_50"
            textSize="text-xs"
            gap_x="gap-x-1.5"
            py=""
            px=""
          />
        )}
      </div> */}
      <div className="flex text-sm items-center gap-x-4">
        <div className="text-title text-sm ">
          {forRoadmap === "Release" ? "Release" : "Time-frame"}
          {/* Columns */}
        </div>
        {forRoadmap === "Release" ? (
          <div
            className={`relative  ${selectedReleases.size === 0 && "p-2 "}  ${
              isColumnDropdownOpen ? "bg-button-hover" : ""
            } rounded border  border-white  p-1 hover:bg-button-hover cursor-pointer`}
            ref={dropdownRef}
            onClick={() => setIsColumnDropdownOpen(!isColumnDropdownOpen)}
          >
            <div className="flex gap-x-2 items-center">
              <span className=" flex  gap-4    ">
                {selectedReleases.size === 0
                  ? "Select Columns"
                  : getSelectionNames()
                      .slice(0, 3)
                      .map((item) => {
                        return (
                          <div
                            className="flex items-center justify-center space-x-[6px] px-2 py-1  rounded-md  "
                            style={{
                              backgroundColor: `rgba(105, 105, 105, 0.2)`,
                            }} // 10% opacity
                            // 10% opacity
                          >
                            <div className="mb-[1px]">
                              <IconWrapper
                                icon={React.createElement(
                                  fieldIconMapping.release
                                )}
                                size={12}
                              />
                            </div>

                            <h2>{item}</h2>
                          </div>
                        );
                      })}
              </span>
              {selectedReleases.size > 0 && (
                <div>
                  <div
                    className={`rounded-full ${
                      selectedReleases.size > 1 ? "p-0.5 pr-1" : ""
                    } bg-button-active_10 text-center`}
                  >
                    {selectedReleases.size > 3 && (
                      <>
                        {selectedTimeFrames.size > 3
                          ? `+${selectedTimeFrames.size - 3} `
                          : ""}
                        {selectedReleases.size > 3
                          ? `+${selectedReleases.size - 3} `
                          : ""}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            {isColumnDropdownOpen && (
              <>
                {forRoadmap === "Release" && (
                  <div className="absolute z-10 bg-white rounded shadow border p-1 top-full mt-0.5 left-0 max-h-[320px] overflow-auto custom-scrollbar min-w-32">
                    {(releases ?? [])
                      .filter((item) => selectedReleases.has(item._id))
                      .map((item) => (
                        <div key={item._id}>
                          <SimpleCheckboxItem
                            option={item}
                            handleOptionClick={(option) =>
                              handleTimeDropdownOptionClick(option._id)
                            }
                            iconSize="12"
                            selected={selectedReleases.has(item._id)}
                          />
                        </div>
                      ))}
                    {(releases ?? [])
                      .filter((item) => !selectedReleases.has(item._id))
                      .map((item) => (
                        <div key={item._id}>
                          <SimpleCheckboxItem
                            option={item}
                            handleOptionClick={(option) =>
                              handleTimeDropdownOptionClick(option._id)
                            }
                            iconSize="12"
                            selected={selectedReleases.has(item._id)}
                          />
                        </div>
                      ))}
                  </div>
                )}

                {(forRoadmap === "Strategy" || forRoadmap === "Ideas") && (
                  <div className="absolute z-10 bg-white rounded shadow border p-1 top-full mt-0.5 left-0 max-h-[320px] overflow-auto custom-scrollbar min-w-32">
                    {(timeFrames ?? [])
                      .filter((item) => selectedTimeFrames.has(item._id))
                      .map((item) => (
                        <div key={item._id}>
                          <SimpleCheckboxItem
                            option={{
                              ...item,
                              iconColor: item.color,
                              icon: fieldIconMapping.timeFrames,
                            }}
                            handleOptionClick={(option) =>
                              handleTimeDropdownOptionClick(option._id)
                            }
                            iconSize="12"
                            selected={selectedTimeFrames.has(item._id)}
                          />
                        </div>
                      ))}
                    {(timeFrames ?? [])
                      .filter((item) => !selectedTimeFrames.has(item._id))
                      .map((item) => (
                        <div key={item._id}>
                          <SimpleCheckboxItem
                            option={{
                              ...item,
                              iconColor: item.color,
                              icon: fieldIconMapping.timeFrames,
                            }}
                            handleOptionClick={(option) =>
                              handleTimeDropdownOptionClick(option._id)
                            }
                            iconSize="12"
                            selected={selectedTimeFrames.has(item._id)}
                          />
                        </div>
                      ))}
                  </div>
                )}
              </>
            )}
          </div>
        ) : (
          <div
            className={`relative  ${
              selectedTimeFrames.size === 0 && " p-2 "
            } rounded border ${isColumnDropdownOpen?"bg-button-hover":""}  border-white p-1 hover:bg-button-hover cursor-pointer`}
            ref={dropdownRef}
            onClick={() => setIsColumnDropdownOpen(!isColumnDropdownOpen)}
          >
            <div className="flex gap-x-2 items-center">
              <span className=" flex  gap-4    ">
                {selectedTimeFrames.size === 0
                  ? "Select Columns"
                  : getSelectionNames()
                      .slice(0, 3)
                      .map((item) => {
                        return (
                          <div
                            className="flex items-center justify-center space-x-[6px] px-2 py-1  rounded-md "
                            style={{ backgroundColor: `${item.color}2A` }} // 10% opacity
                          >
                            <span
                              className="h-[10px] w-[10px] mb-[1px] rounded-full"
                              style={{ backgroundColor: item.color }}
                            ></span>

                            <h2>{item.label}</h2>
                          </div>
                        );
                      })}
              </span>
              {selectedTimeFrames.size > 0 && (
                <div>
                  <div
                    className={`rounded-full ${
                      selectedReleases.size > 3 ? "p-0.5 pr-1" : ""
                    } bg-button-active_10 text-center`}
                  >
                    {selectedTimeFrames.size > 3 && (
                      <>
                        {" "}
                        {selectedTimeFrames.size > 3
                          ? `+${selectedTimeFrames.size - 3} `
                          : ""}
                        {selectedReleases.size > 3
                          ? `+${selectedReleases.size - 3} `
                          : ""}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            {isColumnDropdownOpen && (
              <>
                {forRoadmap === "Release" && (
                  <div className="absolute z-10 bg-white rounded shadow border p-1 top-full mt-0.5 left-0 max-h-[320px] overflow-auto custom-scrollbar min-w-32">
                    {(releases ?? [])
                      .filter((item) => selectedReleases.has(item._id))
                      .map((item) => (
                        <div key={item._id}>
                          <SimpleCheckboxItem
                            option={item}
                            handleOptionClick={(option) =>
                              handleTimeDropdownOptionClick(option._id)
                            }
                            iconSize="12"
                            selected={selectedReleases.has(item._id)}
                          />
                        </div>
                      ))}
                    {(releases ?? [])
                      .filter((item) => !selectedReleases.has(item._id))
                      .map((item) => (
                        <div key={item._id}>
                          <SimpleCheckboxItem
                            option={item}
                            handleOptionClick={(option) =>
                              handleTimeDropdownOptionClick(option._id)
                            }
                            iconSize="12"
                            selected={selectedReleases.has(item._id)}
                          />
                        </div>
                      ))}
                  </div>
                )}

                {(forRoadmap === "Strategy" || forRoadmap === "Ideas") && (
                  <div className="absolute z-10 bg-white rounded shadow border p-1 top-full mt-0.5 left-0 max-h-[320px] overflow-auto custom-scrollbar min-w-32">
                    {(timeFrames ?? [])
                      .filter((item) => selectedTimeFrames.has(item._id))
                      .map((item) => (
                        <div key={item._id}>
                          <SimpleCheckboxItem
                            option={{
                              ...item,
                              iconColor: item.color,
                              icon: fieldIconMapping.timeFrames,
                            }}
                            handleOptionClick={(option) =>
                              handleTimeDropdownOptionClick(option._id)
                            }
                            iconSize="12"
                            selected={selectedTimeFrames.has(item._id)}
                          />
                        </div>
                      ))}
                    {(timeFrames ?? [])
                      .filter((item) => !selectedTimeFrames.has(item._id))
                      .map((item) => (
                        <div key={item._id}>
                          <SimpleCheckboxItem
                            option={{
                              ...item,
                              iconColor: item.color,
                              icon: fieldIconMapping.timeFrames,
                            }}
                            handleOptionClick={(option) =>
                              handleTimeDropdownOptionClick(option._id)
                            }
                            iconSize="12"
                            selected={selectedTimeFrames.has(item._id)}
                          />
                        </div>
                      ))}
                  </div>
                )}
              </>
            )}
          </div>
        )}
        {/* <div
          className={`relative  ${
            selectedReleases.size === 0 &&
            selectedTimeFrames.size === 0 &&
            "border"
          } rounded  p-1 hover:bg-button-hover cursor-pointer`}
          ref={dropdownRef}
          onClick={() => setIsColumnDropdownOpen(!isColumnDropdownOpen)}
        >
          <div className="flex gap-x-2 items-center">
            <span className=" flex  gap-4    ">
              {selectedReleases.size === 0 && selectedTimeFrames.size === 0
                ? "Select Columns"
                : getSelectionNames().map((item) => {
                    console.log(item);
                    return (
                      <div
                        className="flex items-center space-x-1 px-2 py-1  rounded-md "
                        style={{ backgroundColor: `${item.color}2A` }} // 10% opacity
                      >
                        <span
                          className="h-3 w-3 rounded-full"
                          style={{ backgroundColor: item.color }}
                        ></span>

                        <h2>{item.label}</h2>
                      </div>
                    );
                  })}
            </span>
            {(selectedReleases.size > 0 || selectedTimeFrames.size > 0) && (
              <div>
                <div
                  className={`rounded-full ${
                    selectedTimeFrames.size > 1 || selectedReleases.size > 1
                      ? "p-0.5 pr-1"
                      : ""
                  } bg-button-active_10 text-center`}
                >
                  {(selectedTimeFrames.size > 1 ||
                    selectedReleases.size > 1) && (
                    <>
                      {selectedTimeFrames.size > 1
                        ? `+${selectedTimeFrames.size - 1} `
                        : ""}
                      {selectedReleases.size > 1
                        ? `+${selectedReleases.size - 1} `
                        : ""}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          {isColumnDropdownOpen && (
            <>
              {forRoadmap === "Release" && (
                <div className="absolute z-10 bg-white rounded shadow border p-1 top-full mt-0.5 left-0 max-h-[320px] overflow-auto custom-scrollbar min-w-32">
                  {(releases ?? [])
                    .filter((item) => selectedReleases.has(item._id))
                    .map((item) => (
                      <div key={item._id}>
                        <SimpleCheckboxItem
                          option={item}
                          handleOptionClick={(option) =>
                            handleTimeDropdownOptionClick(option._id)
                          }
                          iconSize="12"
                          selected={selectedReleases.has(item._id)}
                        />
                      </div>
                    ))}
                  {(releases ?? [])
                    .filter((item) => !selectedReleases.has(item._id))
                    .map((item) => (
                      <div key={item._id}>
                        <SimpleCheckboxItem
                          option={item}
                          handleOptionClick={(option) =>
                            handleTimeDropdownOptionClick(option._id)
                          }
                          iconSize="12"
                          selected={selectedReleases.has(item._id)}
                        />
                      </div>
                    ))}
                </div>
              )}

              {(forRoadmap === "Strategy" || forRoadmap === "Ideas") && (
                <div className="absolute z-10 bg-white rounded shadow border p-1 top-full mt-0.5 left-0 max-h-[320px] overflow-auto custom-scrollbar min-w-32">
                  {(timeFrames ?? [])
                    .filter((item) => selectedTimeFrames.has(item._id))
                    .map((item) => (
                      <div key={item._id}>
                        <SimpleCheckboxItem
                          option={{
                            ...item,
                            iconColor: item.color,
                            icon: fieldIconMapping.timeFrames,
                          }}
                          handleOptionClick={(option) =>
                            handleTimeDropdownOptionClick(option._id)
                          }
                          iconSize="12"
                          selected={selectedTimeFrames.has(item._id)}
                        />
                      </div>
                    ))}
                  {(timeFrames ?? [])
                    .filter((item) => !selectedTimeFrames.has(item._id))
                    .map((item) => (
                      <div key={item._id}>
                        <SimpleCheckboxItem
                          option={{
                            ...item,
                            iconColor: item.color,
                            icon: fieldIconMapping.timeFrames,
                          }}
                          handleOptionClick={(option) =>
                            handleTimeDropdownOptionClick(option._id)
                          }
                          iconSize="12"
                          selected={selectedTimeFrames.has(item._id)}
                        />
                      </div>
                    ))}
                </div>
              )}
            </>
          )}
        </div> */}
      </div>
      {/* <div className="flex items-center gap-x-2 -ml-1 mt-2.5">
        {option.addOptions.includes("status") && (
          <StatusSection
            item={status ?? {}}
            statusArray={statusArray}
            handleStatusOptionClick={(option) =>
              handleStatusOptionClick(option)
            }
            position=""
            with_title={false}
          />
        )}
        {option.addOptions.includes("products") &&
          forRoadmap !== "Strategy" && (
            <ProductSection
              item={{
                label: getSelectedProductLabel(),
                icon: React.createElement(fieldIconMapping.product),
                color: getSelectedProductColor(),
              }}
              products={products}
              handleProductOptionClick={(option) => {
                handleProductOptionClick(option);
              }}
              position=""
              with_title={false}
              selectedProducts={selectedProducts}
              with_multi_select={true}
            />
          )}
        {option.addOptions.includes("assignee") && (
          <div className="mr-3 p-1 flex items-center">
            <img
              src={DemoProfileImage}
              alt="Profile"
              className="w-6 h-6 rounded-full"
            />
          </div>
        )}
      </div> */}
    </div>
  );
};

export default AddRoadmapColumnSelection;
