/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import IconText from "components/common/IconText";
import ProfileIcon from "components/common/ProfileIcon";
import ProductSection from "components/common/detailView/detailViewRightSidebarSections/ProductSection";
import ReleaseSection from "components/common/detailView/detailViewRightSidebarSections/ReleaseSection";
import StatusSection from "components/common/detailView/detailViewRightSidebarSections/StatusSection";
import TagsSection from "components/common/detailView/detailViewRightSidebarSections/TagsSection";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveFeatureId,
  setFeatureShowDetailViewDrawer,
} from "reduxStore/slices/featureSlice";
import {
  updateFeatureData,
  updateFeatureTags,
} from "reduxStore/operations/featuresAPI";
import { fieldIconMapping } from "utils";
import KanbanRoadmapsSection from "components/common/kanban/KanbanRoadmapsSection";
import KanbanObjectivesSection from "components/common/kanban/KanbanObjectivesSection";
import KanbanKeyResultsSection from "components/common/kanban/KanbanKeyResultsSection";
import KanbanScoreSection from "components/common/kanban/KanbanScoreSection";
import KanbanIdeasSection from "components/common/kanban/KanbanIdeasSelection";
import AssigneeSection from "../../../common/detailView/detailViewRightSidebarSections/AssigneeSection";

const Feature2KanbanCard = ({
  feature,
  innerRef,
  draggableProps,
  dragHandleProps,
  statusArray,
}) => {
  const dispatch = useDispatch();
  const { selectedColumns, showDetailViewDrawer, groupByOption } = useSelector(
    (state) => state.features
  );
  const { products, tags, releases } = useSelector((state) => state.company);
  const { users } = useSelector((state) => state.users);

  const handleKanbanItemClick = () => {
    dispatch(setActiveFeatureId(feature._id));
    dispatch(setFeatureShowDetailViewDrawer(!showDetailViewDrawer));
  };

  const handleTagOptionClick = useCallback(
    (option) => {
      let { fullTags } = option;
      dispatch(updateFeatureTags(option, fullTags, feature));
    },
    [dispatch]
  );

  return (
    <div
      className="{flex flex-col bg-white border border-gray-200 rounded-lg p-4 text-primary hover:cursor-pointer text-xs"
      onClick={handleKanbanItemClick}
      ref={innerRef}
      // Conditionally spread draggableProps and dragHandleProps only when drag-and-drop is enabled
      {...(groupByOption !== "tags" &&
        groupByOption !== "objective" && {
          ...draggableProps,
          ...dragHandleProps,
        })}
    >
      <IconText
        icon={React.createElement(fieldIconMapping.feature)}
        text={feature.title}
        truncateLength="70"
        align={"items-start"}
        iconColor="text-features-icon"
        textColor="text-title"
        hoverable={false}
        px=""
      />
      {selectedColumns.includes("tags") && (
        <div className="flex gap-x-2 items-center mt-4">
          <TagsSection
            item={feature}
            with_title={false}
            with_icon_before_tags={true}
            tags={tags ?? []}
            py="py-2"
            dropdownPosition="top-full left-0"
            handleTagOptionClick={handleTagOptionClick}
          />
        </div>
      )}
      {selectedColumns.includes("score") && (
        <KanbanScoreSection item={feature} />
      )}
      {selectedColumns.includes("idea") && (
        <KanbanIdeasSection ideas={feature?.ideas} />
      )}
      {selectedColumns.includes("roadmap") && (
        <KanbanRoadmapsSection roadmaps={feature?.roadmaps} />
      )}
      {selectedColumns.includes("objective") && (
        <KanbanObjectivesSection objectives={feature?.objectives} />
      )}
      {selectedColumns.includes("key_result") && (
        <KanbanKeyResultsSection keyResults={feature?.keyResults} />
      )}
      <hr className="w-full my-2.5" />
      <div className="flex items-center justify-between">
        <div className="flex flex-1 gap-x-3 items-center">
          {selectedColumns.includes("status") && (
            <div className="relative">
              <StatusSection
                item={feature?.status ?? {}}
                statusArray={statusArray}
                handleStatusOptionClick={(option) => {
                  if (feature?.status?._id !== option._id) {
                    let { icon, ...restOption } = option;
                    dispatch(
                      updateFeatureData(
                        feature._id,
                        { status: option._id },
                        "status",
                        feature,
                        restOption
                      )
                    );
                  }
                }}
                position=""
                with_title={false}
                with_label={false}
              />
            </div>
          )}
          {selectedColumns.includes("release") && (
            <div className="relative">
              <ReleaseSection
                itemLabel={feature.release?.label ?? "No Release"}
                releases={releases}
                handleReleaseOptionClick={(option) => {
                  if (feature?.release?._id !== option._id) {
                    let { icon, ...restOption } = option;
                    dispatch(
                      updateFeatureData(
                        feature._id,
                        { release: option._id },
                        "release",
                        feature,
                        restOption
                      )
                    );
                  }
                }}
                with_title={false}
              />
            </div>
          )}

          {selectedColumns.includes("product") && (
            <div className="relative">
              <ProductSection
                item={feature?.product ?? "No Product"}
                products={products}
                handleProductOptionClick={(option) => {
                  if (feature?.product?._id !== option._id) {
                    let { icon, ...restOption } = option;
                    dispatch(
                      updateFeatureData(
                        feature._id,
                        { product: option._id },
                        "product",
                        feature,
                        restOption
                      )
                    );
                  }
                }}
                position=""
                with_title={false}
              />
            </div>
          )}
        </div>
        {selectedColumns.includes("assignee") && (
          <AssigneeSection
            item={feature}
            assignee={feature.assignee}
            itemLabel={feature?.assignee?.name ?? ""}
            assigneesArray={users}
            handleAssineeOptionClick={(option) => {
              if (feature?.assignee?._id !== option._id) {
                let { icon, ...restOption } = option;
                dispatch(
                  updateFeatureData(
                    feature._id,
                    { assignee: option._id },
                    "assignee",
                    feature,
                    restOption
                  )
                );
              }
            }}
            with_title={false}
            with_text={false}
            textLength={9}
          />
        )}
      </div>
    </div>
  );
};

export default Feature2KanbanCard;
