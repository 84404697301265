import React from "react";
import Loader from "../components/common/Loader";

const Loading = () => {
  return (
    <div className="absolute  inset-0 flex items-center h-full justify-center bg-black bg-opacity-5 z-[1000]">
      {/* <div className="w-10 h-10 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div> */}
      <Loader />
    </div>
  );
};

export default Loading;
