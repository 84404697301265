import { toast } from "react-toastify";
import { addApiKey, removeApiKey, setApiKeys } from "../slices/apiKeySlice";
import { apiKeysEndPoints } from "../../api/api";
import { apiConnector } from "../../api/apiconnector";
const { GET_API_KEYS, CREATE_API_KEY, DELETE_API_KEY } = apiKeysEndPoints;

export const fetchApiKeys = () => async (dispatch) => {
  try {
    const response = await apiConnector("GET", GET_API_KEYS());
    if (response.status === 200) {
      // API is expected to return an array of API key objects
      dispatch(setApiKeys(response.data.reverse()));
    } else {
      console.error("Failed to fetch API keys:", response.statusText);
      toast.error("Failed to fetch API keys");
    }
  } catch (error) {
    console.error("Error fetching API keys:", error);
    toast.error("An error occurred while fetching API keys");
  } finally {
  }
};

// Thunk to create a new API key
export const createApiKey = (title) => async (dispatch) => {
  try {
    // POST the title to the CREATE_API_KEY endpoint.
    const response = await apiConnector("POST", CREATE_API_KEY(), { title });
    if (response.status === 201) {
      // Response is assumed to return both the raw apiKey and the stored object (newApiKey)
      const { apiKey, newApiKey } = response.data;
      dispatch(addApiKey(newApiKey));
      //   toast.success("API Key created successfully");
      // Optionally, you might want to return the raw apiKey to show it to the user once.
      return apiKey;
    } else {
      console.error("Failed to create API key:", response.statusText);
      toast.error("Failed to create API key");
    }
  } catch (error) {
    console.error("Error creating API key:", error);
    toast.error("An error occurred while creating API key");
  } finally {
  }
};

// Thunk to delete an API key by its id
export const deleteApiKey = (id) => async (dispatch) => {
  try {
    // DELETE endpoint requires the API key id in the URL
    const response = await apiConnector("DELETE", `${DELETE_API_KEY(id)}`);
    if (response.status === 200) {
      console.log(id);
      dispatch(removeApiKey(id));
      //   toast.success("API Key deleted successfully");
    } else {
      console.error("Failed to delete API key:", response.statusText);
      toast.error("Failed to delete API key");
    }
  } catch (error) {
    console.error("Error deleting API key:", error);
    toast.error("An error occurred while deleting API key");
  } finally {
  }
};
