import React, { useEffect, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";
import { useDispatch } from "react-redux";
import ActiveModuleStatusField from "./ActiveModuleStatusField";
import {
  getStatuses,
  addStatuses,
  deleteStatuses,
  updateStatuses,
} from "reduxStore/operations/statusAPI";
import { fieldIconMapping } from "../../../../utils";

const StatusField = () => {
  const dispatch = useDispatch();

  // Ant Design Dropdown menu items
  const items = [
    {
      label: "Feature",
      key: "Feature",
      icon: React.createElement(fieldIconMapping.feature, { size: 12 }),
    },
    {
      label: "Idea",
      key: "Idea",
      icon: React.createElement(fieldIconMapping.idea, { size: 12 }),
    },
    {
      label: "Objective",
      key: "Objective",
      icon: React.createElement(fieldIconMapping.objective, { size: 12 }),
    },
    {
      label: "Roadmap",
      key: "Roadmap",
      icon: React.createElement(fieldIconMapping.roadmap, { size: 12 }),
    },
  ];

  // Set default selected option to the first one
  const [activeModuleOption, setActiveModuleOption] = useState(items[0]);

  const handleMenuClick = (e) => {
    // Find and set the entire option object based on the key clicked
    const selectedOption = items.find((item) => item.key === e.key);
    setActiveModuleOption(selectedOption);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const handleAddStatus = (newStatus) => {
    dispatch(addStatuses(newStatus));
  };

  const handleEditStatus = (statusId, updatedValue) => {
    dispatch(updateStatuses(statusId, updatedValue));
  };

  const handleDeleteStatus = (statusId, replaceId) => {
    dispatch(deleteStatuses(statusId, replaceId.id, replaceId.module));
  };

  useEffect(() => {
    dispatch(getStatuses());
  }, [dispatch]);

  return (
    <>
      <div className="flex justify-between items-end gap-x-5 text-sm cursor-default  pb-2  ">
        <div className="text-[16px] space-y-2  ">
          <h2 className="text-title">Status</h2>
          <p className="text-primary py-1 ">
            Select the object and manage the connected statuses
          </p>
        </div>
        <Dropdown className=" w-[8rem] " menu={menuProps}>
          <Button>
            <Space className=" flex justify-between items-center w-full ">
              {/* Display the icon and label for the active module option */}
              <div className=" flex justify-center items-center gap-2 ">
                {activeModuleOption.icon}
                {activeModuleOption.label}
              </div>
              <DownOutlined className=" w-3 h-3 mt-2 " />
            </Space>
          </Button>
        </Dropdown>
      </div>

      <div className="mt-8 cursor-default">
        <ActiveModuleStatusField
          module={activeModuleOption.label.toLowerCase()}
          handleAddStatus={handleAddStatus}
          handleEditStatus={handleEditStatus}
          handleDeleteStatus={handleDeleteStatus}
        />
      </div>
    </>
  );
};

export default StatusField;

// import React, { useEffect, useState, useRef } from "react";
// import { AngleSmallDown, AngleSmallUp } from "react-flaticons";
// import DropdownItem from "components/common/DropdownItem";
// import ActiveModuleStatusField from "./ActiveModuleStatusField";
// import { useDispatch } from "react-redux";
// import { getStatuses } from "reduxStore/operations/statusAPI";
// import {
//   addStatuses,
//   deleteStatuses,
//   updateStatuses,
// } from "reduxStore/operations/statusAPI";
// import { DownOutlined, UserOutlined } from "@ant-design/icons";
// import { Button, Dropdown, message, Space, Tooltip } from "antd";

// const StatusField = () => {
//   const dispatch = useDispatch();
//   const moduleOptions = [
//     { label: "Feature" },
//     { label: "Idea" },
//     { label: "Objective" },
//     { label: "Roadmap" },
//     // { label: "Key result" },
//   ];
//   const [activeModuleOption, setActiveModuleOption] = useState(
//     moduleOptions[0]
//   );
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const dropdownRef = useRef(null);

//   const handleModuleSelect = (option) => {
//     console.log(option);
//     setActiveModuleOption(option);
//     setIsDropdownOpen(false);
//   };

//   const handleAddStatus = (newStatus) => {
//     dispatch(addStatuses(newStatus));
//   };

//   const handleEditStatus = (statusId, updatedValue) => {
//     dispatch(updateStatuses(statusId, updatedValue));
//   };

//   const handleDeleteStatus = (statusId, replaceId) => {
//     dispatch(deleteStatuses(statusId, replaceId.id, replaceId.module));
//   };

//   useEffect(() => {
//     dispatch(getStatuses());
//   }, [dispatch]);

//   useEffect(() => {
//     // Handler for clicks outside of the dropdown
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setIsDropdownOpen(false);
//       }
//     };

//     // Add event listener for clicks outside
//     document.addEventListener("mousedown", handleClickOutside);

//     // Clean up the event listener on component unmount
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const handleMenuClick = (e) => {
//     // message.info("Click on menu item.");
//     // console.log("click", e);
//     setActiveModuleOption({ label: e.key });
//   };
//   const items = [
//     {
//       label: "Feature",
//       key: "Feature",
//       icon: <UserOutlined />,
//       default: true,
//     },
//     {
//       label: "Idea",
//       key: "Idea",
//       icon: <UserOutlined />,
//     },
//     {
//       label: "Objective",
//       key: "Objective",
//       icon: <UserOutlined />,
//     },
//     {
//       label: "Roadmap",
//       key: "Roadmap",
//       icon: <UserOutlined />,
//     },
//   ];
//   const menuProps = {
//     items,
//     onClick: handleMenuClick,
//   };

//   return (
//     <>
//       <div className="flex justify-between items-center gap-x-5 text-sm cursor-default">
//         <div className=" text-[15px] space-y-3 ">
//           <h2 className="text-title ">Status</h2>
//           <p className="text-primary">
//             Select the object and manage the connected statuses
//           </p>
//         </div>
//         <Dropdown menu={menuProps}>
//           <Button>
//             <Space>
//               Button
//               <DownOutlined />
//             </Space>
//           </Button>
//         </Dropdown>
//         {/* <span
//           className="flex relative cursor-pointer justify-between items-center p-2 border rounded w-32"
//           onClick={() => setIsDropdownOpen(!isDropdownOpen)}
//           ref={dropdownRef}
//         >
//           {activeModuleOption.label.replace("_", " ")}
//           {isDropdownOpen ? (
//             <AngleSmallUp color="#696969" size={14} />
//           ) : (
//             <AngleSmallDown color="#696969" size={14} />
//           )}
//           {isDropdownOpen && (
//             <ul className="absolute top-full left-0 border rounded shadow bg-white z-10 w-full">
//               {moduleOptions.map((option, index) => (
//                 <DropdownItem
//                   key={index}
//                   option={option}
//                   handleOptionClick={handleModuleSelect}
//                 />
//               ))}
//             </ul>
//           )}
//         </span> */}
//       </div>
//       <div className="mt-10 cursor-default">
//         <ActiveModuleStatusField
//           module={activeModuleOption.label.toLowerCase()}
//           handleAddStatus={handleAddStatus}
//           handleEditStatus={handleEditStatus}
//           handleDeleteStatus={handleDeleteStatus}
//         />
//       </div>
//     </>
//   );
// };

// export default StatusField;
