/* eslint-disable react/prop-types */
import { capitalizeFirstLetter } from "utils";
import { generateStatusArray } from "utils/status";
import React, { useEffect, useMemo, useState } from "react";
import Loader from "components/common/Loader";
import IdeasRowComponent from "./IdeasRowComponent";
import IconText from "components/common/IconText";
import { useSelector } from "react-redux";
import ListViewGroupHeader from "components/common/classicList/ListViewGroupHeader";
import { isObjectEmpty, fieldIconMapping } from "utils";

const IdeasClassicListView = ({
  groupedData,
  collapsedGroups,
  toggleGroupCollapse,
  selectedIdeas,
  handleIdeaSelect,
  handleGroupSelect,
  selectedColumns,
  groupByOption,
}) => {
  const { statuses } = useSelector((state) => state.company);
  const { ideas, isLoading, isViewsLoading, filters } = useSelector(
    (state) => state.ideas
  );

  const [ideaStatuses, setIdeaStatuses] = useState([]);

  useEffect(() => {
    setIdeaStatuses(statuses.idea);
  }, [statuses]);

  const statusArray = useMemo(
    () => generateStatusArray(ideaStatuses),
    [ideaStatuses]
  );

  const [localGroupByOption, setLocalGroupByOption] = useState(null);

  useEffect(() => {
    setLocalGroupByOption(groupByOption);
  }, [groupByOption]);

  const hasData =
    groupedData &&
    Object.keys(groupedData).length > 0 &&
    Object.values(groupedData).some((groupData) => groupData.items.length > 0);

  const loading =
    ((localGroupByOption === null || localGroupByOption !== groupByOption) &&
      isObjectEmpty(filters)) ||
    isLoading ||
    isViewsLoading;

  return (
    <div className="w-full h-full overflow-x-auto">
      {loading ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <table className="table-auto bg-white">
          <thead className="sticky z-30 bg-white top-0 text-sm">
            <tr>
              <th className="min-w-[44rem] sticky top-0 left-0 text-primary text-start pt-5 pl-6 font-normal bg-white">
                Idea
              </th>
              {(selectedColumns ?? []).map((header, index) =>
                header !== "score" ? (
                  <th
                    key={index}
                    className="text-start text-primary font-normal min-w-32 pt-5 px-3"
                  >
                    {header === "accounts"
                      ? "Customer"
                      : capitalizeFirstLetter(header)}
                  </th>
                ) : (
                  // <th
                  //   key={index}
                  //   className="text-start text-primary font-normal min-w-32 pt-5 px-2"
                  // >
                  //   <IconText
                  //     icon={React.createElement(fieldIconMapping.score)}
                  //     text="Score"
                  //     gap_x="gap-x-1"
                  //     px=""
                  //     py=""
                  //     hoverable={false}
                  //   />
                  // </th>
                  <th
                    key={index}
                    className="text-start text-primary font-normal min-w-32 pt-5 px-3"
                  >
                    # {capitalizeFirstLetter(header)}
                  </th>
                )
              )}
            </tr>
          </thead>
          {ideas && !hasData && localGroupByOption === "default" ? (
            <div className="absolute flex items-center justify-center h-[87%] w-full">
              <p className="text-lg text-gray-500">No ideas to show</p>
            </div>
          ) : (
            Object.entries(groupedData).map(
              ([groupId, groupData], groupIndex) => (
                <React.Fragment key={groupIndex}>
                  {localGroupByOption !== "default" && (
                    <ListViewGroupHeader
                      group={{
                        _id: groupId,
                        color: groupData.color,
                        label: groupData.label,
                      }}
                      groupByOption={localGroupByOption}
                      items={ideas}
                      selectedItems={selectedIdeas}
                      toggleGroupCollapse={toggleGroupCollapse}
                      handleGroupSelect={handleGroupSelect}
                      collapsedGroups={collapsedGroups}
                      statusArray={statusArray}
                    />
                  )}
                  <tbody>
                    {!collapsedGroups[groupId] &&
                      (groupData.items ?? []).map((idea, index) => (
                        <React.Fragment key={index}>
                          <IdeasRowComponent
                            idea={idea}
                            onSelect={handleIdeaSelect}
                            isSelected={selectedIdeas.has(idea._id)}
                            selectedColumns={selectedColumns}
                          />
                        </React.Fragment>
                      ))}
                  </tbody>
                </React.Fragment>
              )
            )
          )}
        </table>
      )}
    </div>
  );
};

export default IdeasClassicListView;
