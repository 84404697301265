/* eslint-disable react/prop-types */
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { AngleSmallDown } from "react-flaticons";
import { useNavigate, useLocation } from "react-router-dom";
import {
  transformFiltersToArray,
  transformFiltersToObject,
  isObjectEmpty,
} from "utils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DropdownTabItem from "./topbarTabscomponents/DropdownTabItem";
import ViewNameInput from "./topbarTabscomponents/ViewNameInput";
import TabItem from "./topbarTabscomponents/TabItem";
import AddedActiveTabItem from "./topbarTabscomponents/AddedActiveTabItem";
import { addViewToPersonal } from "reduxStore/operations/viewsAPI";
import { removeViewFromPersonal } from "reduxStore/operations/viewsAPI";
import DropdownItem from "components/common/DropdownItem";

const TopbarTabs = ({
  tabs,
  setActiveProperties,
  activeState,
  showSaveViewOption = true,
  topbarTabFunctions,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const view = searchParams.get("view");

  const {
    selectedColumns,
    filters,
    viewType,
    groupByOption,
    sortOption,
    kanbanViews,
    listViews,
    isViewsLoading,
  } = useSelector((state) => state[activeState]);

  const { personalViews } = useSelector((state) => state.personalViews);
  const { selectedIdeaFilter } = useSelector(
    (state) => state.selectedIdeaFilter
  );

  let urlViewType;
  if (activeState !== "objectives" && activeState !== "roadmaps") {
    urlViewType = searchParams.get("viewType");
  } else {
    urlViewType = viewType;
  }

  const allTab = tabs[0];

  const [state, setState] = useState({
    fullTabs: null,
    activeTab: null,
    viewNameInput: "",
    isViewNameInputVisible: false,
    isMoreViewsDropdownVisible: false,
    activeDropdownIndex: null,
    editingViewId: null,
  });

  const {
    fullTabs,
    activeTab,
    viewNameInput,
    isViewNameInputVisible,
    isMoreViewsDropdownVisible,
    activeDropdownIndex,
    editingViewId,
  } = state;

  const [hasChanges, setHasChanges] = useState(false);
  const dropdownRef = useRef(null);
  const [localKanbanViews, setLocalKanbanViews] = useState(null);
  const [localListViews, setLocalListViews] = useState(null);
  const [localViews, setLocalViews] = useState(null);
  const [openSaveViewDropdown, setOpenSaveViewDropdown] = useState(false);

  useEffect(() => {
    const viewsMapping = {
      list: listViews,
      classiclist: listViews,
      modernlist: listViews,
      kanban: kanbanViews,
    };
    if (isObjectEmpty(viewsMapping[urlViewType])) {
      if (localKanbanViews === null && urlViewType === "kanban") {
        topbarTabFunctions.fetchAllViews(urlViewType);
        setLocalKanbanViews([]);
      } else if (
        localListViews === null &&
        (!urlViewType ||
          urlViewType === "classiclist" ||
          urlViewType === "modernlist" ||
          urlViewType === "list")
      ) {
        topbarTabFunctions.fetchAllViews("list");
        setLocalListViews([]);
      }
    }

    setLocalViews(viewsMapping[!urlViewType ? viewType : urlViewType]);
  }, [
    urlViewType,
    viewType,
    localListViews,
    localKanbanViews,
    kanbanViews,
    listViews,
    topbarTabFunctions,
  ]);

  useEffect(() => {
    if (localViews) {
      const transformedLocalViews = localViews.map((view) => ({
        ...view,
        filters: transformFiltersToObject(view.filters),
      }));

      const updatedTabs = [...tabs, ...transformedLocalViews];

      setState((prev) => ({
        ...prev,
        fullTabs: updatedTabs,
      }));
    }
  }, [localViews]);

  useEffect(() => {
    if (fullTabs) {
      const updatedTabs = [...fullTabs];
      updatedTabs[0].label = allTab.label;
      setState((prev) => ({
        ...prev,
        fullTabs: updatedTabs,
      }));
    }
  }, [allTab.label]);

  useEffect(() => {
    if (fullTabs) {
      if (view === undefined || view === "all") {
        setState((prev) => ({
          ...prev,
          activeTab: allTab,
        }));
      } else {
        const activeView = fullTabs.find((tab) => tab._id === view);
        setState((prev) => ({
          ...prev,
          activeTab: activeView || allTab,
        }));
      }
    }
  }, [fullTabs, view]);

  useEffect(() => {
    if (fullTabs && activeTab) {
      const activeTabIndex = fullTabs.findIndex(
        (tab) => tab._id === activeTab?._id
      );

      if (activeTabIndex > 3) {
        const updatedTabs = [...fullTabs];
        const temp = updatedTabs[activeTabIndex];
        updatedTabs[activeTabIndex] = updatedTabs[3];
        updatedTabs[3] = temp;

        setState((prev) => ({
          ...prev,
          fullTabs: updatedTabs,
        }));
      }
    }
  }, [fullTabs, activeTab?._id]);

  const handleTabClick = (tab) => {
    if (activeState !== "roadmaps" && activeState !== "objectives") {
      navigate(`?viewType=${viewType}&view=${tab?._id ? tab._id : "all"}`);
    } else {
      navigate(`?view=${tab?._id ? tab._id : "all"}`);
    }
    setState((prev) => ({
      ...prev,
      activeTab: tab,
      isMoreViewsDropdownVisible: false,
      isViewNameInputVisible: false,
      editingViewId: null,
      activeDropdownIndex: -1,
      viewNameInput: "",
    }));
  };

  useEffect(() => {
    setActiveProperties(
      activeTab?.sortOption,
      activeTab?.selectedColumns,
      activeTab?.groupByOption,
      activeTab?.filters
    );
  }, [activeTab]);

  useEffect(() => {
    if (activeTab !== null) {
      const transformAndRemoveType = (filters) =>
        transformFiltersToArray(filters).filter(
          (filter) => filter.field !== "type"
        );

      const currentSettings = {
        filters: transformAndRemoveType(activeTab?.filters || []),
        groupByOption: activeTab?.groupByOption,
        sortOption: activeTab?.sortOption,
        selectedColumns: activeTab?.selectedColumns,
      };

      const reduxSettings = {
        filters: transformAndRemoveType(filters),
        groupByOption: groupByOption,
        sortOption: sortOption,
        selectedColumns: selectedColumns,
      };

      const hasChanges = !(
        JSON.stringify(currentSettings.filters) ===
          JSON.stringify(reduxSettings.filters) &&
        currentSettings.groupByOption === reduxSettings.groupByOption &&
        currentSettings.sortOption?.field === reduxSettings.sortOption?.field &&
        currentSettings.sortOption?.order === reduxSettings.sortOption?.order &&
        JSON.stringify(currentSettings.selectedColumns) ===
          JSON.stringify(reduxSettings.selectedColumns)
      );

      setHasChanges(hasChanges);
    } else {
      setHasChanges(false);
    }




  }, [groupByOption, filters, sortOption, selectedColumns,viewType]);

  const handleMenuDotsClick = (index) => {
    setState((prevState) => ({
      ...prevState,
      activeDropdownIndex:
        prevState.activeDropdownIndex === index ? null : index,
      isViewNameInputVisible: false,
      editingViewId: null,
      isMoreViewsDropdownVisible: index < 4 ? false : true,
    }));
  };

  const toggleViewsDropdown = () => {
    setState((prevState) => ({
      ...prevState,
      isMoreViewsDropdownVisible: !prevState.isMoreViewsDropdownVisible,
      activeDropdownIndex: null,
    }));
  };

  const handleEditView = () => {
    const viewToEdit = fullTabs[activeDropdownIndex];
    setState((prevState) => ({
      ...prevState,
      viewNameInput: viewToEdit.label,
      isViewNameInputVisible: true,
      editingViewId: viewToEdit._id,
    }));
  };

  const handleDeleteView = async () => {
    const isAPersonalView = (personalViews ?? []).find(
      (view) => view._id === fullTabs[activeDropdownIndex]._id
    );
    if (isAPersonalView) {
      await dispatch(removeViewFromPersonal(fullTabs[activeDropdownIndex]._id));
    }
    topbarTabFunctions.deleteView(fullTabs[activeDropdownIndex]._id, viewType);
    setState((prevState) => ({
      ...prevState,
      viewNameInput: "",
      isViewNameInputVisible: false,
      isMoreViewsDropdownVisible: false,
      activeDropdownIndex: null,
      editingViewId: null,
    }));
  };

  const handleAddViewClick = (viewId, label) => {
    if ((label && label.length < 3) || (!label && viewNameInput.length < 3)) {
      return toast.error("View name must be at least 3 characters long.");
    }
    if (
      (label && label.toLowerCase() === allTab.label.toLowerCase()) ||
      viewNameInput.toLowerCase() === allTab.label.toLowerCase()
    ) {
      return toast.error("View name already exists");
    }

    const ideaOption = {
      label: "Idea",
      _id: "Idea",
    };
    const feedbackOption = {
      label: "Feedback",
      _id: "Feedback",
    };

    const filtersData = transformFiltersToArray(filters); // Transform filters to array
    if (pathname === "/ideas") {
      if (selectedIdeaFilter === "idea") {
        // Check if the "type" field exists
        const typeFilter = filtersData.find(
          (filter) => filter.field === "type"
        );
        if (typeFilter) {
          // If it exists, add ideaOption if not already present
          if (!typeFilter.value.some((item) => item._id === ideaOption._id)) {
            typeFilter.value.push(ideaOption);
          }
        } else {
          // If it doesn't exist, add a new "type" field with ideaOption
          filtersData.push({
            field: "type",
            value: [ideaOption],
          });
        }
      } else if (selectedIdeaFilter === "feedback") {
        // Check if the "type" field exists
        const typeFilter = filtersData.find(
          (filter) => filter.field === "type"
        );
        if (typeFilter) {
          // If it exists, add feedbackOption if not already present
          if (
            !typeFilter.value.some((item) => item._id === feedbackOption._id)
          ) {
            typeFilter.value.push(feedbackOption);
          }
        } else {
          // If it doesn't exist, add a new "type" field with feedbackOption
          filtersData.push({
            field: "type",
            value: [feedbackOption],
          });
        }
      }
    }

    if (viewId || editingViewId) {
      topbarTabFunctions.editView(
        editingViewId ? editingViewId : viewId,
        viewType,
        label ? label : viewNameInput,
        sortOption,
        filtersData,
        selectedColumns,
        groupByOption
      );
    } else {
      topbarTabFunctions.addView(
        viewType,
        viewNameInput,
        sortOption,
        filtersData,
        selectedColumns,
        groupByOption
      );
    }
    setState((prevState) => ({
      ...prevState,
      viewNameInput: "",
      isViewNameInputVisible: false,
      isMoreViewsDropdownVisible: false,
      activeDropdownIndex: null,
      editingViewId: null,
    }));
  };

  const handlePersonalClick = () => {
    const viewToEdit = fullTabs[activeDropdownIndex];
    if (personalViews.find((view) => view._id === viewToEdit._id)) {
      dispatch(removeViewFromPersonal(viewToEdit._id));
    } else {
      dispatch(addViewToPersonal(viewToEdit._id));
    }
  };

  const handleInputCrossClick = () => {
    setState((prevState) => ({
      ...prevState,
      isViewNameInputVisible: false,
      viewNameInput: "",
      editingViewId: null,
      activeDropdownIndex: null,
    }));
  };

  const handleViewNameChange = (value) => {
    setState((prevState) => ({
      ...prevState,
      viewNameInput: value,
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setState((prevState) => ({
          ...prevState,
          editingViewId: null,
          viewNameInput: "",
          isViewNameInputVisible: false,
          isMoreViewsDropdownVisible: false,
          activeDropdownIndex: null,
        }));
        setOpenSaveViewDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className=" relative flex items-center   " ref={dropdownRef}>
      {/* {(fullTabs ?? []).slice(0, 1).map((tab, index) => (
        <>
          <TabItem
            tab={tab}
            index={index}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />
        </>
      ))}
      {!isViewsLoading &&
        fullTabs?.length > 1 &&
        (fullTabs ?? []).slice(1, 4).map((tab, index) => (
          <>
            <AddedActiveTabItem
              tab={tab}
              index={index + 1}
              activeTab={activeTab}
              handleTabClick={handleTabClick}
              activeDropdownIndex={activeDropdownIndex}
              handleMenuDotsClick={handleMenuDotsClick}
              handleEditView={handleEditView}
              handleDeleteView={handleDeleteView}
              editingViewId={editingViewId}
              setState={setState}
              handleAddViewClick={handleAddViewClick}
              handlePersonalClick={handlePersonalClick}
              viewNameInput={viewNameInput}
              personalViews={personalViews}
              handleCancelClick={handleInputCrossClick}
              handleViewNameChange={(value) => handleViewNameChange(value)}
            />
          </>
        ))}
      {fullTabs?.length > 4 && (
        <div className="relative">
          <div
            className={`-ml-2 flex items-center gap-x-1 p-1.5 rounded cursor-pointer hover:bg-button-hover ${
              isMoreViewsDropdownVisible ? "bg-button-active_10" : ""
            }`}
            onClick={toggleViewsDropdown}
          >
            <span className="text-sm">More</span>
            <AngleSmallDown size={12} />
          </div>
          {isMoreViewsDropdownVisible && (
            <div className="absolute w-48 top-full bg-white border-2 mt-1 rounded-lg -right-2 shadow-lg p-2">
              {(fullTabs ?? []).slice(4).map((tab, index) => (
                <>
                  <DropdownTabItem
                    tab={tab}
                    index={index + 4}
                    activeDropdownIndex={activeDropdownIndex}
                    setState={setState}
                    handleTabClick={handleTabClick}
                    handleMenuDotsClick={handleMenuDotsClick}
                    handleEditView={handleEditView}
                    handleDeleteView={handleDeleteView}
                    editingViewId={editingViewId}
                    handleAddViewClick={handleAddViewClick}
                    viewNameInput={viewNameInput}
                    personalViews={personalViews}
                    handlePersonalClick={handlePersonalClick}
                    handleCancelClick={handleInputCrossClick}
                    handleViewNameChange={(value) =>
                      handleViewNameChange(value)
                    }
                  />
                </>
              ))}
            </div>
          )}
        </div>
      )} */}
      {isViewNameInputVisible && editingViewId === null && (
        <ViewNameInput
          viewNameInput={viewNameInput}
          handleAddViewClick={() => handleAddViewClick(null, null)}
          handleCancelClick={handleInputCrossClick}
          handleViewNameChange={(value) => handleViewNameChange(value)}
        />
      )}
      {showSaveViewOption && hasChanges && !isViewNameInputVisible && (
        <div
          className=" ml-auto mr-3   flex rounded justify-between  overflow-hidden items-center cursor-pointer bg-blue-500 text-white w-fit"
          // className=" ml-auto  flex rounded justify-between items-center cursor-pointer bg-button-active_10 text-title_50 w-fit"
          onClick={() => {
            activeTab?._id === undefined
              ? setState((prevState) => ({
                  ...prevState,
                  isViewNameInputVisible: true,
                  isMoreViewsDropdownVisible: false,
                  activeDropdownIndex: null,
                  editingViewId: null,
                }))
              : handleAddViewClick(activeTab._id, activeTab.label);
          }}
        >
          <div className="p-1.5   hover:bg-blue-600  ">
            <h2 className=" text-center text-nowrap ">
              {activeTab?._id === undefined ? "Save view" : "Update view"}
            </h2>
          </div>
          {activeTab?._id !== undefined && (
            <div
              className={`   rounded hover:bg-blue-600 p-2 ${
                openSaveViewDropdown && "bg-blue-600"
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setOpenSaveViewDropdown(!openSaveViewDropdown);
              }}
            >
              <AngleSmallDown size={14} />
              {openSaveViewDropdown && (
                <div className="absolute top-full text-primary bg-white p-2 rounded border shadow w-40 right-[8px]   mt-0.5">
                  <DropdownItem
                    option={{ label: "Save as New" }}
                    handleOptionClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        isViewNameInputVisible: true,
                        isMoreViewsDropdownVisible: false,
                        activeDropdownIndex: null,
                        editingViewId: null,
                      }));
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TopbarTabs;
