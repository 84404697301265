/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import IconText from "components/common/IconText";
import Progress from "components/common/Progress";

import StatusSection from "components/common/detailView/detailViewRightSidebarSections/StatusSection";
import TagsSection from "components/common/detailView/detailViewRightSidebarSections/TagsSection";
import TypeSection from "components/common/detailView/detailViewRightSidebarSections/TypeSection";
import HealthSection from "components/common/detailView/detailViewRightSidebarSections/HealthSection";
import TimeFrameSection from "components/common/detailView/detailViewRightSidebarSections/TimeFrameSection";
import AssigneeSection from "components/common/detailView/detailViewRightSidebarSections/AssigneeSection";
import RoadmapsSection from "components/common/detailView/detailViewRightSidebarSections/RoadmapsSection";

import { fieldIconMapping, toSnakeCase } from "utils";
import { objectiveTypes, getHealthBgColor } from "utils/objectiveUtils";
import { generateStatusArray } from "utils/status";
import {
  getInitiativeBasedObjectiveProgress,
  getKeyResultBasedObjectiveProgress,
} from "utils/progressUtils";

import {
  updateObjectiveTags,
  updateObjectiveData,
} from "reduxStore/operations/objectivesAPI";

const ObjectiveDetailViewRightDetailSidebar = ({ item }) => {
  const dispatch = useDispatch();
  const { statuses, tags, timeFrames } = useSelector((state) => state.company);
  const { ideas } = useSelector((state) => state.ideas);
  const { features } = useSelector((state) => state.features);
  const { keyResults } = useSelector((state) => state.keyResults);
  const { users } = useSelector((state) => state.users);
  const [allObjectiveStatuses, setAllObjectiveStatuses] = useState(null);
  const [statusArray, setStatusArray] = useState(null);

  const objectiveKeyResults = useMemo(() => {
    return (keyResults ?? []).filter((keyResult) =>
      (item?.keyResults ?? []).includes(keyResult._id)
    );
  }, [item?.keyResults, keyResults]);

  const objecvtiveProgress =
    item.type === "Key_result_based"
      ? getKeyResultBasedObjectiveProgress(objectiveKeyResults, ideas, features)
      : getInitiativeBasedObjectiveProgress(item, ideas, features);

  useEffect(() => {
    setAllObjectiveStatuses(statuses.objective);
  }, [statuses]);

  useEffect(() => {
    if (allObjectiveStatuses !== null) {
      setStatusArray(generateStatusArray(allObjectiveStatuses));
    }
  }, [allObjectiveStatuses]);

  const handleItemType = (type) => {
    if (type == "Initiative_based") {
      return "Goal";
    } else {
      return "OKR";
    }
  };

  return (
    <div className="flex flex-col gap-y-2 h-full overflow-auto custom-scrollbar">
      <TypeSection
        itemLabel={handleItemType(item.type)}
        position="gap-x-12"
        with_border={false}
        types={objectiveTypes() ?? []}
        with_dropdown={false}
        showHover={true}
      />
      <StatusSection
        item={item?.status ?? {}}
        statusArray={statusArray}
        position={"gap-x-10"}
        handleStatusOptionClick={(option) => {
          let { icon, ...restOption } = option;
          let isUpdated = "status";
          if (item?.status?._id !== option?._id) {
            dispatch(
              updateObjectiveData(
                item._id,
                { status: option._id },
                isUpdated,
                restOption,
                item
              )
            );
          }
        }}
      />
      <div className="flex items-center gap-x-2.5">
        <IconText
          icon={React.createElement(fieldIconMapping.progress)}
          text={"Progress"}
          hoverable={false}
          iconSize={16}
          gap_x="gap-x-2"
        />
        <div>
          <Progress
            value={objecvtiveProgress ?? 0}
            color={getHealthBgColor(item?.health)}
            showHover={true}
            type={item.type === "Initiative_based" ? "Goal" : "OKR"}
          />
        </div>
      </div>
      <HealthSection
        itemLabel={item?.health ?? ""}
        position="gap-x-11"
        handleHealthOptionClick={(option) => {
          let { icon, ...restOption } = option;
          let isUpdated = "health";
          if (item?.health !== toSnakeCase(option.label)) {
            dispatch(
              updateObjectiveData(
                item._id,
                { health: toSnakeCase(option.label) },
                isUpdated,
                restOption,
                item
              )
            );
          }
        }}
      />
      {/* <ImportanceSection
    value={item?.importance ?? ""}
    position={"gap-x-3"}
    handleImportanceOptionClick={(value) =>
      dispatch(updateObjectiveData(item._id, { importance: value }))
    }
  /> */}
      <TimeFrameSection
        item={{
          ...item.timeFrame,
          icon: React.createElement(fieldIconMapping.timeFrames),
        }}
        for_add={true}
        timeFrames={timeFrames}
        handleTimeFrameOptionClick={(option) => {
          let { icon, ...restOption } = option;
          let isUpdated = "timeFrame";
          if (item?.status?._id !== option?._id) {
            dispatch(
              updateObjectiveData(
                item._id,
                { timeFrame: option._id },
                isUpdated,
                restOption,
                item
              )
            );
          }
        }}
        position="gap-x-1"
      />

      <TagsSection
        item={item}
        tags={tags ?? []}
        handleTagOptionClick={(option) => {
          dispatch(updateObjectiveTags(item, option.itemId, option.fullTags));
        }}
        position="gap-x-14"
      />
      <AssigneeSection
        item={item}
        isOwner={true}
        assignee={item.assignee}
        itemLabel={"Owner" ?? ""}
        assigneesArray={users}
        position={"gap-x-10"}
        handleAssineeOptionClick={(option) => {
          if (item?.assignee?._id !== option._id) {
            let { icon, ...restOption } = option;
            dispatch(
              updateObjectiveData(
                item._id,
                { assignee: option._id },
                "assignee",
                restOption,
                item
              )
            );
          }
        }}
      />
      <hr className="my-4" />

      <div className="ml-1.5 text-sm mb-1">Roadmaps</div>
      {item?.roadmaps && item?.roadmaps?.length > 0 ? (
        <RoadmapsSection
          item={item}
          roadmaps={item?.roadmaps}
          with_timeFrame={false}
          timeFrames={timeFrames}
        />
      ) : (
        <div className="px-1.5 -mt-1 text-sm text-title">None</div>
      )}
    </div>
  );
};

export default ObjectiveDetailViewRightDetailSidebar;
