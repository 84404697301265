import { apiConnector } from "api/apiconnector"; // Ensure you have an apiConnector function to handle API requests
import { integrationsEndPoints } from "api/api"; // Import your API endpoints
import { toast } from "react-toastify";
import { updateIntegrationStatus } from "../slices/integrationsSlice";

const {
  GET_INTEGRATION,
  CREATE_INTEGRATION,
  UPDATE_INTEGRATION,
  DELETE_INTEGRATION,
  GET_ALL_INTEGRATIONS,
  SLACK_OAUTH,
} = integrationsEndPoints;

export const createIntegration = (payload, navigate) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("POST", CREATE_INTEGRATION(), {
        ...payload,
      });
      if (response.status === 201) {
        toast.success("Integration created successfully");
        navigate("/settings/integrations");
      }
    } catch (error) {
      toast.error(
        error.response.data.message ?? "Error in creating integration"
      );
    }
  };
};

export const getAllIntegrations = (companyId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "GET",
        GET_ALL_INTEGRATIONS(),
        null,
        null,
        { companyId }
      );
      if (response.status === 200) {
        response.data.forEach((obj) => {
          dispatch(updateIntegrationStatus(obj));
        });
      }
    } catch (error) {
      toast.error(
        error.response.data.message ?? "Error in fetching integration"
      );
    }
  };
};

export const getIntegration = (name, companyId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "GET",
        GET_INTEGRATION(name),
        null,
        null,
        { companyId }
      );
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      toast.error(
        error.response.data.message ?? "Error in fetching integration"
      );
    }
  };
};

export const updateIntegration = (payload, navigate) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("PUT", UPDATE_INTEGRATION(), {
        ...payload,
      });
      if (response.status === 200) {
        toast.success("Integration details updated successfully");
        navigate("/settings/integrations");
      }
    } catch (error) {
      toast.error(
        error.response.data.message ?? "Error in updating integration"
      );
    }
  };
};

export const deleteIntegration = (name, companyId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_INTEGRATION(name),
        null,
        null,
        {
          companyId,
        }
      );
      if (response.status === 200) {
        dispatch(updateIntegrationStatus(response.data));
        toast.success("Integration deleted successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message ?? "Error in deleting integration"
      );
    }
  };
};

export const slackIntegration = (code) => {
  return async (dispatch) => {
    console.log(code)
    try {
      const response = await apiConnector("POST", SLACK_OAUTH, {
        code,
      });
      if (response.status === 200) {
        //  dispatch(updateIntegrationStatus(response.data));
        console.log(response.data);
        toast.success("Slack integration successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response.data.message ?? "Error in deleting integration"
      );
    }
  };
};
