import React, { useEffect } from "react";
import IconWrapper from "../../common/IconWrapper";
import { useLocation } from "react-router-dom";
import useFilterLogic from "../../../hooks/useFilterLogic";
import { useDispatch, useSelector } from "react-redux";
import { CommentCheck } from "react-flaticons";
import { setSelectedIdeaFilter } from "../../../reduxStore/slices/selectedIdeaFilterOptionSlice";

const TopbarTitle = ({ title, icon, topbarFunctions }) => {
  const { pathname } = useLocation();
  const location = useLocation();
  const dispatch = useDispatch();
  if (pathname === "/ideas") {
    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const type = queryParams.get("type");

      if (type === "idea" || type === "feedback") {
        dispatch(setSelectedIdeaFilter(type));
      }
    }, [location.search, dispatch]);
  }
  const { selectedIdeaFilter } = useSelector(
    (state) => state.selectedIdeaFilter
  );
  const isFeedback = pathname === "/ideas" && selectedIdeaFilter === "feedback";
  // pathname === "/ideas" && Array.isArray(items?.type) && items.type[0]?._id === "Feedback";

  const {
    selectedFilterOption,
    subFilterDropdownOptions,
    setSelectedFilterOption,
    setSubFilterDropdownOptions,
    handleFilterOptionClick,
    handleSubFilterOptionClick,
    handleResetFilterClick,
    handleRemoveFilter,
  } = useFilterLogic(topbarFunctions);

  // Helper function to get icon color based on the pathname
  const getIconColour = (pathname) => {
    if (pathname === "/ideas") {
      if (isFeedback) {
        return "#7C33F4";
      }
      return "#EDB900";
    }
    if (pathname === "/features") {
      return "#3380F4";
    }
    if (pathname === "/features2") {
      return "#3380F4";
    }
    if (pathname === "/objectives") {
      return "#F43379";
    }
    if (pathname === "/roadmaps") {
      return "#141414";
    }
    return "#000000"; // Default color if no match
  };

  // Helper function to convert HEX to RGBA for lighter background
  const getLighterBackground = (hexColor, opacity) => {
    const rgb = hexColor
      .replace("#", "")
      .match(/.{1,2}/g)
      .map((x) => parseInt(x, 16));
    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${opacity})`;
  };

  const borderColor = getIconColour(pathname); // Border color
  const backgroundColor = getLighterBackground(borderColor, 0.05); // Background color with 10% opacity

  return (
    <div
      className="flex justify-center items-center px-2 py-1 gap-2 border rounded-md"
      style={{
        borderColor: borderColor, // Set border color dynamically
        backgroundColor: backgroundColor, // Set lighter background color dynamically
      }}
    >
      {isFeedback ? (
        <CommentCheck size={14} color={getIconColour(pathname)} />
      ) : (
        <IconWrapper
          icon={icon}
          size={14}
          text={title}
          iconColor={borderColor}
          isASidebarComponent={false}
          isIconActive={true}
        />
      )}
      <h2 className=" text-title ">{title}</h2>
    </div>
  );
};

export default TopbarTitle;
