import React, { useState } from "react";

const CustomURLPrompt = ({ previousUrl, onConfirm, onCancel }) => {
  const [url, setUrl] = useState(previousUrl || "");

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-5 z-50">
      <div className="bg-white rounded-lg p-5 shadow-lg w-80">
        {/* <h2 className="text-xs font-semibold mb-3">Enter URL</h2> */}
        <input
          type="text"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          className="w-full border-b-2 p-1 focus:outline-none"
          placeholder="https://example.com"
        />
        <div className="flex justify-end mt-4 space-x-2">
          <span
            className="cursor-pointer text-gray-300  hover:underline"
             onClick={onCancel}
          >
            Cancel
          </span>
          <span
            className="cursor-pointer text-blue-500  hover:underline"
             onClick={() => onConfirm(url)}
          >
            Confirm
          </span>
        </div>
      </div>
    </div>
  );
};

export default CustomURLPrompt;
