import React, { useState } from "react";
import { useSelector } from "react-redux";
import { userEndPoints } from "../../../api/api";
import { apiConnector } from "api/apiconnector";
import { toast } from "react-toastify";
import { Empty, Input, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const { RE_USERS } = userEndPoints;

const InvitedUsers = () => {
  const { invitedUsers } = useSelector((state) => state.invitedUsers);
  const [loadingEmail, setLoadingEmail] = useState(null); // Track loading state for each email
  const [searchTerm, setSearchTerm] = useState("");
  const [isFocused, setIsFocused] = useState(false);

  const handleReInvite = async (email) => {
    setLoadingEmail(email); // Set loading state to the email being processed
    const data = { email: email };

    try {
      await apiConnector("POST", RE_USERS, data);
      toast.success(`Invite sent to ${email}`);
    } catch (error) {
      console.log(error);
      toast.error(error.response?.data?.message ?? "Failed to re-invite user.");
    } finally {
      setLoadingEmail(null); // Reset loading state
    }
  };

  // Filter invited users based on the search term (case-insensitive)
  const filteredInvitedUsers = invitedUsers.filter((user) =>
    user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      {invitedUsers && invitedUsers.length > 0 ? (
        <div>
          {/* Search Input */}
          <div className="mb-10">
            <Input
              placeholder="Search invited member"
              prefix={
                <SearchOutlined
                  style={{
                    color: isFocused ? "#696969" : "#d3d3d3", // Dark gray on focus, light gray otherwise
                    fontSize: 12,
                    marginRight: "8px", // Gap between icon and text
                  }}
                />
              }
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
            />
          </div>
          {/* Table of Invited Users */}
          <table className="min-w-full table-auto">
            <thead>
              <tr className="text-sm">
                <th className="text-title text-start pr-1 font-normal w-[24rem]">
                  Email
                </th>
                <th className="text-title text-start pr-1 px-12 font-normal">
                  {/* Column for the action */}
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredInvitedUsers.map((user, index) => (
                <tr
                  key={index}
                  className="text-title text-[14px] rounded relative"
                >
                  <td className="truncate pr-1 py-2">{user.email}</td>
                  <td className="truncate pr-1 px-12 py-2">
                    {loadingEmail === user.email ? (
                      <span className="text-blue-500">Sending...</span>
                    ) : (
                      <span
                        className="cursor-pointer text-button-active hover:underline"
                        onClick={() => handleReInvite(user.email)}
                      >
                        Resend invite
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="flex justify-center items-start pt-8 h-[80vh]">
          {/* <p className="text-lg text-gray-500">There are no invited users.</p> */}
          <Empty
            styles={{ image: { height: 60 } }}
            description={
              <Typography.Text className=" font-bold text-primary">
                No member
              </Typography.Text>
            }
          ></Empty>
        </div>
      )}
    </div>
  );
};

export default InvitedUsers;

// import React, { useState } from "react";
// import { useSelector } from "react-redux";
// import { userEndPoints } from "../../../api/api";
// import { apiConnector } from "api/apiconnector";
// import { toast } from "react-toastify";

// const { RE_USERS } = userEndPoints;

// const InvitedUsers = () => {
//   const { invitedUsers } = useSelector((state) => state.invitedUsers);
//   const [loadingEmail, setLoadingEmail] = useState(null); // Track loading state for each email

//   const handleReInvite = async (email) => {
//     setLoadingEmail(email); // Set loading state to the email being processed
//     const data = { email: email };

//     try {
//       const response = await apiConnector("POST", RE_USERS, data);
//       toast.success(`Invite sent to ${email}`);
//     } catch (error) {
//       console.log(error);
//       toast.error(error.response?.data?.message ?? "Failed to re-invite user.");
//     } finally {
//       setLoadingEmail(null); // Reset loading state
//     }
//   };

//   return (
//     <div>
//       {invitedUsers && invitedUsers.length > 0 ? (
//         <div className="">
//           <table className="min-w-full table-auto">
//             <thead>
//               <tr className="text-sm">
//                 <th className="text-primary text-start pr-1 font-normal w-[24rem]   ">
//                   Email
//                 </th>
//                 <th className="text-primary text-start pr-1 px-12 font-normal">
//                   {" "}
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {invitedUsers.map((user, index) => (
//                 <tr
//                   key={index}
//                   className="text-gray-700 text-[14px] rounded relative"
//                 >
//                   <td className="truncate pr-1  py-2">{user.email}</td>
//                   <td className="truncate pr-1 px-12 py-2 ">
//                     {loadingEmail === user.email ? (
//                       <span className="text-blue-500">Sending...</span>
//                     ) : (
//                       <span
//                         className="cursor-pointer text-button-active  hover:underline"
//                         onClick={() => handleReInvite(user.email)}
//                       >
//                         Resend invite
//                       </span>
//                     )}
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       ) : (
//         <div className="flex justify-center items-center h-[80vh]">
//           <p className="text-lg text-gray-500">There are no invited users.</p>
//         </div>
//       )}
//     </div>
//   );
// };

// export default InvitedUsers;
