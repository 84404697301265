import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/common/Loader";
import IconWrapper from "components/common/IconWrapper";
import ColorDropdown from "./ColorDropdown";
import { fieldIconMapping, colors } from "utils";
import { Modal, Input, DatePicker, Button, Form, Divider } from "antd";
import dayjs from "dayjs";
import {
  editTimeFrameInCompany,
  addTimeFrameToCompany,
  deleteTimeFrameOfCompany,
} from "reduxStore/operations/timeFramesAPI";
import CustomizationsLabel from "../../common/CustomizationsLabel";
import IconText from "../../common/IconText";
import { Calendar, Pencil, PlusSmall, Trash } from "react-flaticons";
import { TbEdit } from "react-icons/tb";

const TimeFramesField = () => {
  const dispatch = useDispatch();
  const { timeFrames } = useSelector((state) => state.company);

  // States for dates and color
  const [activeStartDate, setActiveStartDate] = useState("");
  const [activeEndDate, setActiveEndDate] = useState("");
  const [hex, setHex] = useState(colors[0]);
  const [isColorDropdownOpen, setIsColorDropdownOpen] = useState(false);

  // Modal visibility states
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  // Loading states for async operations
  const [addConfirmLoading, setAddConfirmLoading] = useState(false);
  const [editConfirmLoading, setEditConfirmLoading] = useState(false);

  // Add modal state
  const [addNewTimeFrameLabel, setAddNewTimeFrameLabel] = useState("");
  // For add, originalStartDate and originalEndDate are kept for fallback (could be empty)
  const [originalStartDate, setOriginalStartDate] = useState("");
  const [originalEndDate, setOriginalEndDate] = useState("");

  // Edit modal state
  const [activeEditTimeFrameId, setActiveEditTimeFrameId] = useState(null);
  const [editLabel, setEditLabel] = useState("");
  const [originalEditLabel, setOriginalEditLabel] = useState("");
  const [orignalEditTimeFrameColor, setOrignalEditTimeFrameColor] =
    useState("");

  // Reset functions for modals
  const resetAddOptions = () => {
    setAddNewTimeFrameLabel("");
    setIsAddModalVisible(false);
    setActiveStartDate("");
    setActiveEndDate("");
  };

  const resetEditOptions = () => {
    setEditLabel("");
    setOriginalEditLabel("");
    setIsColorDropdownOpen(false);
    setActiveStartDate("");
    setActiveEndDate("");
    setActiveEditTimeFrameId(null);
    setOrignalEditTimeFrameColor("");
    setIsEditModalVisible(false);
  };

  // Opens the edit modal and loads the time frame data into state
  const editButtonClick = (
    timeFrameValue,
    startDate,
    endDate,
    timeFrameId,
    timeFrameColor,
    shouldOpenColorDropdown = false
  ) => {
    setHex(timeFrameColor);
    setIsColorDropdownOpen(shouldOpenColorDropdown);
    setOriginalEditLabel(timeFrameValue);
    setEditLabel(timeFrameValue);
    setActiveEditTimeFrameId(timeFrameId);
    setOriginalStartDate(startDate);
    setOriginalEndDate(endDate);
    setOrignalEditTimeFrameColor(timeFrameColor);
    setActiveStartDate(startDate);
    setActiveEndDate(endDate);
    setIsEditModalVisible(true);
  };

  // Opens the add modal (clearing any previous state)
  const addButtonClick = () => {
    resetAddOptions();
    setHex(colors[0]);
    setIsAddModalVisible(true);
  };

  // Called when the user confirms an edit
  const handleEditTimeFrame = () => {
    if (
      editLabel !== originalEditLabel ||
      activeStartDate ||
      activeEndDate ||
      hex !== orignalEditTimeFrameColor
    ) {
      setEditConfirmLoading(true);
      dispatch(
        editTimeFrameInCompany(
          activeEditTimeFrameId,
          editLabel !== "" ? editLabel : originalEditLabel,
          hex,
          activeStartDate || originalStartDate,
          activeEndDate || originalEndDate
        )
      )
        .then(() => {
          setEditConfirmLoading(false);
          resetEditOptions();
        })
        .catch(() => {
          setEditConfirmLoading(false);
        });
    } else {
      resetEditOptions();
    }
  };

  // Called when the user confirms adding a new time frame
  const handleAddTimeFrame = () => {
    if (addNewTimeFrameLabel !== "" || activeStartDate || activeEndDate) {
      setAddConfirmLoading(true);
      dispatch(
        addTimeFrameToCompany(
          addNewTimeFrameLabel,
          hex,
          activeStartDate || originalStartDate,
          activeEndDate || originalEndDate
        )
      )
        .then(() => {
          setAddConfirmLoading(false);
          resetAddOptions();
        })
        .catch(() => {
          setAddConfirmLoading(false);
        });
    } else {
      resetAddOptions();
    }
  };

  // Delete action for the currently edited time frame
  const handleDeleteTimeFrame = (id) => {
    dispatch(deleteTimeFrameOfCompany(id));
    resetEditOptions();
  };

  if (!timeFrames) {
    return (
      <div className="flex items-center justify-center h-[600px]">
        <Loader />
      </div>
    );
  }

  return (
    <div className="text-sm text-title_10 cursor-default">
      <div className="text-[16px] space-y-3">
        <div className="flex items-center justify-between">
          <h2 className="text-title">Time-frame</h2>
          <Button
            onClick={addButtonClick}
            type="primary"
            // icon={<PlusSmall size={20} />}
            // className="pl-2"
          >
            Add Time-frame
          </Button>
        </div>
        <p className="text-primary">
          Set and track timelines to keep your plans on schedule
        </p>
      </div>
      <Divider className=" p-0 mt-6 mb-10 " />
      <div className="flex flex-col gap-y-2">
        {timeFrames.length > 0 ? (
          timeFrames.map((timeFrame) => (
            <div
              key={timeFrame._id}
              className="flex items-center text-primary text-sm gap-x-2 w-fit group"
            >
              <IconText
                icon={React.createElement(fieldIconMapping.timeFrames)}
                iconColor={
                  activeEditTimeFrameId === timeFrame._id
                    ? hex
                    : timeFrame.color
                }
                text={timeFrame.label}
                textColor="text-title"
              />
              <div
                onClick={() =>
                  editButtonClick(
                    timeFrame.label,
                    timeFrame.startDate,
                    timeFrame.endDate,
                    timeFrame._id,
                    timeFrame.color
                  )
                }
                className="rounded-full p-2 ml-1 hover:bg-topbar-button-hover opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              >
                <TbEdit size={17} />
              </div>
              <div
                onClick={() => handleDeleteTimeFrame(timeFrame._id)}
                className="rounded-full p-2 opacity-0 hover:bg-topbar-button-hover group-hover:opacity-100 transition-opacity duration-200"
              >
                <Trash size={14} />
              </div>
            </div>
          ))
        ) : (
          <div className="flex items-center gap-x-3">
            <IconWrapper
              icon={React.createElement(fieldIconMapping.timeFrames)}
              size="14"
              iconColor={colors[0]}
            />
            <div className="p-2 border rounded w-48 cursor-not-allowed bg-row-background">
              No Time-frame
            </div>
          </div>
        )}
      </div>

      {/* Add Modal */}
      <Modal
        title={
          <h2 className="flex gap-2 items-center pt-1 pb-2">
            <Calendar size={18} className="mb-[2px]" />
            <span>Time Frame</span>
          </h2>
        }
        open={isAddModalVisible}
        onOk={handleAddTimeFrame}
        onCancel={resetAddOptions}
        okText="Add Time-frame"
        confirmLoading={addConfirmLoading}
      >
        <Form.Item label="Timeframe Name" colon={false} className="mb-0" />
        <div className="  flex justify-center items-center gap-1.5  ">
          <div className=" mb-[18px] ">
            <ColorDropdown
              icon={React.createElement(fieldIconMapping.timeFrames)}
              iconColor={hex}
              clickable={true}
              hex={hex}
              setHex={setHex}
              openDropdownCondition={isColorDropdownOpen}
              onClick={() => setIsColorDropdownOpen(!isColorDropdownOpen)}
            />
          </div>
          <Input
            placeholder="Q3 2025"
            value={addNewTimeFrameLabel}
            onChange={(e) => setAddNewTimeFrameLabel(e.target.value)}
            style={{ marginBottom: 16 }}
          />
        </div>

        {/* <div className="mb-4 flex gap-1">
          <span className="mr-2">Select Color</span>
   
        </div> */}
        <Form.Item label="Period(optional)" colon={false} className="mb-0" />
        <DatePicker.RangePicker
          value={[
            activeStartDate ? dayjs(activeStartDate) : null,
            activeEndDate ? dayjs(activeEndDate) : null,
          ]}
          onChange={(dates, dateStrings) => {
            setActiveStartDate(dateStrings[0]);
            setActiveEndDate(dateStrings[1]);
          }}
          style={{ marginBottom: 20 }}
        />
      </Modal>

      {/* Edit Modal */}
      <Modal
        title="Time Frame"
        open={isEditModalVisible}
        onCancel={resetEditOptions}
        footer={[
          <Button key="cancel" onClick={resetEditOptions}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handleEditTimeFrame}
            loading={editConfirmLoading}
          >
            Update
          </Button>,
        ]}
      >
        <Form.Item label="Timeframe Name" colon={false} className="mb-0" />
        <div className="  flex justify-center items-center gap-1.5  ">
          <div className=" mb-[18px] ">
            <ColorDropdown
              icon={React.createElement(fieldIconMapping.timeFrames)}
              iconColor={hex}
              clickable={true}
              hex={hex}
              setHex={setHex}
              openDropdownCondition={isColorDropdownOpen}
              onClick={() => setIsColorDropdownOpen(!isColorDropdownOpen)}
            />
          </div>
          <Input
            placeholder="Q3 2025"
            value={editLabel}
            onChange={(e) => setEditLabel(e.target.value)}
            style={{ marginBottom: 16 }}
          />
        </div>

        {/* <div className="mb-4 flex">
          <span className="mr-2">Select Color</span>
        </div> */}
        <Form.Item label="Period(optional)" colon={false} className="mb-0" />
        <DatePicker.RangePicker
          value={[
            activeStartDate
              ? dayjs(activeStartDate)
              : originalStartDate
              ? dayjs(originalStartDate)
              : null,
            activeEndDate
              ? dayjs(activeEndDate)
              : originalEndDate
              ? dayjs(originalEndDate)
              : null,
          ]}
          onChange={(dates, dateStrings) => {
            setActiveStartDate(dateStrings[0]);
            setActiveEndDate(dateStrings[1]);
          }}
          style={{ marginBottom: 20 }}
        />
      </Modal>
    </div>
  );
};

export default TimeFramesField;

// import React, { useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import Loader from "components/common/Loader";
// import IconWrapper from "components/common/IconWrapper";
// import ColorDropdown from "./ColorDropdown";
// import { fieldIconMapping, colors } from "utils";
// import { Modal, Input, DatePicker, Button, Form } from "antd";
// import dayjs from "dayjs";
// import {
//   editTimeFrameInCompany,
//   addTimeFrameToCompany,
//   deleteTimeFrameOfCompany,
// } from "reduxStore/operations/timeFramesAPI";
// import CustomizationsLabel from "../../common/CustomizationsLabel";
// import IconText from "../../common/IconText";
// import { Calendar, Pencil, PlusSmall, Trash } from "react-flaticons";
// import { TbEdit } from "react-icons/tb";

// const TimeFramesField = () => {
//   const dispatch = useDispatch();
//   const { timeFrames } = useSelector((state) => state.company);

//   // States for dates and color
//   const [activeStartDate, setActiveStartDate] = useState("");
//   const [activeEndDate, setActiveEndDate] = useState("");
//   const [hex, setHex] = useState(colors[0]);
//   const [isColorDropdownOpen, setIsColorDropdownOpen] = useState(false);

//   // Modal visibility states
//   const [isAddModalVisible, setIsAddModalVisible] = useState(false);
//   const [isEditModalVisible, setIsEditModalVisible] = useState(false);

//   // Add modal state
//   const [addNewTimeFrameLabel, setAddNewTimeFrameLabel] = useState("");
//   // For add, originalStartDate and originalEndDate are kept for fallback (could be empty)
//   const [originalStartDate, setOriginalStartDate] = useState("");
//   const [originalEndDate, setOriginalEndDate] = useState("");

//   // Edit modal state
//   const [activeEditTimeFrameId, setActiveEditTimeFrameId] = useState(null);
//   const [editLabel, setEditLabel] = useState("");
//   const [originalEditLabel, setOriginalEditLabel] = useState("");
//   const [orignalEditTimeFrameColor, setOrignalEditTimeFrameColor] =
//     useState("");

//   // Reset functions for modals
//   const resetAddOptions = () => {
//     setAddNewTimeFrameLabel("");
//     setIsAddModalVisible(false);
//     setActiveStartDate("");
//     setActiveEndDate("");
//   };

//   const resetEditOptions = () => {
//     setEditLabel("");
//     setOriginalEditLabel("");
//     setIsColorDropdownOpen(false);
//     setActiveStartDate("");
//     setActiveEndDate("");
//     setActiveEditTimeFrameId(null);
//     setOrignalEditTimeFrameColor("");
//     setIsEditModalVisible(false);
//   };

//   // Opens the edit modal and loads the time frame data into state
//   const editButtonClick = (
//     timeFrameValue,
//     startDate,
//     endDate,
//     timeFrameId,
//     timeFrameColor,
//     shouldOpenColorDropdown = false
//   ) => {
//     setHex(timeFrameColor);
//     setIsColorDropdownOpen(shouldOpenColorDropdown);
//     setOriginalEditLabel(timeFrameValue);
//     setEditLabel(timeFrameValue);
//     setActiveEditTimeFrameId(timeFrameId);
//     setOriginalStartDate(startDate);
//     setOriginalEndDate(endDate);
//     setOrignalEditTimeFrameColor(timeFrameColor);
//     setActiveStartDate(startDate);
//     setActiveEndDate(endDate);
//     setIsEditModalVisible(true);
//   };

//   // Opens the add modal (clearing any previous state)
//   const addButtonClick = () => {
//     resetAddOptions();
//     setHex(colors[0]);
//     setIsAddModalVisible(true);
//   };

//   // Called when the user confirms an edit
//   const handleEditTimeFrame = () => {
//     if (
//       editLabel !== originalEditLabel ||
//       activeStartDate ||
//       activeEndDate ||
//       hex !== orignalEditTimeFrameColor
//     ) {
//       dispatch(
//         editTimeFrameInCompany(
//           activeEditTimeFrameId,
//           editLabel !== "" ? editLabel : originalEditLabel,
//           hex,
//           activeStartDate || originalStartDate,
//           activeEndDate || originalEndDate
//         )
//       );
//     }
//     resetEditOptions();
//   };

//   // Called when the user confirms adding a new time frame
//   const handleAddTimeFrame = () => {
//     if (addNewTimeFrameLabel !== "" || activeStartDate || activeEndDate) {
//       dispatch(
//         addTimeFrameToCompany(
//           addNewTimeFrameLabel,
//           hex,
//           activeStartDate || originalStartDate,
//           activeEndDate || originalEndDate
//         )
//       );
//     }
//     resetAddOptions();
//   };

//   // Delete action for the currently edited time frame
//   const handleDeleteTimeFrame = (id) => {
//     dispatch(deleteTimeFrameOfCompany(id));
//     resetEditOptions();
//   };

//   if (!timeFrames) {
//     return (
//       <div className="flex items-center justify-center h-[600px]">
//         <Loader />
//       </div>
//     );
//   }

//   return (
//     <div className="text-sm text-title_10 cursor-default">
//       <div className="text-[16px] space-y-3 pb-8">
//         <div className=" flex items-center justify-between ">
//           <h2 className="text-title">Time-frame</h2>
//           <Button
//             onClick={addButtonClick}
//             type="primary"
//             icon={<PlusSmall size={20} />}
//             className=" pl-2 "
//           >
//             Add Time-frame
//           </Button>
//         </div>
//         <p className="text-primary">
//           Select the object and manage the connected statuses
//         </p>
//       </div>
//       <div className="flex flex-col gap-y-2">
//         {timeFrames.length > 0 ? (
//           timeFrames.map((timeFrame, index) => (
//             <div
//               key={timeFrame._id}
//               className="flex items-center text-primary text-sm gap-x-2 w-fit group"
//             >
//               <IconText
//                 icon={React.createElement(fieldIconMapping.timeFrames)}
//                 iconColor={
//                   activeEditTimeFrameId === timeFrame._id
//                     ? hex
//                     : timeFrame.color
//                 }
//                 text={timeFrame.label}
//                 textColor="text-title"
//               />
//               <div
//                 onClick={() =>
//                   editButtonClick(
//                     timeFrame.label,
//                     timeFrame.startDate,
//                     timeFrame.endDate,
//                     timeFrame._id,
//                     timeFrame.color
//                   )
//                 }
//                 className="rounded-full p-2 ml-1 hover:bg-topbar-button-hover opacity-0 group-hover:opacity-100 transition-opacity duration-200"
//               >
//                 <TbEdit size={17} />
//               </div>
//               <div
//                 onClick={() => handleDeleteTimeFrame(timeFrame._id)}
//                 className="rounded-full p-2 opacity-0 hover:bg-topbar-button-hover group-hover:opacity-100 transition-opacity duration-200"
//               >
//                 <Trash size={14} />
//               </div>
//             </div>
//           ))
//         ) : (
//           <div className="flex items-center gap-x-3">
//             <IconWrapper
//               icon={React.createElement(fieldIconMapping.timeFrames)}
//               size="14"
//               iconColor={colors[0]}
//             />
//             <div className="p-2 border rounded w-48 cursor-not-allowed bg-row-background">
//               No Time-frame
//             </div>
//           </div>
//         )}
//       </div>

//       {/* Add Modal */}
//       <Modal
//         title={
//           <h2 className="flex gap-2 items-center pt-1 pb-2">
//             <Calendar size={18} className="mb-[2px]" />
//             <span className=" ">Time Frame</span>
//           </h2>
//         }
//         open={isAddModalVisible}
//         onOk={handleAddTimeFrame}
//         onCancel={resetAddOptions}
//         okText="Add Time-frame"
//       >
//         <Form.Item label="Timeframe Name" colon={false} className=" mb-0 " />
//         <Input
//           placeholder="Q3 2025"
//           value={addNewTimeFrameLabel}
//           onChange={(e) => setAddNewTimeFrameLabel(e.target.value)}
//           style={{ marginBottom: 16 }}
//         />
//         <div className="mb-4 flex gap-1 ">
//           <span className="mr-2">Select Color</span>
//           <ColorDropdown
//             icon={React.createElement(fieldIconMapping.timeFrames)}
//             iconColor={hex}
//             clickable={true}
//             hex={hex}
//             setHex={setHex}
//             openDropdownCondition={isColorDropdownOpen}
//             onClick={() => setIsColorDropdownOpen(!isColorDropdownOpen)}
//           />
//         </div>
//         <Form.Item label="Period(optional)" colon={false} className=" mb-0 " />
//         <DatePicker.RangePicker
//           value={[
//             activeStartDate ? dayjs(activeStartDate) : null,
//             activeEndDate ? dayjs(activeEndDate) : null,
//           ]}
//           onChange={(dates, dateStrings) => {
//             setActiveStartDate(dateStrings[0]);
//             setActiveEndDate(dateStrings[1]);
//           }}
//           style={{ marginBottom: 20 }}
//         />
//       </Modal>

//       {/* Edit Modal */}
//       <Modal
//         title="Time Frame"
//         open={isEditModalVisible}
//         onCancel={resetEditOptions}
//         footer={[
//           <Button key="cancel" onClick={resetEditOptions}>
//             Cancel
//           </Button>,
//           <Button key="save" type="primary" onClick={handleEditTimeFrame}>
//             Update
//           </Button>,
//         ]}
//       >
//         <Form.Item label="Timeframe Name" colon={false} className=" mb-0 " />
//         <Input
//           placeholder="Q3 2025"
//           value={editLabel}
//           onChange={(e) => setEditLabel(e.target.value)}
//           style={{ marginBottom: 16 }}
//         />
//         <div className="mb-4 flex ">
//           <span className="mr-2">Select Color</span>
//           <ColorDropdown
//             icon={React.createElement(fieldIconMapping.timeFrames)}
//             iconColor={hex}
//             clickable={true}
//             hex={hex}
//             setHex={setHex}
//             openDropdownCondition={isColorDropdownOpen}
//             onClick={() => setIsColorDropdownOpen(!isColorDropdownOpen)}
//           />
//         </div>
//         <Form.Item label="Period(optional)" colon={false} className=" mb-0 " />
//         <DatePicker.RangePicker
//           value={[
//             activeStartDate
//               ? dayjs(activeStartDate)
//               : originalStartDate
//               ? dayjs(originalStartDate)
//               : null,
//             activeEndDate
//               ? dayjs(activeEndDate)
//               : originalEndDate
//               ? dayjs(originalEndDate)
//               : null,
//           ]}
//           onChange={(dates, dateStrings) => {
//             setActiveStartDate(dateStrings[0]);
//             setActiveEndDate(dateStrings[1]);
//           }}
//           style={{ marginBottom: 20 }}
//         />
//       </Modal>
//     </div>
//   );
// };

// export default TimeFramesField;

// import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import Loader from "components/common/Loader";
// import IconWrapper from "components/common/IconWrapper";
// import ColorDropdown from "./ColorDropdown";
// import { fieldIconMapping, colors } from "utils";
// import CalendarSection from "./CalendarSection";
// import {
//   editTimeFrameInCompany,
//   addTimeFrameToCompany,
//   deleteTimeFrameOfCompany,
// } from "reduxStore/operations/timeFramesAPI";
// import EditInputForm from "./EditInputForm";
// import AddInputForm from "./AddInputForm";
// import { CrossSmall, Delete, Trash } from "react-flaticons";
// import CustomizationsLabel from "../../common/CustomizationsLabel";

// const TimeFramesField = () => {
//   const addRef = useRef(null);
//   const editRefs = useRef({});
//   const dispatch = useDispatch();

//   const { timeFrames } = useSelector((state) => state.company);

//   const [isCalendarOpen, setIsCalendarOpen] = useState(false);
//   const [activeStartDate, setActiveStartDate] = useState("");
//   const [activeEndDate, setActiveEndDate] = useState("");
//   const [hex, setHex] = useState(colors[0]);
//   const [isColorDropdownOpen, setIsColorDropdownOpen] = useState(false);
//   const [orignalEditTimeFrameColor, setOrignalEditTimeFrameColor] =
//     useState("");

//   // Add Related States
//   const [isAddInputOpen, setIsAddInputOpen] = useState(false);
//   const [addNewTimeFrameLabel, setAddNewTimeFrameLabel] = useState("");

//   // Edit Related States
//   const [inputLabelClicked, setInputLabelClicked] = useState(false);
//   const [activeEditTimeFrameId, setActiveEditTimeFrameId] = useState(null);
//   const [editLabel, setEditLabel] = useState("");
//   const [originalEditLabel, setOriginalEditLabel] = useState("");
//   const [originalStartDate, setOriginalStartDate] = useState("");
//   const [originalEndDate, setOriginalEndDate] = useState("");

//   const resetEditOptions = () => {
//     setEditLabel("");
//     setOriginalEditLabel("");
//     setIsColorDropdownOpen(false);
//     setIsCalendarOpen(false);
//     setOriginalStartDate("");
//     setOriginalEndDate("");
//     setActiveStartDate("");
//     setActiveEndDate("");
//     setActiveEditTimeFrameId(null);
//     setOrignalEditTimeFrameColor("");
//   };

//   const resetAddOptions = () => {
//     setAddNewTimeFrameLabel("");
//     setIsCalendarOpen(false);
//     setIsAddInputOpen(false);
//     setActiveStartDate("");
//     setActiveEndDate("");
//   };

//   const editButtonClick = (
//     timeFrameValue,
//     startDate,
//     endDate,
//     timeFrameId,
//     timeFrameColor,
//     shouldOpenColorDropdown = false
//   ) => {
//     if (activeEditTimeFrameId === null) {
//       setHex(timeFrameColor);
//     } else {
//       setHex(hex);
//     }
//     setIsColorDropdownOpen(shouldOpenColorDropdown);
//     setOriginalEditLabel(timeFrameValue);
//     if (activeEditTimeFrameId === null) {
//       setEditLabel(timeFrameValue);
//     } else {
//       setEditLabel(editLabel);
//     }
//     setActiveEditTimeFrameId(timeFrameId);
//     setOriginalStartDate(startDate);
//     setOriginalEndDate(endDate);
//     setOrignalEditTimeFrameColor(timeFrameColor);
//   };

//   const addButtonClick = () => {
//     setIsAddInputOpen(true);
//     setAddNewTimeFrameLabel("");
//     setOriginalStartDate(null);
//     setOriginalEndDate(null);
//     setHex(colors[0]);
//   };

//   const handleEditTimeFrame = () => {
//     if (
//       editLabel !== originalEditLabel ||
//       activeStartDate ||
//       activeEndDate ||
//       hex !== orignalEditTimeFrameColor
//     ) {
//       dispatch(
//         editTimeFrameInCompany(
//           activeEditTimeFrameId,
//           editLabel !== "" ? editLabel : originalEditLabel,
//           hex,
//           activeStartDate || originalStartDate,
//           activeEndDate || originalEndDate
//         )
//       );
//     }
//     resetEditOptions();
//   };

//   const handleAddTimeFrame = () => {
//     if (addNewTimeFrameLabel !== "" || activeStartDate || activeEndDate) {
//       dispatch(
//         addTimeFrameToCompany(
//           addNewTimeFrameLabel,
//           hex,
//           activeStartDate || originalStartDate,
//           activeEndDate || originalEndDate
//         )
//       );
//     }
//     resetAddOptions();
//   };

//   const handleDeleteTimeFrame = (TimeFrameId) => {
//     dispatch(deleteTimeFrameOfCompany(TimeFrameId || activeEditTimeFrameId));
//     resetEditOptions();
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (
//         activeEditTimeFrameId &&
//         editRefs.current[activeEditTimeFrameId] &&
//         !editRefs.current[activeEditTimeFrameId].contains(event.target)
//       ) {
//         handleEditTimeFrame();
//         resetAddOptions();
//         setInputLabelClicked(false);
//       } else if (
//         isAddInputOpen &&
//         addRef.current &&
//         !addRef.current.contains(event.target)
//       ) {
//         handleAddTimeFrame();
//         resetEditOptions();
//         setInputLabelClicked(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => document.removeEventListener("mousedown", handleClickOutside);
//   }, [
//     activeEditTimeFrameId,
//     activeStartDate,
//     activeEndDate,
//     editLabel,
//     addNewTimeFrameLabel,
//     isAddInputOpen,
//     hex,
//   ]);

//   if (!timeFrames) {
//     return (
//       <div className="flex items-center justify-center h-[600px]">
//         <Loader />
//       </div>
//     );
//   }

//   return (
//     <div className="text-sm text-title_10 cursor-default  ">
//       <div className=" text-[15px] space-y-3 pb-8 ">
//         <h2 className="text-title ">Time-frame</h2>
//         <p className="text-primary">
//           Select the object and manage the connected statuses
//         </p>
//       </div>
//       <div className="flex flex-col gap-y-2">
//         {timeFrames.length > 0 ? (
//           timeFrames.map((timeFrame, index) => {
//             return (
//               <div
//                 key={timeFrame._id}
//                 className="flex items-center text-primary text-sm gap-x-2 w-fit group "
//                 ref={(el) => (editRefs.current[timeFrame._id] = el)}
//                 onClick={() =>
//                   editButtonClick(
//                     timeFrame.label,
//                     timeFrame.startDate,
//                     timeFrame.endDate,
//                     timeFrame._id,
//                     timeFrame.color
//                   )
//                 }
//               >
//                 <ColorDropdown
//                   icon={React.createElement(fieldIconMapping.timeFrames)}
//                   iconColor={
//                     activeEditTimeFrameId === timeFrame._id
//                       ? hex
//                       : timeFrame.color
//                   }
//                   openDropdownCondition={
//                     isColorDropdownOpen &&
//                     activeEditTimeFrameId === timeFrame._id
//                   }
//                   hex={hex}
//                   setHex={setHex}
//                   onClick={(e) => {
//                     e.stopPropagation(),
//                       !isColorDropdownOpen
//                         ? editButtonClick(
//                             timeFrame.label,
//                             timeFrame.startDate,
//                             timeFrame.endDate,
//                             timeFrame._id,
//                             timeFrame.color,
//                             true
//                           )
//                         : setIsColorDropdownOpen(false);
//                   }}
//                   clickable={true}
//                 />
//                 {activeEditTimeFrameId === timeFrame._id ? (
//                   <EditInputForm
//                     inputLabel={editLabel}
//                     setInputLabel={setEditLabel}
//                     inputFocusCondition={inputLabelClicked}
//                     onEditInputEnter={handleEditTimeFrame}
//                     onInputButtonClick={setIsCalendarOpen}
//                     startDate={activeStartDate || originalStartDate}
//                     endDate={activeEndDate || originalEndDate}
//                     isCalendarActiveCondition={false}
//                     setIsCalendarOpen={setIsCalendarOpen}
//                     setStartDate={setActiveStartDate}
//                     setEndDate={setActiveEndDate}
//                     withCalendar={false}
//                     handleRemoveOptionClick={() => handleDeleteTimeFrame()}
//                     showRemovable={index > 2}
//                   />
//                 ) : (
//                   <>
//                     {/* <div
//                       className="p-2 border rounded w-48 cursor-text"
//                       onClick={() => {
//                         setInputLabelClicked(true);
//                       }}
//                     >
//                       {timeFrame.label}
//                     </div> */}
//                     <CustomizationsLabel
//                       label={timeFrame.label}
//                       onClick={setInputLabelClicked}
//                       handleDeleteItem={handleDeleteTimeFrame}
//                       id={timeFrame._id}
//                       showDelete={index > 2}
//                     />
//                     {/* {index > 2 && (
//                     <CalendarSection
//                       startDate={timeFrame?.startDate ?? null}
//                       endDate={timeFrame?.endDate ?? null}
//                       setIsCalendarOpen={setIsCalendarOpen}
//                       isCalendarActiveCondition={
//                         isCalendarOpen &&
//                         activeEditTimeFrameId === timeFrame._id
//                       }
//                     />
//                   )} */}
//                   </>
//                 )}
//               </div>
//             );
//           })
//         ) : (
//           <div className="flex items-center gap-x-3">
//             <div>
//               <IconWrapper
//                 icon={React.createElement(fieldIconMapping.timeFrames)}
//                 size="14"
//                 iconColor={colors[0]}
//               />
//             </div>
//             <div className="p-2 border rounded w-48 cursor-not-allowed bg-row-background">
//               No Time-frame
//             </div>
//           </div>
//         )}
//         {isAddInputOpen ? (
//           <div className="flex items-center gap-x-2 w-fit" ref={addRef}>
//             <AddInputForm
//               hex={hex}
//               iconColor={hex}
//               setHex={setHex}
//               icon={React.createElement(fieldIconMapping.timeFrames)}
//               iconClickable={true}
//               openIconDropdownCondition={isColorDropdownOpen}
//               onIconClick={() => setIsColorDropdownOpen(!isColorDropdownOpen)}
//               inputLabel={addNewTimeFrameLabel}
//               setInputLabel={(value) => setAddNewTimeFrameLabel(value)}
//               withCalendar={false}
//               startDate={activeStartDate || originalStartDate}
//               endDate={activeEndDate || originalEndDate}
//               setStartDate={setActiveStartDate}
//               setEndDate={setActiveEndDate}
//               setIsCalendarOpen={setIsCalendarOpen}
//               isCalendarActiveCondition={isCalendarOpen}
//               onInputEnter={() => handleAddTimeFrame()}
//               handleCloseOptionClick={resetAddOptions}
//             />
//           </div>
//         ) : (
//           <div
//             className="text-primary cursor-pointer hover:bg-button-hover p-1 w-fit rounded"
//             onClick={() => {
//               addButtonClick();
//             }}
//           >
//             + Add
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default TimeFramesField;
