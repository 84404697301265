import React, { useEffect } from "react";
import Loader from "../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import { getAllFeatures } from "../../reduxStore/operations/features2API";
import Feature2ListView from "./views/list/Feature2ListView";
import Feature2KanbanView from "./views/kanban/Feature2KanbanView";

const Features2Component = ({handlePagination}) => {
  const dispatch = useDispatch();

  const {
    features,
    groupByOption,
    selectedColumns,
    viewType,
    isLoading,
    isScrollLoading,
  } = useSelector((state) => state.features2);

  // useEffect(() => {
  //   dispatch(getAllFeatures());
  // }, []);

  return (
    <div className="relative overflow-scroll bg-white shadow w-full h-full">
      {isLoading ? (
        <div className="flex items-center justify-center h-full">
          <Loader />
        </div>
      ) : (
        <>
          {viewType === "classiclist" && (
            <>
              <Feature2ListView
                groupedData={features}
                selectedColumns={selectedColumns}
                //   collapsedGroups={collapsedGroups}
                //   toggleGroupCollapse={toggleGroupCollapse}
                //   selectedFeatures={selectedFeatures}
                // handleFeatureSelect={handleFeatureSelect}
                //   handleGroupSelect={handleLocalGroupSelect}
                groupByOption={groupByOption}
                isScrollLoading={isScrollLoading}
                handlePagination={handlePagination}
              />
            </>
          )}
          {viewType === "kanban" && (
            <Feature2KanbanView groupedData={features} />
          )}
        </>
      )}
      {/* {selectedFeatures.size > 0 && viewType !== "kanban" && (
      <MasterFeatureSelectionPopup
        selectedFeatures={selectedFeatures}
        resetSelectedFeatures={resetSelectedFeatures}
      />
    )} */}
    </div>
  );
};

export default Features2Component;
