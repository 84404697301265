/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import RoadmapDetailViewTopbar from "./RoadmapDetailViewTopbar";
import { fieldIconMapping } from "utils";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveRoadmapId,
  setRoadmapShowDetailViewDrawer,
} from "reduxStore/slices/roadmapsSlice";
import { updateRoadmapData } from "reduxStore/operations/roadmapsAPI";
import DOMPurify from "dompurify";
import DetailViewHeader from "components/common/detailView/DetailViewHeader";
import DescriptionDetailViewTab from "components/common/detailView/DescriptionDetailViewTab";
import LinkIdeasDetailViewTab from "components/common/detailView/LinkIdeasDetailViewTab";
import { isEmptyDescription } from "utils/descriptionUtils";
import Editor from "../../../tiptapEditor/Index";
const RoadmapDetailViewComponent = ({
  item,
  showBreadcrumb = false,
  onNext = () => {},
  onPrev = () => {},
  shouldNavigate = false,
}) => {
  const dispatch = useDispatch();
  const { activeRoadmapId, showDetailViewDrawer } = useSelector(
    (state) => state.roadmaps
  );
  const [activeTab, setActiveTab] = useState("Overview");
  const handleTabClick = (option) => {
    setActiveTab(option);
  };
  const [isQuillEditorActive, setIsQuillEditorActive] = useState(
    // isEmptyDescription(item?.description ?? "")
    false
  );

  const handleEditorClose = () => {
    setIsQuillEditorActive(false);
  };

  const handleEditorOpen = () => {
    setIsQuillEditorActive(true);
  };

  const [selectedIdeas, setSelectedIdeas] = useState(new Set());

  const handleIdeaSelect = (idea) => {
    setSelectedIdeas((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(idea._id)) {
        newSelected.delete(idea._id);
      } else {
        newSelected.add(idea._id);
      }
      return newSelected;
    });
  };

  const removeSelectedIdeas = () => {
    setSelectedIdeas(new Set());
  };

  const handleFormSubmit = (content) => {
    // Sanitize the HTML content
    const sanitizedContent = DOMPurify.sanitize(content);
    dispatch(updateRoadmapData(item._id, { description: sanitizedContent }));
    setIsQuillEditorActive(false);
  };

  const [editorContent, setEditorContent] = useState("");
  const editorRef = useRef(null);

  useEffect(() => {
    setEditorContent(item.description);
  }, [item]);

  const handleSave = () => {
    dispatch(updateRoadmapData(item._id, { description: editorContent }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (editorRef.current && !editorRef.current.contains(event.target)) {
        if (editorContent !== item.description) {
          handleSave();
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleSave]);

  return (
    <>
      {!item ? (
        <div className="w-full h-full m-auto">Loading....</div>
      ) : (
        <div className="flex flex-col h-full pb-5">
          <div className="flex-none pt-4 px-4 pb-7 text-primary sticky top-0 bg-white z-40">
            <DetailViewHeader
              type="Roadmap"
              item={item}
              showBreadcrumb={showBreadcrumb}
              showDetailViewDrawer={showDetailViewDrawer}
              activeItem={activeRoadmapId}
              setShowDetailViewDrawer={setRoadmapShowDetailViewDrawer}
              setActiveItemId={setActiveRoadmapId}
              Icon={fieldIconMapping.roadmap}
              shouldNavigate={shouldNavigate}
            />
          </div>
          <div className="flex-none px-6">
            <RoadmapDetailViewTopbar
              roadmap={item}
              onNext={onNext}
              onPrev={onPrev}
              onTabClick={handleTabClick}
              activeTab={activeTab}
              updateRoadmapData={(title) =>
                dispatch(updateRoadmapData(item._id, { title: title }, "title"))
              }
            />
          </div>
          <div className="overflow-auto h-full w-full px-6 pt-3 text-primary custom-scrollbar">
            {activeTab === "Overview" && (
           
              <div ref={editorRef}>
                <Editor
                  content={item.description}
                  onChange={(content) => setEditorContent(content)}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default RoadmapDetailViewComponent;
