import { createSlice } from "@reduxjs/toolkit";
import { isObject, transformFiltersToObject } from "utils";

const initialState = {
  ideas: [],
  selectedColumns: [
    "status",
    "score",
    "importance",
    "product",
    "tags",
    "accounts",
  ],
  // filters: {
  //   type: [
  //     {
  //       _id: "Idea",
  //       label: "Idea",
  //     },
  //   ],
  // },
  filters: {},
  sortOption: { order: "asc", field: "created" },
  listViews: [],
  kanbanViews: [],
  groupByOption: "default",
  viewType: "modernlist",
  activeIdeaId: null,
  showDetailViewDrawer: false,
  modernListExists: true,
  isLoading: false,
  isViewsLoading: false,
  detailViewIdeas: [],
};

const priorityMap = {
  status: 1,
  importance: 2,
  score: 3,
  product: 4,
  accounts: 5,
  tags: 6,
  assignee: 7,
  roadmaps: 8,
  features: 9,
};

const ideasSlice = createSlice({
  name: "ideas",
  initialState,
  reducers: {
    setIdeas(state, action) {
      state.ideas = action.payload;
      state.isLoading = false;
    },
    setDetailViewIdeas(state, action) {
      state.detailViewIdeas = action.payload;
    },
    updateIdea(state, action) {
      const { updatedIdea, isUpdated } = action.payload;
      const index = state.ideas.findIndex(
        (Idea) => Idea._id === updatedIdea._id
      );

      if (index !== -1) {
        if (isUpdated === "score") {
          state.ideas[index].score = updatedIdea.score;
        } else if (isUpdated === "features") {
          state.ideas[index].features = updatedIdea.features;
        } else if (isUpdated === "tags") {
          state.ideas[index].tags = updatedIdea.tags;
        } else if (isUpdated === "accounts") {
          state.ideas[index].accounts = updatedIdea.accounts;
        } else {
          state.ideas[index] = updatedIdea;
        }
      }
    },
    updateIdeas(state, action) {
      const { updatedIdeas, isUpdatedArray } = action.payload;

      updatedIdeas.forEach((updatedIdea) => {
        const index = state.ideas.findIndex(
          (idea) => idea._id === updatedIdea._id
        );
        if (index !== -1) {
          if (isUpdatedArray.length > 0) {
            isUpdatedArray.forEach((updateKey) => {
              state.ideas[index][updateKey] = updatedIdea[updateKey];
            });
          } else {
            state.ideas[index] = updatedIdea;
          }
        }
      });
    },
    updateIdeaRoadmaps(state, action) {
      const { updatedIdeas, roadmap } = action.payload;
      updatedIdeas.forEach((updatedIdea) => {
        const index = state.ideas.findIndex(
          (idea) => idea._id === updatedIdea.idea._id
        );
        if (index !== -1) {
          state.ideas[index].roadmaps.push(roadmap);
        }
      });
    },
    removeIdeaRoadmaps(state, action) {
      const { updatedIdeas, roadmap } = action.payload;
      updatedIdeas.forEach((updatedIdea) => {
        const index = state.ideas.findIndex(
          (idea) => idea._id === updatedIdea.idea._id
        );
        if (index !== -1) {
          // Remove the roadmap from the idea's roadmaps array
          state.ideas[index].roadmaps = state.ideas[index].roadmaps.filter(
            (r) => r !== roadmap
          );
        }
      });
    },
    appendIdea(state, action) {
      const newIdea = action.payload;
      // state.ideas.unshift(newIdea);
      state.ideas.push(newIdea);
    },
    removeIdea(state, action) {
      const deletedIdeaId = action.payload;
      const index = state.ideas.findIndex((idea) => idea._id === deletedIdeaId);
      if (index !== -1) {
        state.ideas.splice(index, 1);
      }
    },
    removeIdeas(state, action) {
      const { ideaIds } = action.payload;
      ideaIds.forEach((ideaId) => {
        const index = state.ideas.findIndex((idea) => idea._id === ideaId);
        if (index !== -1) {
          state.ideas.splice(index, 1);
        }
      });
    },
    toggleIdeaColumn(state, action) {
      const column = action.payload;

      if (state.selectedColumns.includes(column)) {
        state.selectedColumns = state.selectedColumns.filter(
          (col) => col !== column
        );
      } else {
        const columnPriority = priorityMap[column];
        let insertIndex = state.selectedColumns.length;

        for (let i = 0; i < state.selectedColumns.length; i++) {
          const currentPriority = priorityMap[state.selectedColumns[i]];
          if (columnPriority < currentPriority) {
            insertIndex = i;
            break;
          }
        }

        state.selectedColumns.splice(insertIndex, 0, column);
      }
    },
    setIdeaFilters(state, action) {
      state.filters = action.payload;
    },
    updateIdeaFilter(state, action) {
      const { key, value } = action.payload;

      if (!state.filters[key]) {
        state.filters[key] = [];
      }

      const existingIndex = state.filters[key].findIndex(
        (v) => JSON.stringify(v) === JSON.stringify(value)
      );

      if (existingIndex !== -1) {
        state.filters[key] = state.filters[key].filter(
          (v) => JSON.stringify(v) !== JSON.stringify(value)
        );
      } else {
        state.filters[key].push(value);
      }

      if (state.filters[key].length === 0) {
        delete state.filters[key];
      }
    },
    clearIdeaFilters(state) {
      state.filters = {};
    },
    setIdeaSortOption(state, action) {
      state.sortOption = action.payload;
    },
    setIdeaActiveProperties(state, action) {
      const { filters, groupByOption, sortOption, selectedColumns } =
        action.payload;

      const finalFilters = isObject(filters)
        ? filters
        : transformFiltersToObject(filters);

      state.filters = finalFilters || state.filters;
      state.groupByOption = groupByOption || state.groupByOption;
      state.sortOption = sortOption || state.sortOption;
      state.selectedColumns = selectedColumns || state.selectedColumns;
    },
    setIdeaViews(state, action) {
      const { views, viewTypeName } = action.payload;
      if (
        viewTypeName === "classiclist" ||
        viewTypeName === "modernlist" ||
        viewTypeName === "list"
      ) {
        state.listViews = views;
      } else {
        state.kanbanViews = views;
      }
      state.isViewsLoading = false;
    },
    appendIdeaView(state, action) {
      const { newlyAddedView, viewTypeName } = action.payload;
      if (
        viewTypeName === "list" ||
        viewTypeName === "modernlist" ||
        viewTypeName === "classiclist"
      ) {
        state.listViews.push(newlyAddedView);
      } else {
        state.kanbanViews.push(newlyAddedView);
      }
    },
    removeIdeaView(state, action) {
      const { deletedViewId, viewTypeName } = action.payload;
      let index = -1;
      if (
        viewTypeName === "list" ||
        viewTypeName === "modernlist" ||
        viewTypeName === "classiclist"
      ) {
        index = state.listViews.findIndex((view) => view._id === deletedViewId);
        if (index !== -1) {
          state.listViews.splice(index, 1);
        }
      } else {
        index = state.kanbanViews.findIndex(
          (view) => view._id === deletedViewId
        );
        if (index !== -1) {
          state.kanbanViews.splice(index, 1);
        }
      }
    },
    updateIdeaView(state, action) {
      const { updatedView, viewTypeName } = action.payload;
      let index = -1;
      if (
        viewTypeName === "list" ||
        viewTypeName === "modernlist" ||
        viewTypeName === "classiclist"
      ) {
        index = state.listViews.findIndex(
          (view) => view._id === updatedView._id
        );
        if (index !== -1) {
          state.listViews[index] = updatedView;
        }
      } else {
        index = state.kanbanViews.findIndex(
          (view) => view._id === updatedView._id
        );
        if (index !== -1) {
          state.kanbanViews[index] = updatedView;
        }
      }
    },
    setIdeaGroupByOption(state, action) {
      state.groupByOption = action.payload;
    },
    setIdeaViewType(state, action) {
      state.viewType = action.payload;
      state.isViewsLoading = false;
    },
    setActiveIdeaId(state, action) {
      state.activeIdeaId = action.payload;
    },
    setIdeaShowDetailViewDrawer(state, action) {
      state.showDetailViewDrawer = action.payload;
    },
    setIsIdeaLoading(state, action) {
      state.isLoading = action.payload;
    },
    setIsIdeaViewLoading(state, action) {
      state.isViewsLoading = action.payload;
    },

    addIdeaFeature(state, action) {
      const { ideas, featureId } = action.payload;

      const ideaSet = new Set(ideas);
      state.ideas?.forEach((idea) => {
        if (ideaSet.has(idea?._id)) {
          const featureSet = new Set(idea.features);
          featureSet.add(featureId);

          idea.features = Array.from(featureSet);
        }
      });
    },

    removeIdeaFeature(state, action) {
      const { ideas, featureId } = action.payload;
      const ideaSet = new Set(ideas);
      state.ideas?.forEach((idea) => {
        if (ideaSet.has(idea?._id)) {
          idea.features = idea.features.filter((id) => id !== featureId);
        }
      });
    },

    updateIdeaStatus(state, action) {
      const updatedStatus = action.payload;

      state.ideas?.forEach((idea) => {
        if (idea?.status?._id === updatedStatus._id) {
          idea.status = updatedStatus;
        }
      });
    },
    replaceIdeaStatus(state, action) {
      const { statusId, replaceStatus } = action.payload;

      state.ideas?.forEach((idea) => {
        if (idea?.status?._id === statusId) {
          idea.status = replaceStatus;
        }
      });
    },
  },
});

export const {
  setIdeas,
  setDetailViewIdeas,
  setActiveIdeaId,
  setIdeaActiveProperties,
  setIdeaFilters,
  setIdeaGroupByOption,
  setIdeaShowDetailViewDrawer,
  setIdeaSortOption,
  setIdeaViewType,
  setIdeaViews,
  updateIdeaView,
  appendIdeaView,
  removeIdeaView,
  updateIdea,
  updateIdeas,
  updateIdeaRoadmaps,
  removeIdeaRoadmaps,
  appendIdea,
  removeIdea,
  removeIdeas,
  updateIdeaFilter,
  clearIdeaFilters,
  toggleIdeaColumn,
  setIsIdeaLoading,
  setIsIdeaViewLoading,
  addIdeaFeature,
  removeIdeaFeature,
  updateIdeaStatus,
  replaceIdeaStatus,
} = ideasSlice.actions;

export const ideasReducer = ideasSlice.reducer;
