import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "components/common/Loader";
import IconWrapper from "components/common/IconWrapper";
import ColorDropdown from "./ColorDropdown";
import { fieldIconMapping } from "utils";
import { Modal, Input, Button, Form, Divider } from "antd";
import {
  addTagToCompany,
  deleteTagOfCompany,
  editTagInCompany,
} from "reduxStore/operations/tagsAPI";
import CustomizationsLabel from "../../common/CustomizationsLabel";
import IconText from "../../common/IconText";
import EditInputForm from "./EditInputForm";
import { Pencil, PlusSmall, Trash } from "react-flaticons";
import { TbEdit } from "react-icons/tb";

const TagsField = () => {
  const dispatch = useDispatch();
  const { tags } = useSelector((state) => state.company);

  // Modal visibility states
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  // Loading states for async operations
  const [addConfirmLoading, setAddConfirmLoading] = useState(false);
  const [editConfirmLoading, setEditConfirmLoading] = useState(false);

  // Add modal state
  const [addNewTagLabel, setAddNewTagLabel] = useState("");

  // Edit modal state
  const [activeEditTagId, setActiveEditTagId] = useState(null);
  const [editLabel, setEditLabel] = useState("");
  const [originalEditTagLabel, setOriginalEditTagLabel] = useState("");

  // Reset functions for modals
  const resetAddOptions = () => {
    setAddNewTagLabel("");
    setIsAddModalVisible(false);
  };

  const resetEditOptions = () => {
    setActiveEditTagId(null);
    setEditLabel("");
    setOriginalEditTagLabel("");
    setIsEditModalVisible(false);
  };

  // Open the add modal
  const addButtonClick = () => {
    resetAddOptions();
    setIsAddModalVisible(true);
  };

  // Open the edit modal for a specific tag
  const editButtonClick = (id, label) => {
    setActiveEditTagId(id);
    setEditLabel(label);
    setOriginalEditTagLabel(label);
    setIsEditModalVisible(true);
  };

  // Add tag action with loading state
  const handleAddTag = () => {
    if (addNewTagLabel.trim() !== "") {
      setAddConfirmLoading(true);
      dispatch(addTagToCompany(addNewTagLabel.trim()))
        .then(() => {
          setAddConfirmLoading(false);
          resetAddOptions();
        })
        .catch(() => {
          setAddConfirmLoading(false);
        });
    }
  };

  // Edit tag action with loading state
  const handleEditTag = () => {
    if (editLabel.trim() === "") {
      return;
    } else if (originalEditTagLabel.trim() === editLabel.trim()) {
      resetEditOptions();
    } else {
      setEditConfirmLoading(true);
      dispatch(editTagInCompany(activeEditTagId, editLabel.trim()))
        .then(() => {
          setEditConfirmLoading(false);
          resetEditOptions();
        })
        .catch(() => {
          setEditConfirmLoading(false);
        });
    }
  };

  // Delete tag action (for edit modal)
  const handleDeleteTag = (id) => {
    dispatch(deleteTagOfCompany(id));
    resetEditOptions();
  };

  if (!tags) {
    return (
      <div className="flex items-center justify-center h-[600px]">
        <Loader />
      </div>
    );
  }

  return (
    <div className="text-sm text-title_10 cursor-default">
      <div className="text-[16px] space-y-3">
        <div className="flex items-center justify-between">
          <h2 className="text-title">Tags</h2>
          <Button
            onClick={addButtonClick}
            type="primary"
            // icon={<PlusSmall size={20} />}
            // className="pl-2"
          >
            Add Tag
          </Button>
        </div>
        <p className="text-primary">
          Categorize records for better organization and easy filtering
        </p>
      </div>
      <Divider className=" p-0 mt-6 mb-10 " />
      <div className="flex flex-col gap-y-2">
        {tags.length > 0 ? (
          tags.map((tag) => (
            <div
              key={tag._id}
              className="flex items-center text-primary rounded text-sm w-fit group"
            >
              <IconText
                icon={React.createElement(fieldIconMapping.tags)}
                iconColor={"#696969"}
                text={tag.label}
                textColor="text-title"
              />
              <div
                onClick={() => editButtonClick(tag._id, tag.label)}
                className="rounded-full p-2 ml-1 hover:bg-topbar-button-hover opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              >
                <TbEdit size={17} />
              </div>
              <div
                onClick={() => handleDeleteTag(tag._id)}
                className="rounded-full p-2 opacity-0 hover:bg-topbar-button-hover group-hover:opacity-100 transition-opacity duration-200"
              >
                <Trash size={14} />
              </div>
            </div>
          ))
        ) : (
          <div className="flex items-center gap-x-3">
            <div>
              <IconWrapper
                icon={React.createElement(fieldIconMapping.tags)}
                size="14"
              />
            </div>
            <div className="p-2 border rounded w-48 cursor-not-allowed bg-row-background">
              No Tags
            </div>
          </div>
        )}
      </div>

      {/* Add Modal */}
      <Modal
        title={
          <h2 className="flex gap-2 items-center pt-1 pb-2">
            <IconWrapper
              icon={React.createElement(fieldIconMapping.tags)}
              size="14"
            />
            <span>Tag</span>
          </h2>
        }
        open={isAddModalVisible}
        onOk={handleAddTag}
        onCancel={resetAddOptions}
        okText="Add Tag"
        confirmLoading={addConfirmLoading}
      >
        <Form.Item label="Tag Name" colon={false} className="mb-0" />
        <Input
          placeholder="Bug"
          value={addNewTagLabel}
          onChange={(e) => setAddNewTagLabel(e.target.value)}
          style={{ marginBottom: 20 }}
        />
      </Modal>

      {/* Edit Modal */}
      <Modal
        title={
          <h2 className="flex gap-2 items-center pt-1 pb-2">
            <IconWrapper
              icon={React.createElement(fieldIconMapping.tags)}
              size="14"
            />
            <span>Tag</span>
          </h2>
        }
        open={isEditModalVisible}
        onCancel={resetEditOptions}
        footer={[
          <Button key="cancel" onClick={resetEditOptions}>
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handleEditTag}
            loading={editConfirmLoading}
          >
            Update
          </Button>,
        ]}
      >
        <Form.Item label="Tag Name" colon={false} className="mb-0" />
        <Input
          placeholder="Bug"
          value={editLabel}
          onChange={(e) => setEditLabel(e.target.value)}
          style={{ marginBottom: 20 }}
        />
      </Modal>
    </div>
  );
};

export default TagsField;

// import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import Loader from "components/common/Loader";
// import IconWrapper from "components/common/IconWrapper";
// import EditInputForm from "./EditInputForm";
// import ColorDropdown from "./ColorDropdown";
// import { fieldIconMapping } from "utils";
// import AddInputForm from "./AddInputForm";
// import {
//   addTagToCompany,
//   deleteTagOfCompany,
//   editTagInCompany,
// } from "reduxStore/operations/tagsAPI";
// import CustomizationsLabel from "../../common/CustomizationsLabel";

// const TagsField = () => {
//   const addRef = useRef(null);
//   const editRefs = useRef({});

//   const dispatch = useDispatch();

//   const { tags } = useSelector((state) => state.company);

//   const [activeEditTagId, setActiveEditTagId] = useState(null);
//   const [editLabel, setEditLabel] = useState("");
//   const [orignalEditTagLabel, setOrignalEditTagLabel] = useState("");
//   const [isAddInputOpen, setIsAddInputOpen] = useState(false);
//   const [addNewTagLabel, setAddNewTagLabel] = useState("");

//   const resetEditOptions = () => {
//     setActiveEditTagId(null);
//     setEditLabel("");
//     setOrignalEditTagLabel("");
//   };

//   const resetAddOptions = () => {
//     setAddNewTagLabel("");
//     setIsAddInputOpen(false);
//   };

//   const addButtonClick = () => {
//     setAddNewTagLabel("");
//     setIsAddInputOpen(true);
//   };

//   const editButtonClick = (id,label) => {
//     setActiveEditTagId(id);
//     setEditLabel(label);
//     setOrignalEditTagLabel(label);
//   };

//   const handleAddTag = () => {
//     if (addNewTagLabel.trim() !== "") {
//       dispatch(addTagToCompany(addNewTagLabel.trim()));
//     }
//     resetAddOptions();
//   };

//   const handleEditTag = () => {
//     if (editLabel.trim() === "") {
//       return;
//     } else if (
//       orignalEditTagLabel.trim() ===
//       editLabel.trim()
//     ) {
//       resetEditOptions();
//     } else {
//       dispatch(editTagInCompany(activeEditTagId, editLabel.trim()));
//       resetEditOptions();
//     }
//   };

//   const handleDeleteTag = (id) => {
//     dispatch(deleteTagOfCompany(id||activeEditTagId));
//     resetEditOptions();
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (
//         isAddInputOpen &&
//         addRef.current &&
//         !addRef.current.contains(event.target)
//       ) {
//         handleAddTag();
//         resetEditOptions();
//       } else if (
//         activeEditTagId &&
//         editRefs.current[activeEditTagId] &&
//         !editRefs.current[activeEditTagId].contains(event.target)
//       ) {
//         handleEditTag();
//         resetAddOptions();
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [isAddInputOpen, addNewTagLabel, activeEditTagId, editLabel]);

//   return (
//     <div className="text-sm text-title_10 cursor-default">
//       {!tags ? (
//         <div className="flex items-center justify-center h-[600px]">
//           <Loader />
//         </div>
//       ) : (
//         <div>
//          <div className=" text-[15px] space-y-3 pb-8 ">
//           <h2 className="text-title ">Tags</h2>
//           <p className="text-primary">
//           Select the object and manage the connected statuses
//           </p>
//         </div>
//           <div className="flex flex-col gap-y-2">
//             {tags.length > 0 ? (
//               tags.map((tag) => (
//                 <div
//                   key={tag._id}
//                   className={`flex items-center text-primary text-sm gap-x-3 w-fit`}
//                   ref={(el) => (editRefs.current[tag._id] = el)}
//                 >
//                   <ColorDropdown
//                     icon={React.createElement(fieldIconMapping.tags)}
//                     iconColor={"#696969"}
//                     openDropdownCondition={false}
//                     clickable={false}
//                   />
//                   {activeEditTagId === tag._id ? (
//                     <EditInputForm
//                       inputLabel={editLabel}
//                       setInputLabel={(value) => setEditLabel(value)}
//                       onEditInputEnter={handleEditTag}
//                       inputFocusCondition={true}
//                       onInputButtonClick={null}
//                       handleRemoveOptionClick={handleDeleteTag}
//                     />
//                   ) : (
//                     // <div
//                     //   className={`p-2 border rounded w-48 cursor-text`}
//                     //   onClick={() => editButtonClick(tag)}
//                     // >
//                     //   {tag.label}

//                     // </div>
//                      <CustomizationsLabel
//                      label={tag.label}
//                      onClick={editButtonClick}
//                      handleDeleteItem={handleDeleteTag}
//                      id={ tag._id}
//                      showDelete={true}
//                    />
//                   )}
//                 </div>
//               ))
//             ) : (
//               <div className="flex items-center gap-x-3">
//                 <div>
//                   <IconWrapper
//                     icon={React.createElement(fieldIconMapping.tags)}
//                     size="14"
//                   />
//                 </div>
//                 <div className="p-2 border rounded w-48 cursor-not-allowed bg-row-background">
//                   No Tags
//                 </div>
//               </div>
//             )}
//             {isAddInputOpen ? (
//               <div className="flex items-center gap-x-3 w-fit" ref={addRef}>
//                 <AddInputForm
//                   icon={React.createElement(fieldIconMapping.tags)}
//                   iconColor={"#696969"}
//                   iconClickable={false}
//                   onIconClick={null}
//                   openIconDropdownCondition={false}
//                   inputLabel={addNewTagLabel}
//                   setInputLabel={setAddNewTagLabel}
//                   onInputEnter={handleAddTag}
//                   handleCloseOptionClick={resetAddOptions}
//                 />
//               </div>
//             ) : (
//               <div
//                 className="text-primary cursor-pointer hover:bg-button-hover p-1 w-fit rounded"
//                 onClick={addButtonClick}
//               >
//                 + Add
//               </div>
//             )}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default TagsField;
