import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllIntegrations,
  getIntegration,
} from "../../../reduxStore/operations/integrationsAPI";
import SettingsTopbar from "../SettingsTopbar";
import { useNavigate,Link } from "react-router-dom";
import { IoCheckmarkCircle } from "react-icons/io5";
import { ChartNetwork } from "react-flaticons";
import { apiConnector } from "../../../api/apiconnector";
import { integrationsEndPoints } from "../../../api/api";
import { toast } from "react-toastify";
import { fieldIconMapping } from "../../../utils";
const { GET_SLACK_INTEGRATION } = integrationsEndPoints;

const Integrations = () => {
  const { companyId } = useSelector((state) => state.auth);
  const integrations = useSelector((state) => state.integrations);
  const { jira } = integrations;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tabs = [{ label: "Integrations", key: "Integrations" }];

  const [isSlackIntegrated, setIsSlackIntegrated] = useState(false);

  const jiraClickHandler = async () => {
    navigate("/settings/integrations/jira");
  };

  useEffect(() => {
    dispatch(getAllIntegrations(companyId));
    const getSlackInt = async () => {
      try {
        const response = await apiConnector(
          "GET",
          GET_SLACK_INTEGRATION("slack"),
          null,
          null,
          { companyId }
        );
        console.log(response);
        if (response.status === 200 && response.data !== null) {
          setIsSlackIntegrated(response.data.data.isSlackConnected || false);
        }
      } catch (error) {
        // toast.error(
        //   error.response.data.message ?? "Error in fetching integration"
        // );
      }
    };

    getSlackInt();
  }, []);

  // const CLIENT_ID = import.meta.env.VITE_SLACK_CLIENT_ID;
  // const REDIRECT_URI = import.meta.env.VITE_SLACK_REDIRECT_URI;
  // const SCOPE =
  //   "app_mentions:read,channels:history,channels:read,chat:write,chat:write.customize,chat:write.public,commands,files:write,im:write,links:write,triggers:read,triggers:write,users.profile:read,users:read,users:read.email,groups:write";
  // // const SCOPE =
  // //   "chat:write,app_mentions:read,chat:write.public,commands,im:write,users:read.email,users:read,users.profile:read,channels:history,groups:history,channels:read,groups:read";

  // const slackAuthUrl = `https://slack.com/oauth/v2/authorize?client_id=${CLIENT_ID}&scope=${SCOPE}&user_scope=&redirect_uri=${REDIRECT_URI}`;

  const handleSlackConnect = () => {
    navigate("/settings/integrations/slack");
  };
  const handleZapierClick = () => {
    navigate("/settings/integrations/zapier");
  };

  return (
    <div className="w-full flex justify-center  ">
      <div>
        <SettingsTopbar
          // tabs={tabs}
          title="Integrations"
          description="Connect your tools to streamline workflows and stay in sync"
          icon={React.createElement(fieldIconMapping.integration)}
          width="w-[51rem]"
        />
        <div className=" flex pt-2 gap-6">
          <div
            onClick={jiraClickHandler}
            className="p-4 flex gap-4 border rounded-lg items-center w-[16rem] cursor-pointer hover:bg-slate-50"
          >
            <div>
              <div className="flex gap-3 items-center">
                <img src="/jiraIcon.svg" alt="jira" width={30} height={30} />
                <h4 className="text-lg">Jira</h4>
              </div>
              <div className="text-xs text-gray-600 mt-2">
                Sync Jira Projects and Epics
              </div>
            </div>
            <div className="self-baseline">
              {jira && <IoCheckmarkCircle fill="#4bae4f" fontSize={20} />}
            </div>
          </div>

          <div
            onClick={handleSlackConnect}
            className="p-4 flex gap-4 border rounded-lg items-center w-[16rem] cursor-pointer hover:bg-slate-50"
          >
            <div>
              <div className="flex gap-3 items-center">
                <img src="/slack_icon.svg" alt="jira" width={30} height={30} />

                <h4 className="text-lg">Slack</h4>
              </div>
              <div className="text-xs text-gray-600 mt-2">
                Import Feedback and Ideas
              </div>
            </div>
            <div className="self-baseline">
              {isSlackIntegrated && (
                <IoCheckmarkCircle fill="#4bae4f" fontSize={20} />
              )}
            </div>
          </div>

          <Link
            to={"zapier"}
            // onClick={handleZapierClick}
            className="p-4 flex gap-4 border rounded-lg items-center w-[16rem] cursor-pointer hover:bg-slate-50"
          >
            <div>
              <div className="flex gap-3 items-center">
                <img src="/Zapier.svg" alt="jira" width={30} height={30} />

                <h4 className="text-lg">Zapier</h4>
              </div>
              <div className="text-xs text-gray-600 mt-2">
              Connect Lane with 1000+ apps
              </div>
            </div>
            {/* <div className="self-baseline">
              {isSlackIntegrated && (
                <IoCheckmarkCircle fill="#4bae4f" fontSize={20} />
              )}
            </div> */}
          </Link>
        </div>
      </div>
      {/* <a href="https://slack.com/oauth/v2/authorize?client_id=8342581361574.8365572967169&scope=chat:write,commands&redirect_uri=https://redirectmeto.com/http://localhost:5173/settings/integrations/slack"><img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" /></a> */}

      {/* <a
        href={`https://slack.com/oauth/v2/authorize?client_id=${CLIENT_ID}&scope=chat:write,app_mentions:read,chat:write.public,commands,im:write,users:read.email,users:read,users.profile:read,channels:history,groups:history,channels:read,groups:read&user_scope=&redirect_uri=${REDIRECT_URI}`}
      >
        <img
          alt="Add to Slack"
          height="40"
          width="139"
          src="https://platform.slack-edge.com/img/add_to_slack.png"
          srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
        />
      </a> */}
    </div>
  );
};

export default Integrations;
