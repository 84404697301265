/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useState } from "react";
import { CrossSmall } from "react-flaticons";
import AddFeaturePopupOverlay from "./addNewPopupOverlays/AddFeaturePopupOverlay";
import AddIdeaPopupOverlay from "./addNewPopupOverlays/AddIdeaPopupOverlay";
import AddObjectivePopupOverlay from "./addNewPopupOverlays/AddObjectivePopupOverlay";
import AddRoadmapPopupOverlay from "./addNewPopupOverlays/addRoadmap/AddRoadmapPopupOverlay";
import IconText from "./IconText";
import { fieldIconMapping } from "../../utils";
import IconWrapper from "./IconWrapper";

const AddNewPopupOverlay = ({ option, onClose }) => {
  const [name, setName] = useState(
    option.label === "Roadmap" ? "Strategic Roadmap" : ""
  );
  const [isNameEditable, setIsNameEditable] = useState(
    option.label === "Roadmap" ? false : true
  );
  const [isWarn, setIsWarn] = useState(false);
  const [type, setType] = useState(
    option.label === "Idea" ? "Idea" : "Initiative_based"
  );
  const popupRef = useRef(null);

  // Function to handle click outside of the popup
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    // Set up the event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="fixed inset-0 bg-add-popup-background bg-opacity-50 flex justify-center items-center pb-64 text-xs">
      <div
        ref={popupRef}
        className="border border-gray-300 bg-white p-5 rounded-md relative min-w-[600px]  w-auto max-w-[680px]"
      >
        <div className="flex flex-col">
          <div className="flex items-center justify-between mb-2">
            {option.label === "Idea" && (
              <div className=" flex gap-4 ">
                <div
                  className={`flex justify-center items-center gap-2 pb-1  border-b  hover:cursor-pointer   ${
                    type === "Idea" ? "border-active" : " border-white "
                  }     `}
                >
                  <IconWrapper
                    icon={React.createElement(fieldIconMapping.ideas)}
                    iconColor={type === "Idea" ? "#EDB900" : " #696969 "}
                    size={14}
                  />
                  <h2
                    onClick={() => setType("Idea")}
                    className={`mt-[2px]  text-sm ${
                      type === "Idea" ? "#EDB900" : " text-primary "
                    } `}
                  >
                    Idea
                  </h2>
                </div>
                <div
                  className={`flex justify-center items-center gap-2 pb-1  border-b  hover:cursor-pointer ${
                    type === "Feedback" ? "border-active" : " border-white "
                  }     `}
                >
                  <IconWrapper
                    icon={React.createElement(fieldIconMapping.feedback)}
                    iconColor={type === "Feedback" ? "#7C33F4" : "#696969"}
                    size={14}
                  />
                  <h2
                    onClick={() => setType("Feedback")}
                    className={`mt-[2px]  text-sm sm ${
                      type === "Feedback" ? "#EDB900" : " text-primary "
                    } `}
                  >
                    Feedback
                  </h2>
                </div>
              </div>
            )}
            {option.label === "Objective" && (
              <div className=" flex gap-4 ">
                <div className=" mt-[6px] ">
                  <IconWrapper
                    icon={React.createElement(fieldIconMapping.objective)}
                    iconColor="#F43379"
                    size={14}
                  />
                </div>
                <div
                  className={`flex justify-center items-center gap-2 pb-1  border-b  hover:cursor-pointer   ${
                    type === "Initiative_based"
                      ? "border-active"
                      : " border-white "
                  }     `}
                >
                  <h2
                    onClick={() => setType("Initiative_based")}
                    className={`mt-[2px] text-sm   ${
                      type === "Initiative_based" ? "" : "  text-primary "
                    }  `}
                  >
                    Goal
                  </h2>
                </div>
                <div
                  className={`flex justify-center items-center gap-2 pb-1  border-b  hover:cursor-pointer ${
                    type === "Key_result_based"
                      ? "border-active"
                      : " border-white "
                  }     `}
                >
                  <h2
                    onClick={() => setType("Key_result_based")}
                    className={`text-sm  mt-[2px]  ${
                      type === "Key_result_based" ? "" : "  text-primary "
                    } `}
                  >
                    OKR
                  </h2>
                </div>
              </div>
            )}
            {option.label !== "Idea" && option.label !== "Objective" && (
              <IconText
                icon={React.createElement(option.icon)}
                iconColor={option?.iconColor ?? ""}
                text={option?.label ?? ""}
                textColor="text-title_50"
                hoverable={false}
                gap_x="gap-x-2"
                px=""
                py=""
              />
            )}

            <button
              onClick={onClose}
              className="self-end rounded p-0.5 hover:bg-button-hover"
            >
              <CrossSmall color="#374957" />
            </button>
          </div>
          {isNameEditable ? (
            <input
              type="text"
              value={name}
              className={`w-full text-[18px] py-2 px-2 mb-3.5  rounded-md focus:outline-none ${
                isWarn ? " shadow-[0_0_5px_rgba(255,0,0,0.5)] " : "  "
              }  `}
              onChange={(e) => setName(e.target.value)}
              placeholder={
                isWarn
                  ? `Enter ${option.label.toLowerCase()} name`
                  : option.label === "Idea"
                  ? type === "Idea"
                    ? "Idea title"
                    : "Feedback title"
                  : option.label === "Objective"
                  ? type === "Key_result_based"
                    ? "Objective title"
                    : "Goal title"
                  : `${option.label} title`
              }
              
              required
              autoFocus
            />
          ) : (
            // <div className="text-lg py-2 mb-3.5">{name}</div>
            <></>
          )}
          {option.label === "Feature" && (
            <AddFeaturePopupOverlay
              option={option}
              name={name}
              setIsWarn={setIsWarn}
            />
          )}
          {option.label === "Idea" && (
            <AddIdeaPopupOverlay
              option={option}
              name={name}
              setIsWarn={setIsWarn}
              type={type}
              setType={setType}
            />
          )}
          {option.label === "Objective" && (
            <AddObjectivePopupOverlay
              option={option}
              name={name}
              type={type}
              setIsWarn={setIsWarn}
            />
          )}
          {option.label === "Roadmap" && (
            <AddRoadmapPopupOverlay
              option={option}
              name={name}
              setName={setName}
              setIsNameEditable={setIsNameEditable}
              setIsWarn={setIsWarn}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddNewPopupOverlay;
