/* eslint-disable react/prop-types */
import React from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { Info } from "react-flaticons";

const Progress = ({ value, color, showHover = false, type = null }) => {
  return (
    <div className=" relative w-[30px] mx-4">
      <CircularProgressbar
        value={value ?? 0}
        text={`${value.toFixed(0)}` ?? "0"}
        styles={buildStyles({
          textSize: "42px",
          pathColor: color,
          textColor: "black",
        })}
      />
      {showHover && (
        <div className=" group">
          <Info
            size={14}
            className="cursor-pointer absolute right-[-26px] top-2 text-black   "
          />
          {
            <div className="absolute  hidden  group-hover:block  text-black   w-[19rem] py-3   top-4 right-[-9rem]  z-50  ">
              {/* <div className="absolute hidden group-hover:block  text-black rounded-md shadow-md p-3 top-3"> */}
              <div className=" bg-white p-3 px-4  rounded-md shadow-md text-gray-600   text-sm ">
                {type === "Goal" && (
                  <p>
                    {" "}
                    Progress is calculated automatically as the average
                    completion of all opportunities
                  </p>
                )}
                {type === "OKR" && (
                  <p className=" pt-3 ">
                    {" "}
                    Progress is calculated automatically as the average progress
                    of linked Key results.
                  </p>
                )}
                {type === "KR" && (
                  <p className=" pt-3 ">
                    {" "}
                    Progress is calculated automatically as the average
                    completion of all opportunities
                  </p>
                )}
              </div>
            </div>
          }
        </div>
      )}
    </div>
  );
};

export default Progress;
