import React from "react";
import SettingsTopbar from "./SettingsTopbar";
import { RiBillFill } from "react-icons/ri";
import { CreditCard } from "react-flaticons";

const Billing = () => {
  const tabs = [{ label: "Billing", key: "Billing" }];
  return (
    <div className="w-full flex justify-center  ">
      <div>
        <SettingsTopbar
          // tabs={tabs}
          title="Plan"
          description="Manage your subscription, payment details, and invoices"
          icon={<CreditCard />}
        />
        <div className="flex justify-center items-start h-[70vh] ">
          <p className=" text-lg text-gray-500 pt-12 ">
            Special pricing coming your way very soon
          </p>
        </div>
      </div>
    </div>
  );
};

export default Billing;
