import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fieldIconMapping, colors } from "utils";
import Loader from "components/common/Loader";
import IconWrapper from "components/common/IconWrapper";
import ColorDropdown from "./ColorDropdown";
import { Modal, Input, Button, Form, Divider } from "antd";
import {
  addProductToCompany,
  deleteProductOfCompany,
  editProductInCompany,
} from "reduxStore/operations/productAPI";
import CustomizationsLabel from "../../common/CustomizationsLabel";
import IconText from "../../common/IconText";
import { Delete, Edit, Pencil, PlusSmall, Trash } from "react-flaticons";
import { TbEdit } from "react-icons/tb";

const ProductField = () => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.company);

  // Modal visibility states
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);

  // Color dropdown open state
  const [isColorDropdownOpen, setIsColorDropdownOpen] = useState(false);

  // Shared product states
  const [hex, setHex] = useState(colors[0]);

  // Add modal state
  const [addNewProductLabel, setAddNewProductLabel] = useState("");
  const [addConfirmLoading, setAddConfirmLoading] = useState(false);

  // Edit modal state
  const [activeEditId, setActiveEditId] = useState(null);
  const [editLabel, setEditLabel] = useState("");
  const [originalEditLabel, setOriginalEditLabel] = useState("");
  const [originalEditProductColor, setOriginalEditProductColor] = useState("");
  const [editConfirmLoading, setEditConfirmLoading] = useState(false);

  const resetEditOptions = () => {
    setActiveEditId(null);
    setEditLabel("");
    setOriginalEditLabel("");
    setOriginalEditProductColor("");
    setHex(colors[0]);
    setIsColorDropdownOpen(false);
  };

  const resetAddOptions = () => {
    setAddNewProductLabel("");
    setHex(colors[0]);
    setIsColorDropdownOpen(false);
  };

  const addButtonClick = () => {
    resetAddOptions();
    setIsAddModalVisible(true);
  };

  const editButtonClick = (id, productValue, productColor) => {
    setActiveEditId(id);
    setEditLabel(productValue);
    setOriginalEditLabel(productValue);
    setOriginalEditProductColor(productColor);
    setHex(productColor);
    setIsEditModalVisible(true);
    setIsColorDropdownOpen(false);
  };

  const handleAddProduct = () => {
    if (addNewProductLabel.trim() !== "") {
      setAddConfirmLoading(true);
      dispatch(addProductToCompany(addNewProductLabel.trim(), hex))
        .then(() => {
          setAddConfirmLoading(false);
          resetAddOptions();
          setIsAddModalVisible(false);
        })
        .catch(() => {
          setAddConfirmLoading(false);
        });
    }
  };

  const handleEditProduct = () => {
    if (editLabel.trim() === "") return;
    if (
      originalEditLabel === editLabel.trim() &&
      hex === originalEditProductColor
    ) {
      resetEditOptions();
      setIsEditModalVisible(false);
    } else {
      setEditConfirmLoading(true);
      dispatch(editProductInCompany(activeEditId, editLabel.trim(), hex))
        .then(() => {
          setEditConfirmLoading(false);
          resetEditOptions();
          setIsEditModalVisible(false);
        })
        .catch(() => {
          setEditConfirmLoading(false);
        });
    }
  };

  const handleDeleteProduct = (id) => {
    dispatch(deleteProductOfCompany(id));
    resetEditOptions();
    setIsEditModalVisible(false);
  };

  if (!products) {
    return (
      <div className="flex items-center justify-center h-[600px]">
        <Loader />
      </div>
    );
  }

  return (
    <div className="text-sm text-title_10 cursor-default">
      <div className="text-[16px] space-y-3">
        <div className=" flex items-center justify-between ">
          <h2 className="text-title">Product</h2>
          <Button
            onClick={addButtonClick}
            type="primary"
            // icon={<PlusSmall size={20} />}
            // className=" pl-2 "
          >
            Add Product
          </Button>
        </div>

        <p className="text-primary  text-wrap ">
          Define and manage different products within your workspace to keep
          everything <br /> organized
        </p>
      </div>
      <Divider className=" p-0 mt-6 mb-10 " />
      <div className="flex flex-col gap-y-2">
        {products.length > 0 ? (
          products.map((product) => (
            <div
              key={product._id}
              className="flex items-center text-primary  rounded text-sm w-fit group"
            >
              <IconText
                icon={React.createElement(fieldIconMapping["product"])}
                iconColor={activeEditId === product._id ? hex : product.color}
                text={product.label}
                textColor="text-title"
              />
              <div
                onClick={() =>
                  editButtonClick(product._id, product.label, product.color)
                }
                className="rounded-full p-2 ml-1 hover:bg-topbar-button-hover opacity-0 group-hover:opacity-100 transition-opacity duration-200"
              >
                <TbEdit size={17} />
              </div>
              <div
                onClick={() => handleDeleteProduct(product._id)}
                className="rounded-full p-2 opacity-0 hover:bg-topbar-button-hover group-hover:opacity-100 transition-opacity duration-200"
              >
                <Trash size={14} />
              </div>
            </div>
          ))
        ) : (
          <div className="flex items-center gap-x-3">
            <div>
              <IconWrapper
                icon={React.createElement(fieldIconMapping.product)}
                size="14"
              />
            </div>
            <div className="p-2 border rounded w-48 cursor-not-allowed bg-row-background">
              No Product
            </div>
          </div>
        )}
      </div>

      {/* Add Modal */}
      <Modal
        title={
          <h2 className="flex gap-2 items-center pt-1 pb-2">
            <IconWrapper
              icon={React.createElement(fieldIconMapping.product)}
              size="14"
            />
            <span>Product</span>
          </h2>
        }
        open={isAddModalVisible}
        onOk={handleAddProduct}
        onCancel={() => {
          resetAddOptions();
          setIsAddModalVisible(false);
        }}
        okText="Add Product"
        confirmLoading={addConfirmLoading}
      >
        <Form.Item label="Product Name" colon={false} className="mb-0" />
        <div className="  flex justify-center items-center gap-1.5  ">
          <div className=" mb-[18px] ">
            <ColorDropdown
              icon={React.createElement(fieldIconMapping["product"])}
              iconColor={hex}
              clickable={true}
              hex={hex}
              setHex={setHex}
              openDropdownCondition={isColorDropdownOpen}
              onClick={() => setIsColorDropdownOpen(!isColorDropdownOpen)}
            />
          </div>
          <Input
            placeholder="Web App"
            value={addNewProductLabel}
            onChange={(e) => setAddNewProductLabel(e.target.value)}
            style={{ marginBottom: 16 }}
          />
        </div>
        {/* <div className="flex items-center">
          <span className="mr-2">Select Color</span>
          <ColorDropdown
            icon={React.createElement(fieldIconMapping["product"])}
            iconColor={hex}
            clickable={true}
            hex={hex}
            setHex={setHex}
            openDropdownCondition={isColorDropdownOpen}
            onClick={() => setIsColorDropdownOpen(!isColorDropdownOpen)}
          />
        </div> */}
      </Modal>

      {/* Edit Modal */}
      <Modal
        title="Product"
        open={isEditModalVisible}
        onCancel={() => {
          resetEditOptions();
          setIsEditModalVisible(false);
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              resetEditOptions();
              setIsEditModalVisible(false);
            }}
          >
            Cancel
          </Button>,
          <Button
            key="save"
            type="primary"
            onClick={handleEditProduct}
            loading={editConfirmLoading}
          >
            Update
          </Button>,
        ]}
      >
        <Form.Item label="Product Name" colon={false} className="mb-0" />
        <div className="  flex justify-center items-center gap-1.5  ">
          <div className=" mb-[18px] ">
            <ColorDropdown
              icon={React.createElement(fieldIconMapping["product"])}
              iconColor={hex}
              clickable={true}
              hex={hex}
              setHex={setHex}
              openDropdownCondition={isColorDropdownOpen}
              onClick={() => setIsColorDropdownOpen(!isColorDropdownOpen)}
            />
          </div>
          <Input
            placeholder="Web App"
            value={editLabel}
            onChange={(e) => setEditLabel(e.target.value)}
            style={{ marginBottom: 16 }}
          />
        </div>

        {/* <div className="flex items-center">
          <span className="mr-2">Select Color</span>
        
        </div> */}
      </Modal>
    </div>
  );
};

export default ProductField;

// import React, { useEffect, useRef, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { fieldIconMapping, colors } from "utils";
// import Loader from "components/common/Loader";
// import IconWrapper from "components/common/IconWrapper";
// import ColorDropdown from "./ColorDropdown";
// import EditInputForm from "./EditInputForm";
// import AddInputForm from "./AddInputForm";
// import {
//   addProductToCompany,
//   deleteProductOfCompany,
//   editProductInCompany,
// } from "reduxStore/operations/productAPI";
// import CustomizationsLabel from "../../common/CustomizationsLabel";

// const ProductField = () => {
//   const addRef = useRef(null);
//   const editRefs = useRef([]);

//   const dispatch = useDispatch();

//   const { products } = useSelector((state) => state.company);

//   const [hex, setHex] = useState(colors[0]);
//   const [isColorDropdownOpen, setIsColorDropdownOpen] = useState(false);
//   const [activeEditId, setActiveEditId] = useState(null);
//   const [editLabel, setEditLabel] = useState("");
//   const [orignalEditLabel, setOrignalEditLabel] = useState("");
//   const [orignalEditProductColor, setOrignalEditProductColor] = useState("");
//   const [isAddInputOpen, setIsAddInputOpen] = useState(false);
//   const [addNewProductLabel, setAddNewProductLabel] = useState("");

//   const resetEditOptions = () => {
//     setIsColorDropdownOpen(false);
//     setActiveEditId(null);
//     setEditLabel("");
//     setOrignalEditLabel("");
//     setOrignalEditProductColor("");
//   };
//   const resetAddOptions = () => {
//     setAddNewProductLabel("");
//     setIsAddInputOpen(false);
//   };

//   const addButtonClick = () => {
//     setAddNewProductLabel("");
//     setIsAddInputOpen(true);
//     setHex(colors[0]);
//   };

//   const editButtonClick = (
//     id,
//     productValue,
//     productColor,
//     shouldOpenColorDropdown
//   ) => {
//     if (activeEditId === null) {
//       setHex(productColor);
//     } else {
//       setHex(hex);
//     }
//     setIsColorDropdownOpen(shouldOpenColorDropdown);
//     setActiveEditId(id);
//     if (editLabel !== "") {
//       setEditLabel(editLabel);
//     } else {
//       setEditLabel(productValue);
//     }
//     setOrignalEditLabel(productValue);
//     setOrignalEditProductColor(productColor);
//   };

//   const handleAddProduct = () => {
//     if (addNewProductLabel.trim() !== "") {
//       dispatch(addProductToCompany(addNewProductLabel.trim(), hex));
//     }
//     resetAddOptions();
//   };

//   const handleEditProduct = () => {
//     if (editLabel.trim() === "") {
//       return;
//     } else if (
//       orignalEditLabel === editLabel.trim() &&
//       hex === orignalEditProductColor
//     ) {
//       resetEditOptions();
//     } else {
//       dispatch(editProductInCompany(activeEditId, editLabel.trim(), hex));
//       resetEditOptions();
//     }
//   };

//   const handleDeleteProduct = (id) => {
//     dispatch(deleteProductOfCompany(id || activeEditId));
//     resetEditOptions();
//   };

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (
//         isAddInputOpen &&
//         addRef.current &&
//         !addRef.current.contains(event.target)
//       ) {
//         handleAddProduct();
//         resetEditOptions();
//       } else if (
//         activeEditId !== null &&
//         editRefs.current[activeEditId] &&
//         !editRefs.current[activeEditId].contains(event.target)
//       ) {
//         handleEditProduct();
//         resetAddOptions();
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);

//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [isAddInputOpen, addNewProductLabel, activeEditId, editLabel, hex]);

//   return (
//     <div className="text-sm text-title_10 cursor-default">
//       {!products ? (
//         <div className="flex items-center justify-center h-[600px]">
//           <Loader />
//         </div>
//       ) : (
//         <div>
//           <div className=" text-[15px] space-y-3 pb-8 ">
//           <h2 className="text-title ">Product</h2>
//           <p className="text-primary">
//           Select the object and manage the connected statuses
//           </p>
//         </div>
//           <div className="flex flex-col gap-y-2">
//             {products.length > 0 ? (
//               products.map((product) => (
//                 <div
//                   key={product._id}
//                   className={`flex items-center text-primary text-sm gap-x-2 w-fit`}
//                   ref={(el) => (editRefs.current[product._id] = el)}
//                 >
//                   <ColorDropdown
//                     icon={React.createElement(fieldIconMapping["product"])}
//                     iconColor={
//                       activeEditId === product._id ? hex : product.color
//                     }
//                     clickable={true}
//                     hex={hex}
//                     setHex={setHex}
//                     onClick={() => {
//                       !isColorDropdownOpen
//                         ? editButtonClick(
//                             product._id,
//                             product.label,
//                             product.color,
//                             true
//                           )
//                         : setIsColorDropdownOpen(false);
//                     }}
//                     openDropdownCondition={
//                       isColorDropdownOpen && activeEditId === product._id
//                     }
//                   />
//                   {activeEditId === product._id ? (
//                     <EditInputForm
//                       inputLabel={editLabel}
//                       setInputLabel={(value) => setEditLabel(value)}
//                       onEditInputEnter={() => handleEditProduct()}
//                       onInputButtonClick={setIsColorDropdownOpen}
//                       inputFocusCondition={!isColorDropdownOpen}
//                       handleRemoveOptionClick={() => handleDeleteProduct()}
//                     />
//                   ) : (
//                     // <div
//                     //   className={`p-2 border rounded w-48 cursor-text`}
//                     //   onClick={() => {
//                     //     editButtonClick(
//                     //       product._id,
//                     //       product.label,
//                     //       product.color,
//                     //       false
//                     //     );
//                     //   }}
//                     // >
//                     //   {product.label}
//                     // </div>
//                     <CustomizationsLabel
//                       label={product.label}
//                       onClick={editButtonClick}
//                       handleDeleteItem={handleDeleteProduct}
//                       id={product._id}
//                       showDelete={true}
//                       color={product.color}
//                       editBoolean={false}
//                     />
//                   )}
//                 </div>
//               ))
//             ) : (
//               <div className="flex items-center gap-x-3">
//                 <div>
//                   <IconWrapper
//                     icon={React.createElement(fieldIconMapping.product)}
//                     size="14"
//                   />
//                 </div>
//                 <div className="p-2 border rounded w-48 cursor-not-allowed bg-row-background">
//                   No Product
//                 </div>
//               </div>
//             )}
//             {isAddInputOpen ? (
//               <div className="flex items-center gap-x-2 w-fit" ref={addRef}>
//                 <AddInputForm
//                   icon={React.createElement(fieldIconMapping["product"])}
//                   iconColor={hex}
//                   hex={hex}
//                   setHex={setHex}
//                   onIconClick={() =>
//                     setIsColorDropdownOpen(!isColorDropdownOpen)
//                   }
//                   openIconDropdownCondition={isColorDropdownOpen}
//                   inputLabel={addNewProductLabel}
//                   setInputLabel={(value) => setAddNewProductLabel(value)}
//                   onInputEnter={() => handleAddProduct()}
//                   handleCloseOptionClick={resetAddOptions}
//                 />
//               </div>
//             ) : (
//               <div
//                 className="text-primary cursor-pointer hover:bg-button-hover p-1 w-fit rounded"
//                 onClick={() => {
//                   addButtonClick();
//                 }}
//               >
//                 + Add
//               </div>
//             )}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ProductField;
