import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCookie } from "utils/cookieUtils";
import { setLoading } from "reduxStore/slices/authSlice";
import Loader from "../common/Loader";
import { companyRegister } from "../../reduxStore/operations/authAPI";
import { useSearchParams } from "react-router-dom";
import { FaEyeSlash, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { IoEyeSharp, IoLockOpenOutline, IoMailOutline } from "react-icons/io5";
import { Building, User } from "react-flaticons";
import { toast } from "react-toastify";

const CompanyRegister = () => {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const { loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [passwordLength, setPasswordLength] = useState(false);
  const [isUpperCase, setIsUpperCase] = useState(false);
  const [isLowerCase, setIsLowerCase] = useState(false);
  const [isNumber, setIsNumber] = useState(false);
  const [isSymbol, setIsSymbol] = useState(false);
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    if (password.length >= 8) {
      setPasswordLength(true);
    } else {
      setPasswordLength(false);
    }
    const hasUpperCase = password
      .split("")
      .some((char) => char === char.toUpperCase() && isNaN(char));

    setIsUpperCase(hasUpperCase);

    const hasLowerCase = password
      .split("")
      .some((char) => char === char.toLowerCase() && isNaN(char));

    setIsLowerCase(hasLowerCase);

    const hasNumber = password
      .split("")
      .some((char) => !isNaN(char) && char !== " ");
    setIsNumber(hasNumber);
    const specialChars = /[@$!%*?&^_#]/;
    const hasSpecialChar = specialChars.test(password);
    setIsSymbol(hasSpecialChar);
  }, [password]);

  const Usertoken = searchParams.get("token");

  const isStrongPassword = (password) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*?&^_#])[A-Za-z\d@$!%*?&^_#]{8,}$/;
    return regex.test(password);
  };

  const validateForm = () => {
    let errors = {};

    if (!name) {
      errors.name = "Name is required.";
    }
    if (!companyName) {
      errors.companyName = "Company name is required.";
    }

    if (!password) {
      errors.password = "Password is required.";
    } else if (
      !isStrongPassword(password) ||
      !passwordLength ||
      !isUpperCase ||
      !isLowerCase ||
      !isNumber ||
      !isSymbol
    ) {
      errors.password =
        "Password require to contain at least 8 characters including one Capital letter, one small letter and one special character [@$!%*?&^_#].";
    }

    setErrorMessages(errors);

    return Object.keys(errors).length === 0;
  };

  const handleCompanyRegister = (e) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(setLoading(true));
      dispatch(
        companyRegister(name, companyName, Usertoken, password, navigate)
      );
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    if (token) {
      navigate("/"); // Redirect to home page if user is already logged in
    }
  }, [navigate]);

  return (
    // <div className="flex flex-col gap-4 items-center justify-center min-h-screen bg-gray-100">
    //   <div className=" relative bg-white p-8 rounded shadow-md w-full max-w-sm">
    //     <div className="  absolute top-[-120px] right-0 w-full  flex items-center justify-center  ">
    //       <img
    //         src="/logo.png  "
    //         alt="logo"
    //         className=" w-0 h-0  xl:w-[200px] xl:h-[100px] "
    //       />{" "}
    //     </div>
    //     <h2 className="text-2xl mb-6 text-center">Welcome onboard!</h2>
    //     <form onSubmit={handleCompanyRegister}>
    //       <div className="mb-4">
    //         <label className="block text-gray-700">Name</label>
    //         <input
    //           type="text"
    //           className={`w-full p-2 border ${
    //             errorMessages.name ? "border-red-500" : "border-gray-300"
    //           } rounded mt-1`}
    //           value={name}
    //           placeholder="Name"
    //           onChange={(e) => setName(e.target.value)}
    //         />
    //         {errorMessages.name && (
    //           <p className="text-red-500 text-sm mt-1">{errorMessages.name}</p>
    //         )}
    //       </div>

    //       <div className="mb-4">
    //         <label className="block text-gray-700">Company Name</label>
    //         <input
    //           type="text"
    //           className={`w-full p-2 border ${
    //             errorMessages.companyName ? "border-red-500" : "border-gray-300"
    //           } rounded mt-1`}
    //           value={companyName}
    //           placeholder="Company Name"
    //           onChange={(e) => setCompanyName(e.target.value)}
    //         />
    //         {errorMessages.companyName && (
    //           <p className="text-red-500 text-sm mt-1">
    //             {errorMessages.companyName}
    //           </p>
    //         )}
    //       </div>

    //       <div className="mb-4">
    //         <label className="block text-gray-700">Password</label>
    //         <div className="relative">
    //           <input
    //             type={showPassword ? "text" : "password"}
    //             className="w-full p-2 border border-gray-300 rounded mt-1 pr-10" // Added padding-right for the icon
    //             value={password}
    //             onChange={(e) => setPassword(e.target.value)}
    //             placeholder="Sshhhh…"
    //             required
    //           />
    //           <button
    //             type="button"
    //             className="absolute right-2 mt-1 inset-y-0 flex items-center text-gray-600"
    //             onClick={() => setShowPassword(!showPassword)}
    //           >
    //             {showPassword ? (
    //               <IoEyeSharp className="h-5 w-5" />
    //             ) : (
    //               <FaEyeSlash className="h-5 w-5" />
    //             )}
    //           </button>
    //         </div>
    //       </div>

    //       <button
    //         type="submit"
    //         className="w-full bg-blue-500 text-white p-2 rounded mt-4 hover:bg-blue-600"
    //       >
    //         {loading ? <Loader size="20" color="white" /> : "Take me in"}
    //       </button>
    //     </form>
    //   </div>
    // </div>
    <div className="h-screen flex flex-col  md:flex-row font-inter overflow-hidden ">
      <div className="w-full md:w-[60%] xl:w-[45%] max-w-[55rem] min-w-[24rem]  md:min-w-[32rem] flex flex-col items-center justify-center p-2 md:p-4">
        <div className="flex flex-col gap-8   justify-center  p-4 md:p-8  max-w-[30rem]">
          {/* <div className=" relative bg-white p-8 rounded shadow-md w-full max-w-sm"> */}
          <img
            src="/lane_icon_signin.svg"
            alt="logo"
            className="w-[125px] h-[75px] mr-auto"
          />

          {/* <h2 className="text-2xl mb-6 text-center">Welcome onboard!</h2> */}
          <div>
            <h2 className="text-2xl text-left w-full mb-4 ">
              Welcome onboard!
            </h2>
            <p className=" text-[#696969] ">
              You're just a step away! Complete your details to get started.
            </p>
          </div>
          <form onSubmit={handleCompanyRegister}>
            <div className="mb-4">
              <label className="block text-[16px] text-[#696969]">Name</label>
              {/* <input
                type="text"
                className={`w-full p-2 border ${
                  errorMessages.name ? "border-red-500" : "border-gray-300"
                } rounded mt-1`}
                value={name}
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              /> */}
              <div className="relative w-full">
                <User
                  className="absolute left-3 top-[15px] text-gray-400"
                  size={18}
                />
                <input
                  type="text"
                  className={`w-full pl-10  p-2 border ${
                    errorMessages.name ? "border-red-500" : "border-gray-300"
                  } rounded mt-1`}
                  value={name}
                  // placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              {errorMessages.name && (
                <p className="text-red-500 text-sm mt-1">
                  {errorMessages.name}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label className="block text-[16px] text-primary">
                Company Name
              </label>

              {/* <input
                type="text"
                className={`w-full p-2 border ${
                  errorMessages.companyName
                    ? "border-red-500"
                    : "border-gray-300"
                } rounded mt-1`}
                value={companyName}
                placeholder="Company Name"
                onChange={(e) => setCompanyName(e.target.value)}
              /> */}
              <div className="relative w-full">
                <Building
                  className="absolute left-3 top-[15px] text-gray-400"
                  size={18}
                />
                <input
                  type="text"
                  className={`w-full p-2 pl-10  border ${
                    errorMessages.companyName
                      ? "border-red-500"
                      : "border-gray-300"
                  } rounded mt-1`}
                  value={companyName}
                  // placeholder="Company Name"
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </div>
              {errorMessages.companyName && (
                <p className="text-red-500 text-sm mt-1">
                  {errorMessages.companyName}
                </p>
              )}
            </div>

            <div className="mb-4">
              <label className="block text-[16px] text-[#696969]">
                Password
              </label>
              {/* <label className="block text-gray-700">Password</label> */}
              {/* <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  className="w-full p-2 border border-gray-300 rounded mt-1 pr-10" // Added padding-right for the icon
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Sshhhh…"
                  required
                />
                <button
                  type="button"
                  className="absolute right-2 mt-1 inset-y-0 flex items-center text-gray-600"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <IoEyeSharp className="h-5 w-5" />
                  ) : (
                    <FaEyeSlash className="h-5 w-5" />
                  )}
                </button>  
              </div> */}
              <div className="relative">
                <IoLockOpenOutline
                  className="absolute left-3 top-[14px] text-gray-400"
                  size={20}
                />
                <input
                  type={showPassword ? "text" : "password"}
                  className="w-full p-2 pl-10 border border-gray-300 rounded-md mt-1"
                  value={password}
                  onChange={(e) => {
                    setIsHidden(false);
                    setPassword(e.target.value);
                  }}
                  // placeholder="Sshhhh…"
                  required
                />

                <button
                  type="button"
                  className="absolute right-2 mt-1 inset-y-0 flex items-center text-gray-600"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    // <IoEyeSharp className="h-5 w-5" />
                    <FaRegEye className=" h-[19px] w-[19px] text-[#696969] " />
                  ) : (
                    // <FaEyeSlash className="h-5 w-5" />
                    <FaRegEyeSlash className=" h-5 w-5 text-[#696969] " />
                  )}
                </button>
              </div>
            </div>
            <h2
              className={`   font-semibold text-sm text-red-600  transition-all duration-100   ${
                isHidden ? "  opacity-0  " : "  opacity-100  "
              }  `}
            >
              <span className={`${passwordLength ? "text-green-500" : ""}`}>
                {" "}
                Use at least 8 characters,
              </span>{" "}
              <span className={`${isUpperCase ? "text-green-500" : ""}`}>
                an uppercase
              </span>{" "}
              <span className={`${isLowerCase ? "text-green-500" : ""}`}>
                , a lowercase,
              </span>{" "}
              <span className={`${isNumber ? "text-green-500" : ""}`}>
                a number
              </span>{" "}
              <span className={`${isSymbol ? "text-green-500" : ""}`}>
                and a special symbol (@, $, !, %, *, ?, &, ^, _, #).
              </span>
            </h2>

            <button
              type="submit"
              className="w-full font-bold  bg-signup-button text-white p-2 rounded mt-4 hover:bg-blue-600"
            >
              {loading ? <Loader size="20" color="white" /> : "Take me in"}
            </button>
          </form>
          {/* </div> */}
        </div>
      </div>
      <div className="hidden md:block bg-[#F9FBFC] md:w-[40%] xl:w-[55%] relative pl-[6rem]">
        {/* Quote */}
        <h2 className="pt-[64px] pr-0 md:pr-[7rem]  md:text-[17px] lg:text-[24px]  xl:text-[28px]  text-gray-800">
          “A study across 181 companies found that the average core feature
          adoption rate is 24.5%”
        </h2>

        {/* Background Image */}
        <div className="absolute top-[14rem] left-[6rem] bottom-0 border  rounded-xl overflow-hidden">
          <img
            src="/signup3-cropped.svg"
            alt="Background"
            className="w-full xl:min-w-[60rem]  h-full object-cover"
            style={{
              objectPosition: "top left", // Align the image to the top-left corner
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyRegister;
