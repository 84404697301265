/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import IconText from "components/common/IconText";
import IconWrapper from "components/common/IconWrapper";
import { fieldIconMapping } from "utils";
import AccountsSearchDropdown from "../dropdown/AccountsSearchDropdown";

const AccountsSection = ({
  item,
  position = "gap-x-12",
  with_title = true,
  with_icon_before_accounts = false,
  py = "py-3.5",
  accounts = [],
  handleAccountOptionClick,
  dropdownPosition = "top-full right-0",
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState(
    new Set((item?.accounts ?? []).map((account) => account._id))
  );
  const [selectAccounts, setSelectAccounts] = useState(item?.accounts);

  useEffect(() => {
    if (item && item.accounts) {
      const initialAccounts = new Set(
        item.accounts.map((account) => account._id)
      );
      setSelectedAccounts(initialAccounts);
      setSelectAccounts(item.accounts);
    }
  }, [item]);

  const initialSelectedAccounts = new Set(
    item.accounts.map((account) => account._id)
  );
  const dropdownRef = useRef(null);

  const getSelectedAcountObjects = () => {
    return Array.from(selectedAccounts).map((id) =>
      selectAccounts.find((account) => account._id === id)
    );
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    if (!areSetsEqual(initialSelectedAccounts, selectedAccounts)) {
      handleAccountOptionClick({
        accounts: Array.from(selectedAccounts),
        itemId: item?._id,
        accounts: getSelectedAcountObjects(),
      });
    }
    setIsDropdownOpen((prev) => !prev);
  };

  const onItemSelect = (option) => {
    const accountId = option._id;
    const updatedAccounts = new Set(selectedAccounts);

    if (updatedAccounts.has(accountId)) {
      updatedAccounts.delete(accountId);
      setSelectAccounts((prevAccounts) =>
        prevAccounts.filter((account) => account._id !== accountId)
      );
    } else {
      updatedAccounts.add(accountId);
      setSelectAccounts((prevAccounts) => [...prevAccounts, option]);
    }

    setSelectedAccounts(updatedAccounts);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);

        // Trigger API call only if the selected accounts have changed
        if (!areSetsEqual(initialSelectedAccounts, selectedAccounts)) {
          handleAccountOptionClick({
            accounts: Array.from(selectedAccounts),
            itemId: item?._id,
            fullAccounts: getSelectedAcountObjects(),
          });
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [selectedAccounts, handleAccountOptionClick, item?._id]);

  // Helper function to check if two sets are equal
  const areSetsEqual = (setA, setB) => {
    if (setA.size !== setB.size) return false;
    for (let item of setA) if (!setB.has(item)) return false;
    return true;
  };

  return (
    <div
      className={`flex items-start ${position} text-title_50`}
      onClick={(e) => e.stopPropagation()}
    >
      {with_title && (
        <IconText
          icon={React.createElement(fieldIconMapping.accounts)}
          text="Customer"
          iconColor="#696969"
          iconSize={16}
          hoverable={false}
          gap_x="gap-x-2"
        />
      )}
      <div className={`flex flex-col w-full`}>
        <div
          className={`relative gap-2 -ml-0.5 my-0.5 ${
            item?.accounts?.length > 0
              ? "flex flex-wrap items-center p-1"
              : `pl-2 pr-14 ${py}`
          } ${
            isDropdownOpen ? "bg-button-active_10" : "hover:bg-button-hover"
          } cursor-pointer rounded`}
          onClick={toggleDropdown}
          ref={dropdownRef}
        >
          {with_icon_before_accounts && (
            <IconWrapper
              icon={React.createElement(fieldIconMapping.accounts)}
              iconColor={"#A9A9A9"}
              size={14}
            />
          )}

          {/* Render selected accounts */}
          {(selectAccounts ?? []).map((account, index) => (
            <div
              key={index}
              className="rounded bg-tags-active px-1.5 py-1 text-xs"
            >
              {account?.name ?? ""}
            </div>
          ))}
          {isDropdownOpen && (
            <div
              className={`absolute flex flex-col min-w-48 ${dropdownPosition} mt-0.5 z-50 gap-y-4`}
            >
              <AccountsSearchDropdown
                searchPlaceholder="Search accounts..."
                items={accounts}
                isDropdownOpen={isDropdownOpen}
                selectedItems={selectedAccounts}
                onItemSelect={onItemSelect}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountsSection;
