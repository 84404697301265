/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getIdeaActivityLogs } from "reduxStore/operations/activityLogsAPI";
import ProfileIcon from "components/common/ProfileIcon";
import { formatDateWithTime } from "utils/timeUtils";
import ActivityLogSection from "components/common/detailView/detailViewRightSidebarSections/ActivityLogSection";

const IdeaDetailViewRightLogSidebar = ({ item }) => {
  const dispatch = useDispatch();
  const { activityLogs } = useSelector((state) => state.activityLogs);

  useEffect(() => {
    dispatch(getIdeaActivityLogs(item._id));
  }, [dispatch, item._id]);

  return (
    <div className="flex flex-col gap-y-2">
      {activityLogs.idea
        .filter((it) => it.idea === item._id)
        .map((log) => (
          <React.Fragment key={log._id}>
            <div className="flex items-start gap-x-3 p-2">
              <ActivityLogSection log={log} />
            </div>
            <hr className="w-4/5 mx-auto" />
          </React.Fragment>
        ))}
      <div className="flex items-start gap-x-3 p-2">
        {item.assignee ? (
          <ProfileIcon name={item?.assignee?.name ?? ""} />
        ) : (
          <img src="/slack_icon.svg" alt="jira" width={22} height={22} />
        )}

        <div>
          <div className="text-sm text-title_50">Created {!item.assignee && "via Slack"} </div>
          <div className="text-xs text-primary">
            {formatDateWithTime(item.createdAt)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdeaDetailViewRightLogSidebar;
