/* eslint-disable react/prop-types */
import React from "react";
import ViewNameInput from "./ViewNameInput";
import { MenuDots } from "react-flaticons";
import EditDeleteDropdown from "../../dropdowns/EditDeleteDropdown";
import TabItem from "./TabItem";

const AddedActiveTabItem = ({
  tab,
  index,
  activeTab,
  handleTabClick,
  activeDropdownIndex,
  handleMenuDotsClick,
  handleEditView,
  handleDeleteView,
  editingViewId,
  viewNameInput,
  handleViewNameChange,
  handleAddViewClick,
  handlePersonalClick,
  personalViews,
  handleCancelClick,
  roadmapId,
}) => {
  const isEditing = editingViewId && editingViewId === tab._id;
  if (!activeTab) {
    return;
  }
  return (
    <div className="relative text-nowrap  flex gap-x-1 items-center justify-between cursor-pointer">
      
      {isEditing ? (
        <ViewNameInput
          viewNameInput={viewNameInput}
          handleViewNameChange={handleViewNameChange}
          handleAddViewClick={handleAddViewClick}
          handleCancelClick={handleCancelClick}
        />
      ) : (
        <>
        
          <TabItem
            tab={tab}
            index={index}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />
          
          {activeTab?._id === tab?._id && (
            <div
              className={`relative hover:bg-button-hover cursor-pointer p-1.5 rounded-full ${
                activeDropdownIndex === index ? "bg-button-active_10" : ""
              }`}
              onClick={() => handleMenuDotsClick(index)}
            >
              <MenuDots size={12} />
            </div>
          )}
          {activeDropdownIndex === index && !isEditing && (
            <div
              className={`absolute bg-white top-full border-2 rounded-lg ${
                index === 0 && tab?.label?.length < 5 ? "-right-6" : "right-[-1.5rem]"
              }
               shadow-lg z-50`}
            >
              <EditDeleteDropdown
                editingViewId={editingViewId}
                handleDeleteView={handleDeleteView}
                handleEditView={handleEditView}
                handlePersonalClick={handlePersonalClick}
                isAPersonalView={personalViews.find(
                  (item) => item._id === tab._id
                )}
                roadmapId={roadmapId}
                inRoadmapTopbar={true}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AddedActiveTabItem;
