import React from "react";
import TopbarTitle from "../topbar/TopbarTitle";
import { Select, ConfigProvider } from "antd";

const Topbar2 = ({
  title = "",
  titleIcon,
  groupByDropdownOptions,
  groupBy,
  handleGroupByChange,
  sortOption,
  sortOptions,
  handleSortByChange,
}) => {
  return (
    <div className="flex flex-col pt-4 px-6 gap-8 h-fit border-b border-gray-200 text-gray-500 sticky top-0 bg-white z-40 text-sm">
      <div className="flex items-center justify-between">
        <TopbarTitle title={title} icon={titleIcon} />
      </div>
      <div className="flex items-center justify-between pb-1">
        <div>toggle</div>
        <div className="flex gap-4 items-center">
          {/* Wrap the Select inside ConfigProvider to override theme tokens */}
          <ConfigProvider
            theme={{
              token: {
                colorText: "#696969", // Default option text color
                colorTextPlaceholder: "#000000",
                colorPrimary: "#000000",
                colorBgSolid: "#ffffff",
                colorBgBase: "#ffffff",
              },

              components: {
                Select: {
                  optionSelectedBg: "#ECECEC",
                  //   optionActiveBg: "#ECECEC",
                  activeOutlineColor: "#000000",
                  optionSelectedColor: "#000000",
                  optionSelectedFontWeight: "400",
                },
              },
            }}
          >
            <Select
              variant="borderless"
              popupMatchSelectWidth={120}
              placement="bottomRight"
              value={groupBy}
              defaultValue={groupBy}
              onChange={handleGroupByChange}
              options={groupByDropdownOptions}
              className="text-primary"
              optionLabelProp="fixedLabel"
              suffixIcon={null}
            />
          </ConfigProvider>
          {/* *********************
          SORTBY
          ********************* */}
          <ConfigProvider
            theme={{
              token: {
                colorText: "#696969", // Default option text color
                colorTextPlaceholder: "#000000",
                colorPrimary: "#000000",
                colorBgSolid: "#ffffff",
                colorBgBase: "#ffffff",
              },

              components: {
                Select: {
                  optionSelectedBg: "#ECECEC",
                  //   optionActiveBg: "#ECECEC",
                  activeOutlineColor: "#000000",
                  optionSelectedColor: "#000000",
                  optionSelectedFontWeight: "400",
                },
              },
            }}
          >
            <Select
              variant="borderless"
              popupMatchSelectWidth={120}
              placement="bottomRight"
              value={sortOption.field}
              defaultValue={sortOption.field}
              onSelect={handleSortByChange}
              options={sortOptions}
              className="text-primary"
              optionLabelProp="fixedLabel"
              suffixIcon={null}
            />
          </ConfigProvider>
          <h2>Filter</h2>
        </div>
      </div>
    </div>
  );
};

export default Topbar2;
