/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { AngleSmallDown, AngleSmallUp } from "react-flaticons";
import IconText from "components/common/IconText";
import { fieldIconMapping } from "../../../utils";

const DualKanbanModuleSection = ({
  ideas = [],
  features = [],
  icon,
  truncateLength,
  iconColor,
  textColor,
  onFeatureClick,
  onIdeaClick,
}) => {
  const [isHovered, setIsHovered] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  // Merge ideas and features while ensuring correct type handling
  // const items = [
  //   ...ideas.map((idea) => ({ ...idea, isIdea: true })), // Mark ideas explicitly
  //   ...features.map((feature) => ({ ...feature, isIdea: false })), // Mark features explicitly
  // ];


  const items = [
    ...ideas
      .filter((idea) => idea.type === "Idea")
      .map((idea) => ({ ...idea, isIdea: true })), // Mark ideas explicitly
    ...ideas
      .filter((idea) => idea.type === "Feedback")
      .map((idea) => ({ ...idea, isIdea: true })), // Mark feedback explicitly
    ...features.map((feature) => ({ ...feature, isIdea: false })), // Mark features explicitly
  ];
  



  // Handle click event for items based on type
  const handleItemClick = (item) => {
    if (item.isIdea) {
      onIdeaClick(item);
    } else {
      onFeatureClick(item);
    }
  };

  return (
    <div className="rounded">
      {/* Show all items if expanded, otherwise only the first */}
      {(isExpanded ? items : items.slice(0, 1)).map((item, index) => (
        <div
          key={item._id}
          className="flex items-center justify-between hover:bg-button-hover rounded"
          onMouseEnter={() => setIsHovered(index)}
          onMouseLeave={() => setIsHovered(null)}
          onClick={(e) => {
            e.stopPropagation();
            handleItemClick(item);
          }}
        >
          <IconText
            icon={
              item.isIdea
                ? item.type === "Idea"
                  ? React.createElement(fieldIconMapping.idea)
                  : React.createElement(fieldIconMapping.feedback)
                : React.createElement(fieldIconMapping.feature)
            }
            text={item.title}
            iconSize="14"
            truncateLength={items.length > 1 ? truncateLength : 40}
            textColor={textColor}
            textSize="text-xs"
            iconColor={
              item.isIdea
                ? item.type === "Idea"
                  ? "text-ideas-icon"
                  : "text-feedbacks-icon"
                : "text-features-icon"
            }
            gap_x="gap-x-2"
            px="px-1"
            hoverable={false}
          />

          {/* Expand/Collapse button */}
          {index === 0 && items.length > 1 && (
            <div className="flex items-center">
              {!isExpanded ? (
                <div className="flex items-center">
                  <div className="bg-white rounded-full">
                    <div className="text-xs text-title_50 bg-button-active_10 p-0.5 rounded-full">
                      {`+${items.length - 1}`}
                    </div>
                  </div>
                  <div
                    className={`mx-1 p-1 hover:bg-topbar-button-hover rounded ${
                      isHovered === index ? "" : "invisible"
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsExpanded(true);
                    }}
                  >
                    <AngleSmallDown size={12} color="black" />
                  </div>
                </div>
              ) : (
                isHovered === index && (
                  <div
                    className="mx-1 p-1 hover:bg-topbar-button-hover rounded"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsExpanded(false);
                    }}
                  >
                    <AngleSmallUp size={12} color="black" />
                  </div>
                )
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default DualKanbanModuleSection;
