import React, { useState } from "react";
import SettingsTopbar from "./SettingsTopbar";
import { CreditCard, PlusSmall } from "react-flaticons";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, Input, Form, Empty, Tooltip } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import {
  createApiKey,
  deleteApiKey,
} from "../../reduxStore/operations/apiKeysApi";

import { FaCode } from "react-icons/fa6";

const formatDate = (isoString) => {
  const date = new Date(isoString);
  const month = date.toLocaleString("en-US", { month: "long" });
  const day = date.getDate();
  const year = date.getFullYear();
  const hour24 = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hour24 >= 12 ? "PM" : "AM";
  const hour12 = hour24 % 12 === 0 ? 12 : hour24 % 12;
  // Pad minutes if needed.
  const minuteStr = minutes < 10 ? "0" + minutes : minutes;
  // Format as "March 12,2023 10:32 AM" (no space after comma)
  return `${month} ${day},${year} ${hour12}:${minuteStr} ${ampm}`;
};

const ApiKeys = () => {
  const { apiKeys } = useSelector((state) => state.apiKeys);
  const dispatch = useDispatch();

  // Modal state: visibility, key label, generated API key, loading and tooltip state.
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [keyLabel, setKeyLabel] = useState("");
  const [generatedApiKey, setGeneratedApiKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  // Flag to track if modal is still active (used to cancel async state updates)
  const [isActive, setIsActive] = useState(false);

  // Open modal: mark as active and show the modal.
  const showModal = () => {
    setIsActive(true);
    setIsModalVisible(true);
  };

  // Close modal: mark as inactive and reset modal state.
  const handleCancel = () => {
    setIsActive(false);
    setIsModalVisible(false);
    setGeneratedApiKey("");
    setKeyLabel("");
    setLoading(false);
  };

  // Create API Key (step 1 → step 2)
  const handleCreateKey = async () => {
    if (keyLabel.trim() === "") {
      return;
    }
    setLoading(true);
    try {
      // Dispatch the createApiKey thunk with the key label.
      // It is assumed that your thunk returns the generated API key string.
      const apiKey = await dispatch(createApiKey(keyLabel));
      // Only update state if the modal is still active
      if (isActive) {
        setGeneratedApiKey(apiKey);
      }
    } catch (error) {
      console.error("Error creating API key:", error);
    }
    if (isActive) {
      setLoading(false);
    }
  };

  // Copy generated API key to clipboard and show tooltip.
  const handleCopy = () => {
    if (generatedApiKey) {
      navigator.clipboard.writeText(generatedApiKey);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  };

  // Helper function: show first 4 characters, and mask the rest with asterisks.
  const maskToken = (token) => {
    if (!token) return "";
    // If token is longer than 12 characters, show the first 4 and mask the next 8 characters
    if (token.length > 20) {
      return token.slice(0, 4) + "*".repeat(16);
    }
    // Otherwise, mask all characters after the first 4
    const visible = token.slice(0, 4);
    const maskedCount = token.length - 4;
    return visible + "*".repeat(Math.max(maskedCount, 0));
  };

  return (
    <div className="w-full flex flex-col items-center">
      {/* Top Section */}
      <div>
        <SettingsTopbar
          title="API"
          description="Connect Lane with external applications"
          icon={<FaCode />}
        />

        <div className="flex flex-col justify-center items-start max-w-[40rem]">
          <div className="pb-8 text-[16px] w-full space-y-3">
            <div className="flex items-center justify-between">
              <h2 className="text-title">API Keys</h2>
              <Button
                onClick={showModal}
                type="primary"
                // icon={<PlusSmall size={20} />}
                // className="pl-2"
              >
                Create API Key
              </Button>
            </div>
            <p className="text-primary">
              Generate and manage API keys to securely connect Lane with
              external applications and integrations.
            </p>
          </div>
          <div className="w-full mt-8">
            {apiKeys && apiKeys.length > 0 ? (
              <table className="min-w-full table-auto">
                <thead>
                  <tr className="text-sm">
                    <th className="text-title text-start pr-1 font-normal bg-white">
                      Label
                    </th>
                    <th className="text-title text-start pr-1 px-3 font-normal bg-white">
                      Key
                    </th>
                    <th className="text-title text-start pr-1 px-3 font-normal bg-white">
                      Created at
                    </th>
                    <th className="text-title text-start pr-1 px-3 font-normal bg-white">
                      {/* Action Column */}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {apiKeys.map((key) => (
                    <tr
                      key={key._id}
                      className="group text-title text-[14px] rounded relative"
                    >
                      <td className="truncate pr-1 py-2">{key.title}</td>
                      <td className="truncate pr-1 px-3 py-2">
                        {maskToken(key.keyToken)}
                      </td>
                      <td className="truncate pr-1 py-2   pl-3 ">
                        {formatDate(key.createdAt)}
                      </td>
                      <td className="truncate pr-1 px-3 py-2 opacity-0 group-hover:opacity-100 transition-all duration-200">
                        <Button
                          type="link"
                          danger
                          onClick={() => dispatch(deleteApiKey(key._id))}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="flex justify-center items-top pt-12 h-[80vh]">
                <Empty
                  imageStyle={{ height: 70 }}
                  description={
                    <span className="font-bold text-primary">No API Keys</span>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal for Creating an API Key */}
      <Modal
        open={isModalVisible}
        title={
          <div style={{ display: "flex", alignItems: "center" }}>
            <FaCode style={{ marginRight: 8 }} />
            <span>API Key</span>
          </div>
        }
        onCancel={handleCancel}
        footer={
          generatedApiKey ? (
            // After key generation, show only "Close"
            <Button key="close" onClick={handleCancel}>
              Close
            </Button>
          ) : (
            // Before key generation, show Cancel and Create Key buttons
            <>
              <Button key="cancel" onClick={handleCancel}>
                Cancel
              </Button>
              <Button
                key="create"
                type="primary"
                onClick={handleCreateKey}
                loading={loading}
              >
                Create Key
              </Button>
            </>
          )
        }
      >
        <Form layout="vertical">
          <Form.Item label="Key Label" className="mt-4">
            <Input
              value={keyLabel}
              onChange={(e) => setKeyLabel(e.target.value)}
              placeholder="Zapier Key"
              readOnly={!!generatedApiKey || loading}
            />
          </Form.Item>
          {generatedApiKey && (
            <Form.Item label="API Key">
              <Input
                value={generatedApiKey}
                readOnly
                addonAfter={
                  <Tooltip title={copied ? "Copied" : "Copy"} open={copied}>
                    <CopyOutlined
                      style={{ cursor: "pointer" }}
                      onClick={handleCopy}
                    />
                  </Tooltip>
                }
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default ApiKeys;
