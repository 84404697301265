/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { fieldIconMapping } from "utils";
import IconText from "components/common/IconText";
import StatusSection from "components/common/detailView/detailViewRightSidebarSections/StatusSection";
import ProfileIcon from "components/common/ProfileIcon";
import { useDispatch, useSelector } from "react-redux";
import { generateStatusArray } from "utils/status";
import { CrossSmall } from "react-flaticons";
import { updateIdeaData } from "reduxStore/operations/ideasAPI";
import {
  setActiveIdeaId,
  setIdeaShowDetailViewDrawer,
} from "reduxStore/slices/ideaSlice";
import AssigneeSection from "../../../common/detailView/detailViewRightSidebarSections/AssigneeSection";

const ObjectiveDetailViewIdeaRowComponent = ({ item, removeIdeas }) => {
  const dispatch = useDispatch();
  const { statuses } = useSelector((state) => state.company);
  const { ideas } = useSelector((state) => state.ideas);
  const { users } = useSelector((state) => state.users);
  const [ideaStatuses, setIdeaStatuses] = useState([]);

  useEffect(() => {
    setIdeaStatuses(statuses.idea);
  }, [statuses]);

  const ideaStatusArray = useMemo(
    () => generateStatusArray(ideaStatuses),
    [ideaStatuses]
  );

  const objectiveIdeas = useMemo(() => {
    // return (ideas ?? []).filter((idea) =>
    //   (item?.ideas ?? []).includes(idea._id)
    // );
    return [
      ...(ideas ?? []).filter(
        (idea) => (item?.ideas ?? []).includes(idea._id) && idea.type === "Idea"
      ),
      ...(ideas ?? []).filter(
        (idea) =>
          (item?.ideas ?? []).includes(idea._id) && idea.type === "Feedback"
      ),
    ];
  }, [ideas, item?.ideas]);

  // Truncate text function
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  return (
    <>
      {(objectiveIdeas ?? []).map((idea) => (
        <div
          key={idea._id}
          className="flex items-center justify-between p-1 hover:bg-row-background rounded cursor-pointer group"
          onClick={() => {
            dispatch(setActiveIdeaId(idea._id));
            dispatch(setIdeaShowDetailViewDrawer(true));
          }}
        >
          <IconText
            icon={React.createElement(
              idea.type === "Idea"
                ? fieldIconMapping.idea
                : fieldIconMapping.feedback
            )}
            iconColor={
              idea.type === "Idea" ? "text-ideas-icon" : "text-feedbacks-icon"
            }
            text={truncateText(idea.title, 40)}
            hoverable={false}
            px=""
            py=""
            gap_x="gap-x-2"
          />
          <div className="flex items-center gap-x-2">
            <StatusSection
              item={idea?.status ?? {}}
              statusArray={ideaStatusArray}
              with_title={false}
              with_label={false}
              dropdownMenuPosition={"right-0"}
              handleStatusOptionClick={(option) => {
                dispatch(updateIdeaData(idea._id, { status: option._id }));
              }}
            />
            <AssigneeSection
              item={idea}
              assignee={idea.assignee}
              itemLabel={idea?.assignee?.name ?? ""}
              assigneesArray={users}
              dropdownMenuPosition="top-full right-2 "
              handleAssineeOptionClick={(option) => {
                if (idea?.assignee?._id !== option._id) {
                  let { icon, ...restOption } = option;
                  dispatch(
                    updateIdeaData(
                      idea._id,
                      { assignee: option._id },
                      "assignee",
                      idea,
                      restOption
                    )
                  );
                }
              }}
              with_title={false}
              with_text={false}
              textLength={4}
            />

            <div
              className="hover:bg-button-hover rounded-full p-0.5 opacity-0 group-hover:opacity-100 transition-opacity"
              onClick={(e) => {
                e.stopPropagation();
                removeIdeas([idea._id]);
              }}
            >
              <CrossSmall size={14} />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ObjectiveDetailViewIdeaRowComponent;
