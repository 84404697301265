import React, { useEffect, useState } from "react";
import SettingsTopbar from "../SettingsTopbar";
import Loader from "../../common/Loader";
import {
  createIntegration,
  deleteIntegration,
  getAllIntegrations,
  getIntegration,
  updateIntegration,
} from "../../../reduxStore/operations/integrationsAPI";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Form, Input } from "antd";

const IntegrationDetails = () => {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [connecting, setConnecting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [url, setUrl] = useState("");
  const [jiraEmail, setJiraEmail] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate("/");
  const integrations = useSelector((state) => state.integrations);
  const { jira } = integrations;
  const { companyId } = useSelector((state) => state.auth);
  const tabs = [{ label: "Jira", key: "Jira" }];

  // Create a form instance
  const [form] = Form.useForm();

  useEffect(() => {
    if (!companyId) {
      return;
    }
    setPageLoading(true); // Start loading
    dispatch(getAllIntegrations(companyId)).finally(() =>
      setPageLoading(false)
    ); // Stop loading
  }, [jira, companyId]);

  // Load integration details and populate form fields
  useEffect(() => {
    dispatch(getIntegration("jira", companyId)).then((res) => {
      setJiraEmail(res.email);
      setUrl(res.domain);
      // Set the form fields using the form instance
      form.setFieldsValue({
        email: res.email || "",
        domain: res.domain || "",
        apiToken: "", // Clear the token or set it if needed
      });
    });
  }, [dispatch, companyId, form]);

  const submitHandler = (values) => {
    setConnecting(true);
    const payload = {
      name: "jira",
      email: values.email,
      domain: values.domain,
      apiToken: values.apiToken,
      companyId,
    };

    const action = jira ? updateIntegration : createIntegration;
    dispatch(action(payload, navigate)).finally(() => setConnecting(false));
  };

  const jiraDisconnectHandler = () => {
    dispatch(deleteIntegration("jira", companyId));
    navigate("/settings/integrations");
  };

  // Discard handler: reset form fields to empty values
  const discardHandler = () => {
    if (isEditing) {
      setIsEditing(false);
      return;
    }
    form.setFieldsValue({
      email: "",
      domain: "",
      apiToken: "",
    });
  };
  return (
    <div className="w-full flex justify-center">
      <div>
        <SettingsTopbar
          title="Jira"
          description="Push, pull, and sync issues directly between Jira and Lane"
          isBack={true}
        />
        {pageLoading ? (
          // <Loader /> // Show loader while fetching integrations
          // <div>Loading...</div>
          <div className=" flex justify-center pt-12 ">
            <Loader />
          </div>
        ) : (
          <div className="flex flex-col w-[40rem]">
            {!jira || isEditing ? (
              <Form form={form} onFinish={submitHandler} layout="vertical">
                <Alert
                  className="mb-5"
                  message="We use API token to connect to your Jira workspace"
                  type="info"
                  showIcon
                />
                <Form.Item
                  className="w-[22rem]"
                  label="Workspace URL"
                  name="domain"
                  rules={[
                    { required: true, message: "Please input your domain!" },
                  ]}
                >
                  <Input placeholder="E.g. https://test.atlassian.net" />
                </Form.Item>

                <Form.Item
                  className="w-[22rem]"
                  label="Account email"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                    { type: "email", message: "Please enter a valid email!" },
                  ]}
                >
                  <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                  className="w-[22rem]"
                  label="API token"
                  name="apiToken"
                  rules={[
                    { required: true, message: "Please input your API Token!" },
                  ]}
                >
                  <Input placeholder="API Token" />
                </Form.Item>

                <Form.Item>
                  <div className="flex gap-2 mt-8">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={connecting}
                    >
                      {isEditing ? "Update" : "Connect"}
                    </Button>
                    <Button
                      type="default"
                      htmlType="button"
                      onClick={discardHandler}
                    >
                      Discard
                    </Button>
                  </div>
                </Form.Item>
                <div className=" w-[40rem] pb-8 pt-20   ">
                  <h3 className="text-[15px] font-bold text-title mb-1   ">
                    How It Works
                  </h3>
                  <div className="space-y-2">
                    <div>
                      <h4 className="text-sm font-semibold text-gray-800 mb-0.5">
                        1. Authenticate Your Jira Account:
                      </h4>
                      <p className="text-sm ">
                        Connect Jira to Lane by entering your Jira API key,
                        workspace link, and admin email. This step ensures a
                        safe and authenticated link between the two platforms.
                      </p>
                    </div>
                    <div>
                      <h4 className="text-sm font-semibold text-gray-800 mb-0.5">
                        2. Sync Issue Seamlessly:
                      </h4>
                      <p className="text-sm ">
                        Once connected, you can push, pull, and sync issues
                        between Jira and Lane, ensuring your development tasks
                        and feedback stay aligned.
                      </p>
                    </div>
                  </div>
                </div>
              </Form>
            ) : (
              <div>
                <div className="flex items-center w-full pb-8">
                  <h2 className="text-title_50">Manage your Jira workspace</h2>
                  <Button
                    className="ml-auto mr-4"
                    color="default"
                    variant="outlined"
                    onClick={() => {
                      setIsEditing(true);
                      form.setFieldsValue({
                        domain: "",
                        apiToken: "",
                      });
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    color="default"
                    variant="outlined"
                    onClick={jiraDisconnectHandler}
                  >
                    Delete
                  </Button>
                </div>

                <div className="text-sm text-nowrap space-y-2">
                  <div className="flex">
                    <h2 className="text-primary w-[10rem]">Workspace URL</h2>
                    <p className="text-title">{url}</p>
                  </div>
                  <div className="flex">
                    <h2 className="text-primary w-[10rem]">Account email</h2>
                    <p className="text-title">{jiraEmail}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default IntegrationDetails;
