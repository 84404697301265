import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { signin, signUpWithGoogle } from "reduxStore/operations/authAPI";
import { setLoading } from "reduxStore/slices/authSlice";
import Loader from "../common/Loader";
import {
  FaEyeSlash,
  FaLockOpen,
  FaRegEye,
  FaRegEyeSlash,
} from "react-icons/fa";
import {
  IoEyeSharp,
  IoLockOpenOutline,
  IoLockOpenSharp,
  IoMailOutline,
} from "react-icons/io5";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.auth);

  const handleSignIn = (e) => {
    e.preventDefault();
    dispatch(setLoading(true));
    dispatch(signin(email, password, navigate));
    setPassword("");
  };

  useEffect(() => {
    const token = localStorage.getItem("jwt");
    if (token) {
      navigate("/");
    }
  }, [navigate]);

  const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
  const handleLoginSuccess = (response) => {
    dispatch(signUpWithGoogle(response.credential, navigate));
  };

  const handleLoginFailure = (error) => {
    console.error("Login Failed", error);
  };

  return (
    // <div className="flex flex-col gap-6 items-center justify-center min-h-screen bg-gray-100">
    //   <div className=" relative bg-white p-8 rounded shadow-md w-full max-w-sm">
    //     <div className="  absolute top-[-140px] right-0 w-full  flex items-center justify-center  ">
    //       <img src="/logo.png  " alt="logo" className=" w-[200px] h-[100px] " />
    //     </div>

    //     <h2 className="text-2xl mb-6 text-center">Welcome back</h2>
    //     <form onSubmit={handleSignIn}>
    //       <div className="mb-6">
    //         <GoogleOAuthProvider clientId={clientId}>
    //           <div
    //             style={{
    //               display: "flex",
    //               justifyContent: "center",
    //               alignItems: "center",
    //               width: "100%",
    //             }}
    //           >
    //             <GoogleLogin
    //               onSuccess={handleLoginSuccess}
    //               onError={handleLoginFailure}
    //               text="continue_with"
    //               shape="rectangular"
    //               size="large"
    //               theme="outline"
    //               logo_alignment="center"
    //               width="320"
    //             />
    //           </div>
    //         </GoogleOAuthProvider>
    //       </div>

    //       <div className="flex items-center justify-center mb-4">
    //         <div className="flex-grow border-t border-gray-300"></div>
    //         <span className="mx-4 text-gray-500">OR</span>
    //         <div className="flex-grow border-t border-gray-300"></div>
    //       </div>
    //       <div className="mb-4">
    //         <label className="block text-gray-700">Email</label>
    //         <input
    //           type="email"
    //           className="w-full p-2 border border-gray-300 rounded mt-1"
    //           value={email}
    //           onChange={(e) => setEmail(e.target.value)}
    //           placeholder="name@company.com"
    //           required
    //         />
    //       </div>

    //       <div className="mb-4">
    //         <label className="block text-gray-700">Password</label>
    //         <div className="relative">
    //           <input
    //             type={showPassword ? "text" : "password"}
    //             className="w-full p-2 border border-gray-300 rounded mt-1 pr-10" // Added padding-right for the icon
    //             value={password}
    //             onChange={(e) => setPassword(e.target.value)}
    //             placeholder="Sshhhh…"
    //             required
    //           />
    //           <button
    //             type="button"
    //             className="absolute right-2 mt-1 inset-y-0 flex items-center text-gray-600"
    //             onClick={() => setShowPassword(!showPassword)}
    //           >
    //             {showPassword ? (
    //               <IoEyeSharp className="h-5 w-5" />
    //             ) : (
    //               <FaEyeSlash className="h-5 w-5" />
    //             )}
    //           </button>
    //         </div>
    //           <p className="text-end text-gray-600">
    //             <Link to="/forgot-password" className="text-blue-500">
    //               Forgot password
    //             </Link>
    //           </p>
    //       </div>
    //       <button
    //         type="submit"
    //         className="w-full bg-blue-500 text-white p-2 rounded mt-4 hover:bg-blue-600"
    //       >
    //         {loading ? <Loader size="20" color="white" /> : "Sign In"}
    //       </button>
    //     </form>
    //   </div>
    //   <p className="text-center text-gray-600 ">
    //     Don't have an account?{" "}
    //     <Link to="/signup" className="text-blue-500">
    //       Sign Up
    //     </Link>
    //   </p>
    // </div>

    <div className="h-screen flex flex-col  md:flex-row font-inter overflow-hidden ">
      {/* Left Section (Form) */}
      <div className="w-full md:w-[60%] xl:w-[45%] max-w-[55rem]  min-w-[24rem]  md:min-w-[32rem] flex flex-col items-center justify-center p-2 md:p-4">
        <div className="flex justify-center p-4 md:p-8 w-[90%] max-w-[30rem]">
          {/* <div className="flex justify-center bg-white p-4 md:p-8 w-[90%] max-w-[30rem]"> */}
          {/* Logo */}
          <div className="flex flex-col gap-8 items-center justify-center w-full">
            <img
              src="/lane_icon_signin.svg"
              alt="logo"
              className="w-[125px] h-[75px] mr-auto"
            />
            <h2 className="text-2xl text-left w-full">
              Sign in to your account
            </h2>

            {/* Sign In Form */}
            <form
              className="w-full  flex flex-col justify-center items-center     "
              onSubmit={handleSignIn}
            >
              {/* Google OAuth Button */}
              <div className=" flex justify-center items-center   mb-8">
                <GoogleOAuthProvider clientId={clientId}>
                  <div className=" hidden md:block font-bold">
                    <GoogleLogin
                      onSuccess={handleLoginSuccess}
                      onError={handleLoginFailure}
                      text="continue_with"
                      shape="rectangular"
                      size="large"
                      theme="outline"
                      logo_alignment="center"
                      width="400"
                    />
                  </div>
                  <div className=" block md:hidden font-bold">
                    <GoogleLogin
                      onSuccess={handleLoginSuccess}
                      onError={handleLoginFailure}
                      text="continue_with"
                      shape="rectangular"
                      size="large"
                      theme="outline"
                      logo_alignment="center"
                      width="300"
                    />
                  </div>
                </GoogleOAuthProvider>
              </div>

              {/* Divider */}
              <div className="flex items-center justify-center mb-8 w-full ">
                <div className="flex-grow border-t border-gray-300"></div>
                <span className="mx-4 text-[#9E9B9B] text-[12px] ">
                  or continue with email
                </span>
                <div className="flex-grow border-t border-gray-300"></div>
              </div>

              {/* Email Input */}
              <div className="mb-6 w-full ">
                <label className="block text-[16px] text-[#696969]">
                  Email
                </label>
                <div className="relative w-full">
                  <IoMailOutline
                    className="absolute left-3 top-[14px] text-gray-400"
                    size={20}
                  />
                  <input
                    type="email"
                    className="w-full p-2 pl-10 border border-gray-300 rounded-md mt-1"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>

              {/* Password Input */}
              <div className="mb-6 w-full ">
                <label className="block text-[16px] text-[#696969]">
                  Password
                </label>
                {/* <IoLockOpenOutline/> */}
                <div className="relative">
                  <IoLockOpenOutline
                    className="absolute left-3 top-[14px] text-gray-400"
                    size={20}
                  />
                  <input
                    type={showPassword ? "text" : "password"}
                    className="w-full p-2 pl-10 border border-gray-300 rounded-md mt-1"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    // placeholder="Sshhhh…"
                    required
                  />
                  <button
                    type="button"
                    className="absolute right-2 mt-1 inset-y-0 flex items-center text-gray-600"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      // <IoEyeSharp className="h-5 w-5" />
                      <FaRegEye className=" h-[19px] w-[19px] text-[#696969] " />
                    ) : (
                      // <FaEyeSlash className="h-5 w-5" />
                      <FaRegEyeSlash className=" h-5 w-5 text-[#696969] " />
                    )}
                  </button>
                </div>
                <p className="text-end pt-2  text-gray-600">
                  <Link to="/forgot-password" className="text-[#4694DB]">
                    Forgot password
                  </Link>
                </p>
              </div>

              {/* Sign In Button */}
              <button
                type="submit"
                className="w-full  font-bold bg-signup-button text-white p-2 rounded hover:bg-blue-600"
              >
                {loading ? <Loader size="20" color="white" /> : "Sign in"}
              </button>
            </form>
          </div>
        </div>

        {/* Sign Up Link */}
        <p className="text-center text-[#696969] mt-2">
          Don't have an account?{" "}
          <Link to="/signup" className="text-[#4694DB]">
            Sign Up
          </Link>
        </p>
      </div>
      <div className="hidden md:block bg-[#F9FBFC] md:w-[40%] xl:w-[55%] relative pl-[6rem]">
        {/* Quote */}
        <h2 className="pt-[64px] pr-0 xl:pr-[7rem] md:text-[17px] lg:text-[24px]  xl:text-[28px]  text-gray-800">
          “Great product managers thrive on turning ambiguity into clarity and
          challenges into opportunities”
        </h2>
        {/* Background Image */}
        <div className="absolute top-[14rem] left-[6rem] bottom-0 border  rounded-xl overflow-hidden">
          <img
            src="/signinfull.svg"
            alt="Background"
            className="w-full h-full object-cover"
            style={{
              objectPosition: "top left", // Align the image to the top-left corner
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SignIn;
