import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import SettingsTopbar from "../SettingsTopbar";
import { Outlet } from "react-router-dom";
import { toSnakeCase } from "utils";
import { Following } from "react-flaticons";

const SettingsCustomers = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [{ label: "Customer", key: "Customer" }];

  const getInitialTab = () => {
    const currentPath = location.pathname.split("/").pop();
    const foundTab = tabs.find((tab) => toSnakeCase(tab.label) === currentPath);
    return foundTab ? foundTab : tabs[0];
  };

  const [activeTab, setActiveTab] = useState(getInitialTab());

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (activeTab.label === "Customer") {
      navigate("");
    } else {
      navigate(`${toSnakeCase(activeTab.label)}`);
    }
  }, [activeTab, navigate]);

  return (
    <div className="relative w-full flex justify-center">
      <div className="  ">
        <div className="">
          <SettingsTopbar
            // tabs={tabs}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
            isAccounts={true}
            title="Customers"
            description="Customize your workspace to match your workflow"
            icon={<Following />}
          />
        </div>
        <div className=" ">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default SettingsCustomers;
