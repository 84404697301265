/* eslint-disable react/prop-types */
import IconText from "components/common/IconText";
import TopbarOptionButtons from "./topbarOptionButtons/TopbarOptionButtons";
import TopbarTabs from "./topbarTabs/TopbarTabs";
import Breadcrumb from "../../common/Breadcrumb";
import React, { useEffect } from "react";
import TopbarTitleRight from "./tobarTitleRight/TopbarTitleRight";
import RoadmapIdTopbarTabs from "./topbarTabs/RoadmapTopbarTabs";
import { useDispatch, useSelector } from "react-redux";
import { getAllIntegrations } from "../../../reduxStore/operations/integrationsAPI";
import { useLocation } from "react-router-dom";
import TopbarIdeaToggle from "./topbarTabs/TopbarIdeaToggle";
import useFilterLogic from "../../../hooks/useFilterLogic";
import TopbarTitle from "./TopbarTitle";

const Topbar = ({
  title,
  titleIcon,
  tabs,
  groupByDropdownOptions,
  showButtonDropdownOptions,
  filterOptions,
  sortOptions,
  topbarFunctions,
  topbarTabFunctions,
  setActiveProperties,
  with_different_view_option = true,
  activeState,
  showBreadcrumb = false,
  breadCrumbs,
  handleRightSidebarClick = () => {},
  withShareOption = false,
  withTopSidebarOption = false,
  showSaveViewOption = true,
  with_group_by_option = true,
  searchTerm,
  onSearchChange,
  roadmap,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { companyId } = useSelector((state) => state.auth);
  const { personalViews } = useSelector((state) => state.personalViews);
  const params = new URLSearchParams(window.location.search);
  const isIdea = pathname === "/ideas" ? true : false;
  const view = params.get("view");
  const viewName =
    view &&
    personalViews.find((personalView) => personalView._id === view)?.label; // Use optional chaining to safely access label

  useEffect(() => {
    dispatch(getAllIntegrations(companyId));
  }, []);
  const isBottomPadding = /^\/roadmaps\/[^\/]+$/.test(pathname);

  return (
    <div
      className={`flex flex-col pt-4 px-6 h-fit border-b border-gray-200  text-gray-500 sticky top-0 bg-white z-40 text-sm `}
    >
      <div className="flex items-center  justify-between">
        {!showBreadcrumb ? (
          !isIdea ? (
            // <IconText
            //   icon={titleIcon}
            //   iconSize={14}
            //   text={viewName || title}
            //   hoverable={false}
            //   isASidebarComponent={false}
            //   px=""
            //   isTextActive={true}
            // />
            <TopbarTitle
              title={viewName || title}
              icon={titleIcon}
              topbarFunctions={topbarFunctions}
            />
          ) : !view || view === "all" ? (
            <TopbarIdeaToggle
              icon={titleIcon}
              text={viewName || title}
              isTextActive={true}
              topbarFunctions={topbarFunctions}
            />
          ) : (
            <TopbarTitle
              title={viewName || title}
              icon={titleIcon}
              topbarFunctions={topbarFunctions}
            />
          )
        ) : (
          <div className="-ml-2 my-2">
            <Breadcrumb crumbs={breadCrumbs} iconSize="14" />
          </div>
        )}
        <TopbarTitleRight
          handleRightSidebarClick={handleRightSidebarClick}
          withShareOption={withShareOption}
          withTopSidebarOption={withTopSidebarOption}
          searchPlaceholder={"Search " + title + "..."}
          roadmap={roadmap}
          searchTerm={searchTerm}
          onSearchChange={onSearchChange}
        />
      </div>

      <div
        className={`flex   items-center   justify-end   ${
          isBottomPadding ? "mt-[24px] " : "pb-[3px] mt-[27px]  "
        } `}
      >
        <div className="mr-auto">
          {roadmap ? (
            <RoadmapIdTopbarTabs
              tabs={tabs}
              title={title}
              setActiveProperties={setActiveProperties}
              activeState={activeState}
              showSaveViewOption={showSaveViewOption}
              topbarTabFunctions={topbarTabFunctions}
              roadmapId={roadmap._id}
            />
          ) : (
            <div className=" absolute bottom-[2px] right-[450px] ">
              <TopbarTabs
                tabs={tabs}
                title={title}
                setActiveProperties={setActiveProperties}
                activeState={activeState}
                showSaveViewOption={showSaveViewOption}
                topbarTabFunctions={topbarTabFunctions}
              />
            </div>
          )}
        </div>
        <div className=" w-full ">
          <TopbarOptionButtons
            showButtonDropdownOptions={showButtonDropdownOptions}
            filterOptions={filterOptions}
            sortOptions={sortOptions}
            groupByDropdownOptions={groupByDropdownOptions}
            topbarFunctions={topbarFunctions}
            with_different_view_option={with_different_view_option}
            activeState={activeState}
            with_group_by_option={with_group_by_option}
            isPersonal={view}
          />
        </div>
      </div>
    </div>
  );
};

export default Topbar;
