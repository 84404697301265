/* eslint-disable react/prop-types */
import DropdownItem from "components/common/DropdownItem";

const GroupByDropdown = ({
  groupByDropdownOptions,
  handleGroupByOptionClick,
  activeOption,
}) => {
  return (
    <div
      className={`absolute bg-white w-36 rounded-lg py-2 px-1 mx-1 border-2 shadow-lg top-full right-[355px]`}
    >
      <ul>
        {(groupByDropdownOptions ?? []).map((option, index) => {
          const isActive = activeOption === option.label;
          return (
            <li
              key={index}
              className={`mb-0.5 rounded-md ${
                isActive ? "  border-title border   " : ""
              } `}
            >
              <DropdownItem
                option={option}
                textSize="text-sm"
                textColor={isActive && "text-title"}
                iconColor={isActive && "text-title"}
                iconSize={14}
                handleOptionClick={() => {
                  handleGroupByOptionClick(option);
                }}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default GroupByDropdown;
