// import React, { useEffect, useRef, useState } from "react";
// import SettingsTopbar from "../SettingsTopbar";
// import {
//   getIntegration,
//   slackIntegration,
// } from "../../../reduxStore/operations/integrationsAPI";
// import { useDispatch, useSelector } from "react-redux";
// import { ChartNetwork } from "react-flaticons";
// import { apiConnector } from "../../../api/apiconnector";
// import { integrationsEndPoints } from "../../../api/api";
// const { GET_SLACK_INTEGRATION } = integrationsEndPoints;

// const Slack = () => {
//   const dispatch = useDispatch();
//   const { companyId } = useSelector((state) => state.auth);
//   const [isSlackIntegrated, setIsSlackIntegrated] = useState(false);

//   useEffect(() => {
//     const getSlackInt = async () => {
//       try {
//         const response = await apiConnector(
//           "GET",
//           GET_SLACK_INTEGRATION("slack"),
//           null,
//           null,
//           { companyId }
//         );
//         if (response.status === 200) {
//           if (response.data !== null) {
//             setIsSlackIntegrated(true);
//           }
//         }
//       } catch (error) {
//         // toast.error(
//         //   error.response.data.message ?? "Error in fetching integration"
//         // );
//       }
//     };

//     getSlackInt();
//   }, []);

//   const code = new URLSearchParams(window.location.search).get("code");

//   const hasCalled = useRef(false);

//   useEffect(() => {
//     if (code && !hasCalled.current) {
//       dispatch(slackIntegration(code));
//       hasCalled.current = true;
//     }
//   }, [code]);

//   const CLIENT_ID = import.meta.env.VITE_SLACK_CLIENT_ID;
//   const REDIRECT_URI = import.meta.env.VITE_SLACK_REDIRECT_URI;
//   const SCOPE =
//     "app_mentions:read,channels:history,channels:read,chat:write,chat:write.customize,chat:write.public,commands,files:write,im:write,links:write,triggers:read,triggers:write,users.profile:read,users:read,users:read.email,groups:write";

//   const slackAuthUrl = `https://slack.com/oauth/v2/authorize?client_id=${CLIENT_ID}&scope=${SCOPE}&user_scope=&redirect_uri=${REDIRECT_URI}`;

//   const handleSlackConnect = () => {
//     window.location.href = slackAuthUrl;
//   };

//   return (
//     <>
//       <SettingsTopbar
//         icon={<ChartNetwork />}
//         title="Slack"
//         crumb={{ name: "Slack", path: "/settings/integrations/slack" }}
//       />
//       <div className="p-6 flex gap-5">
//         {isSlackIntegrated ? (
//           <div>
//             <h2 className="pb-4 text-primary text-sm  ">
//               Company is connected to Slack
//             </h2>
//             <button className="text-red-600 font-semibold text-sm border border-red-500 hover:bg-red-500 hover:text-white transition-all duration-2100 px-4 py-2 rounded-lg">
//               Disconnect
//             </button>
//           </div>
//         ) : (
//           <div className=" ">
//             <h2 className="pb-4 text-primary text-sm  ">
//               Import customer feedback and ideas to Lane
//             </h2>
//             <div className="  w-fit flex p-2 border border-gray-300 rounded  justify-center  gap-3 items-center   cursor-pointer hover:bg-slate-50 ">
//               <img src="/slack_icon.svg" alt="jira" width={25} height={25} />

//               <h4 onClick={handleSlackConnect} className="text-sm ">
//                 Add to <span className=" font-bold ">Slack</span>
//               </h4>
//             </div>
//             {/* <div className="self-baseline">
//             {jira && <IoCheckmarkCircle fill="#4bae4f" fontSize={20} />}
//           </div> */}
//           </div>
//         )}
//       </div>
//     </>
//   );
// };

// export default Slack;

import React, { useEffect, useRef, useState } from "react";
import SettingsTopbar from "../SettingsTopbar";
import {
  getIntegration,
  slackIntegration,
} from "../../../reduxStore/operations/integrationsAPI";
import { useDispatch, useSelector } from "react-redux";
import { ChartNetwork } from "react-flaticons";
import { apiConnector } from "../../../api/apiconnector";
import { integrationsEndPoints } from "../../../api/api";
import { toast } from "react-toastify";
import { Button } from "antd";

const { GET_SLACK_INTEGRATION } = integrationsEndPoints;

const Slack = () => {
  const dispatch = useDispatch();
  const { companyId } = useSelector((state) => state.auth);
  const [isSlackIntegrated, setIsSlackIntegrated] = useState(false);
  const [teamName, setTeamName] = useState("");
  const [teamId, setTeamId] = useState("");
  const [isLoading, setIsLoading] = useState(true); // Loading state

  useEffect(() => {
    const getSlackInt = async () => {
      try {
        const response = await apiConnector(
          "GET",
          GET_SLACK_INTEGRATION("slack"),
          null,
          null,
          { companyId }
        );
        console.log(response);
        if (response.status === 200 && response.data !== null) {
          setIsSlackIntegrated(response.data.data.isSlackConnected || false);
          setTeamName(response.data.data.team_name);
          setTeamId(response.data.data.team_id);
        }
      } catch (error) {
        console.error("Error fetching Slack integration:", error);
      } finally {
        setIsLoading(false); // Stop loading once request completes
      }
    };

    getSlackInt();
  }, []);

  const handleSlackDisconnect = async () => {
    setIsLoading(true); // Start loading

    try {
      const response = await apiConnector(
        "PUT",
        GET_SLACK_INTEGRATION("slack"),
        { team_id: teamId }, // Move the payload here
        null,
        null
      );

      if (response.status === 200 && response.data !== null) {
        setIsSlackIntegrated(!response.data.success);
        setTeamName("");
        setTeamId("");
        toast.success("Slack integration disconnected successfully");
      }
    } catch (error) {
      console.error("Error disconnecting Slack integration:", error);
    } finally {
      setIsLoading(false); // Stop loading after request completes
    }
  };

  const code = new URLSearchParams(window.location.search).get("code");
  const hasCalled = useRef(false);

  useEffect(() => {
    if (code && !hasCalled.current) {
      dispatch(slackIntegration(code));
      hasCalled.current = true;
    }
  }, [code]);

  const CLIENT_ID = import.meta.env.VITE_SLACK_CLIENT_ID;
  const REDIRECT_URI = import.meta.env.VITE_SLACK_REDIRECT_URI;
  const SCOPE =
    "app_mentions:read,channels:history,channels:read,chat:write,chat:write.customize,chat:write.public,commands,files:write,im:write,links:write,triggers:read,triggers:write,users.profile:read,users:read,users:read.email,groups:write";

  const slackAuthUrl = `https://slack.com/oauth/v2/authorize?client_id=${CLIENT_ID}&scope=${SCOPE}&user_scope=&redirect_uri=${REDIRECT_URI}`;

  const handleSlackConnect = () => {
    if (!isLoading) {
      window.location.href = slackAuthUrl;
    }
  };

  return (
    <div className="w-full flex justify-center  ">
      <div>
        <SettingsTopbar
          icon={<ChartNetwork />}
          title="Slack"
          description="Import customer requests and feedback to Lane"
          crumb={{ name: "Slack", path: "/settings/integrations/slack" }}
          isBack={true}
        />
        <div className=" flex gap-5">
          {isLoading ? (
            <p className="text-gray-500">Loading...</p>
          ) : isSlackIntegrated ? (
            <div className="  w-[40rem]">
              <h2 className="pb-4 text-title text-sm">
                This account is connected to the {teamName} Slack workspace.
              </h2>
              <Button
                onClick={handleSlackDisconnect}
                disabled={isLoading}
                type="primary"
                danger
                style={{
                  fontSize: "0.875rem", // equivalent to text-sm
                  // textDecoration: "underline",
                  transition: "all 0.3s",
                  cursor: isLoading ? "not-allowed" : "pointer",
                }}
              >
                {isLoading ? "Disconnecting..." : "Disconnect"}
              </Button>
              <h2 className="text-[16px] font-bold text-gray-900 mb-4 pt-20">
                How it works
              </h2>
              <ol className="list-decimal text-sm list-inside space-y-3  ">
                <div>
                  <h4 className="text-sm font-semibold text-gray-800 mb-0.5">
                    1. Authenticate Your Slack Connection:
                  </h4>
                  <p className="text-sm ">
                    Connect your Slack workspace to Lane by authenticating your
                    account, ensuring a secure integration.
                  </p>
                </div>
                <div>
                  <h4 className="text-sm font-semibold text-gray-800 mb-0.5">
                    2.Add the Lane App to Your Slack Channels:
                  </h4>
                  <p className="text-sm ">
                    Choose the Slack channels where you'd like to capture
                    feedback and add the Lane app to them.
                  </p>
                </div>
                <div>
                  <h4 className="text-sm font-semibold text-gray-800 mb-0.5">
                    3. Record Feedback Using the Slack Shortcut:
                  </h4>
                  <p className="text-sm ">
                    Use the Lane shortcut in Slack to create a new record
                    instantly. This action automatically adds a feedback record
                    in Lane, complete with a link to the original conversation.
                  </p>
                </div>
                <div>
                  <h4 className="text-sm font-semibold text-gray-800 mb-0.5">
                    4.Receive a Confirmation in Slack:
                  </h4>
                  <p className="text-sm ">
                    A confirmation message is posted in the Slack thread,
                    confirming that your feedback has been successfully recorded
                    in Lane.
                  </p>
                </div>
              </ol>
              <p className="text-sm text-wrap pt-4 ">
                This streamlined process lets you capture and track feedback
                effortlessly, keeping your workflow efficient and your data
                connected.
              </p>
            </div>
          ) : (
            <div>
              <h2 className="pb-4 text-primary text-sm">
                Add Lane to your Slack workspace
              </h2>
              <div
                className={`w-fit flex p-2 border border-gray-300 rounded justify-center gap-3 items-center cursor-pointer transition-all ${
                  isLoading
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-slate-50"
                }`}
                onClick={handleSlackConnect}
              >
                <img src="/slack_icon.svg" alt="slack" width={25} height={25} />
                <h4 className="text-sm">
                  Add to <span className="font-bold">Slack</span>
                </h4>
              </div>
              <div className=" w-[40rem] pb-10 ">
                <h2 className="text-[16px] font-bold text-gray-900 mb-4 pt-20">
                  How it works
                </h2>
                <ol className="list-decimal text-sm list-inside space-y-3  ">
                  <div>
                    <h4 className="text-sm font-semibold text-gray-800 mb-0.5">
                      1. Authenticate Your Slack Connection:
                    </h4>
                    <p className="text-sm ">
                      Connect your Slack workspace to Lane by authenticating
                      your account, ensuring a secure integration.
                    </p>
                  </div>
                  <div>
                    <h4 className="text-sm font-semibold text-gray-800 mb-0.5">
                      2.Add the Lane App to Your Slack Channels:
                    </h4>
                    <p className="text-sm ">
                      Choose the Slack channels where you'd like to capture
                      feedback and add the Lane app to them.
                    </p>
                  </div>
                  <div>
                    <h4 className="text-sm font-semibold text-gray-800 mb-0.5">
                      3. Record Feedback Using the Slack Shortcut:
                    </h4>
                    <p className="text-sm ">
                      Use the Lane shortcut in Slack to create a new record
                      instantly. This action automatically adds a feedback
                      record in Lane, complete with a link to the original
                      conversation.
                    </p>
                  </div>
                  <div>
                    <h4 className="text-sm font-semibold text-gray-800 mb-0.5">
                      4.Receive a Confirmation in Slack:
                    </h4>
                    <p className="text-sm ">
                      A confirmation message is posted in the Slack thread,
                      confirming that your feedback has been successfully
                      recorded in Lane.
                    </p>
                  </div>
                </ol>
                <p className="text-sm text-wrap pt-4 ">
                  This streamlined process lets you capture and track feedback
                  effortlessly, keeping your workflow efficient and your data
                  connected.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Slack;
