/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fieldIconMapping } from "utils";
import {
  setActiveIdeaId,
  setIdeaShowDetailViewDrawer,
  setDetailViewIdeas,
} from "reduxStore/slices/ideaSlice";
import KanbanModuleSection from "./KanbanModuleSection";
import {
  setActiveFeatureId,
  setFeatureShowDetailViewDrawer,
} from "reduxStore/slices/featureSlice";
import { setDetailViewFeatures } from "reduxStore/slices/featureSlice";
import DualKanbanModuleSection from "./DualKanbanModuleSection";

const KanbanIdeasFeaturesSection = ({ ideas, features }) => {
  const dispatch = useDispatch();
  const { ideas: allIdeas } = useSelector((state) => state.ideas);
  const { features: allFeatures } = useSelector((state) => state.features);

  const filteredIdeas = useMemo(() => {
    return (allIdeas ?? []).filter((idea) => (ideas ?? []).includes(idea._id));
  }, [ideas, allIdeas]);

  const filteredFeatures = useMemo(() => {
    return (allFeatures ?? []).filter((feature) =>
      (features ?? []).includes(feature._id)
    );
  }, [features, allFeatures]);

  const handleIdeaClick = (idea) => {
    dispatch(setActiveIdeaId(idea._id));
    dispatch(setIdeaShowDetailViewDrawer(true));
    dispatch(setDetailViewIdeas(filteredIdeas));
  };
  const handleFeatureClick = (feature) => {
    dispatch(setActiveFeatureId(feature._id));
    dispatch(setDetailViewFeatures(filteredFeatures));
    dispatch(setFeatureShowDetailViewDrawer(true));
  };


  return (
    <DualKanbanModuleSection
      ideas={filteredIdeas}
      features={filteredFeatures}
      icon={fieldIconMapping.idea}
      truncateLength={35}
    //   truncateLength={filteredIdeas?.length > 1 ? 28 : 40}
      iconColor="text-ideas-icon"
      textColor="text-title_50"
      onFeatureClick={handleFeatureClick}
      onIdeaClick={handleIdeaClick}
    />
  );
};

export default KanbanIdeasFeaturesSection;
