/* eslint-disable react/prop-types */
import React from "react";
import { useLocation } from "react-router-dom";
import { getActiveIconColor } from "utils";

const IconWrapper = ({
  text = "Roadmaps",
  icon,
  size = 16,
  iconColor = "",
  isASidebarComponent = false,
  isIconActive,
}) => {
  const location = useLocation();
  const { pathname } = location;
  if (!icon || !icon.props) {
    return <></>; // Return an empty fragment if icon is null
  }
  const tailwindColorClass =
    iconColor || (isASidebarComponent && getActiveIconColor(pathname, text));

  return React.cloneElement(icon, {
    size,
    className: `  ${icon.props.className || ""} ${
      isIconActive ? "text-sidebar-icon-active" : "  "
    } ${tailwindColorClass}`.trim(),
    style: { color: tailwindColorClass },
  });
};

export default IconWrapper;
