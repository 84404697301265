import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";

import PrivateRoute from "./components/common/PrivateRoute";

// import Feedback from "./pages/Feedback";
import Tasks from "./pages/Tasks";
import Settings from "./pages/Settings";
import Profile from "./pages/Profile";
import Features from "./pages/features/Features";
import FeaturesDetailView from "./pages/features/FeaturesDetailView";
import Ideas from "./pages/ideas/Ideas";
import IdeasDetailView from "./pages/ideas/IdeaDetailView";
import Objectives from "./pages/objectives/Objectives";
import Roadmaps from "./pages/roadmaps/Roadmaps";
import RoadmapsDetailView from "./pages/roadmaps/RoadmapsDetailView";

import SignIn from "./components/auth/SignIn";
import SignUp from "./components/auth/SignUp";
import SettingsCustomizations from "./components/settings/customizations/SettingsCustomizations";
import StatusField from "./components/settings/customizations/statusField/StatusField";
import ProductField from "./components/settings/customizations/ProductField";
import TagsField from "./components/settings/customizations/TagsField";
import ReleaseField from "./components/settings/customizations/ReleaseField";
import TimeFramesField from "./components/settings/customizations/TimeFramesField";
import Accounts from "./components/settings/customers/Accounts";
import SettingsCustomers from "./components/settings/customers/SettingsCustomers";

import { fetchCompanyData } from "./reduxStore/operations/companyAPI";
import {
  getFavoriteViews,
  getPersonalViews,
} from "./reduxStore/operations/viewsAPI";
import {
  fetchInvitedUsers,
  fetchUserDetails,
  fetchUsersByCompanyId,
} from "./reduxStore/operations/userAPI";
import { getAllFeatures } from "./reduxStore/operations/featuresAPI";
import { getAllObjectives } from "./reduxStore/operations/objectivesAPI";
import { getAllIdeas } from "./reduxStore/operations/ideasAPI";
import { getAllRoadmaps } from "./reduxStore/operations/roadmapsAPI";
import { getReleaseRoadmaps } from "./reduxStore/operations/releaseAPI";
import { getTimeFrameRoadmaps } from "./reduxStore/operations/timeFramesAPI";

import { getCookie, getUserIdFromToken } from "./utils/cookieUtils";
import { initClarity } from "./clarity";
import { initializePendo, trackEvent } from "./pendo";
import { loadUsetifulScript, setUsetifulTags } from "usetiful-sdk";

import { getAllKeyResults } from "./reduxStore/operations/keyResultsAPI";
import Insights from "./pages/insights/Insights";
import InsightsTab from "./components/insights/InsightsTab";
import Feedback from "./components/insights/Feedback";
import Billing from "./components/settings/Billing";
import Integrations from "./components/settings/integrations";
import IntegrationDetails from "./components/settings/integrations/IntegrationDetails";
import User from "./components/settings/customers/User";
import ChangePassword from "./components/settings/customers/Change-password";
import ForgotPassword from "./components/auth/ForgotPassword";
import Register from "./components/auth/Register";
import CompanyRegister from "./components/auth/CompanyRegister";
import { loadBrevoSDK } from "./brevo";
import Invite from "./components/auth/Invite";
import SignUpWithGoogle from "./components/auth/SignUpWithGoogle";
import Users from "./components/settings/Users";
import Default from "./components/settings/Users/Default";
import InvitedUsers from "./components/settings/Users/InvitedUsers";
import Slack from "./components/settings/integrations/Slack";
import { getIntegration } from "./reduxStore/operations/integrationsAPI";
import { fetchApiKeys } from "./reduxStore/operations/apiKeysApi";
import ApiKeys from "./components/settings/apiKeys";
import Zapier from "./components/settings/integrations/Zapier";
import Features2 from "./pages/features2/features2";

const App = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { companyId, token } = useSelector((state) => state.auth);
  const { releases, timeFrames } = useSelector((state) => state.company);
  const userId = getUserIdFromToken(token);

  const jwt = localStorage.getItem("jwt");

  useEffect(() => {
    const fetchData = async (retryCount = 0) => {
      if (companyId && token && jwt) {
        try {
          // Load objectives first
          await dispatch(getAllFeatures());
          // await dispatch(getAllObjectives());

          // Load the other data after objectives are fetched
          await Promise.all([
            dispatch(fetchCompanyData(companyId)),
            dispatch(fetchUsersByCompanyId(companyId)),
            dispatch(getPersonalViews()),
            dispatch(getFavoriteViews()),
            dispatch(getAllObjectives()),
            dispatch(fetchInvitedUsers()),
            // dispatch(getAllFeatures()),
            dispatch(getAllIdeas()),
            dispatch(getAllRoadmaps()),
            dispatch(getAllKeyResults()),
            dispatch(fetchApiKeys()),
          ]);
        } catch (error) {
          console.error(`Attempt ${retryCount + 1} failed:`, error);

          if (retryCount < 3) {
            // Retry up to 3 times
            setTimeout(() => fetchData(retryCount + 1), 1000); // Retry after 1 seconds
          }
        }
      }
    };

    fetchData();
  }, [dispatch, companyId, token, jwt]);

  useEffect(() => {
    if (companyId && releases) {
      const releaseIds = (releases ?? []).map((release) => release._id);
      dispatch(getReleaseRoadmaps(releaseIds));
    }
  }, [releases, companyId, dispatch]);

  useEffect(() => {
    if (companyId && timeFrames) {
      const timeFrameIds = (timeFrames ?? []).map((timeFrame) => timeFrame._id);
      dispatch(getTimeFrameRoadmaps(timeFrameIds));
    }
  }, [timeFrames, companyId, dispatch]);

  useEffect(() => {
    initClarity();
    loadBrevoSDK();
  }, []);

  useEffect(() => {
    if (user) {
      const userInfo = {
        visitor: {
          id: user._id,
          email: user.email,
          full_name: user.name,
        },
        account: {
          id: user.company._id,
          name: user.company.company_name,
        },
      };
      if (window.FreshworksWidget) {
        // Call the FreshworksWidget to identify the user and open the ticket form
        window.FreshworksWidget(
          "identify", // Identify action
          "ticketForm", // Open the ticket form
          {
            name: user.name, // User's name
            email: user.email, // User's email
          },
          {
            formId: 1234, // Your ticket form ID
          }
        );
      }
      loadUsetifulScript("ec856d16157e3a05982fe799c0a04ec5"); // This contains your unique token key.
      setUsetifulTags({
        userId: user._id, // This identifies the user and should be unique. Change it.
        firstName: user.name, // This property defines user’s first name. Change it.
        lastName: "", // This property defines user’s last name. Change it.
      });

      initializePendo(userInfo);

      trackEvent("page_view", { page: "home" });
    }
  }, [user]);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserDetails(userId));
    }
  }, [userId, dispatch]);
  return (
    <Routes>
      <Route element={<PrivateRoute />}>
        <Route path="/objectives" element={<Objectives />} />
        <Route path="/roadmaps" element={<Roadmaps />} />
        <Route path="/roadmaps/:roadmapId" element={<RoadmapsDetailView />} />
        {/* <Route path="/feedback" element={<Feedback />} /> */}
        <Route path="/ideas" element={<Ideas />} />
        <Route path="/insights" element={<Insights />}>
          <Route index element={<InsightsTab />} />
          <Route path="feedback" element={<Feedback />} />
        </Route>
        <Route path="/ideas/:ideaId" element={<IdeasDetailView />} />
        <Route path="/" element={<Navigate to="/objectives" />} />
        <Route path="/features" element={<Features />} />
        <Route path="/features2" element={<Features2 />} />
        <Route path="/features/:featureId" element={<FeaturesDetailView />} />
        <Route path="/tasks" element={<Tasks />} />
        <Route path="/settings" element={<Settings />}>
          <Route path="profile" element={<Profile />}>
            <Route index element={<User />} />
            <Route path="security" element={<ChangePassword />} />
          </Route>
          <Route path="customizations" element={<SettingsCustomizations />}>
            <Route index element={<StatusField />} />
            <Route path="release" element={<ReleaseField />} />
            <Route path="product" element={<ProductField />} />
            <Route path="tags" element={<TagsField />} />
            <Route path="time-frame" element={<TimeFramesField />} />
          </Route>
          <Route path="customers" element={<SettingsCustomers />}>
            <Route index element={<Accounts />} />
          </Route>
          <Route path="plan" element={<Billing />} />
          <Route path="members" element={<Users />}>
            <Route index element={<Default />} />
            <Route path="invited-users" element={<InvitedUsers />} />
          </Route>
          <Route path="integrations" element={<Integrations />} />
          <Route path="integrations/slack" element={<Slack />} />
          <Route path="integrations/zapier" element={<Zapier />} />
          <Route path="integrations/:name" element={<IntegrationDetails />} />
          <Route path="api" element={<ApiKeys />} />
        </Route>
      </Route>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<Register />} />
      <Route path="/company-register" element={<CompanyRegister />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/invite" element={<Invite />} />
      <Route path="/google-register" element={<SignUpWithGoogle />} />
    </Routes>
  );
};

export default App;
