/* eslint-disable react/prop-types */
import React, { useState } from "react";
import LinkIdeasDetailViewTab from "components/common/detailView/LinkIdeasDetailViewTab";
import LinkFeaturesDetailViewTab from "components/common/detailView/LinkFeaturesDetailViewTab";
import { BsFilter } from "react-icons/bs";
import DropdownMenu from "./DropdownMenu";
import LinkFeatureIdeaDetailViewTab from "../LinkFeatureIdeaDetailViewTab";

const InitiativeDropdown = ({
  item,
  selectedIdeas,
  handleIdeaSelect,
  removeSelectedIdeas,
  selectedFeatures,
  handleFeatureSelect,
  removeSelectedFeatures,
  updateIdeas,
  updateFeatures,
  setMainIsDropdownOpen,
}) => {
  const [isIdeaToBeAdded, setIsIdeaToBeAdded] = useState(true);
  const [isFeatureToBeAdded, setIsFeatureToBeAdded] = useState(true);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(
    new Set(["feature", "idea", "feedback"])
  );

  const FilterOption = [
    { _id: "feature", label: "Features" },
    { _id: "idea", label: "Ideas" },
    { _id: "feedback", label: "Feedback" },
  ];

  const handleFilterToggle = (option) => {
    setSelectedFilter((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(option._id)) {
        newSet.delete(option._id);
      } else {
        newSet.add(option._id);
      }
      return newSet;
    });
  };

  return (
    <div className="absolute flex items-center justify-center gap-2   bg-white rounded shadow border p-2 w-full top-full mt-1 z-20">
      {/* {isIdeaToBeAdded && (
        <div className=" w-full ">
          <LinkIdeasDetailViewTab
            currentItem={item}
            selectedItems={selectedIdeas}
            handleItemSelect={handleIdeaSelect}
            clearSelectedItems={removeSelectedIdeas}
            with_linked_item_list={false}
            updateLinkedItems={(ideas) => updateIdeas(ideas)}
            // with_different_tabs={[
            //   {
            //     label: "Features",
            //     active: isFeatureToBeAdded,
            //     onClick: () => {
            //       setIsIdeaToBeAdded(false);
            //       setIsFeatureToBeAdded(true);
            //     },
            //   },
            //   {
            //     label: "Ideas",
            //     active: isIdeaToBeAdded,
            //     onClick: () => {
            //       setIsIdeaToBeAdded(true);
            //       setIsFeatureToBeAdded(false);
            //     },
            //   },
            // ]}
          />
        </div>
      )}
      {isFeatureToBeAdded && (
        <div className=" w-full ">
          <LinkFeaturesDetailViewTab
            currentItem={item}
            selectedItems={selectedFeatures}
            handleItemSelect={handleFeatureSelect}
            clearSelectedItems={removeSelectedFeatures}
            with_linked_item_list={false}
            updateLinkedItems={(features) => updateFeatures(features)}
            // with_different_tabs={[
            //   {
            //     label: "Features",
            //     active: isFeatureToBeAdded,
            //     onClick: () => {
            //       setIsIdeaToBeAdded(false);
            //       setIsFeatureToBeAdded(true);
            //     },
            //   },
            //   {
            //     label: "Ideas",
            //     active: isIdeaToBeAdded,
            //     onClick: () => {
            //       setIsIdeaToBeAdded(true);
            //       setIsFeatureToBeAdded(false);
            //     },
            //   },
            // ]}
          />
        </div>
      )} */}
      <LinkFeatureIdeaDetailViewTab
        selectedFilter={selectedFilter}
        currentItem={item}
        selectedItems={selectedFeatures}
        handleItemSelect={handleFeatureSelect}
        clearSelectedItems={removeSelectedFeatures}
        with_linked_item_list={false}
        setMainIsDropdownOpen={setMainIsDropdownOpen}
        updateLinkedItems={(items) => {
          updateIdeas(items.ideas);
          updateFeatures(items.features);
          setMainIsDropdownOpen(false);
        }}
      />

      <BsFilter
        className={`rounded p-1   absolute top-[10px] right-[3px]    hover:bg-button-hover ${
          isDropdownOpen ? " bg-button-active_10 " : ""
        }  `}
        size={32}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      />
      <div className="  text-sm ">
        {isDropdownOpen && (
          <DropdownMenu
            options={FilterOption ?? []}
            isOpen={true}
            position={`top-full w-32 right-0 `}
            handleOptionClick={(option) => {
              handleFilterToggle(option);
            }}
            selectedItems={selectedFilter}
            multiSelect={true}
            type="type"
          />
        )}
      </div>
    </div>
  );
};

export default InitiativeDropdown;
