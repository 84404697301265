import {
  appendTag,
  updateTag,
  removeTag,
  setTags,
} from "../slices/companySlice";
import { apiConnector } from "api/apiconnector";
import { tagEndPoints } from "api/api";
import { setFeatures } from "../slices/featureSlice";
import { setObjectives } from "../slices/objectiveSlice";
import { setRoadmaps } from "../slices/roadmapsSlice";
import { setIdeas } from "../slices/ideaSlice";
import { setKeyResults } from "../slices/keyResultSlice";
import { getAllKeyResults } from "./keyResultsAPI";
import { getAllRoadmaps } from "./roadmapsAPI";
import { getAllIdeas } from "./ideasAPI";
import { getAllFeatures } from "./featuresAPI";
import { getPersonalViews } from "./viewsAPI";
import { getAllObjectives } from "./objectivesAPI";
const {
  ADD_COMPANY_TAG,
  DELETE_COMPANY_TAG,
  EDIT_COMPANY_TAG,
  GET_COMPANY_TAGS,
} = tagEndPoints;

export const getCompanyTags = () => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("GET", GET_COMPANY_TAGS());
      if (response.status === 200) {
        dispatch(setTags(response.data.tags.reverse()));
      }
    } catch (error) {
      console.error("Error getting tags", error);
    }
  };
};

export const addTagToCompany = (label) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("POST", ADD_COMPANY_TAG(), {
        label,
      });
      if (response.status === 201) {
        console.log(response.data.tag);
        dispatch(appendTag(response.data.tag));
      } else {
        console.error("Failed to add tag:", response.statusText);
      }
    } catch (error) {
      console.error("Error adding tag:", error);
    }
  };
};

export const editTagInCompany = (tagId, label) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("PUT", EDIT_COMPANY_TAG(tagId), {
        label,
      });
      if (response.status === 200) {
        // dispatch(setObjectives(null));
        // dispatch(setRoadmaps(null));
        // dispatch(setFeatures(null));
        // dispatch(setIdeas(null));
        // dispatch(setKeyResults(null));
        dispatch(updateTag(response.data.tag));
        dispatch(getAllObjectives());
        dispatch(getAllFeatures()),
        dispatch(getAllIdeas()),
        dispatch(getAllRoadmaps()),
        dispatch(getAllKeyResults());
      } else {
        console.error("Failed to delete tag:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };
};

export const deleteTagOfCompany = (tagId) => {
  return async (dispatch) => {
    try {
      const response = await apiConnector("DELETE", DELETE_COMPANY_TAG(tagId));
      if (response.status === 200) {
        // dispatch(setObjectives(null));
        // dispatch(setRoadmaps(null));
        // dispatch(setFeatures(null));
        // dispatch(setIdeas(null));
        // dispatch(setKeyResults(null));
        dispatch(removeTag(tagId));
        dispatch(getAllObjectives());
        dispatch(getPersonalViews()),
        dispatch(getAllFeatures()),
        dispatch(getAllIdeas()),
        dispatch(getAllRoadmaps()),
        dispatch(getAllKeyResults());
      } else {
        console.error("Failed to delete tag:", response.statusText);
      }
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };
};
