/* eslint-disable react/prop-types */
import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import IconText from "components/common/IconText";
import DropdownMenu from "components/common/detailView/dropdown/DropdownMenu";
import StatusRowSection from "components/common/classicList/StatusRowSection";
import ProductRowSection from "components/common/classicList/ProductRowSection";
import TagsRowSection from "components/common/classicList/TagsRowSection";
import AssigneeRowSection from "components/common/classicList/AssigneeRowSection";
import ReleaseRowSection from "components/common/classicList/ReleaseRowSection";
import ModuleRowSection from "components/common/classicList/ModuleRowSection";
import TagsSearchDropdown from "components/common/detailView/dropdown/TagsSearchDropdown";

import { toSnakeCase, fieldIconMapping } from "utils";
import { generateStatusArray } from "utils/status";

import "./FeaturesRowStyle.css";

import {
  setActiveFeatureId,
  setFeatureShowDetailViewDrawer,
} from "reduxStore/slices/featureSlice";

import {
  updateFeatureData,
  updateFeatureTags,
} from "reduxStore/operations/featuresAPI";

import useRowOutsideClick from "hooks/useRowOutsideClick";
import { getReleaseRoadmaps } from "reduxStore/operations/releaseAPI";

// Main Component
const FeaturesRowComponent = ({
  feature,
  onSelect,
  isSelected,
  selectedColumns,
  pl = "pl-0",
  py = "py-2",
  textSize = "text-sm",
  withCheckboxOption = true,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { releases, products, tags, statuses } = useSelector(
    (state) => state.company
  );
  const { showDetailViewDrawer } = useSelector((state) => state.features);
  const { roadmaps } = useSelector((state) => state.roadmaps);
  const { keyResults } = useSelector((state) => state.keyResults);
  const { objectives } = useSelector((state) => state.objectives);
  const { ideas } = useSelector((state) => state.ideas);
  const { users } = useSelector((state) => state.users);

  const featureRoadmaps = useMemo(() => {
    return (roadmaps ?? []).filter((roadmap) =>
      (feature?.roadmaps ?? []).includes(roadmap._id)
    );
  }, [feature?.roadmaps, roadmaps]);

  const featureIdeas = useMemo(() => {
    return (ideas ?? []).filter((idea) =>
      (feature?.ideas ?? []).includes(idea._id)
    );
  }, [feature?.ideas, ideas]);

  const featureKeyResults = useMemo(() => {
    return (keyResults ?? []).filter((keyResult) =>
      (feature?.keyResults ?? []).includes(keyResult._id)
    );
  }, [feature?.keyResults, keyResults]);

  const featureObjectives = useMemo(() => {
    return (objectives ?? []).filter((objective) =>
      (feature?.objectives ?? []).includes(objective._id)
    );
  }, [feature?.objectives, objectives]);

  const [isHovered, setIsHovered] = useState(false);
  const [featureStatuses, setFeatureStatuses] = useState([]);
  const [dropdownState, setDropdownState] = useState({
    isStatusDropdownOpen: false,
    isReleaseDropdownOpen: false,
    isProductDropdownOpen: false,
    isAssigneeDropdownOpen: false,
    isTagDropdownOpen: false,
  });
  const dropdownRef = useRef(null);

  const [selectedTags, setSelectedTags] = useState(
    new Set(feature?.tags?.map((tag) => tag._id))
  );
  const [selectTags, setSelectTags] = useState(feature?.tags ?? []);
  const [initialSelectedTags, setInitialSelectedTags] = useState(
    new Set((feature?.tags ?? []).map((tag) => tag._id))
  );

  useEffect(() => {
    setSelectTags(feature?.tags ?? []);
    setSelectedTags(new Set(feature?.tags?.map((tag) => tag._id)));
    setInitialSelectedTags(
      new Set((feature?.tags ?? []).map((tag) => tag._id))
    );
  }, [feature]);

  // Update feature statuses when statuses change
  useEffect(() => {
    setFeatureStatuses(statuses.feature);
  }, [statuses]);

  // Generate status array based on feature statuses
  const statusArray = useMemo(
    () => generateStatusArray(featureStatuses),
    [featureStatuses]
  );

  // Close all dropdowns
  const closeAllDropdowns = () => {
    setDropdownState({
      isStatusDropdownOpen: false,
      isReleaseDropdownOpen: false,
      isProductDropdownOpen: false,
      isAssigneeDropdownOpen: false,
      isTagDropdownOpen: false,
    });
  };

  // Handle row click to toggle detail view drawer
  const handleRowClick = () => {
    dispatch(setActiveFeatureId(feature._id));
    dispatch(setFeatureShowDetailViewDrawer(!showDetailViewDrawer));
  };

  // Handle option clicks for different dropdowns
  const handleStatusOptionClick = (option) => {

    console.log(option);
    if (feature?.status?._id !== option._id) {
      let { icon, ...restOption } = option;
      dispatch(
        updateFeatureData(
          feature._id,
          { status: option._id },
          "status",
          feature,
          restOption
        )
      );
    }
    closeAllDropdowns();
  };

  const handleProductOptionClick = (option) => {
    
    if (feature?.product?._id !== option._id) {
      let { icon, ...restOption } = option;
      dispatch(
        updateFeatureData(
          feature._id,
          { product: option._id },
          "product",
          feature,
          restOption
        )
      );
    }
    closeAllDropdowns();
  };
  
  const handleAssineeOptionClick = (option) => {
    if (feature?.assignee?._id !== option._id) {
      let { icon, ...restOption } = option;
      dispatch(
        updateFeatureData(
          feature._id,
          { assignee: option._id },
          "assignee",
          feature,
          restOption
        )
      );
    }
    closeAllDropdowns();
  };

  const handleTagDropdownOptionClick = (option) => {
    const tagId = option._id;
    const updatedTags = new Set(selectedTags);

    if (updatedTags.has(tagId)) {
      updatedTags.delete(tagId);
      setSelectTags((prevTags) => prevTags.filter((tag) => tag._id !== tagId));
    } else {
      updatedTags.add(tagId);
      setSelectTags((prevTags) => [...prevTags, option]);
    }

    setSelectedTags(updatedTags);
  };
  const getSelectedTagObjects = () => {
    return Array.from(selectedTags).map((id) =>
      selectTags.find((tag) => tag._id === id)
    );
  };
  const handleTagsOptionClick = (option) => {
    let fullTags = getSelectedTagObjects();
    dispatch(updateFeatureTags(option, fullTags, feature));
    closeAllDropdowns();
  };

  const handleReleaseOptionClick = (option) => {
    if (feature?.release?._id !== option._id) {
      let { icon, ...restOption } = option;
      dispatch(
        updateFeatureData(
          feature._id,
          { release: option._id },
          "release",
          feature,
          restOption
        )
      );
    }
    closeAllDropdowns();
  };

  // Toggle specific dropdown while closing others
  const toggleDropdown = useCallback((type) => {
    setDropdownState((prev) => {
      const newState = Object.keys(prev).reduce((acc, key) => {
        acc[key] = key === type ? !prev[key] : false;
        return acc;
      }, {});
      return newState;
    });
  }, []);

  useRowOutsideClick({
    item: feature,
    selectedTags,
    initialSelectedTags,
    tagDropdownState: dropdownState.isTagDropdownOpen,
    dropdownRef,
    handleTagsOptionClick,
    getSelectedTagObjects,
    closeAllDropdowns,
  });

  return (
    <tr
      className={`group text-xs text-title_50 cursor-pointer hover:bg-row-background relative ${
        isSelected ? "bg-button-active_10" : ""
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleRowClick}
      ref={dropdownRef}
    >
      {/* Checkbox and Feature Title */}
      <td
        className={`py-1 ${pl} relative sticky top-0 left-0 z-20 group-hover:bg-row-background group-hover:rounded-l-lg ${
          isSelected ? "bg-button-active_10" : "bg-white"
        }`}
      >
        <div className="flex">
          {withCheckboxOption && (
            <div
              className={`flex p-1 items-center ${
                !isSelected ? (isHovered ? "visible" : "invisible") : "visible"
              }`}
            >
              <input
                type="checkbox"
                checked={isSelected}
                onChange={() => onSelect(feature)}
                onClick={(e) => e.stopPropagation()}
                className="cursor-pointer"
              />
            </div>
          )}
          <IconText
            icon={React.createElement(fieldIconMapping.feature)}
            iconSize={14}
            text={feature?.title ?? ""}
            truncateLength={pl !== "pl-0" ? 70 : 80}
            textColor="text-title"
            textSize={textSize}
            isASidebarComponent={false}
            hoverable={false}
            iconColor="text-features-icon"
            px=""
            py={py}
          />
        </div>
      </td>

      {/* Dynamic Columns */}
      {selectedColumns?.map((key, index) => {
        const snakeCaseKey = toSnakeCase(key);

        return (
          <td
            key={index}
            className={`${
              key === "tags" ||
              key === "roadmap" ||
              key === "objective" ||
              key === "key_result" ||
              key === "idea"
                ? "min-w-44 max-w-66"
                : "max-w-44"
            } truncate pr-1 px-3`}
          >
            {/* Render content based on column type */}
            {(() => {
              switch (snakeCaseKey) {
                case "status":
                  return (
                    <StatusRowSection
                      status={feature?.[snakeCaseKey] ?? ""}
                      dropdownState={dropdownState}
                      toggleDropdown={() =>
                        toggleDropdown("isStatusDropdownOpen")
                      }
                      statusArray={statusArray}
                    />
                  );

                case "release":
                  return (
                    <ReleaseRowSection
                      release={feature?.[snakeCaseKey]?.label ?? "No Release"}
                      dropdownState={dropdownState}
                      toggleDropdown={() =>
                        toggleDropdown("isReleaseDropdownOpen")
                      }
                    />
                  );

                case "score":
                  switch (feature?.score?.type) {
                    case "Value/Effort":
                      return (
                        feature?.score?.value_effort_score?.toFixed(1) ?? "0.0"
                      );
                    case "RICE":
                      return feature?.score?.rice_score?.toFixed(1) ?? "0.0";
                    case "Impact/Effort":
                      return (
                        feature?.score?.impact_effort_score?.toFixed(1) ?? "0.0"
                      );
                    default:
                      return "";
                  }

                case "product":
                  return (
                    <ProductRowSection
                      item={feature?.[snakeCaseKey] ?? ""}
                      dropdownState={dropdownState}
                      toggleDropdown={() =>
                        toggleDropdown("isProductDropdownOpen")
                      }
                      products={products ?? []}
                    />
                  );

                case "tags":
                  return (
                    <TagsRowSection
                      tags={selectTags ?? []}
                      dropdownState={dropdownState}
                      toggleDropdown={() => toggleDropdown("isTagDropdownOpen")}
                    />
                  );

                case "assignee":
                  return (
                    <AssigneeRowSection
                      name={feature?.[snakeCaseKey]?.name ?? ""}
                      dropdownState={dropdownState}
                      toggleDropdown={() =>
                        toggleDropdown("isAssigneeDropdownOpen")
                      }
                    />
                  );

                case "objective":
                  return (
                    <ModuleRowSection
                      moduleItems={featureObjectives ?? []}
                      icon={React.createElement(fieldIconMapping.objective)}
                      iconColor="text-objectives-icon"
                    />
                  );
                case "roadmap":
                  return (
                    <ModuleRowSection
                      moduleItems={featureRoadmaps ?? []}
                      icon={React.createElement(fieldIconMapping.roadmap)}
                      iconColor="text-roadmaps-icon"
                    />
                  );
                case "idea":
                  return (
                    <ModuleRowSection
                      moduleItems={featureIdeas ?? []}
                      icon={React.createElement(fieldIconMapping.idea)}
                      iconColor="text-ideas-icon"
                    />
                  );
                case "key_result":
                  return (
                    <ModuleRowSection
                      moduleItems={featureKeyResults ?? []}
                      icon={React.createElement(fieldIconMapping.key_results)}
                      iconColor="text-key_results-icon"
                    />
                  );
                default:
                  return feature?.[snakeCaseKey] ?? "";
              }
            })()}

            {/* Dropdown Menus */}
            {snakeCaseKey === "status" && (
              <DropdownMenu
                options={statusArray || []}
                isOpen={dropdownState.isStatusDropdownOpen}
                position="top-full -mt-3 w-32"
                handleOptionClick={handleStatusOptionClick}
                item={feature}
                type="status"
              />
            )}

            {snakeCaseKey === "release" && (
              <DropdownMenu
                options={[{ _id: null, label: "No Release" }]
                  .concat(releases ?? [])
                  .map((option) => ({
                    ...option,
                    icon: fieldIconMapping["release"],
                  }))}
                isOpen={dropdownState.isReleaseDropdownOpen}
                position="top-full -mt-3 w-32"
                handleOptionClick={handleReleaseOptionClick}
                withAddOption={true}
                handleAddOptionClick={() =>
                  navigate("/settings/customizations/release")
                }
                item={feature}
                type="release"
              />
            )}

            {snakeCaseKey === "product" && (
              <DropdownMenu
                options={products ?? []}
                isOpen={dropdownState.isProductDropdownOpen}
                position="top-full -mt-3 w-32"
                handleOptionClick={handleProductOptionClick}
                withAddOption={true}
                handleAddOptionClick={() =>
                  navigate("/settings/customizations/product")
                }
                item={feature}
                type="product"
              />
            )}
            {snakeCaseKey === "assignee" && (
              <DropdownMenu
                options={users ?? []}
                isOpen={dropdownState.isAssigneeDropdownOpen}
                position="top-full -mt-3 w-32"
                handleOptionClick={handleAssineeOptionClick}
                withAddOption={true}
                handleAddOptionClick={() =>
                  navigate("/settings/customizations/product")
                }
                item={feature}
                type="assignee"
              />
            )}

            {snakeCaseKey === "tags" && (
              <div className="absolute w-48 z-20 bg-white -ml-8">
                <TagsSearchDropdown
                  searchPlaceholder={"Search Tags..."}
                  items={tags ?? []}
                  selectedItems={selectedTags}
                  isDropdownOpen={dropdownState.isTagDropdownOpen}
                  onItemSelect={handleTagDropdownOptionClick}
                />
              </div>
            )}
          </td>
        );
      })}
    </tr>
  );
};

export default FeaturesRowComponent;
